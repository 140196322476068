import { Slide, toast } from 'react-toastify'

const customToast = {
  success(msg, options = {}) {
    return toast.success(msg, {
      autoClose: 2000,
      closeOnClick: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
      ...options,
    })
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      autoClose: false,
      closeOnClick: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
      ...options,
    })
  },
  info(msg, options = {}) {
    return toast.info(msg, {
      autoClose: 2000,
      closeOnClick: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
      ...options,
    })
  },
  clearQueue() {
    return toast.clearWaitingQueue()
  },
  clearToastById(id) {
    return toast.dismiss(id)
  },
}

export default customToast
