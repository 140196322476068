import React from 'react'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'

export default function Hamburger({ visible, onClick }) {
  return (
    <HamburgerContainer onClick={() => onClick(!visible)}>
      <HamburgerBtn>
        <Bread visible={visible} className="bread__top" />
        <Bread visible={visible} className="bread__medium" />
        <Bread visible={visible} className="bread__bottom" />
      </HamburgerBtn>
      {visible ? <TranslatedText i18nKey="CLOSE" /> : <TranslatedText i18nKey="MENU" />}
    </HamburgerContainer>
  )
}
const HamburgerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontWeight-bold);
  cursor: pointer;
  color: var(--color-primary);
  text-transform: uppercase;
  @media (min-width: 1220px) {
    display: none;
  }
`

const Bread = styled.i`
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0.5px;
  position: absolute;
  background: var(--color-primary);
  transition: transform 0.1096s cubic-bezier(0.52, 0.16, 0.52, 0.84);
  :focus {
    background: var(--color-primary);
  }
  &.bread__top {
    top: 5px;
    transform: ${(p) => (p.visible ? 'translate(0px, 8.5px) rotate(45deg)' : 'rotate(0deg)')};
  }
  &.bread__medium {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(p) => (p.visible ? 'none' : 'block')};
  }
  &.bread__bottom {
    bottom: 5px;
    transform: ${(p) => (p.visible ? 'translate(0px, -8.5px) rotate(-45deg)' : 'rotate(0deg)')};
  }
`
const HamburgerBtn = styled.button`
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: 0;
  background-color: transparent;
  outline: 0;
  padding: 0;
  margin-right: 10px;

  &:hover {
    ${Bread} {
      background: var(--color-primary);
    }
  }
`
