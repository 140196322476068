import React from "react"

const Leasing = () => (
  <svg 
    width="43" 
    height="36" 
    fill="none" 
  >
    <path 
      d="m40.578 22.54-14.34 7.222-1.258-.383 1.04-2.52c.902-2.039-.02-4.418-2.06-5.3L12.22 16.44A5.825 5.825 0 0 0 10 16c-.578 0-1.14.102-1.66.262L0 18.219V32.62L8 30l16.32 5.7c.52.198 1.078.28 1.621.28.797 0 1.598-.18 2.34-.582l14.64-7.84-2.343-5.019Z" fill="#467EFF"/><path d="M32 11.64c2.32-.84 4-3.038 4-5.64 0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2.602 1.68 4.8 4 5.64V20h8v-4h-4v-4.36ZM30 8c-1.102 0-2-.898-2-2 0-1.102.898-2 2-2 1.102 0 2 .898 2 2 0 1.102-.898 2-2 2Z" fill="#467EFF"/>
  </svg>
)

export default Leasing