import React, { useMemo } from 'react'
import { Label, Layout } from '@staccx/bento'
import { Heading } from '../../../components/text/Headings'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import EnvironmentReportTable from '../components/EnvironmentReportTable'
import { formatNumber } from '../../../utils/tableFuncs'
import Table from '../../../components/tables/Table'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useUserContext } from '../../../context/UserProvider'
import { useQuery } from 'react-query'
import { fetchEnvironmentReport } from '../../../api/api'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import StatsTable from '../components/StatsTable'

const EnvironmentReport = () => {
  const { chosenCompany: company, environmentReportYear, handleEnvironmentReportYear } = useUserContext()
  const companyId = company && company.id

  const { data, status } = useQuery(['environmentReport', companyId], () => fetchEnvironmentReport(companyId), {
    enabled: !!companyId,
    onSuccess: (data) => data.forEach((doc) => (doc.litersPerTenKm = (doc.volume / doc.totalKm) * 10))
  })

  const environmentReport = useMemo(() => {
    if (!data) {
      return []
    }
    return data.filter((report) => report.year.toString() === environmentReportYear)
  }, [environmentReportYear, data])

  const environmentReportYears = useMemo(() => {
    if (!data) {
      return []
    }
    return [...new Set(data.map((row) => row.year))].sort((a, b) => b - a)
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const filteredReport = environmentReport.filter(agreementFilter('consolidatedInvoiceAgreement'))

  const averageCo2PerCar =
    filteredReport.length > 0
      ? filteredReport.reduce((total, current) => total + current.co2Emission, 0) / filteredReport.length
      : 0

  const averageNoxPerCar =
    filteredReport.length > 0
      ? filteredReport.reduce((total, current) => total + current.nOXEmission, 0) / filteredReport.length
      : 0

  const statsData = [{ averageCo2PerCar, averageNoxPerCar }]

  const items = environmentReportYears.map((env) => ({ value: env, label: env }))
  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'ENVIRONMENTREPORT_HEADING'}>
          {(value) => <Heading>{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  value={{ value: environmentReportYear, label: environmentReportYear }}
                  options={items}
                  onChange={(selectedItem) => handleEnvironmentReportYear(selectedItem.value)}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <Layout rowGap={'medium'}>
        <EnvironmentReportTable isLoading={status === 'loading'} data={filteredReport} />
        <StatsTable data={statsData} />
      </Layout>
    </Container>
  )
}

export default EnvironmentReport
