import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { color, fontWeight, Heading, Wrapper } from '@staccx/bento'
import DropDown from '../../../components/DropDownMenu'
import { useChosenCompany, useFeatureToggles, useUser, useUserContext } from '../../../context/UserProvider'
import { TranslatedText } from '@staccx/i18n'
import Briefcase from '../../../icons/Briefcase'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import ProfileForm from '../../../components/modal/ProfileForm'
import Dialog from '../../../components/modal/Dialog'
import { Link } from 'react-router-dom'

const UserInfo = () => {
  const [editProfile, editProfileSet] = useState(false)
  const user = useUser()
  const companies = user ? user.user.companies : []
  const company = useChosenCompany()
  const consolidateAgreements = company?.consolidateAgreement
  const { consolidateInvoice, handleSetConsolidateInvoice } = useUserContext()

  const checkLastLogIn = useMemo(() => {
    const sixMonthsAgo = new Date(Date.now() - 6 * 30 * 24 * 60 * 60 * 1000)
    const userLastLogIn = new Date(user?.user?.lastLogin)
    return sixMonthsAgo.getTime() > userLastLogIn.getTime()
  }, [user])

  return (
    <PopoverPrimitive.Root>
      <PopoverTriggerButton>
        <BtnRow>
          <BtnColumn>
            <Briefcase />
          </BtnColumn>
          <BtnColumn>{company && company.name}</BtnColumn>
        </BtnRow>
      </PopoverTriggerButton>
      <Dialog open={editProfile} onOpenChange={editProfileSet}>
        <ProfileForm onFinish={() => editProfileSet(false)} />
      </Dialog>
      {user && (
        <PopoverContent>
          <CustomWrapper>
            <DropDown
              value={company}
              options={companies}
              onChange={(company) => {
                localStorage.setItem('companyId', company.id)
                localStorage.removeItem('consolidateInvoice')
                window.location.replace('/home')
              }}
              getOptionValue={(option) => `${option['id']}`}
              getOptionLabel={(option) => `${option['name']}`}
            />
            <>
              <TranslatedText i18nKey={'SUB_DEPARTMENT'}>
                {(value) => <StyledHeading level="4">{value}</StyledHeading>}
              </TranslatedText>
              <DropDown
                options={[
                  {
                    value: '_ALL_',
                    label: <TranslatedText i18nKey="ALL" />
                  },
                  ...consolidateAgreements?.map((c) => ({ value: c.ConsolidateCode, label: c.ConsolidateDescription }))
                ]}
                value={{
                  value: consolidateInvoice?.ConsolidateCode,
                  label: consolidateInvoice?.ConsolidateDescription
                }}
                onChange={(consolidateInvoice) => {
                  handleSetConsolidateInvoice({ ConsolidateCode: consolidateInvoice.value })
                }}
              />
            </>
            <Row>
              <TranslatedText i18nKey="LOGGED_IN_USER">
                {(value) => <StyledHeading level="6">{value}</StyledHeading>}
              </TranslatedText>
            </Row>
            <Row>
              <ContactRow>
                <Column>
                  <ContactDetail>{user.user.name}</ContactDetail>
                </Column>
              </ContactRow>
            </Row>
            <StyledLink to={'/profile'}>
              <TranslatedText i18nKey={'YOUR_PROFILE'} />
            </StyledLink>
            {checkLastLogIn && (
              <StatusRow>
                <BadgeColumn>
                  <NewBadgeWrapper>
                    <Badge>
                      <span>1</span>
                    </Badge>
                  </NewBadgeWrapper>
                </BadgeColumn>
                <Column>
                  <p>
                    <TranslatedText i18nKey="CONFIRM_YOUR_USER_DETAILS" />
                  </p>
                </Column>
              </StatusRow>
            )}
          </CustomWrapper>
        </PopoverContent>
      )}
    </PopoverPrimitive.Root>
  )
}

export default UserInfo

const CustomWrapper = styled(Wrapper)`
  padding: var(--spacing-smallPlus);
`
const StyledHeading = styled(Heading)`
  margin: var(--spacing-smallPlus) 0 var(--spacing-micro) 0;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: var(--spacing-micro) 0 var(--spacing-micro) 0;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 var(--spacing-tinyMinus) 0 0;
`
const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--spacing-smallMinus);
`
const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: var(--spacing-tinyMinus) 0 var(--spacing-tinyMinus) 0;
`
const BadgeColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: var(--spacing-tinyMinus);
  justify-content: center;
  align-content: center;
`
const NewBadgeWrapper = styled.div`
  position: relative;
  display: inline-block;
`
const Badge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacing-smallPlus);
  height: var(--spacing-smallPlus);
  line-height: 1;
  background: ${color.warning};
  border-radius: 50%;
  text-align: center;
  color: ${color.white};
  font-size: ${(p) => (p.number < 99 ? '12px' : '10px')};
  font-weight: ${fontWeight.bold};
  right: 0;
  bottom: -10px;
`
const PopoverTriggerButton = styled(PopoverPrimitive.PopoverTrigger)`
  margin: var(--spacing-smallPlus) 0 var(--spacing-smallPlus) 0;
  padding: var(--spacing-small);
  color: var(--color-black);
  background-color: var(--color-primaryLight);
  font-size: var(--font-base);
  font-weight: var(--fontWeight-bold);
  border-radius: var(--borderRadius);
  border: none;

  transition: 0.2s;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: var(--color-black);
    background-color: var(--color-subtleHover);
    box-shadow: var(--spacing-tinyMinus) var(--spacing-tinyMinus) var(--spacing-tinyMinus) var(--color-shadow);
  }
`
const BtnRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--spacing-tinyPlus);
`
const BtnColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const PopoverContent = styled(PopoverPrimitive.Content)`
  width: 430px;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);
  border-radius: var(--spacing-small);
  padding: var(--spacing-smallPlus);
  box-shadow: 0 var(--spacing-tinyMinus) var(--spacing-small) 0 rgba(0, 0, 0, 0.2);
`

const ContactDetail = styled.p`
  padding-bottom: 2px;
`
const StyledLink = styled(Link)`
  font-weight: var(--fontWeight-bold);
  color: var(--color-primary);
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
