import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

const DropDown = ({
  defaultValue,
  options = [],
  onChange,
  value,
  placeholder,
  width,
  getOptionValue,
  getOptionLabel,
  isError,
  resetObject,
  radius,
  overlap,
}) => {
  return (
    <StyledSelect
      defaultValue={defaultValue}
      options={[...(resetObject ? [resetObject] : []), ...(options || [])]}
      onChange={onChange}
      value={value}
      width={width}
      radius={radius}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: `var(--color-primary)`,
          primary25: `var(--color-subtleHover)`,
        },
      })}
      components={{
        IndicatorSeparator: () => null,
      }}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      className={['react-select-container', isError ? 'error' : '']}
      classNamePrefix="react-select"
      menuPortalTarget={overlap ? document.querySelector('body') : ''}
    />
  )
}

export default DropDown

const StyledSelect = styled(Select)`
  div.react-select__control {
    width: ${(props) => (props.width ? props.width : '')};
    border-radius: ${(props) => (props.radius ? props.radius : 0)};
    background-color: var(--color-bg);
    height: var(--spacing-large);

    &:hover {
      cursor: pointer;
    }
  }

  &.error div.react-select__control {
    border: var(--spacing-microMinus) solid red;
  }

  div.react-select__dropdown-indicator {
    padding: var(--spacing-tinyPlus) var(--spacing-smallPlus) var(--spacing-tinyPlus) var(--spacing-tinyPlus);
    color: var(--color-primary);
  }

  div.react-select__value-container {
    font-size: var(--font-base);
  }

  div.react-select__menu {
    border-radius: 0;
    margin-top: var(--spacing-tinyMinus);
  }

  div.react-select__menu-list {
    padding: 0;
  }

  div.react-select__option {
    font-size: var(--font-base);

    &:hover {
      cursor: pointer;
      outline: none;
      background-color: var(--color-subtleHover);
      color: var(--color-black);
    }
  }
`
