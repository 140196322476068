import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import TanStackTable from '../../../components/tables/TanStackTable'
import driversTableData from './driversTableData'

const columns = driversTableData()

type DriversTableProps = {
  data: any
  isLoading?: boolean
  showDialog?: any
  showDialogSet?: any
  selectedDriverSet?: any
}

function DriversTable({ data, isLoading, showDialog, showDialogSet, selectedDriverSet }: DriversTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <>
      <TanStackTable
        table={table}
        isLoading={isLoading}
        handleRowClick={(row) => {
          selectedDriverSet(row.original)
          showDialogSet(!showDialog)
        }}
        checkForMissingData
      />
    </>
  )
}

export default DriversTable
