import React from 'react'
import styled from 'styled-components'
import * as SwitchPrimitive from '@radix-ui/react-switch'

const SwitchButton = ({ label, id, onCheckedChange }) => {
  return (
    <Buttons>
      <Switch id={id} onCheckedChange={onCheckedChange}>
        <Thumb />
      </Switch>
      <SwitchLabel>{label}</SwitchLabel>
    </Buttons>
  )
}

export default SwitchButton

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  grid-template-rows: auto 1fr;
  align-items: center;
  row-gap: var(--spacing-smallPlus);
`

const SwitchLabel = styled.div`
  display: flex;
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-base);
  align-items: flex-start;
`
const Switch = styled(SwitchPrimitive.Root)`
  width: var(--spacing-switchWidth);
  height: var(--spacing-switchHeight);
  background-color: var(--color-line);
  border-radius: 9999px;
  position: relative;
  border: var(--spacing-microMinus) solid var(--color-line);

  &[data-state='checked'] {
    background-color: var(--color-primary);
    border: var(--spacing-microMinus) solid var(--color-primary);
  }
`
const Thumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  width: var(--spacing-switchThumb);
  height: var(--spacing-switchThumb);
  background-color: white;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(-5px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(var(--spacing-smallPlus));
  }
`
