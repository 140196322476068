import React from 'react'
import {
  ExpandedState,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import TanStackTable from '../../../../components/tables/TanStackTable'
import invoiceReportDetailsData from './invoiceReportDetailsData'

type InvoiceReportDetailsTableProps = {
  data: any
  isLoading?: boolean
  footerData: any
}

function InvoiceReportDetailsTable({ data, isLoading, footerData }: InvoiceReportDetailsTableProps) {
  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  
  const columns = invoiceReportDetailsData()
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })
  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default InvoiceReportDetailsTable
