import styled from 'styled-components'

export const Embla = styled.div`
  position: relative;
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
`

export const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export const Slide = styled.div`
  min-width: 100%;
  position: relative;
`

export const SlideInner = styled.div`
  display: flex;
  justify-content: center;
`

export const ThumbPadding = styled.div`
  padding: 24px;
`

export const ThumbContainer = styled.div`
  cursor: default;
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export const Thumbnail = styled.div`
  min-width: 100%;
  position: relative;
  min-width: 20%;
  padding: 0 4px 0 4px;
  opacity: ${({ selected }) => (selected ? '1' : '0.4')};
  cursor: pointer;
`

export const ThumbInner = styled.div`
  border: 0;
  outline: 0;
`

export const SlideThumbnail = styled.div`
  > img {
    transition: opacity 0.2s;
    object-fit: contain;
  }
`
