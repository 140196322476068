import React, { useEffect, useState } from 'react'
import CarparkOverview from '../components/CarParkOverview'
import CarTypeFilter from '../components/CarTypeFilter'
import { submenuCarFuelTypes } from '../../../data/routes'
import CarStatusFilter from '../components/CarStatusFilter'
import EventFilter from '../components/EventFilter'
import CarList from '../components/CarList'
import { useUserContext } from '../../../context/UserProvider'
import { useActiveCars, useAllCars, useIncomingCars, useOutgoingCars } from '../../../context/CarProvider'
import { useParams } from '../../../hooks/useParams'
import { useNavigate } from 'react-router-dom'
import Container from '../../../components/Container'
import styled from 'styled-components'
import EventListTable from '../components/EventListTables'
import SearchBox from "../../../components/menu/SearchBox";
import Fuse from 'fuse.js/dist/fuse.common'
import Flex from "../../../components/Flex";


const options = {
  isCaseSensitive: false,
  threshold: 0.1,
  useExtendedSearch: false,
  keys: ['registrationNumber', 'accountNo', 'driver.name', 'makeAndModel', 'year']
}

const Cars = () => {
  let navigate = useNavigate()
  const { fuel, status, cars, hasReplacementCar, isOverMileage } = useParams()
  const [pattern, patternSet] = useState('')
  const [searchedFilteredCars, searchedFilteredCarsSet] = useState(null)
  const [carFilter, carFilterSet] = useState({
    fuel,
    status,
    cars,
    hasReplacementCar,
    isOverMileage,
  })
  useEffect(() => {
    carFilterSet({
      fuel: fuel || null,
      carStatus: status || null,
      cars: cars || null,
      hasReplacementCar: hasReplacementCar || null,
      isOverMileage: isOverMileage || null,
    })
  }, [fuel, status, cars, hasReplacementCar, isOverMileage])

  const [currentFilter, currentFilterSet] = useState(null)
  const { roleType, chosenCompany } = useUserContext()

  const allCarsUnfiltered = useAllCars()
  const allCarsFiltered = useAllCars(carFilter)
  const activeCarsFiltered = useActiveCars(carFilter)
  const filteredIncomingCars = useIncomingCars(carFilter)
  const filteredOutgoingCars = useOutgoingCars(carFilter)
  const isLoaded =
    allCarsUnfiltered && allCarsFiltered && activeCarsFiltered && filteredIncomingCars && filteredOutgoingCars

  if (roleType !== 2) {
    return null
  }

  const handleOnChange = (pattern, fuse) => {
    if (!pattern) {
      searchedFilteredCarsSet(null)
      return patternSet(pattern)
    }

    const result = fuse.search(pattern)
    searchedFilteredCarsSet(result.map((i) => i.item))
    patternSet(pattern)
  }

  const width = '75%'
  const fuse = new Fuse((allCarsFiltered ?? []).map(car => {
    return {
      ...car,
      makeAndModel: `${car.make} ${car.model}`
    }
  }) || [], options)

  return (
    <>
      {isLoaded && (
        <Layout>
          <HeaderContainer>
            <Content>
              <CarStatusFilter
                cars={allCarsFiltered}
                active={activeCarsFiltered}
                incoming={filteredIncomingCars}
                outgoing={filteredOutgoingCars}
                reset={carFilterSet}
                activeFilter={carFilter}
              />
              <CarparkOverview
                active={activeCarsFiltered}
                incoming={filteredIncomingCars}
                outgoing={filteredOutgoingCars}
                filterByCars={allCarsFiltered}
                reset={carFilterSet}
                activeFilter={carFilter}
                carStatus={carFilter.carStatus}
                carsList={allCarsFiltered}
              />
              <CarTypeFilter list={submenuCarFuelTypes('cars')} cars={allCarsUnfiltered} />
            </Content>
          </HeaderContainer>
          <Container width={width}>
              <Flex direction={'column'} gap={'10px'}>
                <div style={{width: '35%'}}>
                  <SearchBox value={pattern} onChange={async (value) => await handleOnChange(value, fuse)} />
                </div>
                <div style={{width:'100%'}}>
                  <EventFilter currentFilter={currentFilter} setFilter={currentFilterSet} companyId={chosenCompany.id} />
                  <div>
                    {currentFilter ? (
                      <EventListTable
                        carsData={searchedFilteredCars || allCarsFiltered}
                        currentFilter={currentFilter}
                        isLoading={!isLoaded}
                      ></EventListTable>
                    ) : (
                      <CarList data={searchedFilteredCars || allCarsFiltered} isLoading={!isLoaded} />
                    )}
                  </div>
                </div>
              </Flex>
          </Container>
        </Layout>
      )}
    </>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderContainer = styled.div`
  margin-top: var(--spacing-hugeMinus);
  background-color: var(--color-white);
  z-index: 0;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 12px 12px;
  margin-bottom: -50px;
`

const Content = styled.div`
  width: 75%;
  margin: 0 auto;
`

export default Cars
