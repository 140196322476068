import React from "react"

const Toll = () => (
  <svg 
    width="44" 
    height="40" 
    fill="none"
  >
    <path 
      d="M15.137 3.352C14.82 1.406 13.18 0 11.234 0H4.766C2.82 0 1.18 1.406.863 3.352a66.031 66.031 0 0 0 0 21.296C1.18 26.594 2.82 28 4.766 28h6.464c1.95 0 3.59-1.406 3.907-3.352a66.028 66.028 0 0 0 0-21.296ZM11.699 20H4.281a65.45 65.45 0 0 1-.261-4h7.94a52.195 52.195 0 0 1-.26 4Zm-7.68-8c.02-1.34.122-2.66.262-4H11.7c.14 1.34.223 2.66.262 4H4.02ZM43.137 15.352C42.82 13.406 41.18 12 39.234 12H32.77c-1.949 0-3.59 1.406-3.906 3.352a66.028 66.028 0 0 0 0 21.296C29.18 38.594 30.82 40 32.766 40h6.465c1.949 0 3.59-1.406 3.906-3.352a66.028 66.028 0 0 0 0-21.296ZM39.699 32h-7.418a65.554 65.554 0 0 1-.261-4h7.94a52.195 52.195 0 0 1-.26 4Zm-7.68-8c.02-1.34.122-2.66.262-4H39.7c.14 1.34.223 2.66.262 4H32.02ZM19.996 0h4v8h-4V0ZM20.004 16h4v8h-4v-8ZM19.996 32h4v8h-4v-8Z" 
      fill="#467EFF"/>
  </svg>
)

export default Toll