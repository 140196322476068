import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../components/tables/TanStackTable'
import environmentReportTableData from './environmentReportTableData'
import { columnSum, formatCurrency } from '../../../../utils/tableFuncs'

const columns = environmentReportTableData()

type EnvironmentReportTableProps = {
  data: any
  isLoading?: boolean
}
function EnvironmentReportTable({ data, isLoading }: EnvironmentReportTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const footerData = {
    co2Emission: isNaN(columnSum(data, 'co2Emission')) ? '0' : formatCurrency(columnSum(data, 'co2Emission')),
    nOXEmission: isNaN(columnSum(data, 'nOXEmission')) ? '0' : formatCurrency(columnSum(data, 'nOXEmission')),
  }

  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default EnvironmentReportTable
