import { differenceInCalendarMonths, parseISO } from 'date-fns'

class Car {
  constructor({
    id,
    registrationNumber,
    fuel,
    year,
    description,
    mileage,
    contractMileage,
    actualDeliveryDate,
    plannedReturnDate,
    simulatedMileage,
    accountNo,
    insuranceCompany,
    detail,
    hasDeviations,
    events,
    status,
    ...rest
  }) {
    this.id = id
    this.registrationNumber = registrationNumber
    this.fuel = mapFuel(fuel)
    this.year = year
    this.description = description
    this.mileage = mileage
    this.contractMileage = contractMileage
    this.actualDeliveryDate = actualDeliveryDate
    this.plannedReturnDate = plannedReturnDate
    this.simulatedMileage = simulatedMileage
    this.accountNo = accountNo
    this.insuranceCompany = insuranceCompany
    const { driver = null } = detail || {}
    this.driver = driver
    this.hasDeviations = hasDeviations
    this.events = events
    this.status = status

    if (this.actualDeliveryDate && this.plannedReturnDate) {
      this.lifetime = differenceInCalendarMonths(parseISO(this.plannedReturnDate), parseISO(this.actualDeliveryDate))
      this.currentLife = differenceInCalendarMonths(new Date(), parseISO(this.actualDeliveryDate))
      this.maturity = `${this.currentLife}/${this.lifetime}`
      this.lifespan = 0.0 + this.currentLife / this.lifetime
    }

    if (rest) {
      Object.keys(rest).forEach((key) => (this[key] = rest[key]))
    }

    if (this.isIncoming) {
      // this.maturity = this.replaces
      //   ? `Erstatter ${this.replaces.registrationNumber}`
      //   : `Erstatter ingen`
      this.registrationNumber = this.budgetNo
    }
  }

  static fromJson(data) {
    return new Car(data)
  }
}

const mapFuel = (fuelType) => {
  switch (fuelType.toLowerCase()) {
    case 'bensin':
      return FUEL_TYPE_BENSIN
    case 'd':
    case 'diesel':
      return FUEL_TYPE_DIESEL
    case 'e':
    case 'elektrisk':
    case 'electric':
      return FUEL_TYPE_ELECTRIC
    case 'annet':
      return FUEL_TYPE_OTHER
    case 'hybrid':
    case 'bh':
    case 'bensinhybrid':
    case 'dieselhybrid':
      return FUEL_TYPE_HYBRID
    default:
      console.warn('Could not map fuel type', fuelType)
      return FUEL_TYPE_OTHER
  }
}

export const mapIncomingToCar = (incoming) => ({
  ...incoming,
  isIncoming: true,
  year: incoming.carYear,
  fuel: mapFuel(incoming.incomingFuelType),
  engine: incoming.carEngine,
  make: incoming.carMake,
  model: incoming.carModel,
})

export const FUEL_TYPE_FILTER_ALL = 'all'
export const FUEL_TYPE_BENSIN = 'gasoline'
export const FUEL_TYPE_DIESEL = 'diesel'
export const FUEL_TYPE_ELECTRIC = 'electric'
export const FUEL_TYPE_HYBRID = 'hybrid'
export const FUEL_TYPE_OTHER = 'other'

export default Car
