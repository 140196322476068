import React from "react"

const Logo = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       viewBox="0 0 336.3 220.6" >
    <g>
			<g>
				<g>
					<g>
						<path fill="#0A4BCD" className="st0" d="M150.3,119.9l53-15.7V38.9l-53-15.7V0l186.1,57v29.6l-186.1,57V119.9z M312.6,72.8v-2.4l-89.8-26.1v54.6
							L312.6,72.8z" />
					</g>
					<g>
						<path fill="#0A4BCD" className="st0" d="M30.7,206.7l-3.7-13H11.6l-3.7,13H0l14.2-48.5h10.6L39,206.7H30.7z M19.6,166.1H19l-6,21.3h12.5L19.6,166.1
							z" />
						<path fill="#0A4BCD" className="st0" d="M67.6,200.8h-0.3c-0.3,0.9-0.8,1.7-1.3,2.6c-0.5,0.8-1.2,1.6-2,2.2c-0.8,0.6-1.8,1.1-2.9,1.5
							c-1.1,0.4-2.4,0.6-3.9,0.6c-3.8,0-6.7-1.2-8.7-3.6c-2.1-2.4-3.1-5.9-3.1-10.4v-23h7.6v22c0,5.6,2.3,8.4,6.9,8.4
							c1,0,1.9-0.1,2.8-0.4c0.9-0.3,1.7-0.6,2.5-1.1c0.7-0.5,1.3-1.2,1.7-1.9c0.4-0.8,0.7-1.7,0.7-2.8v-24.2h7.6v36.1h-7.6V200.8z" />
						<path fill="#0A4BCD" className="st0" d="M96,206.7c-2.6,0-4.6-0.7-6-2c-1.3-1.4-2-3.3-2-5.8v-22.1h-5.6v-6.2h3.1c1.3,0,2.1-0.3,2.6-0.8
							s0.7-1.5,0.7-2.7v-6.3h6.8v9.9h7.6v6.2h-7.6v23.8h7v6.2H96z" />
						<path fill="#0A4BCD" className="st0" d="M125.3,207.6c-2.5,0-4.8-0.4-6.8-1.3c-2.1-0.9-3.8-2.1-5.2-3.8c-1.4-1.6-2.5-3.6-3.3-6
							c-0.8-2.3-1.2-4.9-1.2-7.8c0-2.9,0.4-5.5,1.2-7.8c0.8-2.3,1.9-4.3,3.3-6c1.4-1.6,3.2-2.9,5.2-3.8c2.1-0.9,4.3-1.3,6.8-1.3
							s4.8,0.4,6.8,1.3c2.1,0.9,3.8,2.1,5.2,3.8c1.4,1.6,2.5,3.6,3.3,6c0.8,2.3,1.2,4.9,1.2,7.8c0,2.9-0.4,5.5-1.2,7.8
							c-0.8,2.3-1.9,4.3-3.3,6c-1.4,1.6-3.2,2.9-5.2,3.8C130,207.1,127.8,207.6,125.3,207.6z M125.3,201.2c2.6,0,4.7-0.8,6.3-2.4
							c1.6-1.6,2.4-4,2.4-7.2v-6.1c0-3.2-0.8-5.6-2.4-7.2c-1.6-1.6-3.7-2.4-6.3-2.4c-2.6,0-4.7,0.8-6.3,2.4c-1.6,1.6-2.4,4-2.4,7.2
							v6.1c0,3.2,0.8,5.6,2.4,7.2C120.6,200.5,122.7,201.2,125.3,201.2z" />
						<path fill="#0A4BCD" className="st0" d="M150.3,170.6h7.6v6h0.3c0.8-2.2,2.1-3.9,3.9-5c1.8-1.2,3.9-1.8,6.3-1.8c4.6,0,8.1,1.7,10.6,5
							s3.8,8,3.8,13.9c0,6-1.3,10.6-3.8,13.9s-6,5-10.6,5c-2.4,0-4.5-0.6-6.3-1.8s-3.1-2.9-3.9-5h-0.3v19.9h-7.6V170.6z M166,201
							c2.6,0,4.7-0.8,6.3-2.5c1.6-1.7,2.4-3.9,2.4-6.7v-6.3c0-2.8-0.8-5-2.4-6.7c-1.6-1.7-3.7-2.5-6.3-2.5c-2.3,0-4.3,0.6-5.8,1.7
							c-1.6,1.1-2.4,2.6-2.4,4.5v12.4c0,1.9,0.8,3.3,2.4,4.5C161.8,200.5,163.7,201,166,201z" />
						<path fill="#0A4BCD" className="st0"
									d="M199.1,206.7c-2.6,0-4.5-0.7-5.8-2c-1.3-1.3-1.9-3.2-1.9-5.5v-43.9h7.6v45.2h5v6.2H199.1z" />
						<path fill="#0A4BCD" className="st0" d="M237.7,206.7c-2,0-3.5-0.6-4.6-1.7c-1.1-1.1-1.7-2.6-1.9-4.3h-0.3c-0.7,2.3-2,4-3.8,5.1s-4.1,1.7-6.7,1.7
							c-3.8,0-6.6-1-8.6-2.9c-2-1.9-3-4.6-3-7.9c0-3.6,1.3-6.3,3.9-8.1c2.6-1.8,6.5-2.7,11.5-2.7h6.5v-3.1c0-2.2-0.6-3.9-1.8-5.1
							c-1.2-1.2-3.1-1.8-5.6-1.8c-2.1,0-3.9,0.5-5.2,1.4c-1.3,0.9-2.5,2.1-3.4,3.5l-4.5-4.1c1.2-2,2.9-3.7,5.1-5c2.2-1.3,5-2,8.5-2
							c4.7,0,8.3,1.1,10.8,3.3c2.5,2.2,3.8,5.3,3.8,9.4v18.1h3.8v6.2H237.7z M222.4,201.8c2.4,0,4.3-0.5,5.8-1.6s2.3-2.4,2.3-4.2v-5.2
							h-6.4c-5.2,0-7.9,1.6-7.9,4.9v1.3c0,1.6,0.5,2.8,1.6,3.6C219,201.4,220.5,201.8,222.4,201.8z" />
						<path fill="#0A4BCD" className="st0" d="M249.3,206.7v-36.1h7.6v6h0.3c0.8-1.9,2-3.6,3.6-4.9c1.6-1.3,3.8-1.9,6.6-1.9c3.7,0,6.6,1.2,8.6,3.6
							c2.1,2.4,3.1,5.9,3.1,10.4v22.9h-7.6v-22c0-5.7-2.3-8.5-6.8-8.5c-1,0-1.9,0.1-2.9,0.4c-1,0.3-1.8,0.6-2.5,1.1
							c-0.7,0.5-1.3,1.2-1.8,1.9c-0.4,0.8-0.7,1.7-0.7,2.8v24.2H249.3z" />
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export default Logo
