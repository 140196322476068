import React, { useMemo, useState } from 'react'
import { Heading } from '@staccx/bento'
import { TranslatedText, i18nInstance } from '@staccx/i18n'
import { useUserContext } from '../../../context/UserProvider'
import { useContacts } from '../../../context/DriverProvider'
import { Formik, Field } from 'formik'
import { Button, Input, LayoutItem } from '@staccx/bento'
import Alert from '../../../components/Alert'
import styled, { css } from 'styled-components'
import { fetchContactDetails, updateDriverDetails } from '../../../api/api'
import Chevron from '../../../icons/Chevron'
import customToast from '../../carConfiguration/components/toast/customToast'
import Toast from '../../carConfiguration/components/toast/Toast'
import SignalSpinnerLoader from "../../../components/loaders/SignalSpinnerLoader";

const Yup = require('yup')

const validateSchema = Yup.object().shape({
  name: Yup.string().required(),
  mail: Yup.string().email().required(),
  phone: Yup.string().required(),
  address: Yup.string().nullable().required('Påkrevd'),
  postCode: Yup.string().required('Påkrevd')
})

const CompanyInfo = () => {
  const [contactDetails, setContactDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [driver, setDriver] = useState(null)
  const [selected, setSelected] = useState(false)
  const { roleType } = useUserContext()
  const { contacts } = useContacts()
  const isDriver = roleType !== 2

  const fetchDriverContactDetails = (driverId) => {
    setIsLoading(true)
    fetchContactDetails(driverId)
      .then((details) => setContactDetails(details))
      .catch(() => alert(`Could not find details for driver with id ${driverId}`))
      .finally(() => setIsLoading(false))
  }

  const mainContact = useMemo(() => {
    const mainContacts = contacts?.filter((contact) => contact.type === 'Main Contact')
    if (!mainContacts) return null

    const firstMainContact = mainContacts[0]
    if (firstMainContact) {
      setDriver(firstMainContact.id)
    }
    if (driver && Object.keys(contactDetails).length === 0) {
      fetchDriverContactDetails(driver)
    }
    return Object.keys(contactDetails).length !== 0 ? contactDetails : null
  }, [contacts, driver, contactDetails])

  const toggleSelected = () => {
    setSelected((prevSelected) => !prevSelected)
  }

  const handleSubmit = (details) => {
    if (!isDriver) {
      setIsLoading(true)
      updateDriverDetails(mainContact.companyExternalID, driver, details)
        .then(() => setIsLoading(false))
        .catch((error) => {
          customToast.error(i18nInstance.translate('ERROR_COMPANYINFORMATION'))
          customToast.clearQueue()
        })
    }
  }

  return (
    <>
      <HeadingContainer>
        <StyledHeading level={3}>
          <TranslatedText i18nKey={'YOUR_COMPANY'} />
        </StyledHeading>

        <Button variant={'hidden'} onClick={toggleSelected}>
          <StyledChevron selected={selected} width={40} height={40} />
        </Button>
      </HeadingContainer>

      {mainContact && selected && (
        <Formik
          initialValues={mainContact}
          validateOnBlur={false}
          validateOnChange={true}
          validationSchema={validateSchema}
          onSubmit={handleSubmit}
          render={({ handleSubmit, isValid, touched, errors, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <StyledLayoutItem>
                    <Field
                      name={`name`}
                      render={({ field }) => (
                        <StyledInput
                          id={`name`}
                          {...field}
                          placeholder={<TranslatedText i18nKey="PLACEHOLDER_NAME" />}
                          label={<TranslatedText i18nKey="NAME" />}
                          disabled
                        />
                      )}
                    />
                    {touched.name && errors.name && (
                      <Alert type="warning">
                        {errors.name}
                      </Alert>
                    )}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`phone`}
                      render={({ field }) => (
                        <StyledInput
                          id={`phone`}
                          {...field}
                          label={<TranslatedText i18nKey="PHONE" />}
                          mode={'phone'}
                          placeholder={'+47 411 11 111'}
                          locale={'nb'}
                          onChange={(event) => setFieldValue('phone', event.rawValue)}
                          disabled={isDriver}
                        />
                      )}
                    />
                    {touched.phone && errors.phone && (
                      <Alert type="warning">
                        {errors.phone}
                      </Alert>
                    )}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`mail`}
                      render={({ field }) => (
                        <StyledInput
                          id={`mail`}
                          {...field}
                          placeholder={'Email'}
                          label={<TranslatedText i18nKey="EMAIL" />}
                          disabled={isDriver}
                        />
                      )}
                    />
                    {touched?.mail && errors?.mail && (
                      <Alert type="warning">
                        {errors.mail}
                      </Alert>
                    )}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`address`}
                      render={({ field }) => (
                        <StyledInput
                          id={`address`}
                          {...field}
                          placeholder={'Adresse'}
                          label={<TranslatedText i18nKey="ADDRESS" />}
                          disabled={isDriver}
                        />
                      )}
                    />
                    {touched.address && errors.address && (
                      <Alert type="warning">
                        {errors.address}
                      </Alert>
                    )}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`postCode`}
                      render={({ field }) => {
                        const { onChange, ...rest } = field
                        return (
                          <StyledInput
                            mode={'postal'}
                            {...rest}
                            onChange={(e) => setFieldValue('postCode', e.rawValue)}
                            locale={'nb'}
                            label={<TranslatedText i18nKey="POSTAL_NUMBER" />}
                            placeholder={'0001'}
                            id="postalCode"
                            disabled={isDriver}
                          />
                        )
                      }}
                    />
                    {touched.postCode && errors.postCode && (
                      <Alert type="warning">
                        {errors.postCode}
                      </Alert>
                    )}
                  </StyledLayoutItem>
                </Grid>

                {isLoading ? (
                  <SignalSpinnerLoader />
                ) : (
                  !isDriver && (
                    <ButtonContainer>
                      <Button disabled={!isValid || !touched} type={'submit'} onClick={() => null}>
                        <TranslatedText i18nKey={'SAVE'} />
                      </Button>
                    </ButtonContainer>
                  )
                )}
              </form>
            )
          }}
        />
      )}
      <Toast limit={1} />
    </>
  )
}

export default CompanyInfo

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-mediumPlus);
  grid-row-gap: var(--spacing-mediumPlus);
`

const StyledInput = styled(Input)`
  font-size: var(--font-base);
  input::placeholder {
    color: gray;
  }
`

const StyledLayoutItem = styled(LayoutItem)`
  font-size: var(--font-text);
  color: var(--color-headerText);
`

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: var(--spacing-mediumPlus);
`

const StyledHeading = styled(Heading)`
  padding: 0;
  margin: 0;
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-smallMinus);
`

const StyledChevron = styled(Chevron)`
  color: var(--color-primary);
  ${(props) =>
    props.selected
      ? css`
          transform: rotate(180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
  }
`
