import React from "react"

const Briefcase = () => (
  <svg 
    width="19" 
    height="17" 
    viewBox="0 0 19 17" 
    fill="none"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M6.91289 1.60864C6.91289 1.05636 7.3606 0.608643 7.91289 0.608643H11.8404C12.3927 0.608643 12.8404 1.05636 12.8404 1.60864V3.07244H17.884C18.5003 3.07244 18.9999 3.57206 18.9999 4.18838V7.88401C18.9999 8.96304 18.5221 9.93059 17.7681 10.5863V14.6593C17.7681 15.6158 16.9927 16.3912 16.0362 16.3912H3.71742C2.76093 16.3912 1.98554 15.6158 1.98554 14.6593V10.586C1.23156 9.93042 0.753662 8.96308 0.753662 7.88401V4.18838C0.753662 3.57206 1.25329 3.07244 1.8696 3.07244H6.91289V1.60864ZM11.8404 1.60864L7.91289 1.60864V3.0724H11.8404L11.8404 1.60864ZM1.8696 4.07244C1.80557 4.07244 1.75366 4.12435 1.75366 4.18838V7.88401C1.75366 8.72762 2.15809 9.47646 2.78583 9.94798C3.21702 10.2719 3.75215 10.4637 4.33335 10.4637H9.37671V9.73267C9.37671 9.45652 9.60057 9.23267 9.87671 9.23267C10.1529 9.23267 10.3767 9.45652 10.3767 9.73267V10.4637H15.4202C16.0016 10.4637 16.5367 10.272 16.9678 9.9482C17.5955 9.47671 17.9999 8.72766 17.9999 7.88401V4.18838C17.9999 4.12435 17.948 4.07244 17.884 4.07244H1.8696ZM9.37671 11.4637V12.1964C9.37671 12.4726 9.60057 12.6964 9.87671 12.6964C10.1529 12.6964 10.3767 12.4726 10.3767 12.1964V11.4637H15.4202C15.8967 11.4637 16.3519 11.3704 16.7681 11.2011V14.6593C16.7681 15.0635 16.4404 15.3912 16.0362 15.3912H3.71742C3.31321 15.3912 2.98554 15.0635 2.98554 14.6593V11.201C3.40171 11.3703 3.85687 11.4637 4.33335 11.4637H9.37671Z" 
      fill="#0F55E6"
    />
  </svg>
)

export default Briefcase
