import React from 'react'
import Container from '../../../components/Container'
import { Heading, Layout } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import ReportTermSelect from '../components/ReportTermSelect'
import { useUserContext } from '../../../context/UserProvider'
import { useQuery } from 'react-query'
import { fetchMileageReport, fetchFleetReportTerms } from '../../../api/api'
import MileageReportDetailsTable from '../components/MileageTables/MileageReportDetails'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import getLastDateInMonth from '../../../utils/getLastDateInMonth'
import MileageReportDetailsCard from '../components/MileageTables/MileageReportDetails/MileageReportDetailsCard'

const MileageReportDetails = () => {
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id
  const { account } = useParams()

  const { data: terms = [] } = useQuery(['fleetReportTerms', companyId], () => fetchFleetReportTerms(companyId), {
    enabled: !!companyId,
  })

  const { data = [], status } = useQuery(
    ['mileageReport', companyId, fleetReportTerm],
    () => fetchMileageReport(companyId, fleetReportTerm, getLastDateInMonth(fleetReportTerm), account),
    {
      enabled: !!companyId,
    }
  )

  const agreementFilter = useFilterOnConsolidateAgreement()

  return (
    <Container>
      <TranslatedText i18nKey={'MILEAGE_REPORT_MENUITEM'}>
        {(value) => <Heading level="1">{value}</Heading>}
      </TranslatedText>
      <ContentContainer>
        <MileageReportDetailsCard data={data} account={account} />
        <StyledLayout variant="fiftyFifty" rowGap={'large'}>
          <TranslatedText i18nKey="TERM">
            {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
          </TranslatedText>
        </StyledLayout>
      </ContentContainer>
      <MileageReportDetailsTable
        data={data.filter(agreementFilter('ConsolidatedInvoiceNo'))}
        isLoading={status === 'loading'}
      />
    </Container>
  )
}

export default MileageReportDetails

const ContentContainer = styled.div`
  margin-top: var(--spacing-largeMinus);
`

const StyledLayout = styled(Layout)`
  margin: var(--spacing-medium) 0px var(--spacing-largeMinus) 0px;
`
