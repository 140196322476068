import React from "react"

const Consumption = () => (
  <svg 
    width="32" 
    height="40" 
    fill="none" 
  >
    <path 
      d="M20 0H4C1.8 0 0 1.8 0 4v32c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V12L20 0Zm-4.023 29.68c0 3.746-3.368 4.254-3.961 4.32H12v2h-2v-2h.246C9.7 33.93 6 33.559 6 28.906h3.328c0 2.746 1.555 2.633 1.79 2.633.26 0 1.53-.043 1.53-1.84 0-3.586-6.093-1.996-6.093-7.37 0-3.09 2.336-3.985 3.445-4.235V16h2v2.04l.27.003c.566.102 3.73.758 3.73 4.988h-3.328c0-2.21-1.031-2.558-1.461-2.558-.234 0-1.328.054-1.328 1.87 0 3.169 6.094 2.18 6.094 7.337ZM18 14V3l11 11H18Z" 
      fill="#467EFF"/>
  </svg>
)

export default Consumption