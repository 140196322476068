import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import type { Mileage } from '../../../../../types'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '@staccx/bento'
import { NavLink } from 'react-router-dom'

export default function mileageReportTableData() {
  const columnHelper = createColumnHelper<Mileage>()
  return [
    columnHelper.accessor('RegistrationNo', {
      id: 'RegistrationNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }) => (
        <NavLink
          to={`/reports/mileage/account/${row.getValue('AccountNo')}`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {getValue()}
        </NavLink>
      ),
    }),
    columnHelper.accessor('AccountNo', {
      id: 'AccountNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'ACCOUNT_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('MileageReadTime', {
      id: 'MileageReadTime',
      header: () => <TranslatedText i18nKey={'MILEAGE_READ_TIME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
      meta: { alignmentType: 'date' },
    }),
    columnHelper.accessor('LapsedMonths', {
      id: 'LapsedMonths',
      header: () => <TranslatedText i18nKey={'LAPSED_MONTHS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('RegistreredMileage', {
      id: 'RegistreredMileage',
      header: () => <TranslatedText i18nKey={'REGISTERED_MILEAGE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('AveragePerMonths', {
      id: 'AveragePerMonths',
      header: () => <TranslatedText i18nKey={'AVERAGE_PER_MONTHS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('EstimatedEndMileage', {
      id: 'EstimatedEndMileage',
      header: () => (
        <TranslatedText i18nKey={'ESTIMATED_END_MILEAGE'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('EstimatedEndDeviation', {
      id: 'EstimatedEndDeviation',
      header: () => (
        <TranslatedText i18nKey={'ESTIMATED_END_DEVIATION'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('ChangesToMaxMileage', {
      id: 'ChangesToMaxMileage',
      header: () => (
        <TranslatedText i18nKey={'CHANGES_TO_MAX_MILEAGE'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('DriverName', {
      id: 'DriverName',
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('VehicleBrand', {
      id: 'VehicleBrand',
      header: () => <TranslatedText i18nKey={'MAKE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('VehicleModel', {
      id: 'VehicleModel',
      header: () => <TranslatedText i18nKey={'MODEL'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('CustomerNo', {
      id: 'CustomerNo',
      header: () => <TranslatedText i18nKey={'CUSTOMER_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('ConsolidatedInvoiceNo', {
      id: 'ConsolidatedInvoiceNo',
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_AGREEMENT'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('ConsolidatedInvoiceDesc', {
      id: 'ConsolidatedInvoiceDesc',
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_INVOICE_DESC'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
  ]
}
