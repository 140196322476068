import React from "react"

const CarFleet = () => (
  <svg 
    width="48" 
    height="36" 
    fill="none" 
  >
    <path 
      d="m47.102 13.66-2.204-3.32-2.457 1.62L37.84 1.22A2.008 2.008 0 0 0 36 0H12.54c-.778 0-1.481.441-1.802 1.16L5.641 12.02 3.1 10.34.899 13.66l3.16 2.121C4.04 15.84 4 15.921 4 16v18c0 1.102.898 2 2 2h4l-.02-4h28.04L38 36h4c1.102 0 2-.898 2-2V16c0-.078-.04-.16-.04-.238l3.142-2.102ZM14 24c-1.102 0-2-.898-2-2 0-1.102.898-2 2-2 1.102 0 2 .898 2 2 0 1.102-.898 2-2 2Zm20 0c-1.102 0-2-.898-2-2 0-1.102.898-2 2-2 1.102 0 2 .898 2 2 0 1.102-.898 2-2 2ZM9.14 14l4.68-10h20.86l4.28 10H9.14Z" 
      fill="#467EFF"/>
  </svg>
) 

export default CarFleet