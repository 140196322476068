import { formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'
import { createColumnHelper } from '@tanstack/react-table'
import type { Fleet } from '../../../../../types'
import React from 'react'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { NavLink } from 'react-router-dom'

export default function fleetReportData() {
  const columnHelper = createColumnHelper<Fleet>()
  return [
    columnHelper.accessor('accountID', {
      id: 'accountID',
      header: () => null,
      cell: (row) => null,
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('regNo', {
      id: 'regNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }) => (
        <NavLink
          to={`/reports/fleet/account/${row.getValue('accountID')}`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {getValue()}
        </NavLink>
      ),
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('driverName', {
      id: 'driverName',
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),

    columnHelper.accessor('HK', {
      id: 'hk',
      header: () => <TranslatedText i18nKey={'HK'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) =>
        row.row.original.accountStatus === 'AVVIKLET'
          ? 'ADM'
          : row.row.original.accountStatus === 'ADM'
          ? 'ADM'
          : row.row.original.termsNow || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('totalTerms', {
      id: 'totalTerms',
      header: () => <TranslatedText i18nKey={'TB'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('totalKm', {
      id: 'totalKm',
      header: () => <TranslatedText i18nKey={'TOTAL_KM'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('budgetKm', {
      id: 'budgetKm',
      header: () => <TranslatedText i18nKey={'BUDGET_KM'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('maxKm', {
      id: 'maxKm',
      header: () => <TranslatedText i18nKey={'MAX_KM'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('fixedYear', {
      id: 'fixedYear',
      header: () => <TranslatedText i18nKey={'FIXED_YEAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('fixedContract', {
      id: 'fixedContract',
      header: () => <TranslatedText i18nKey={'FIXED_CONTRACT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('nonFixedYear', {
      id: 'nonFixedYear',
      header: () => <TranslatedText i18nKey={'NON_FIXED_YEAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('nonFixedContract', {
      id: 'nonFixedContract',
      header: () => <TranslatedText i18nKey={'NON_FIXED_CONTRACT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('totalYear', {
      id: 'totalYear',
      header: () => <TranslatedText i18nKey={'TOTAL_YEAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('totalContract', {
      id: 'totalContract',
      header: () => <TranslatedText i18nKey={'TOTAL_CONTRACT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
  ]
}
