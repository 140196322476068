import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import AutoplanSmall from '../../icons/AutoPlanSmall'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '../Container'

const Footer = () => {
  return (
    <AbsoluteContainer
      width={'100%'}
      css={`
        max-width: var(--size-large);
      `}
    >
      <FooterContainer>
        <Links>
          <StyledLink href={'//autoplan.no/om-oss'} target="_blank">
            <TranslatedText i18nKey="MORE_ABOUT" />
          </StyledLink>
          <StyledNavLink to={'help'}>
            <TranslatedText i18nKey="CONTACT_US">{(value) => <span>{value}</span>}</TranslatedText>
          </StyledNavLink>
        </Links>
        <div>
          <AutoplanSmall />
        </div>
      </FooterContainer>
    </AbsoluteContainer>
  )
}

export default Footer

const AbsoluteContainer = styled(Container)`
  position: absolute;
  bottom: 0;
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-bgGray);
  height: 100px;
  margin: 0;
  padding: var(--spacing-medium);
  border-top: 1px solid var(--color-primary);
`
const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
`

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--color-primary);
  font-size: var(--font-base);
  font-weight: var(--fontWeight-normal);
  transition: 0.3s;
  :hover {
    box-shadow: 0 4px 2px -2px var(--color-primary);
  }
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: var(--color-primary);
  font-size: var(--font-base);
  font-weight: var(--fontWeight-normal);

  > span {
    transition: 0.3s;

    :hover {
      box-shadow: 0 4px 2px -2px var(--color-primary);
    }
  }
`
