import React from 'react'

const Close = (props) => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72195 5.11944L10.0148 1.8266L8.60059 0.412384L5.30774 3.70523L2.01489 0.412384L0.600679 1.8266L3.89353 5.11944L0.600586 8.41238L2.0148 9.8266L5.30774 6.53366L8.60068 9.8266L10.0149 8.41238L6.72195 5.11944Z"
      fill="currentColor"
    />
  </svg>
)

export default Close
