import React, { useEffect } from 'react'
import { Car, CarStatus } from '../../../types'
import LinkBanner from './LinkBanner'
import { formatDateString } from '../../../utils/dateHelper'
import customToast from '../../carConfiguration/components/toast/customToast'
import { TranslatedText } from '@staccx/i18n/dist/index.es'
import { patchToggleCarReplacement } from '../../../api/api'
import {
  useChosenCompany,
  useChosenConsolidateInvoice,
  useUserContext,
} from '../../../context/UserProvider'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from '@staccx/bento'

const BannersAndToasts: React.FC<{ car: Car }> = ({ car }) => {
  const user: any = useUserContext()
  const company = useChosenCompany()
  const consolidateInvoice = useChosenConsolidateInvoice()
  const companyId = company ? company.id : null
  const queryClient = useQueryClient()

  const toggleReplacement = async () => {
    await patchToggleCarReplacement(car.accountNo)
    queryClient.invalidateQueries([
      'cars',
      companyId,
      consolidateInvoice?.ConsolidateCode,
    ])
  }

  useEffect(() => {
    customToasts()
  }, [car?.registrationNumber])

  const customToasts = () => {
    if (car.status === 'outgoing' && !car.noCarReplacement) {
      userConfigurationAccess()
    }
  }

  const userConfigurationAccess = () => {
    if (user?.hasConfigurationAccess) {
      customToast.info(
        <>
          <TranslatedText i18nKey="NO_CAR_REPLACEMENT" />
          <ToastButtonContainer>
            <ToastLink to={`/car-order`}>
              <ToastButton>
                <TranslatedText i18nKey="YES" />
              </ToastButton>{' '}
            </ToastLink>
            <ToastButton onClick={() => toggleReplacement()}>
              <TranslatedText i18nKey="NO" />
            </ToastButton>
          </ToastButtonContainer>
        </>,
        { autoClose: false },
      )
    } else {
      customToast.info(
        <>
          <ToastLink to={`/help`}>
            <TranslatedText i18nKey="NO_CAR_REPLACEMENT_CONTACT_AUTOPLAN" />
          </ToastLink>
        </>,
        { autoClose: false },
      )
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {car.status === CarStatus.Incoming &&
        car.replaces &&
        (() => {
          const replacedCar = car.replaces
          return (
            <LinkBanner
              to={`/cars/${replacedCar.registrationNumber}`}
              children={
                <>
                  <TranslatedText i18nKey="CAR_REPLACES" />
                  <> </>
                  <>
                    {`${replacedCar.registrationNumber}, ${replacedCar.make} ${replacedCar.model}.`}
                  </>
                  <> </>
                  <TranslatedText i18nKey="CAR_IN_ORDER" />
                  <> </>
                  <TranslatedText i18nKey="EXPECTED_DELIVERY_DATE" />
                  <> </>
                  {formatDateString(new Date(car.deliveryDate))}
                </>
              }
            />
          )
        })()}

      {car.replacement &&
        (() => {
          const replacementCar = car.replacement

          return (
            <LinkBanner
              to={`/cars/${
                replacementCar.registrationNumber || replacementCar.budgetNo
              }`}
              children={
                <>
                  <TranslatedText i18nKey="CAR_REPLACEMENT" />
                  <> </>
                  <>
                    {replacementCar.make} {replacementCar.model}
                  </>
                  <>{', '}</>
                  <TranslatedText i18nKey="BUDGET_NR" />
                  <> </>
                  <>{replacementCar.budgetNo}</>
                </>
              }
            />
          )
        })()}
    </div>
  )
}

export default BannersAndToasts

const ToastLink = styled(Link)`
  text-decoration: none;
  font-weight: var(--fontWeight-bold);
  color: var(--color-headerText);

  :hover {
    color: var(--color-primary);
  }
`

const ToastButton = styled(Button)`
  position: relative;
  font-size: var(--font-text);
  font-weight: var(--fontWeight-normal);
  color: var(--color-headerText);
  width: var(--spacing-mediumPlus);
  height: var(--spacing-medium);
  margin-right: var(--spacing-smallMinus);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: var(--spacing-micro);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  background-color: var(--color-primaryLight);

  :hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: var(--color-primary);
    color: var(--color-bg);
    border-radius: var(--spacing-micro);
  }
`
const ToastButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-smallMinus);
  margin-bottom: var(--spacing-tiny);
`
