import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import tableData from './fuelReportExpenseDetailsData'

const columns = tableData()

type FuelReportExpenseDetailsTableProps = {
  data: any
  isLoading?: boolean
}
function FuelReportExpenseDetailsTable({ data, isLoading }: FuelReportExpenseDetailsTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  // @ts-ignore  remove this ts-ignore when UserContext is type safe

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <TanStackTable table={table} isLoading={isLoading} />
}

export default FuelReportExpenseDetailsTable
