export const filterOnConsolidateAgreement = ({ consolidateInvoice, chosenCompany }) => field => {
  if (!chosenCompany || !consolidateInvoice) return el => el

  if (consolidateInvoice.ConsolidateCode === "_ALL_") {
    return el => chosenCompany.consolidateAgreement.some(
      e => el[field] === e.ConsolidateCode
    )
  }
  return el => el[field] === consolidateInvoice.ConsolidateCode
}
