import React, { useMemo, useState } from 'react'
import { theming, Wrapper } from '@staccx/bento'
import styled, { css } from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import {
  flattenAccessories,
  packageEquipmentFilter,
  packageFilter,
  packageInteriorFilter,
  packageOtherFilter,
  packageSecurityFilter
} from '../../../api/jato'
import { CarTopSummary } from '../components/CarTopSummary'
import Toast from '../components/toast/Toast'
import { CarEquipmentPackage } from '../components/fields'
import 'react-toastify/dist/ReactToastify.css'
import TERMS_AND_MILEAGE from '../components/data/terms-and-mileage.json'
import { useCarOptionRules } from '../hooks/useCarOptionRules'
import { useCarPrice } from '../hooks/useCarPrice'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import CustomSkeleton from '../components/skeleton/skeleton'
import useCarConfiguration, {
  CHOOSE_CAR,
  CONFIGURE_BASE_CAR,
} from '../hooks/useCarConfiguration'
import FormError from '../components/FormError'
import RequiresModal from '../components/RequiresModal'
import Navigation from '../components/Navigation'
import YearMileage from '../components/YearMileage'
import { SmallHeading } from '../../../components/text/Headings'
import useCarConfigurationQueries from '../hooks/useCarConfigurationQueries'
import { useFlow } from '../../../context/FlowProvider'
import ImageDialog from '../components/imageGallery/ImageDialog'
import Container from '../../../components/Container'
import RadioButtonList from "../../../components/RadioButtonList";

const PREVIOUS = 'previous'

const ConfigureBaseCar = () => {
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)

  const {
    saveAndNavigate,
    errorHandler,
    companyId,
    isRedirecting,
    isLoading
  } = useCarConfiguration({
    page: CONFIGURE_BASE_CAR,
    flow,
    refetch
  })
  const { carConfiguration, vehicleType, visitedSteps, chosenDriver } = flow?.data || {}
  const hasChosenEngine = !!carConfiguration?.derivativeLocal
  const {
    chosenPackages = [],
    chosenOptions = [],
    chosenFinancingOptions,
    chosenWheelPackage,
    chosenAgreements,
    vehicleId
  } = carConfiguration || {}

  const [engineId, engineIdSet] = useState(hasChosenEngine ? carConfiguration.vehicleId : null)
  const [mileage, mileageSet] = useState(chosenFinancingOptions ? chosenFinancingOptions.mileage : TERMS_AND_MILEAGE[0].mileages[0])

  const [openPhotos, openPhotosSet] = useState(false)
  const matchedTerm = chosenFinancingOptions && TERMS_AND_MILEAGE.find(el => el.year === chosenFinancingOptions.term)
  const [term, termSet] = useState(matchedTerm ? matchedTerm : TERMS_AND_MILEAGE[0])

  const { enginesRaw, accessoriesRaw } = useCarConfigurationQueries(CONFIGURE_BASE_CAR, errorHandler, {
    vehicleType,
    companyId,
    vehicleId: engineId,
    make: carConfiguration?.makeKey,
    model: carConfiguration?.modelKey,
    modelYear: carConfiguration?.modelYear,
    bodyCode: carConfiguration?.bodyCode,
    doors: carConfiguration?.numberOfDoors,
    drivenWheels: carConfiguration?.drivenWheels,
    engine: carConfiguration?.derivativeLocal,
  })

  const { data: engines, error: enginesError } = enginesRaw
  const { error: accessoriesError } = accessoriesRaw

  const engineToDisplay = engineId || (hasChosenEngine ? carConfiguration?.vehicleId : null) || (engines && (engines.length === 1 && engines[0].vehicleId))

  const { accessories, photo, photos, standardEquipment, firstRegistrationFee } = useMemo(() => {
    if (!accessoriesRaw.data) return {}

    const { packages, options } = accessoriesRaw.data

    return {
      accessories: { options, packages },
      photo: get(accessoriesRaw, "data.vehiclePhotos[0]", []),
      photos: get(accessoriesRaw, "data.vehiclePhotos", []),
      standardEquipment: get(accessoriesRaw, "data.vehicleStandardEquipment", {}),
      firstRegistrationFee: get(accessoriesRaw, "data.vehiclePerformance.firstRegistrationFee")
    }
  }, [accessoriesRaw.data])

  const {
    selectedOptions,
    includedOptions,
    excludedOptions,
    handleToggleOption,
    getOptionNameByOptionId,
    getOptionExcludedBy,
    getOptionIncludedBy,
    resetSelections,
    fulfillsRequirements,
    getSelectedByPackage,
    requiredSelections,
    optionThatRequiresSelections,
    setRequiredSelections,
    formError,
    removeFormError,
    simpleFormValidator,
    handleRemoveOption,
    getExcludedByOption,
    getOptionDependencies,
    currentSelectedOption
  } = useCarOptionRules(
    flattenAccessories(accessories),
    [...chosenPackages, ...chosenOptions],
    CONFIGURE_BASE_CAR,
    !!engineToDisplay,
    visitedSteps,
    !!carConfiguration?.chosenWheelPackage
  )

  const hybridCodeElseNull = accessoriesRaw ? accessoriesRaw?.data?.vehicleEbrochurePage?.pageItem?.find(page => page?.schemaId === 48602) : null

  const updateFlowHandler = ({ direction, route }) => {
    const chosenEngine = (engines || []).find(e => e.vehicleId === engineToDisplay)

    if (direction === PREVIOUS) {
      saveAndNavigate.mutate({
        direction: 'previous'
      })
    } else {
      saveAndNavigate.mutate({
        carConfiguration: {
          ...(chosenEngine ? chosenEngine : carConfiguration),
          chosenPackages: selectedOptions.filter(packageFilter),
          chosenOptions: selectedOptions.filter(o => !packageFilter(o)),
          chosenFinancingOptions: {
            term: term.year,
            mileage
          },
          ...(hybridCodeElseNull ? { hybridCode: hybridCodeElseNull.dataValue } : {}),
          firstRegistrationFee,
          ...(chosenWheelPackage && Object.keys(chosenWheelPackage).length && { chosenWheelPackage }),
        },
        direction,
        route
      })
    }
  }

  const { monthlyPrice } = useCarPrice({
    configuration: {
      ...(engines || []).find(eng => eng.vehicleId === engineToDisplay),
      ...(engineToDisplay === vehicleId ? { chosenWheelPackage, chosenAgreements } : {}),
      ...(hybridCodeElseNull ? { hybridCode: hybridCodeElseNull.dataValue } : {}),
      firstRegistrationFee
    },
    options: selectedOptions,
    vehicleType,
    financingOptions: { term, mileage },
    errorHandler
  })

  const packages = useMemo(() => {
    if (!accessories) return null
    return {
      equipment: accessories.packages
        .filter(packageEquipmentFilter),
      interior: accessories.packages
        .filter(packageInteriorFilter),
      other: accessories.packages
        .filter(packageOtherFilter),
      security: accessories.packages
        .filter(packageSecurityFilter)
    }
  }, [accessories])

  const handleChangeEngineId = (id) => {
    engineIdSet(id)
    removeFormError('engine')
    resetSelections()
  }

  const carToDisplay = hasChosenEngine && engineToDisplay ?
    (engines || []).find(({ vehicleId }) => vehicleId === engineToDisplay) : carConfiguration

  const reqError = enginesError || accessoriesError

  if (isRedirecting) {
    return null
  }

  return (
    <Container isLoading={isLoading} width='100%'>
      <CarTopSummary
        car={carToDisplay}
        photoUrl={photo || (carToDisplay && carToDisplay.photoUrl)}
        coreCalculatedMonthlyPrice={monthlyPrice}
        vehiclePhotos={photos}
        standardEquipment={standardEquipment}
        onPress={() => {
          if (photos.length > 0) {
            openPhotosSet(!!photos)
          }
        }}
        page={CONFIGURE_BASE_CAR}
        saveAndNavigate={updateFlowHandler}
        routeValidator={simpleFormValidator}
        visitedPages={carConfiguration?.vehicleId !== engineToDisplay ? { [CHOOSE_CAR]: new Date() } : visitedSteps}
        driver={chosenDriver}
      />
      <div>
        {photos &&
          <ImageDialog
            photos={photos}
            isOpen={openPhotos}
            openSet={openPhotosSet}
          />
        }
        <RequiresModal
          requiredSelections={requiredSelections}
          optionThatRequiresSelections={optionThatRequiresSelections}
          currentSelectedOption={currentSelectedOption}
          handleToggleOption={handleToggleOption}
          getOptionNameByOptionId={getOptionNameByOptionId}
          getOptionIncludedBy={getOptionIncludedBy}
          getExcludedByOption={getExcludedByOption}
          touchedOptions={{ selectedOptions, excludedOptions, includedOptions }}
          fulfillsRequirements={fulfillsRequirements}
          getSelectedByPackage={getSelectedByPackage}
          handleRemoveOption={handleRemoveOption}
          getOptionDependencies={getOptionDependencies}
        />
      </div>
      <ConfigureBaseCarWrapper>
        <SelectRow>
          <YearMileage
            selectedTerm={term}
            termHandler={termSet}
            selectedMileage={mileage}
            mileageHandler={mileageSet}
          />
        </SelectRow>
        {engines ? <SelectCarOptions isError={!!formError.engine}>
          <div>
            <TranslatedText i18nKey='MOTOR'>
              {(value) => <SmallHeading>{value}</SmallHeading>}
            </TranslatedText>
            <RadioButtonList
              items={engines.map((vehicle) => ({
                key: vehicle.vehicleId,
                value: vehicle.derivativeLocal
              }))}
              value={engineId}
              onChangeHandler={handleChangeEngineId}
            />

          </div>
        </SelectCarOptions> : (!enginesError ? <div>
            <EngineHeading>
              <TranslatedText i18nKey='MOTOR'>
                {(value) => <SmallHeading>{value}</SmallHeading>}
              </TranslatedText>
            </EngineHeading>
            <CustomSkeleton
              skeletons={[
                {
                  height: 20,
                  width: 20,
                  type: 'circle'
                },
                {
                  height: 20,
                  width: 300
                }
              ]}
              theme={css`
                display: grid;
                grid-template-columns: 0.1fr 1fr;
              `}
            />
          </div> :
          <EngineMissingContainer><TranslatedText i18nKey={'ENGINE_MISSING_MESSAGE'} /></EngineMissingContainer>)}
        {packages ? (
          <SelectCarOptions>
            {packages.equipment && packages.equipment.length > 0 && (
              <div>
                <TranslatedText i18nKey={'EQUIPMENT_PACKAGES'}>
                  {value => <SmallHeading>{value}</SmallHeading>}
                </TranslatedText>
                <PackagesList>
                  {packages.equipment.map((equipmentPackage) => (
                    <PackagesListItem key={equipmentPackage.optionId}>
                      <CarEquipmentPackage
                        equipmentPackage={equipmentPackage}
                        onSelect={handleToggleOption}
                        touchedPackages={{ selectedOptions, excludedOptions, includedOptions }}
                        getOptionExcludedBy={getOptionExcludedBy}
                        getOptionNameByOptionId={getOptionNameByOptionId}
                      />
                    </PackagesListItem>
                  ))}
                </PackagesList>
              </div>
            )}
            {packages.interior && packages.interior.length > 0 && (
              <div>
                <TranslatedText i18nKey={'INTERIOR_PACKAGES'}>
                  {value => <SmallHeading>{value}</SmallHeading>}
                </TranslatedText>
                <PackagesList>
                  {packages.interior.map((equipmentPackage) => (
                    <PackagesListItem key={equipmentPackage.optionId}>
                      <CarEquipmentPackage
                        equipmentPackage={equipmentPackage}
                        onSelect={handleToggleOption}
                        touchedPackages={{ selectedOptions, excludedOptions, includedOptions }}
                        getOptionExcludedBy={getOptionExcludedBy}
                        getOptionNameByOptionId={getOptionNameByOptionId}
                      />
                    </PackagesListItem>
                  ))}
                </PackagesList>
              </div>
            )}
            {packages.security && packages.security.length > 0 && (
              <div>
                <TranslatedText i18nKey={'SECURITY_PACKAGES'}>
                  {value => <SmallHeading>{value}</SmallHeading>}
                </TranslatedText>
                <PackagesList>
                  {packages.security.map((equipmentPackage) => (
                    <PackagesListItem key={equipmentPackage.optionId}>
                      <CarEquipmentPackage
                        equipmentPackage={equipmentPackage}
                        onSelect={handleToggleOption}
                        touchedPackages={{ selectedOptions, excludedOptions, includedOptions }}
                        getOptionExcludedBy={getOptionExcludedBy}
                        getOptionNameByOptionId={getOptionNameByOptionId}
                      />
                    </PackagesListItem>
                  ))}
                </PackagesList>
              </div>
            )}
            {packages.other && packages.other.length > 0 && (
              <div>
                <TranslatedText i18nKey={'OTHER_PACKAGES'}>
                  {value => <SmallHeading>{value}</SmallHeading>}
                </TranslatedText>
                <PackagesList>
                  {packages.other.map((equipmentPackage) => (
                    <PackagesListItem key={equipmentPackage.optionId}>
                      <CarEquipmentPackage
                        equipmentPackage={equipmentPackage}
                        onSelect={handleToggleOption}
                        touchedPackages={{ selectedOptions, excludedOptions, includedOptions }}
                        getOptionExcludedBy={getOptionExcludedBy}
                        getOptionNameByOptionId={getOptionNameByOptionId}
                      />
                    </PackagesListItem>
                  ))}
                </PackagesList>
              </div>
            )}
          </SelectCarOptions>
        ) : !!engineToDisplay && !reqError && (
          <CustomSkeleton
            skeletons={[
              {
                height: 20,
                width: 400
              },
              {
                height: 20,
                width: 20
              }
            ]}
            theme={css`
              justify-content: space-between;
            `}
          />
        )}
        {engines && (<FormError
            page={CONFIGURE_BASE_CAR}
            errors={formError}
            setRequiredSelections={setRequiredSelections}
            navigate={updateFlowHandler}
          />
        )}
        <Navigation updateFlowHandler={updateFlowHandler} />
        <Toast limit={1} />
      </ConfigureBaseCarWrapper>
    </Container>
  )
}

export default ConfigureBaseCar

const ConfigureBaseCarWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-mediumPlus);
`

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-large);
  height: 100%;
  margin: var(--spacing-medium) 0px;
`

const EngineMissingContainer = styled.div`
  padding: var(--spacing-smallMinus);
`

const SelectCarOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-mediumPlus);

  li:not(:first-child) {
    border-top: var(--spacing-microMinus) solid var(--color-line);
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--color-bg);
    border: 1px solid var(--color-line);

    > div {
      cursor: pointer;
      ${props => props.isError ? css`
        border: 1px solid var(--color-warning);
      ` : null}
    }

    > div > div {
      padding-left: ${theming.spacing('smallPlus')};
      padding-right: ${theming.spacing('smallPlus')};
    }

    > div > div:not(:first-child) {
      border-top: var(--spacing-microMinus) solid var(--color-line);
    }

    > span {
      background-color: var(--color-primaryLight);
      line-height: 2.5em;
      padding-left: var(--spacing-smallPlus);
    }
  }
`

const EngineHeading = styled.div`
  background-color: var(--color-primaryLight);

  > span {
    line-height: 2.5em;
    padding-left: var(--spacing-smallPlus);
  }
`

const PackagesList = styled.ul`
`

const PackagesListItem = styled.li`
`
