const { featureToggles: configMapsFeatureToggles = [] } = window.autoplan_env

const featureToggles = {
  customerCarConfiguration: configMapsFeatureToggles.includes('customerCarConfiguration'),
  minBedrift: configMapsFeatureToggles.includes('minBedrift'),
  dinKontaktperson: configMapsFeatureToggles.includes('dinKontaktperson'),
  rapporter: {
    kilometer: configMapsFeatureToggles.includes('rapporter.kilometer'),
    parkeringer: configMapsFeatureToggles.includes('rapporter.parkeringer'),
    forbruk: configMapsFeatureToggles.includes('rapporter.forbruk'),
  },
  meldSkade: configMapsFeatureToggles.includes('meldSkade'),
  dokumentarkiv: configMapsFeatureToggles.includes('dokumentarkiv'),
  tilgang: configMapsFeatureToggles.includes('tilgang'),
  kontaktSkjema: configMapsFeatureToggles.includes('kontaktskjema'),
}

export default featureToggles
