export const formatRequiresText = (requires, getOptionNameByOptionId, optionsExcludedFromRequirement = []) => {
  const texts = []
  requires.forEach((req, index) => {
    if (req.optionId.length > 1) {
      const exclusiveOptions = []
      req.optionId.forEach((oneOfOptions, j) => {
        if (!optionsExcludedFromRequirement.some(o => o.optionId === oneOfOptions)) {
          exclusiveOptions.push(`"${getOptionNameByOptionId(oneOfOptions)}"`)
          if (req.optionId[j + 1]) {
            exclusiveOptions.push("eller")
          }
        }
      })
      texts.push(...exclusiveOptions)
      if (requires[index + 1]) {
        texts.push("og")
      }
    } else if (!optionsExcludedFromRequirement.some(o => o.optionId === req.optionId[0])) {
      texts.push(`"${getOptionNameByOptionId(req.optionId[0])}"`)
      if (requires[index + 1]) {
        texts.push("og")
      }
    }
  })

  return texts
}

export default formatRequiresText