import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Label, Layout } from '@staccx/bento'
import { Heading } from '../../../components/text/Headings'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import InvoiceReportTable from '../components/InvoiceReportTable'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useChosenCompany } from '../../../context/UserProvider'
import { fetchInvoiceReport } from '../../../api/api'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const InvoiceReport = () => {
  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], status } = useQuery(['invoiceReport', companyId], () => fetchInvoiceReport(company.id), {
    enabled: !!companyId
  })

  const invoiceYears = useMemo(() => {
    return [...new Set(data.map((el) => new Date(el.postingDate).getFullYear()))]
  }, [data])

  const [year, yearSet] = useState(new Date().getFullYear())

  useEffect(() => {
    yearSet(invoiceYears[0])
  }, [invoiceYears.length])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const options = invoiceYears.map((item) => ({ value: item, label: item }))
  const selected = { value: year, label: year }

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'INVOICEREPORT_HEADING'}>
          {(value) => <Heading>{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="PERIOD">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown value={selected} options={options} onChange={(selectedItem) => yearSet(selectedItem.value)} />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <InvoiceReportTable
        isLoading={status === 'loading'}
        data={data
          .filter((invoice) => new Date(invoice.postingDate).getFullYear() === year)
          .filter(agreementFilter('consolidateInvoiceCode'))}
      />
    </Container>
  )
}

export default InvoiceReport
