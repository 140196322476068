export function fetchGroupRows(lines) {
  return lines?.reduce((acc, e) => {
    const found = acc.find((x) => e.group === x.group)

    found
      ? (found.amountIncVAT += e.amountIncVAT) &&
        found?.subRows.push({
          carDescription: e?.description,
          amountIncVAT: e?.amountIncVAT,
          sourceID: e?.sourceID,
          sourceType: e?.sourceType,
          documentNo: e?.documentNo,
          lineNo: e?.lineNo,
        })
      : acc.push({
          carDescription: 'SUM',
          amountIncVAT: e?.amountIncVAT,
          oldDescription: e.description,
          group: e?.group,
          sourceID: e?.sourceID,
          sourceType: e?.sourceType,
          documentNo: e?.documentNo,
          lineNo: e?.lineNo,
          subRows: [
            {
              carDescription: e?.description,
              amountIncVAT: e?.amountIncVAT,
              sourceID: e?.sourceID,
              sourceType: e?.sourceType,
              documentNo: e?.documentNo,
              lineNo: e?.lineNo,
            },
          ],
        })

    return acc
  }, [])
}
