import React from 'react'
import styled from 'styled-components'
import { CheckBox, font } from '@staccx/bento'
import { Heading } from '../../../../../components/text/Headings'
import { TranslatedText } from '@staccx/i18n'
import formatOptionName from '../../../../../utils/formatOptionName'
import CustomPopover from '../../popover/Popover'

const getTextUntilRequires = (texts) => {
  const requiresTextIndex = texts.includes('Requires:#-#Krever:') && texts.indexOf('Requires:#-#Krever:')
  const textUntilRequires = requiresTextIndex ? texts.slice(0, requiresTextIndex) : texts
  const textWithoutExcludes = getTextAfterExcludes(textUntilRequires)
  return textWithoutExcludes
}

const excludedStrings = ['Excludes:#-#Utelukker:', 'And#|#Og']

const getTextAfterExcludes = (texts) => {
  const filteredArray = texts.filter((text, i) => {
    return excludedStrings.includes(text) || excludedStrings.includes(texts[i - 1]) ? false : true
  })
  return filteredArray
}

const CarEquipmentPackage = ({
  equipmentPackage,
  onSelect,
  touchedPackages,
  getOptionExcludedBy,
  getOptionNameByOptionId,
}) => {
  const { optionName, optionId, includes, attributes, isRequired: isRequiredByAutoplan } = equipmentPackage

  const { selectedOptions, excludedOptions, includedOptions } = touchedPackages

  const handleCheck = (pack) => {
    onSelect(pack)
  }

  const isSelected =
    (selectedOptions && selectedOptions.some((pack) => pack.optionId === optionId)) || isRequiredByAutoplan

  const isExcluded = excludedOptions && excludedOptions.includes(optionId)

  const isIncluded = includedOptions && includedOptions.includes(optionId)

  const isExcludedBy = isExcluded ? getOptionExcludedBy(optionId).map((item) => item.optionName) : null

  const isDisabled = isIncluded || isExcluded || isRequiredByAutoplan

  const description = getTextUntilRequires(attributes)
  const hasIncludes = (includes && includes.length) || (description && description.length)

  return (
    <Outer isSelected={isSelected} hasIncludes={hasIncludes}>
      <div>
        <StyledHeading level={5}>{formatOptionName(optionName)}</StyledHeading>
      </div>
      {hasIncludes ? (
        <Content>
          <TranslatedText i18nKey={'INCLUDES'}>{(value) => <strong>{value}</strong>}</TranslatedText>
          <PackageContents>
            {includes.length > 0
              ? includes.map((item, i) => <li key={`${i}${item}`}>{formatOptionName(getOptionNameByOptionId(item))}</li>)
              : description.map((item, i) => <li key={`${i}${item}`}>{formatOptionName(item)}</li>)}
          </PackageContents>
        </Content>
      ) : (
        <div />
      )}
      <div>
        <StyledLabel>
          <StyledCheckBox
            onChange={() => handleCheck(equipmentPackage)}
            id={`Package-${optionId}`}
            defaultChecked={isIncluded}
            checked={isSelected}
            disabled={isDisabled}
          />
          <div>
            {isExcluded ? (
              <div>
                <TranslatedText i18nKey={'UNAVAILABLE'} />
                <CustomPopover>
                  <TooltipHeading>
                    <TranslatedText i18nKey={'EXCLUDED_BY'} />
                  </TooltipHeading>
                  <ItemList>
                    <ul>
                      {isExcludedBy.map((i) => (
                        <li key={i}>{formatOptionName(i)}</li>
                      ))}
                    </ul>
                  </ItemList>
                </CustomPopover>
              </div>
            ) : (
              <div>
                <TranslatedText i18nKey={'CHOOSE'} />
              </div>
            )}
          </div>
        </StyledLabel>
      </div>
    </Outer>
  )
}

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
`

const Outer = styled.div`
  display: grid;
  grid-template-columns: ${(p) => (p.hasIncludes ? 'minmax(auto, 30%) 1fr auto' : '1fr 0 auto')};
  align-items: center;
  grid-column-gap: var(--spacing-medium);
  padding: var(--spacing-smallPlus);
`
const Content = styled.div`
  font-size: ${font.small};
`

const PackageContents = styled.ul`
  list-style-type: disc;
  padding-left: 1rem;
`

const TooltipHeading = styled(Heading)`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-base);
`

const StyledLabel = styled.label`
  div {
    display: inline-block;
    vertical-align: middle;
  }
`

const StyledCheckBox = styled(CheckBox)`
  & ~ label::before {
    top: 0;
  }

  & ~ label > svg {
    top: var(--spacing-microPlus);
  }

  & ~ label {
    display: block;
  }

  &[disabled] ~ label::before {
    cursor: default;
    background-color: var(--color-line);
    border-color: var(--color-line);
  }
`

const ItemList = styled.div`
  ul li::before {
    content: '- ';
  }

  span {
    font-weight: var(--fontWeight-bold);
  }

  display: block;
`

export default CarEquipmentPackage
