import React from 'react'

interface DrivenWheelsProps {
  width?: number
  height?: number
  color?: string
}

const DrivenWheels = ({
  width = 50,
  height = 50,
  color = 'black',
}: DrivenWheelsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1101 13.229C26.7424 13.229 28.0657 11.9057 28.0657 10.2734C28.0657 8.64111 26.7424 7.31787 25.1101 7.31787C23.4777 7.31787 22.1545 8.64111 22.1545 10.2734C22.1545 11.9057 23.4777 13.229 25.1101 13.229Z"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.1101 43.1787C26.7424 43.1787 28.0657 41.8554 28.0657 40.2231C28.0657 38.5907 26.7424 37.2676 25.1101 37.2676C23.4777 37.2676 22.1545 38.5907 22.1545 40.2231C22.1545 41.8554 23.4777 43.1787 25.1101 43.1787Z"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3051 45.0872C12.3051 46.2695 11.5169 47.0576 10.3347 47.0576H6.39396C5.21174 47.0576 4.42358 46.2695 4.42358 45.0872V35.2354C4.42358 34.0532 5.21174 33.265 6.39396 33.265H10.3347C11.5169 33.265 12.3051 34.0532 12.3051 35.2354V45.0872Z"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8024 45.0872C45.8024 46.2695 45.0143 47.0576 43.832 47.0576H39.8913C38.7091 47.0576 37.9209 46.2695 37.9209 45.0872V35.2354C37.9209 34.0532 38.7091 33.265 39.8913 33.265H43.832C45.0143 33.265 45.8024 34.0532 45.8024 35.2354V45.0872Z"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.9922 16.0493C43.5981 17.0345 42.613 17.6256 41.4307 17.2315L37.687 15.8523C36.7018 15.4582 36.1107 14.473 36.5048 13.2908L39.6574 4.03003C40.0515 3.04484 41.0367 2.45373 42.2189 2.8478L45.9626 4.22707C46.9478 4.62114 47.5389 5.60633 47.1448 6.78855L43.9922 16.0493Z"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5557 16.0493C10.1616 17.0345 9.17644 17.6256 7.99421 17.2315L4.2505 15.8523C3.26531 15.4582 2.67418 14.473 3.06826 13.2908L6.22087 4.03003C6.61495 3.04484 7.60012 2.45373 8.78235 2.8478L12.5261 4.22707C13.5113 4.62114 14.1024 5.60633 13.7083 6.78855L10.5557 16.0493Z"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6997 10.2734H22.1575"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.0688 10.2734H37.3296"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5015 40.2233H22.1562"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.0688 40.2233H37.9207"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.1118 13.2291V37.2677"
      stroke={color}
      stroke-width="2.48889"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default DrivenWheels
