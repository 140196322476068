import React from 'react'
import styled from 'styled-components'
import Flex from '../../../components/Flex'
import { Car } from '../../../types'
import { formatDateString } from '../../../utils/dateHelper'
import { addMonths } from 'date-fns'
import { TranslatedText } from '@staccx/i18n'

const ServiceCard: React.FC<{ car: Car }> = ({ car }) => {
  return (
    <Wrapper>
      <Flex justify={'space-between'}>
        <Flex gap={'var(--spacing-mediumMinus)'}>
          <ServiceCardWrapper>
            <img src="/service-card.svg" alt="service card image" />
          </ServiceCardWrapper>

          <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
            <HeadingText>
              <TranslatedText i18nKey={'SERVICECARD_MENUITEM'} />
            </HeadingText>
            <Flex gap={'var(--spacing-mediumMinus)'}>
              <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
                <div>
                  <TranslatedText i18nKey={'SERVICE_CARD_NO'} />
                </div>
                <BoldText>{car.budgetNo || 'Ukjent'}</BoldText>
              </Flex>
              <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
                <div>
                  <TranslatedText i18nKey={'EXPIRATION_DATE'} />
                </div>
                <BoldText>
                  {car.plannedReturnDate
                    ? formatDateString(
                        addMonths(new Date(car.plannedReturnDate), 1),
                      )
                    : 'Ukjent'}
                </BoldText>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default ServiceCard

const Wrapper = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-smallPlus);
  font-size: var(--font-text);
  width: 100%;
`

const HeadingText = styled.div`
  font-size: var(--font-base);
  color: var(--color-primary);
`

const BoldText = styled.div`
  font-weight: var(--fontWeight-bold);
`

const ServiceCardWrapper = styled.div`
  width: 140px;
`
