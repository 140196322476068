import React from "react";
import CarConfigContainer from "../components/CarConfigContainer";
import styled from "styled-components";
import useCarConfiguration, { NO_ACCESS } from "../hooks/useCarConfiguration";
import { Button, theming } from "@staccx/bento";
import { TranslatedText } from "@staccx/i18n";
import { useParams } from "react-router-dom"
import { useFlow } from "../../../context/FlowProvider"
import Container from "../../../components/Container"

const NoAccess = () => {
  const { flowId } = useParams();
  const { flow, refetch } = useFlow(flowId);
  const { restartCarConfig, isLoading } = useCarConfiguration({ page: NO_ACCESS, flow, refetch })

  return <Container isLoading={isLoading}>
    <SadFace>
      :-(
    </SadFace>
    <ErrorBody>
      <TranslatedText i18nKey={"ERROR_NO_ACCESS"}>
        {value => <span>{value}</span>}
      </TranslatedText>
      <Button variant="primary" onClick={() => restartCarConfig.mutate()}>
        Start på nytt
      </Button>
    </ErrorBody>
  </Container>
}

export const SadFace = styled.p`
  font-size: 4em;
  text-align: center;
  padding: ${theming.spacing.large()} 0;
`

export const ErrorBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theming.spacing.mediumPlus()};
  padding: ${theming.spacing.large()} 0;
  text-align: center;
  > button, > span {
    display: flex;
  }
`

export default NoAccess
