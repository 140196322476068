import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import TanStackTable from '../../../../components/tables/TanStackTable'
import invoiceReportTableData from './invoiceReportTableData'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../../context/UserProvider'

type InvoiceReportTableProps = {
  data: any
  isLoading?: boolean
}
function InvoiceReportTable({ data, isLoading }: InvoiceReportTableProps) {
  let navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])
  // @ts-ignore  remove this ts-ignore when UserContext is type safe
  const { handleSetConsolidateInvoice, chosenCompany } = useUserContext()

  const columns = invoiceReportTableData(chosenCompany)
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <TanStackTable
      table={table}
      handleRowClick={(e) => {
        handleSetConsolidateInvoice(e.original.consolidateInvoiceCode)
        navigate(`/reports/invoice/${e.original.invoiceNo}`)
      }}
      isLoading={isLoading}
    />
  )
}

export default InvoiceReportTable
