import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Car } from '../../../types'
import Flex from '../../../components/Flex'
import Mileage from './visuals/Mileage'

import { TranslatedText } from '@staccx/i18n'
import MileageDialog from './MileageDialog'

const MileageCard: React.FC<{ car: Car }> = ({ car }) => {
  const [parentWidth, setParentWidth] = useState(0)

  useEffect(() => {
    const parentElement = document.getElementById('parent-div')
    if (parentElement) {
      const width = parentElement.offsetWidth
      setParentWidth(width)
    }
  }, [])

  return (
    <Wrapper>
      <Flex direction={'column'} gap={'20px'}>
        <div style={{ width: '100%' }}>
          <Flex justify={'space-between'} align={'center'}>
            <HeadingText>
              <TranslatedText i18nKey="MILAGE" />
            </HeadingText>
            <MileageDialog car={car} />
          </Flex>
        </div>

        <div id="parent-div" style={{ width: '100%' }}>
          {car.mileage && <Mileage car={car} radius={6} width={parentWidth} />}
        </div>
      </Flex>
    </Wrapper>
  )
}

export default MileageCard

const Wrapper = styled.div`
  background-color: var(--color-white);
  padding: 25px;
  width: 100%;
`

const HeadingText = styled.div`
  font-size: 16px;
  color: var(--color-primary);
`
