import React, { useState } from "react"
import styled from "styled-components"
import { Button, Layout } from "@staccx/bento"
import Alert from "../../../components/Alert"
import Login from "./Login"
import { TranslatedText } from "@staccx/i18n"
import { loggedoutReasons } from "../../../constants/statusMessages"
import Logo from "../../../icons/Logo"

const LOGIN_TYPE_EMAIL = "loginEmail"
const LOGIN_TYPE_SMS = "loginSMS"
const loggedoutReason = sessionStorage.getItem("loggedoutReason")

const LoginChoice = () => {
  const [type, typeSet] = useState(null)

  return (
    <React.Fragment>
      {!type && (
        <Layout style={{ maxWidth: "400px" }}>
          <Center>
            <Logo />
          </Center>{" "}
          <Button onClick={() => typeSet(LOGIN_TYPE_SMS)}>
            <TranslatedText i18nKey="LOGIN_WITH_SMS"/>
          </Button>
          <Button onClick={() => typeSet(LOGIN_TYPE_EMAIL)}>
            <TranslatedText i18nKey="LOGIN_WITH_EMAIL"/>
          </Button>
          <LoggedoutReason/>
        </Layout>
      )}
      {type === LOGIN_TYPE_SMS && <Login type={"sms"}/>}
      {type === LOGIN_TYPE_EMAIL && <Login type={"email"}/>}
    </React.Fragment>
  )
}

const LoggedoutReason = () => {
  switch (loggedoutReason) {
    case loggedoutReasons.UNKNOWN_USER:
      return (
        <TranslatedText i18nKey={loggedoutReason}>
          {value => <Alert type={"warning"}>{value}</Alert>}
        </TranslatedText>
      )

    case loggedoutReasons.NORMAL_LOGOUT:
      return (
        <TranslatedText i18nKey={loggedoutReason}>
          {value => <Alert type={"info"}>{value}</Alert>}
        </TranslatedText>
      )

    default:
      return null
  }
}

const Center = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

LoginChoice.propTypes = {}

export default LoginChoice
