import React from "react"
import { LanguageProvider } from "@staccx/i18n"
import { useI18nContext } from "./context/I18nProvider";

const LanguageProviderProxy = ({ children }) => {
  const i18n = useI18nContext()
  if (!i18n.texts) {
    return null
  }

  return (
    <LanguageProvider
      setLanguage={i18n.setLanguage}
      texts={i18n.texts}
      language={i18n.language}
      languages={i18n.languages}
    >
      {children}
    </LanguageProvider>
  )
}

export default LanguageProviderProxy
