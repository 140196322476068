const Car2 = () => {
  return (
    <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33337 0C8.50537 0 7.83337 0.672 7.83337 1.5V3H3.33337C1.67887 3 0.333374 4.3455 0.333374 6V28.5C0.333374 30.1545 1.67887 31.5 3.33337 31.5H21.3334C22.9879 31.5 24.3334 30.1545 24.3334 28.5V6C24.3334 4.3455 22.9879 3 21.3334 3H16.8334V1.5C16.8334 0.672 16.1629 0 15.3334 0H9.33337ZM10.8334 3H13.8334V7.5H10.8334V3ZM3.33337 6H7.83337V9C7.83337 9.828 8.50537 10.5 9.33337 10.5H15.3334C16.1629 10.5 16.8334 9.828 16.8334 9V6H21.3334V28.5H3.33337V6ZM8.58337 16.5L6.33337 20.25V24.6914C6.33337 25.1414 6.69197 25.5 7.14197 25.5H8.52478C8.97478 25.5 9.33337 25.1414 9.33337 24.6914V24H15.3334V24.6914C15.3334 25.1414 15.692 25.5 16.142 25.5H17.5248C17.9748 25.5 18.3334 25.1414 18.3334 24.6914V20.0713L16.0834 16.5H8.58337ZM8.95837 20.25C9.57337 20.25 10.0834 20.76 10.0834 21.375C10.0834 21.99 9.57337 22.5 8.95837 22.5C8.34337 22.5 7.83337 21.99 7.83337 21.375C7.83337 20.76 8.34337 20.25 8.95837 20.25ZM15.7084 20.25C16.3234 20.25 16.8334 20.76 16.8334 21.375C16.8334 21.99 16.3234 22.5 15.7084 22.5C15.0934 22.5 14.5834 21.99 14.5834 21.375C14.5834 20.76 15.0934 20.25 15.7084 20.25Z"
        fill="#467EFF"
      />
    </svg>
  )
}

export default Car2
