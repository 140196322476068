import React from 'react'
import { useQuery } from 'react-query'
import { useUserContext } from '../../../context/UserProvider'
import { Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { fetchFleetReport, fetchFleetReportTerms } from '../../../api/api'
import { TranslatedText } from '@staccx/i18n'
import LeasingReportTable from '../components/LeasingReportTable'
import ReportTermSelect from '../components/ReportTermSelect'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import { Heading } from '../../../components/text/Headings'

const LeasingReport = () => {
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id
  const { data: terms = [] } = useQuery(['fleetReportTerms', companyId], () => fetchFleetReportTerms(companyId), {
    enabled: !!companyId
  })

  const { data = [], status } = useQuery(
    ['fleetReport', companyId, fleetReportTerm],
    () => fetchFleetReport(companyId, fleetReportTerm, 'leasing'),
    { enabled: !!companyId }
  )

  const agreementFilter = useFilterOnConsolidateAgreement()

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'LEASINGREPORT_HEADING'}>{(value) => <Heading>{value}</Heading>}</TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <LeasingReportTable
        isLoading={status === 'loading'}
        data={data
          .filter(agreementFilter('consolidatedAgreementID'))
          .filter((el) => el.termDate.startsWith(fleetReportTerm))}
      />
    </Container>
  )
}

export default LeasingReport
