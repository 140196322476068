import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import tollReportData from './tollReportData'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../../../context/UserProvider'
import { columnSum, formatCurrency } from '../../../../../utils/tableFuncs'

type TollReportTableProps = {
  data: any
  isLoading?: boolean
}
function TollReportTable({ data, isLoading }: TollReportTableProps) {
  let navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])

  // @ts-ignore  remove this ts-ignore when UserContext is type safe
  const { handleSetConsolidateInvoice, chosenCompany } = useUserContext()
  const columns = tollReportData(chosenCompany)

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    amountIncVAT: isNaN(columnSum(data, 'amountIncVAT')) ? '0' : formatCurrency(columnSum(data, 'amountIncVAT')),
    antallPasseringer: isNaN(columnSum(data, 'antallPasseringer'))
      ? '0'
      : formatCurrency(columnSum(data, 'antallPasseringer')),
  }

  return (
    <TanStackTable
      table={table}
      handleRowClick={(e) => {
        handleSetConsolidateInvoice(e.original.consolidatedInvoiceAgreement)
        navigate(`/reports/toll/${e.original.tYear}/${e.original.tMonth}`)
      }}
      isLoading={isLoading}
      footerData={footerData}
    />
  )
}

export default TollReportTable
