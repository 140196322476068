export const eventFilter = {
  all: "ALL",
  deviations: "deviation",
  invoices: "invoice",
  refuel: "refueling",
  tollPassing: "tollPassing",
  purchase: "purchase",
  additionalProducts: "additionalProducts",
  transactions: "transactions"
}

export default eventFilter