import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import { Text, Wrapper, theming } from '@staccx/bento'
import {
  useBudgetSelections,
  usePendingUserTasks,
} from '../../../context/SigningProvider'
import Container from '../../../components/Container'
import { Selection } from '../components/budgetSelection/Selection'
import Flex from '../../../components/Flex'

const BudgetSelection = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { budgetSelections, isLoading: budgetSelectionsLoading } =
    useBudgetSelections()
  const { pendingUserTasks, isLoading: pendingUserTasksLoading } =
    usePendingUserTasks(id)
  const budgetSelection = useMemo(
    () => findBudgetSelection(budgetSelections, id),
    [budgetSelections, id],
  )
  const selections = _.groupBy(
    budgetSelection?.data?.budgetData.carBudgetOptionalSetup,
    'customerChoice',
  )

  const budgetData = budgetSelection?.data?.budgetData

  const handleReplacesClick = () => {
    if (budgetData.replacesRegNo) {
      navigate(`/cars/${budgetData.replacesRegNo}`)
    }
  }

  const handleDriverClick = () => {
    if (budgetData.driver) {
      navigate(`/drivers?name=${budgetData.driver}`)
    }
  }

  return (
    <Container
      isLoading={budgetSelectionsLoading || pendingUserTasksLoading}
      renderChildrenDuringLoading={false}
    >
      <Wrapper>
        {budgetSelection && pendingUserTasks?.tasks.length > 0 ? (
          <>
            <CarSummary>
              <Title>
                <TranslatedText i18nKey="CUSTOMIZE_CAR_OPTIONS">
                  {(value) => value ?? 'Gjøre tilvalg på bil'}
                </TranslatedText>
              </Title>

              <BudgetInfo>
                <Subtitle>
                  {budgetData.carMake.toUpperCase()}{' '}
                  {budgetData.carModel.toUpperCase()} -{' '}
                  <TranslatedText i18nKey="BUDGET">
                    {(value) => value ?? 'Budsjett'}
                  </TranslatedText>{' '}
                  {budgetData.carBudgetNo}
                </Subtitle>

                <TranslatedText i18nKey="DRIVER">
                  {(value) => (
                    <Flex gap={'4px'}>
                      <BoldText>
                        {value}
                        {':'}
                      </BoldText>
                      <Link onClick={handleDriverClick}>
                        {budgetData.driver || '-'}
                      </Link>
                    </Flex>
                  )}
                </TranslatedText>

                {budgetData?.replacesCarMake &&
                  budgetData?.replacesCarModel &&
                  (budgetData?.replacesRegNo ||
                    budgetData?.replacesAccountNo) && (
                    <TranslatedText i18nKey="REPLACES">
                      {(value) => (
                        <Flex gap={'4px'}>
                          <BoldText>
                            {value}
                            {':'}
                          </BoldText>
                          <Link onClick={handleReplacesClick}>
                            {budgetData?.replacesCarMake.toUpperCase()}{' '}
                            {budgetData?.replacesCarModel.toUpperCase()},{' '}
                            {budgetData.replacesRegNo ||
                              budgetData.replacesAccountNo}
                          </Link>
                        </Flex>
                      )}
                    </TranslatedText>
                  )}
              </BudgetInfo>
            </CarSummary>
            <Selection
              taskId={pendingUserTasks.tasks[0].taskId}
              budgetId={budgetSelection.data.budgetData.carBudgetNo}
              selections={selections}
            />
          </>
        ) : (
          <TranslatedText i18nKey={'BUDGET_SELECTION_OPEN_ERROR'}>
            {(value) => <div>{value}</div>}
          </TranslatedText>
        )}
      </Wrapper>
    </Container>
  )
}

const findBudgetSelection = (budgetSelections, id) =>
  budgetSelections && budgetSelections.find((i) => i.flowId === id)

const CarSummary = styled.div`
  padding: var(--spacing-smallPlus) 0 var(--spacing-smallPlus) 0;
  margin-bottom: ${theming.spacing.medium()};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Title = styled.span`
  font-size: var(--spacing-medium);
  font-weight: 400;
  margin-bottom: ${theming.spacing.medium()};
`

const Subtitle = styled.div`
  font-size: var(--font-h3);
  font-weight: 500;
`

const BudgetInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #e5eeff;
  padding: 16px;
  max-width: 750px;
  border-radius: 4px;
  font-size: var(--font-h4);
`

const BoldText = styled.span`
  font-weight: var(--fontWeight-bold);
`

const Link = styled.div`
  cursor: pointer;
  color: var(--color-black);

  &:hover {
    color: var(--color-primary);
  }
`

export default BudgetSelection
