import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import carListData from './carListData'
import TanStackTable from '../../../../components/tables/TanStackTable'
import { useNavigate } from 'react-router-dom'

const columns = carListData()

type CarListProps = {
  data: any
  isLoading?: boolean
}

function CarList({ isLoading, data }: CarListProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  let navigate = useNavigate()

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <TanStackTable
      table={table}
      isLoading={isLoading}
      handleRowClick={(row) => {
        navigate(`/cars/${row.original.registrationNumber}`)
      }}
    />
  )
}

export default CarList
