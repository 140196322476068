import React from 'react'
import type { InvoiceDetails } from '../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import ExpandClose from '../../../../icons/ExpandClose'
import ExpandOpen from '../../../../icons/ExpandOpen'
import styled from 'styled-components'
import { formatCurrency } from '../../../../utils/tableFuncs'
import PdfDownload from '../../../../components/tables/PdfDownload'

export default function invoiceReportDetailsTableData() {
  const columnHelper = createColumnHelper<InvoiceDetails>()
  return [
    {
      id: 'accountNo',
      header: () => <TranslatedText i18nKey={'CONTRACT_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,

      // cell: (row) => row.getValue() || '',
      cell: ({ row, getValue }: any) => (
        <div
          style={{
            // Since rows are flattened by default,
            // we can use the row.depth property
            // and paddingLeft to visually indicate the depth
            // of the row
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          <>
            {row.getCanExpand() && !!getValue() ? (
              <ExpandButton
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: 'pointer' },
                }}
              >
                {row.getIsExpanded() ? <ExpandOpen width={10} height={10} /> : <ExpandClose width={10} height={10} />}
              </ExpandButton>
            ) : (
              <></>
            )}{' '}
            {getValue()}
          </>
        </div>
      ),
      meta: { alignmentType: 'string' },
      accessorKey: 'accountNo',
    },
    {
      accessorKey: 'group',
      id: 'group',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'POSTING_GROUP'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      // cell: (row) => row.getValue() || '',
      cell: ({ row, getValue }: any) => (
        <div
          style={{
            // Since rows are flattened by default,
            // we can use the row.depth property
            // and paddingLeft to visually indicate the depth
            // of the row
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          <>
            {row.getCanExpand() && !!getValue() ? (
              <ExpandButton
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: 'pointer' },
                }}
              >
                {row.getIsExpanded() ? <ExpandOpen width={10} height={10} /> : <ExpandClose width={10} height={10} />}
              </ExpandButton>
            ) : (
              <></>
            )}{' '}
            {getValue()}
          </>
        </div>
      ),
    },
    columnHelper.accessor('regNo', {
      id: 'regNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('carDescription', {
      id: 'carDescription',
      header: () => <TranslatedText i18nKey={'DESCRIPTION'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => (
        <div style = {{
          overflow: 'hidden',
        }}>
          {PdfDownload(row)}
        </div>
      ),
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('amountIncVAT', {
      id: 'amountIncVAT',
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
  ]
}
const ExpandButton = styled.button<React.HTMLAttributes<HTMLButtonElement>>`
  background-color: transparent;
  border: none;
  color: var(--color-primary);
`
