import React from 'react'
import styled from 'styled-components'
import { NewCarButton } from '../../../theme/Theme.Button'
import AnchorIcon from '../../../icons/Anchor'

const AnchorButton = ({ text, icon, action }) => {
  return (
    <NewCarButton onClick={action}>
      <Row>
        <CustomColumn>
          <Text>
            {icon}
            {text}
          </Text>
        </CustomColumn>
        <Chevron>
          <AnchorIcon stroke={`var(--color-primary)`} />
        </Chevron>
      </Row>
    </NewCarButton>
  )
}
export default AnchorButton

const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  height: 100%;
  padding: var(--spacing-smallPlus);
`
const CustomColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 12px;
`
const Chevron = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`
