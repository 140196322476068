import React from "react"
import { ThemeProvider, theming } from "@staccx/bento"
import { authService } from "./pages/login/routes/Login"
import LanguageProviderProxy from "./LanguageProviderProxy"
import { OpenIdProvider } from "@staccx/open-id"
import AppProvider from "./context/AppProvider"
import AutoplanRoutes from "./Routes"

const App = () => {
  if (window.location.pathname === "/callback-login-silent") {
    authService.renewTokenCallback().catch(console.log)

    return null
  }

  if (window.location.pathname === "/callback-logout") {
    authService.userManager
      .signoutRedirectCallback()
      .then(() => {
        localStorage.removeItem("companyId")
        window.location.replace("/login")
      })
      .catch(console.log)

    return null
  }
  return (
    <OpenIdProvider authService={authService}>
      <ThemeProvider theme={theming.theme}>
        <AppProvider>
          <LanguageProviderProxy>
            <AutoplanRoutes />
          </LanguageProviderProxy>
        </AppProvider>
      </ThemeProvider>
    </OpenIdProvider>
  )
}

export default App
