import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import type { Drivers } from '../../../types'

export default function driversTableData() {
  const columnHelper = createColumnHelper<Drivers>()
  return [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <TranslatedText i18nKey={'NAME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('jobTitle', {
      id: 'jobTitle',
      header: () => <TranslatedText i18nKey={'WORK_ROLE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('phoneNo', {
      id: 'phoneNo',
      header: () => <TranslatedText i18nKey={'TELEPHONE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => <TranslatedText i18nKey={'EMAIL'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('department', {
      id: 'department',
      header: () => <TranslatedText i18nKey={'DEPARTMENT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('webUserType', {
      id: 'webUserType',
      header: () => <TranslatedText i18nKey={'ROLE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => {
        if (row.getValue() === 'Administrator') {
          return <TranslatedText i18nKey={'ADMIN'}>{(value: string) => <span>{value}</span>}</TranslatedText>
        } else if (row.getValue() === 'Driver') {
          return <TranslatedText i18nKey={'DRIVER'}>{(value: string) => <span>{value}</span>}</TranslatedText>
        } else {
          return ''
        }
      },
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('hasConfiguratorAccess', {
      id: 'hasConfiguratorAccess',
      header: () => <TranslatedText i18nKey={'ACCESS_SINGULAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => {
        if (row.getValue()) {
          return <TranslatedText i18nKey={'YES'}>{(value: string) => <span>{value}</span>}</TranslatedText>
        } else {
          return <TranslatedText i18nKey={'NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>
        }
      },
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('regNo', {
      id: 'regNo',
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    })
  ]
}
