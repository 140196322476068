import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import tableData from './fleetReportExpenseData'
import { columnSum, formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'

const columns = tableData()

type FleetReportDetailsProps = {
  data: any
  isLoading?: boolean
}
function FleetReportExpenseTable({ data, isLoading }: FleetReportDetailsProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    amountIncludingVAT: isNaN(columnSum(data, 'amountIncludingVAT'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'amountIncludingVAT')),
  }

  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default FleetReportExpenseTable
