import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import InvoiceList from '../components/InvoiceList'
import { useEventContext } from '../../../context/EventProvider'
import Container from '../../../components/Container'

import {ReportHeading} from "../../../components/text/Headings";

const Invoices = () => {
  const { invoices, isLoading } = useEventContext()

  return (
    <Container>
      <TranslatedText i18nKey={'INVOICES_HEADING'}>{(value) => <ReportHeading>{value}</ReportHeading>}</TranslatedText>
      <InvoiceList data={invoices} isLoading={isLoading} />
    </Container>
  )
}

export default Invoices
