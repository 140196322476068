import React from "react"

const Pdf = () => (
  <span style={{ marginRight: "10px" }}>
    <svg
      width="18"
      height="22"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1162 6.13498L22.1165 6.13532C22.4776 6.52663 22.8279 7.13717 23.0882 7.81901C23.3481 8.49973 23.5 9.20367 23.5 9.765V18.6V27.435C23.5 28.4768 22.7219 29.26 21.8571 29.26H2.14286C1.27805 29.26 0.5 28.4768 0.5 27.435V2.325C0.5 1.28321 1.27805 0.5 2.14286 0.5H15C15.497 0.5 16.1314 0.659596 16.7531 0.939C17.3732 1.21774 17.9315 1.59361 18.2915 1.98293C18.2916 1.98303 18.2917 1.98313 18.2918 1.98322L22.1162 6.13498Z"
        fill="currentColor"
      />
      <path
        d="M22.2857 27.435C22.2857 27.6849 22.0875 27.9 21.8572 27.9H2.14287C1.91037 27.9 1.71429 27.6849 1.71429 27.435V2.32499C1.71429 2.07272 1.91037 1.85999 2.14287 1.85999H15C15.1286 1.85999 15.2732 1.87742 15.4286 1.90939V9.30289H22.2375C22.2697 9.47145 22.2857 9.62839 22.2857 9.76789V27.4379V27.435Z"
        fill="white"
      />
      <path
        d="M4.5957 22V14.8418H6.5C7.20964 14.8418 7.6735 14.876 7.8916 14.9443C8.23991 15.055 8.52637 15.291 8.75098 15.6523C8.97884 16.0137 9.09277 16.4792 9.09277 17.0488C9.09277 17.5664 8.99512 18.001 8.7998 18.3525C8.60449 18.7008 8.36035 18.9466 8.06738 19.0898C7.77441 19.2298 7.26986 19.2998 6.55371 19.2998H5.77734V22H4.5957ZM5.77734 16.0527V18.084H6.43164C6.87109 18.084 7.16895 18.0531 7.3252 17.9912C7.4847 17.9294 7.61491 17.8187 7.71582 17.6592C7.81673 17.4964 7.86719 17.2979 7.86719 17.0635C7.86719 16.8258 7.8151 16.6257 7.71094 16.4629C7.60677 16.3001 7.47819 16.1911 7.3252 16.1357C7.1722 16.0804 6.84831 16.0527 6.35352 16.0527H5.77734ZM10.0596 14.8418H12.2227C12.7695 14.8418 13.1813 14.9036 13.458 15.0273C13.738 15.1478 13.9951 15.3529 14.2295 15.6426C14.4639 15.9323 14.6478 16.3083 14.7812 16.7705C14.9147 17.2327 14.9814 17.8073 14.9814 18.4941C14.9814 19.1126 14.9115 19.6514 14.7715 20.1104C14.6315 20.5661 14.4541 20.9274 14.2393 21.1943C14.0244 21.4613 13.764 21.6631 13.458 21.7998C13.1553 21.9333 12.7646 22 12.2861 22H10.0596V14.8418ZM11.2412 16.0527V20.7939H12.1299C12.5107 20.7939 12.7858 20.7581 12.9551 20.6865C13.1276 20.6149 13.2676 20.5075 13.375 20.3643C13.4857 20.2178 13.5768 19.9883 13.6484 19.6758C13.7201 19.3633 13.7559 18.9515 13.7559 18.4404C13.7559 17.9098 13.7184 17.4915 13.6436 17.1855C13.5719 16.8796 13.458 16.6387 13.3018 16.4629C13.1488 16.2871 12.9616 16.1715 12.7402 16.1162C12.5742 16.0739 12.2536 16.0527 11.7783 16.0527H11.2412ZM15.9922 22V14.8418H20.0156V16.0527H17.1738V17.7471H19.6299V18.958H17.1738V22H15.9922Z"
        fill="currentColor"
      />
      <path
        d="M21.2732 7.1087C21.359 7.2017 21.4393 7.31213 21.5197 7.44001H17.1429V2.68945C17.2607 2.7778 17.3625 2.86848 17.4482 2.95915L21.2732 7.1087Z"
        fill="white"
      />
    </svg>
  </span>
)

export default Pdf
