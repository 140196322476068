import React from 'react'
import useCarConfiguration, { NO_ACCESS } from '../hooks/useCarConfiguration'
import { ErrorBody, SadFace } from './NoAccess'
import { Button } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { useParams } from 'react-router-dom'
import { useFlow } from '../../../context/FlowProvider'
import Container from '../../../components/Container'

const UnknownError = () => {
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)
  const { refresh, isLoading } = useCarConfiguration({ page: NO_ACCESS, flow, refetch })
  return (
    <Container isLoading={isLoading}>
      <SadFace>:-(</SadFace>
      <ErrorBody>
        <TranslatedText i18nKey={'ERROR_UNKNOWN'}>{(value) => <span>{value}</span>}</TranslatedText>
        <Button variant="primary" onClick={() => refresh()}>
          Prøv på nytt
        </Button>
      </ErrorBody>
    </Container>
  )
}

export default UnknownError
