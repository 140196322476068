import {
  FUEL_TYPE_BENSIN,
  FUEL_TYPE_DIESEL,
  FUEL_TYPE_ELECTRIC,
  FUEL_TYPE_FILTER_ALL,
  FUEL_TYPE_HYBRID,
  FUEL_TYPE_OTHER,
} from '../model/car'

import FuelTypeFilterCount from '../components/menu/FuelTypeFilterCount'

import CarFleet from '../icons/CarFleet'
import Consumption from '../icons/Consumption'
import Driver2 from '../icons/Driver2'
import Environment from '../icons/Environment'
import Fuel from '../icons/Fuel'
import Gauge from '../icons/Gauge'
import Invoice from '../icons/Invoice'
import Leasing from '../icons/Leasing'
import Parking from '../icons/Parking'
import Toll from '../icons/Toll'
import Money from '../icons/Money'

export const submenuCarFuelTypes = (path) => [
  {
    name: 'CARS_ALL_MENUITEM',
    filter: FUEL_TYPE_FILTER_ALL,
    path: `/${path}`,
    Count: FuelTypeFilterCount,
  },
  {
    name: 'CARS_DIESEL_MENUITEM',
    filter: FUEL_TYPE_DIESEL,
    path: `/${path}?fuel=${FUEL_TYPE_DIESEL}`,
    Count: FuelTypeFilterCount,
  },
  {
    name: 'CARS_GASOLINE_MENUITEM',
    filter: FUEL_TYPE_BENSIN,
    path: `/${path}?fuel=${FUEL_TYPE_BENSIN}`,
    Count: FuelTypeFilterCount,
  },
  {
    name: 'CARS_HYBRID_MENUITEM',
    filter: FUEL_TYPE_HYBRID,
    path: `/${path}?fuel=${FUEL_TYPE_HYBRID}`,
    Count: FuelTypeFilterCount,
  },
  {
    name: 'CARS_ELECTRIC_MENUITEM',
    filter: FUEL_TYPE_ELECTRIC,
    path: `/${path}?fuel=${FUEL_TYPE_ELECTRIC}`,
    Count: FuelTypeFilterCount,
  },
  {
    name: 'CARS_OTHER_MENUITEM',
    filter: FUEL_TYPE_OTHER,
    path: `/${path}?fuel=${FUEL_TYPE_OTHER}`,
    Count: FuelTypeFilterCount,
  },
]

export const informationSubmenu = [
  {
    name: 'INFORMATION_MENUITEM',
    path: '/information',
    breadcrumb: 'INFORMATION_MENUITEM',
  },
  {
    name: 'SERVICECARD_MENUITEM',
    path: '/information/servicecard',
    breadcrumb: 'SERVICECARD_MENUITEM',
    driversOnly: true,
  },
]

const kilometer = [
  {
    name: 'MILEAGE_REPORT_MENUITEM',
    path: '/reports/mileage',
    breadcrumb: 'MILEAGE_REPORT_MENUITEM',
    icon: <Gauge />,
  },
]

const parking = [
  {
    name: 'PARKING_MENUITEM',
    path: '/reports/parking',
    breadcrumb: 'PARKING_MENUITEM',
    icon: <Parking />,
  },
]

const consumption = [
  {
    name: 'CONSUMPTION_MENUITEM',
    path: '/reports/consumption',
    breadcrumb: 'CONSUMPTION_MENUITEM',
    icon: <Consumption />,
  },
]

export const reportsSubmenuWithIcons = (featureToggles) => [
  {
    name: 'CAR_FLEET_MENUITEM',
    path: '/reports/fleet-export',
    breadcrumb: 'CAR_FLEET_MENUITEM',
    icon: <CarFleet />,
  },
  ...(featureToggles.rapporter.forbruk ? consumption : []),
  {
    name: 'FLEET_MENUITEM',
    path: '/reports/fleet',
    breadcrumb: 'FLEET_MENUITEM',
    icon: <Driver2 />,
  },
  {
    name: 'ENVIRONMENTAL_REPORT_MENUITEM',
    path: '/reports/environment',
    breadcrumb: 'ENVIRONMENTAL_REPORT_MENUITEM',
    icon: <Environment />,
  },
  {
    name: 'FUEL_REPORT_MENUITEM',
    path: '/reports/fuel',
    breadcrumb: 'FUEL_REPORT_MENUITEM',
    icon: <Fuel />,
  },
  {
    name: 'TOLLBOOTH_PASSINGS_MENUITEM',
    path: '/reports/toll',
    breadcrumb: 'TOLLBOOTH_PASSINGS_MENUITEM',
    icon: <Toll />,
  },
  {
    name: 'INVOICE_REPORT_MENUITEM',
    path: '/reports/invoice',
    breadcrumb: 'INVOICE_REPORT_MENUITEM',
    icon: <Invoice />,
  },
  {
    name: 'PURCHASE_REPORT_MENUITEM',
    path: '/reports/purchase',
    breadcrumb: 'PURCHASE_REPORT_MENUITEM',
    icon: <Money />,
  },
  {
    name: 'LEASING_REPORT_MENUITEM',
    path: '/reports/leasing',
    breadcrumb: 'LEASING_REPORT_MENUITEM',
    icon: <Leasing />,
  },
  // {
  //   name: 'MILEAGE_REPORT_MENUITEM',
  //   path: '/reports/mileage',
  //   breadcrumb: 'MILEAGE_REPORT_MENUITEM',
  //   icon: <Gauge />,
  // },
  ...(featureToggles.rapporter.parkeringer ? parking : []),
]

export const reportsSubmenu = (featureToggles) => [
  {
    name: 'CAR_FLEET_MENUITEM',
    path: '/reports/fleet-export',
    breadcrumb: 'CAR_FLEET_MENUITEM',
  },
  ...(featureToggles.rapporter.forbruk ? consumption : []),
  {
    name: 'FLEET_MENUITEM',
    path: '/reports/fleet',
    breadcrumb: 'FLEET_MENUITEM',
  },
  {
    name: 'ENVIRONMENTAL_REPORT_MENUITEM',
    path: '/reports/environment',
    breadcrumb: 'ENVIRONMENTAL_REPORT_MENUITEM',
  },
  {
    name: 'FUEL_REPORT_MENUITEM',
    path: '/reports/fuel',
    breadcrumb: 'FUEL_REPORT_MENUITEM',
  },
  {
    name: 'TOLLBOOTH_PASSINGS_MENUITEM',
    path: '/reports/toll',
    breadcrumb: 'TOLLBOOTH_PASSINGS_MENUITEM',
  },
  {
    name: 'INVOICE_REPORT_MENUITEM',
    path: '/reports/invoice',
    breadcrumb: 'INVOICE_REPORT_MENUITEM',
  },
  {
    name: 'PURCHASE_REPORT_MENUITEM',
    path: '/reports/purchase',
    breadcrumb: 'PURCHASE_REPORT_MENUITEM',
  },
  {
    name: 'LEASING_REPORT_MENUITEM',
    path: '/reports/leasing',
    breadcrumb: 'LEASING_REPORT_MENUITEM',
  },
  // {
  //   name: 'MILEAGE_REPORT_MENUITEM',
  //   path: '/reports/mileage',
  //   breadcrumb: 'MILEAGE_REPORT_MENUITEM',
  // },
  ...(featureToggles.rapporter.parkeringer ? parking : []),
]
