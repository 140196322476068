import React from "react"
import UserProvider from "./UserProvider"
import { QueryClient, QueryClientProvider } from "react-query"
import DriverProvider from "./DriverProvider"
import EventProvider from "./EventProvider";
import CarProvider from "./CarProvider";
import I18nProvider from "./I18nProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      enabled: true,
      retry(failureCount, error) {
        if (error.response && [403, 404].includes(error.response.status)) return false
        else return failureCount < 2
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      //useErrorBoundary: true
    }
  }
})

const AppProvider = ({ children }) => {

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <I18nProvider>
          <DriverProvider>
            <EventProvider>
              <CarProvider>
                {children}
              </CarProvider>
            </EventProvider>
          </DriverProvider>
        </I18nProvider>
      </UserProvider>
    </QueryClientProvider>
  )
}

export default AppProvider
