import styled from 'styled-components'

export const Heading = styled.p`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h2);
  margin-bottom: var(--spacing-mediumPlus);
`

export const SmallHeading = styled.span`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h3);
`

export const SmallSummaryHeading = styled.span`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h3);
  padding: 0 0 var(--spacing-tiny) 0;
`

export const HeadingLargePadded = styled.h2`
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
  padding: var(--spacing-small) 0 var(--spacing-small) 0;
`
export const ReportHeading = styled.p`
  margin: ${(props) => (props.noMargin ? '0' : '0 0 var(--spacing-largeMinus) 0')};
  font-size: var(--font-h1);
  font-weight: var(--fontWeight-bold);
`
export const H4Heading = styled.p`
  margin: var(--spacing-tiny) 0;
  line-height: var(--spacing-mediumPlus);
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h4);
`