import styled from 'styled-components'
import Chevron from '../../../icons/Chevron'
import Copy from '../../../icons/Copy'

export const Right = styled.div`
  display: grid;
  grid-template-columns: 1fr 90px;
`

export const ConfigurationInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  vertical-align: middle;
`

export const Fraction = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-tiny);
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  .chevron&:hover {
    svg {
      animation: moveSlightlyRight 0.2s 1 forwards;
    }

    @keyframes moveSlightlyRight {
      from {
        transform: rotate(-90deg) translateY(0) scale(1);
      }
      to {
        transform: rotate(-90deg) translateY(10px) scale(1.2);
      }
    }
  }

  .copy&:hover {
    svg {
      animation: scaleUp 0.2s 1 forwards;
    }

    @keyframes scaleUp {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
  }
`

export const StyledChevron = styled(Chevron)`
  width: 90px;
  height: 90px;
  transform: rotate(-90deg);
`

export const SeeMore = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    line-height: 2.5em;
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
  }
`

export const StyledCopy = styled(Copy)`
  width: 90px;
  height: 90px;
`
