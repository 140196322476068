import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { activeName } from './constants'
import { TranslatedText } from '@staccx/i18n'

const MenuItem = ({ renderSubmenuForPath, path, title, submenu, icon, onClick, islogo = 'false', count, ...props }) => {
  const { pathname } = useLocation()
  let processedSubMenu = submenu?.map((sub) => ({
    ...sub,
    active: (sub.path === pathname).toString()
  }))
  const isSubMenuRoute = submenu?.some((sub) => sub.path === pathname)
  const isCurrentRoute = (pathname?.substring(1) === path || pathname === path || isSubMenuRoute || false)?.toString()

  return (
    <MenuListItem hasSubMenu={processedSubMenu?.length > 0} key={title}>
      <MenuElement
        to={path}
        islogo={islogo}
        style={({ isActive }) => (isActive ? activeName : undefined)}
        onClick={onClick}
        active={isCurrentRoute}
      >
        {icon}
        {props.children}
        {count > 0 && <CountBadge>{count}</CountBadge>}
      </MenuElement>
      <SubMenu className="sub-menu">
        {processedSubMenu?.length > 0 &&
          processedSubMenu.map((s) => (
            <MenuElement active={s?.active} to={s.path} key={`menu-${s.path}`}>
              <TranslatedText i18nKey={s.name}>
                {(value) => <SubMenuElementTitle>{value}</SubMenuElementTitle>}
              </TranslatedText>
            </MenuElement>
          ))}
      </SubMenu>
    </MenuListItem>
  )
}

export default MenuItem

const MenuElement = styled(NavLink)`
  position: ${(p) => (p.islogo === 'true' ? 'absolute' : 'relative')};
  left: ${(p) => p.islogo === 'true' && '0'};
  top: ${(p) => p.islogo === 'true' && '0'};
  display: flex;
  height: ${(p) => p.islogo === 'true' && '100%'};
  padding: ${(p) => (p.islogo === 'true' ? '0' : 'var(--spacing-small) var(--spacing-small) var(--spacing-small) 0')};
  align-items: center;
  text-decoration: none;
  align-content: center;
  font-size: var(--font-base);
  :hover {
    span {
      color: var(--color-primary);
    }
  }
  span,
  .sub-menu a span {
    // props.active is a string because react doesnt like it when you pass custom props to DOM elements
    ${(props) =>
      props.active === 'true'
        ? css`
            color: var(--color-primary);
          `
        : css`
            color: var(--color-text);
          `}
  }
`

const MenuListItem = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  :hover,
  .sub-menu:hover {
    a span:hover {
      color: var(--color-primary);
      transition: 0.2s;
    }

    ${(props) =>
      props.hasSubMenu
        ? css`
            .sub-menu {
              display: grid;
              text-decoration: none;
            }
          `
        : null};
  }
`

export const MenuTitle = styled.span`
  flex-grow: 1;
  color: var(--color-text);
  font-weight: var(--fontWeight-bold);
`

const SubMenuElementTitle = styled(MenuTitle)`
  font-weight: var(--fontWeight-normal);
`

const SubMenu = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr;
  box-shadow: 2px 2px 4px var(--color-line);
  background-color: var(--color-bg);
  border-radius: 8px;
  position: absolute;
  top: 58px;
  left: 210px;
  column-gap: var(--spacing-large);
  padding: var(--spacing-medium) var(--spacing-large);
  cursor: default;
  white-space: nowrap;
`

const CountBadge = styled.div`
  display: inline-block;
  background-color: var(--color-warning);
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  padding: 2px 6px;
  margin-left: 6px;
  position: relative;
  top: -2px;
`
