import React from "react";
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '../../../../utils/tableFuncs'

export default function eventListTollPassingData() {
    return [
          {
            accessorKey: 'date',
            id: 'date',
            header: () => <TranslatedText i18nKey={'DATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ row }) => {
              const date = row.original.date 
              return date ? formatDate(date) : ''
            },
            meta: { alignmentType: 'date' },
          },
          {
            accessorKey: 'registrationNumber',
            id: 'registrationNumber',
            header: () => <TranslatedText i18nKey={'CAR_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'driver.name',
            id: 'driver.name',
            header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'tollStation',
            id: 'tollStation',
            header: () => <TranslatedText i18nKey={'TOLL_STATION'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'amount',
            id: 'amount',
            header: () => <TranslatedText i18nKey={'AMOUNT'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
    ]
}