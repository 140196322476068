import React from 'react'
import styled, { css } from 'styled-components'
import { useI18nContext } from '../../../context/I18nProvider'
import { TranslatedText } from '@staccx/i18n'

const ContactInformation = ({ contactData }) => {
  const { language } = useI18nContext()

  return (
    <Container>
      <ContainerText>
        <Heading>
          <TranslatedText i18nKey="CONTACT_US" />
        </Heading>
      </ContainerText>

      <ContainerText>
        <ContactContainer>
          <ContactHeading>
            <TranslatedText i18nKey="TELEPHONE" />
          </ContactHeading>
          <ContactHeading phone onClick={() => (window.location = `tel:${contactData.contactPhone}`)}>
            {contactData.contactPhone}
          </ContactHeading>
        </ContactContainer>

        <ContactContainer>
          <Text>
            <TranslatedText i18nKey="EMAIL" />
          </Text>
          <Text email onClick={() => (window.location = `mailto:${contactData.contactEmail}`)}>
            {contactData.contactEmail}
          </Text>
        </ContactContainer>
      </ContainerText>

      <ContainerText>
        <TextBold>
          <TranslatedText i18nKey="POSTAL_AND_VISITING_ADDRESS" />
        </TextBold>
        <Text>{contactData.contactAddress}</Text>
      </ContainerText>

      <ContainerText>
        <Text></Text>
      </ContainerText>
    </Container>
  )
}

export default ContactInformation

const Heading = styled.p`
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
  color: var(--color-black);
  position: relative;
`

const ContactHeading = styled.p`
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
  ${(props) =>
    props.phone
      ? css`
          color: #467eff;
        `
      : css`
          color: #5e5b5b;
        `} // NB: Disse fargene finnes ikke i theme, legge til?
${(props) =>
    props.phone
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : css`
          &: hover;
        `} 
padding-bottom: var(--spacing-smallMinus);
  position: relative;
  padding-right: var(--spacing-tinyPlus);
`

const ContactContainer = styled.div`
  display: flex;
`

const Text = styled.p`
  font-size: var(--font-base);
  padding-bottom: var(--spacing-microPlus);
  ${(props) =>
    props.email
      ? css`
          color: #467eff;
        `
      : css`
          color: #5e5b5b;
        `} // NB: Disse fargene finnes ikke i theme, legge til?
${(props) =>
    props.email
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : css`
          &: hover;
        `} 
${(props) =>
    props.email
      ? css`
          padding-right: 0px;
        `
      : css`
          padding-right: var(--spacing-microPlus);
        `} 
padding-right: var(--spacing-tiny);
`

const TextBold = styled.p`
  font-size: var(--font-base);
  font-weight: var(--fontWeight-veryBold);
`

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`

const ContainerText = styled.div`
  padding-bottom: var(--spacing-largePlus);
`
