const ChevronRight2 = ({ width = 9, height = 14, color = '#467EFF' }, props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0.929688L0.5 2.42969L5.07031 7L0.5 11.5703L2 13.0703L8.07031 7L2 0.929688Z"
        fill={color}
      />
    </svg>
  )
}

export default ChevronRight2
