import React from 'react'
import styled from 'styled-components'
import ChevronRight2 from '../../../icons/ChevronRight2'
import { Link } from 'react-router-dom'

interface LinkBannerProps {
  to: string
  children: React.ReactNode
}

const LinkBanner: React.FC<LinkBannerProps> = ({ to, children }) => {
  return (
    <LinkWrapper to={to}>
      <div>{children}</div>
      <ChevronRight2 />
    </LinkWrapper>
  )
}

export default LinkBanner

const LinkWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 19px;
  width: 100%;
  height: 52px;
  background-color: #fffaac;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: inherit;

  &:hover {
    box-shadow: 0 6px 12px 0 #0000001f;
  }
`
