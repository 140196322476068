import React from 'react'
import PageContainerTitled from './PageContainerTitled'
import { TranslatedText } from '@staccx/i18n'
import Car from './Car'
import CustomSkeleton from './skeleton/skeleton'
import { SmallHeading } from '../../../components/text/Headings'
import {
  Right,
  ConfigurationInfoContainer,
  Fraction,
  ActionContainer,
  StyledCopy,
  SeeMore,
} from './LandingPageComponents'
import { css } from 'styled-components'
import SearchBox from '../../../components/menu/SearchBox'

const FLOW_FETCH_INCREMENT_VALUE = 5

const CompletedCarConfigurations = ({
  numCompletedItems,
  numMaxCompletedItems,
  completedSearch,
  completedSearchSet,
  filteredCompleted,
  completed,
  completedSearchLoading,
  handleOpenConfiguration,
  handleCreateNewConfiguration,
  completedConfigSet,
}) => {
  return (
    <PageContainerTitled
      title="CAR_CONFIG_LANDING_PAGE_COMPLETED_CONFIGURATIONS_HEADING"
      numItems={numCompletedItems}
      maxNumItems={numMaxCompletedItems}
    >
      <SearchBox
        value={completedSearch?.value}
        onChange={(value) =>
          completedSearchSet((prevState) => ({
            ...prevState,
            field: 'data.customConfigurationName',
            value,
          }))
        }
        placeholderi18nKey="SEARCH_BY"
        searchableFields={['NAME', 'OR', 'MODEL']}
        noBorder
      />
      {(filteredCompleted || completed?.flows) && !completedSearchLoading ? (
        (completedSearch.value ? filteredCompleted : completed?.flows)?.map((flow) => (
          <Car key={flow?.flowId} action={() => handleOpenConfiguration(flow)} flow={flow} banner={false}>
            <Right>
              <ConfigurationInfoContainer>
                <Fraction>
                  <span>
                    {flow?.data?.carConfiguration?.makeKey} {flow?.data?.carConfiguration?.modelKey}
                  </span>
                </Fraction>
                <Fraction>
                  <span>{flow?.data?.carConfiguration?.derivativeToDisplay}</span>
                </Fraction>
                <Fraction>
                  <span>
                    <TranslatedText i18nKey="LAST_SAVED">
                      {(value) => `${value}: ${flow?.updatedAt?.split('T')?.[0]}`}
                    </TranslatedText>
                  </span>
                </Fraction>
              </ConfigurationInfoContainer>
              <ActionContainer className="copy" onClick={() => handleCreateNewConfiguration({ flow })}>
                <StyledCopy />
              </ActionContainer>
            </Right>
          </Car>
        ))
      ) : (
        <CustomSkeleton
          skeletons={[
            {
              height: 70,
            },
          ]}
          theme={css`
            grid-template-columns: 1fr;
          `}
        />
      )}
      {numCompletedItems < numMaxCompletedItems && (
        <SeeMore>
          <TranslatedText i18nKey="SEE_MORE">
            {(value) => (
              <SmallHeading
                onClick={() =>
                  completedConfigSet((prevState) => ({
                    ...prevState,
                    limit: prevState.limit + FLOW_FETCH_INCREMENT_VALUE,
                  }))
                }
              >
                {value}
                ...
              </SmallHeading>
            )}
          </TranslatedText>
        </SeeMore>
      )}
    </PageContainerTitled>
  )
}

export default CompletedCarConfigurations
