import styled from 'styled-components';

type DirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type JustifyType =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around';
type AlignType =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch';

interface FlexProps {
  direction?: DirectionType;
  gap?: string;
  justify?: JustifyType;
  align?: AlignType;
  children?: React.ReactNode;
}

const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${(props) => props.direction || 'row'};
    gap: ${(props) => props.gap || '0'};
    justify-content: ${(props) => props.justify || 'flex-start'};
    align-items: ${(props) => props.align || 'flex-start'};
`;

export default Flex;