import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import TanStackTable from '../../../components/tables/TanStackTable'
import invoiceData from './invoiceData'

const columns = invoiceData()

type InvoiceListProps = {
  data: any
  isLoading?: boolean
}

function InvoiceList({ data, isLoading }: InvoiceListProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <TanStackTable table={table} isLoading={isLoading} />
}

export default InvoiceList
