import React from "react";
import styled, { keyframes } from "styled-components";

const spinnerBubble1 = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
`;

const spinnerBubble2 = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
`;

const SignalSpinner = styled.div`
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  color: var(--color-primary);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    opacity: 0;
    border: 4px solid currentColor;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    transform-origin: center;
  }

  &::before {
    animation: ${spinnerBubble1} 2.6s ease-out 1.3s infinite;
  }

  &::after {
    animation: ${spinnerBubble2} 2.6s ease-out 0s infinite;
  }
`;
const SignalSpinnerLoader = () => {
  return (
      <SignalSpinner/>
  );
};

export default SignalSpinnerLoader;

