import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const TextArea = ({ placeholder, handleChange, maxLength = 200 }) => {
  return (
    <>
      <StyledTextArea
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        maxLength={maxLength}
      ></StyledTextArea>
    </>
  )
}

export default TextArea

const StyledTextArea = styled.textarea`
  font-size: var(--font-base);
  padding: var(--spacing-tinyPlus);
  resize: vertical;
  border: 0px;
  border-radius: var(--spacing-tinyMinus);
  width: 75%;
  height: var(--spacing-huge);
  max-height: 150px;
  white-space: pre-line;
  background-color: #f2f4f7;

  :focus {
    border: 1px solid var(--color-primary);
  }

  transition: 0.2s linear;
`
