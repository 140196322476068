import { formatCurrency, formatDate } from '../../../../../utils/tableFuncs'
import { createColumnHelper } from '@tanstack/react-table'
import type { FleetReportExpense } from '../../../../../types'
import React from 'react'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import PdfDownload from '../../../../../components/tables/PdfDownload'

export default function fleetReportExpenseData() {
  const columnHelper = createColumnHelper<FleetReportExpense>()
  return [
    columnHelper.accessor('kostnadsart', {
      id: 'kostnadsart',
      header: () => <TranslatedText i18nKey={'COST_TYPE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('description', {
      id: 'description',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'DETAILED_DESCRIPTION'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => PdfDownload(row),
    }),
    columnHelper.accessor('documentNo', {
      id: 'documentNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'DOCUMENT_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('documentType', {
      id: 'documentType',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'DOCUMENT_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('termDate', {
      id: 'termDate',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'TERM_DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
    }),
    columnHelper.accessor('amountIncludingVAT', {
      id: 'amountIncludingVAT',
      meta: { alignmentType: 'number' },
      header: () => <TranslatedText i18nKey={'INVOICE_INC_VAT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
    }),
  ]
}
