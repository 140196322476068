import React, { useMemo, useState } from 'react'
import { Label, Layout } from '@staccx/bento'
import { Heading } from '../../../components/text/Headings'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import FuelReportTable from '../components/FuelTables/FuelReport'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useQuery } from 'react-query'
import { fetchFuelReport } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const FuelReport = () => {
  const [selectedYear, selectedYearSet] = useState(new Date().getFullYear().toString())

  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], status } = useQuery(['fuelReport', companyId], () => fetchFuelReport(company.id), {
    enabled: !!companyId
  })

  const fuelReportYears = useMemo(() => {
    if (!data) return []
    return [...new Set(data.map((el) => el.tyear))]
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const filterFunc = agreementFilter('consolidatedInvoiceAgreement')

  const items = fuelReportYears.map((year) => ({ value: year, label: year }))
  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'FUELREPORT_HEADING'}>
          {(value) => <Heading>{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="PERIOD">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  options={items}
                  onChange={(selectedYear) => selectedYearSet(selectedYear.value)}
                  value={{ value: selectedYear, label: selectedYear }}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <FuelReportTable
        isLoading={status === 'loading'}
        data={data.filter((el) => el.tyear === selectedYear).filter(filterFunc)}
      />
    </Container>
  )
}

export default FuelReport
