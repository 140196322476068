import React from 'react'

const Chevron = ({ className, rotated = false, width = 24, height = 24 }, props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    transform={`${!rotated ? 'rotate(180)' : 'rotate(0)'}`}
    className={className}
    {...props}
  >
    <polyline fill="none" stroke="currentColor" strokeWidth="2" points="8 10 12 14 16 10" {...props} />
  </svg>
)

export default Chevron
