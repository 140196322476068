const Signing = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1544 0C15.1544 0 11.5724 0.0157314 7.91501 0.904925C6.0863 1.34952 4.21697 2.00243 2.69998 3.0871C1.18299 4.17177 1.26246e-07 5.83659 0 7.92241C0 9.88807 0.912691 11.1645 1.57314 11.9366C2.23359 12.7087 2.53971 12.9235 2.53971 13.887C2.53971 14.2958 2.15396 14.9577 1.51889 15.8325C0.883825 16.7073 0.0147944 17.8037 0.0147944 19.3437C0.0147944 20.9445 1.1451 22.0628 2.08108 22.4283C3.01706 22.7938 3.87367 22.7217 3.87367 22.7217L3.73066 20.2018C3.73066 20.2018 3.32481 20.2035 2.99833 20.076C2.67186 19.9485 2.53971 20.0052 2.53971 19.3437C2.53971 18.8473 2.93313 18.1786 3.56052 17.3144C4.18791 16.4502 5.06462 15.401 5.06462 13.887C5.06462 12.1363 4.10043 11.0088 3.49148 10.2969C2.88253 9.585 2.52491 9.2366 2.52491 7.92241C2.52491 6.72836 3.08013 5.92001 4.16956 5.14106C5.25899 4.3621 6.8652 3.75863 8.51172 3.35833C11.8048 2.55773 15.1741 2.52491 15.1741 2.52491L15.1544 0ZM20.6653 5.04982C20.3862 5.04982 20.1063 5.15702 19.8935 5.37037L18.3204 6.94351L22.1078 10.7309L23.6809 9.15774C24.1064 8.73103 24.1064 8.03964 23.6809 7.61419L21.4371 5.37037C21.2237 5.15702 20.9445 5.04982 20.6653 5.04982ZM16.5352 8.7287L6.32707 18.9368V22.7242H10.1144L20.3226 12.5161L16.5352 8.7287Z"
        fill="#467EFF"
      />
    </svg>
  )
}

export default Signing
