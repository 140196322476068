import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import TextArea from '../../../components/fields/TextArea'
import { Button } from '@staccx/bento'
import { i18nInstance, TranslatedText } from "@staccx/i18n"
import { sendContactUsEmail } from '../../../api/flow'
import CustomSkeleton from '../../carConfiguration/components/skeleton/skeleton'
import customToast from '../../carConfiguration/components/toast/customToast'
import Tick from '../../../icons/Tick'
import Toast from '../../carConfiguration/components/toast/Toast'

const ContactForm = ({ userData, chosenCompany }) => {
  const [message, setMessage] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const MAX_MESSAGE_LENGTH = 200

  const handleClick = async () => {
    if (message && !emailSent) {
      setLoading(true)
      try {
        await sendContactUsEmail({ message, ...userData, company: chosenCompany })
        setMessage('')
        setEmailSent(true)
        setLoading(false)
        customToast.success('Email sent')
      } catch (e) {
        console.error('Failed to send mail', e)
        customToast.error(i18nInstance.translate('MAIL_ERROR'))
        customToast.clearQueue()
        setLoading(false)
      }
    }
  }

  return (
    <ContentDiv>
      {userData ? (
        <>
          <Container>
            <InfoText>
              <TranslatedText i18nKey="NAME" />
            </InfoText>
            <InfoContainer>{userData.name}</InfoContainer>
          </Container>
          <Container>
            <InfoText>
              <TranslatedText i18nKey="EMAIL" />
            </InfoText>
            <InfoContainer>{userData.mail}</InfoContainer>
          </Container>
          <Container>
            <InfoText>
              <TranslatedText i18nKey="TELEPHONE" />
            </InfoText>
            <InfoContainer>{userData.phone}</InfoContainer>
          </Container>
          <Container>
            <InfoText>
              <TranslatedText i18nKey="MY_COMPANY" />
            </InfoText>
            <InfoContainer>{chosenCompany}</InfoContainer>
          </Container>
          <Container>
            <InfoText>
              <TranslatedText i18nKey="WRITE_TO_US" />
            </InfoText>
            <TranslatedText i18nKey="TELL_US_WHAT_WE_CAN_DO_TO_HELP_YOU">
              {(value) => (
                <>
                  <TextArea placeholder={value} handleChange={(value) => setMessage(value)}></TextArea>
                </>
              )}
            </TranslatedText>
          </Container>
          <CharacterCounter>
            <Number>{message?.length || 0}</Number>/<Number>{MAX_MESSAGE_LENGTH}</Number>
          </CharacterCounter>
          <Container>
            <Button variant="rounded" onClick={handleClick}>
              {loading ? (
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              ) : emailSent ? (
                <Tick />
              ) : (
                <TranslatedText i18nKey="BUTTON_SUBMIT" />
              )}
            </Button>
          </Container>
        </>
      ) : (
        <CustomSkeleton
          skeletons={[
            {
              height: 35,
              width: 100,
              count: 6,
            },
            {
              height: 35,
              width: 360,
              count: 5,
            },
          ]}
          theme={css`
            border: 0;
          `}
        />
      )}

      <Toast limit={1} />
    </ContentDiv>
  )
}

export default ContactForm

const ContentDiv = styled.div`
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  padding: var(--spacing-smallPlus);
  padding-top: var(--spacing-mediumMinus);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  margin-left: var(--spacing-largeMinus);
`

const InfoContainer = styled.div`
  width: 75%;
  height: 100%;
  background-color: #f2f4f7; // NB farge finnes ikke i theme
  border-radius: var(--spacing-tinyMinus);
  float: right;
  padding: var(--spacing-tinyPlus);
  cursor: default;
`

const InfoText = styled.p`
  font-size: var(--font-base);
  font-weight: var(--fontWeight-veryBold);
  padding: var(--spacing-tinyPlus);
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-smallPlus);
`

const Spinner = styled.div`
  border: 4px transparent solid;
  border-top: 4px white solid;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CharacterCounter = styled.div`
  display: flex;
  justify-content: right;
`
const Number = styled.span``
