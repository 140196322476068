export const getDrivenWheelsDescription = (str) => {
  switch (str) {
    case '4x4':
      return 'Firehjulsdrift'
    case 'foran':
      return 'Forhjulsdrift'
    default:
      return 'Bakhjulsdrift'
  }
}
