import React from 'react'
import AnchorButton from '../../home/components/AnchorButtons'
import { TranslatedText } from '@staccx/i18n'
import { CloseButton } from '../../../theme/Theme.Button'
import Close from '../../../icons/Close'
import ContactForm from '../../../components/modal/ContactForm'
import Dialog from '../../../components/modal/Dialog'
import { Button } from '@staccx/bento'

const NewEmployee = ({
  contactSite,
  showNewDriverModal,
  showNewDriverModalSet,
  updateDrivers,
  updateCars,
  onEditDriverFinish,
}) => {
  return (
    <Dialog
      open={showNewDriverModal}
      onOpenChange={showNewDriverModalSet}
      trigger={
        contactSite ? (
          <Button variant="newEmployee" action={() => showNewDriverModalSet(true)}>
            <TranslatedText i18nKey="ADD_NEW_EMPLOYEE" />
          </Button>
        ) : (
          <AnchorButton text={<TranslatedText i18nKey="NEW_EMPLOYEE" />} action={() => showNewDriverModalSet(true)} />
        )
      }
    >
      <CloseButton>
        <Close onClick={() => showNewDriverModalSet(false)} />
      </CloseButton>
      <ContactForm
        onFinish={async (setAsDriverOnCurrentCar) => {
          showNewDriverModalSet(false)
          updateDrivers()
          if (setAsDriverOnCurrentCar) {
            updateCars()
          }
          ;() => onEditDriverFinish()
        }}
      />
    </Dialog>
  )
}

export default NewEmployee
