import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ItemGroup, CheckBox } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import styled from 'styled-components'
import { createQueryParams } from '../../../utils/createQueryParams'
import { useParams } from '../../../hooks/useParams'

const CarTypeFilter = ({ list, cars }) => {
  const { fuel } = useParams()

  const selectedFuel = useMemo(() => {
    if (!fuel) return 'all'
    return fuel
  }, [fuel])

  return (
    <Wrap>
      <StyledItemGroup variant="overview">
        {list.map((carType) => {
          const params = createQueryParams(
            {
              fuel: carType.filter,
            },
            false
          )
          return (
            <RadioBtnLink
              to={`/cars?${params.toString()}`}
              key={carType.name}
              active={(selectedFuel === carType.filter).toString()}
            >
              <StyledCheckBox id={carType.filter} checked={selectedFuel === carType.filter}>
                {carType.icon}
                &nbsp;
                <TranslatedText i18nKey={carType.name} />
              </StyledCheckBox>
            </RadioBtnLink>
          )
        })}
      </StyledItemGroup>
    </Wrap>
  )
}
const StyledItemGroup = styled(ItemGroup)`
  > div {
    gap: var(--spacing-smallMinus);
    margin: 0;
    background-color: var(--color-white);
  }
`

const Wrap = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: -75px;
  margin-bottom: var(--spacing-medium);
`

const StyledCheckBox = styled(CheckBox)`
  & ~ label::before {
    top: 15px;
    width: 18px;
    height: 18px;
    background-color: ${({ checked }) => (checked ? 'var(--color-primary)' : 'var(--color-white)')};
  }

  & ~ label > svg {
    top: 18px;
    color: var(--color-white);
    width: var(--spacing-small);
    height: var(--spacing-small);
  }

  & ~ label {
    display: block;
  }

  &[disabled] ~ label::before {
    cursor: default;
    background-color: var(--color-white);
    border-color: var(--color-line);
    width: 18px;
    height: 18px;
  }
`

const RadioBtnLink = styled(Link)`
  font-size: var(--font-text);
  color: var(--color-headerText);
  font-weight: var(--fontWeight-bold);
  text-decoration: none;
`

export default CarTypeFilter
