import React, { useRef, useState } from "react"
import styled from "styled-components"
import { i18nInstance } from "@staccx/i18n"
import Search from "../../../icons/Search"
import Fuse from "fuse.js/dist/fuse.common"

const fuseSettings = {
  isCaseSensitive: false,
  threshold: 0.5,
  useExtendedSearch: false,
  keys: ["content", "categoryName"]
}

const groupStandardEquipmentByCategory = standardEquipment => {
  return standardEquipment?.length > 0 ? standardEquipment?.reduce((acc, item) => {
    const category = item.categoryName || i18nInstance.translate("STANDARD_EQUIPMENT_MISC_CATEGORY")
    acc[category] = [...acc[category] || [], item]
    return acc
  }, {}) : {}
}

const EquipmentModal = ({ equipment }) => {
  const [body, bodySet] = useState({})
  const [sorted, sortedSet] = useState(true)
  const categorizedInitial = groupStandardEquipmentByCategory(equipment)
  const [content, contentSet] = useState(categorizedInitial)
  const SearchAreaRef = useRef()
  const fuse = new Fuse(equipment || [], fuseSettings)

  const sortContent = content => {
    if (content && sorted) {
      return Object.keys(content)?.sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      }) || {}
    }
    return Object.keys(content)
  }

  const doSearch = () => e => {
    let term = e.target.value
    if (term.length > 2) {
      sortedSet(false)
      let searchResult = fuse.search(term).map(result => result.item)

      if (searchResult?.length > 0) {
        bodySet({
          category: searchResult[0].categoryName,
          content: searchResult.filter(item => item.categoryName === searchResult[0].categoryName)
        })
      } else {
        bodySet({})
      }
      return contentSet(groupStandardEquipmentByCategory(searchResult))
    }
    bodySet({})
    sortedSet(true)
    return contentSet(categorizedInitial)
  }

  return <>
    <Parent>
      <SearchArea onClick={() => SearchAreaRef.current.focus()}>
        <SearchIcon>
          <Search />
        </SearchIcon>
        <CategorySearch
          placeholder={i18nInstance.translate("STANDARD_EQUIPMENT_SEARCH")}
          ref={SearchAreaRef}
          onChange={doSearch()}
        ></CategorySearch>
      </SearchArea>
      <Header></Header>
      <Categories>
        <CategoryList>
          {content && sortContent(content).map(item =>
            <li key={item} onClick={() => bodySet({ category: item, content: content[item] })}>
              <Category>
                {item}
              </Category>
            </li>
          )}
        </CategoryList>
      </Categories>

      <Body variant="main">
        <CategoryHeader>
          {body.category}
        </CategoryHeader>
        <ContentList>
          {body.content && body.content.map(item =>
            <li key={item.content}>
              {item.content}
            </li>
          )}
        </ContentList>
      </Body>

    </Parent>
  </>
}

const Category = styled.button`
  padding: var(--spacing-smallPlus) var(--spacing-smallPlus) var(--spacing-smallPlus) var(--spacing-large);
  text-align: left;
  border-left: solid var(--color-white);
  border-bottom: none;
  border-top: none;
  border-right: none;

  &:hover, &:focus {
    background: var(--color-primaryLight);
    cursor: pointer;
    border-left: solid var(--color-primary);
  }

  height: 100%;
  width: 100%;
  margin: 0;
  text-decoration: none;
  background: var(--color-white);
`

const Parent = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: auto 1fr;
  grid-column-gap: var(--spacing-microMinus);
  grid-row-gap: var(--spacing-microMinus);
  background-color: var(--color-line);
  overflow: hidden;
  height: 100%;
  max-height: 90vh;
`
const SearchArea = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  background: var(--color-white);
  display: grid;
  grid-template-columns: var(--spacing-largeMinus) auto;
  align-items: center;
`
const Header = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  background: var(--color-white);
`
const Categories = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  background: var(--color-white);
  overflow-y: scroll;
  max-height: 100%;
`
const Body = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  background: var(--color-subtleHover);
  overflow-y: scroll;
  padding: var(--spacing-mediumPlus) var(--spacing-largeMinus) var(--spacing-mediumPlus) var(--spacing-largeMinus);
`
const ContentList = styled.ul`
  list-style-type: disc;
  list-style-position: inherit;
  padding-top: var(--spacing-mediumMinus);
  padding-left: var(--spacing-medium);
`
const CategoryList = styled.ul`
`
const CategorySearch = styled.input`
  height: 60px;
  width: 100%;
  border: none;
  padding-left: var(--spacing-medium);
  &:focus {
    outline: 0 none;
  }
`
const CategoryHeader = styled.h1`
  font-weight: bold;
`
const SearchIcon = styled.div`
  padding-left: var(--spacing-mediumMinus);
  height: var(--spacing-medium);
  width: var(--spacing-medium);
`
export default EquipmentModal
