import React from 'react'
import styled from 'styled-components'
import imageUrlBuilder from '@sanity/image-url'
import sanity from '../../../api/sanity'
import BlockContent from "@sanity/block-content-to-react"
import blockSerializers from "../../../data/blockSerializers"
import { TranslatedText } from '@staccx/i18n'
import { languageChosen } from '../../reports/components/ReportTermSelect'
import { format } from 'date-fns'
import { nb, enGB } from 'date-fns/locale'

const builder = imageUrlBuilder(sanity)

const NewsArticle = ({ article }) => {
  const { heading, ingress, body, image, author, updatedAt } = article

  const urlFor = (source) => {
    return builder.image(source)
  }

  const language = languageChosen().code

  const date = new Date(updatedAt)
  const updatedAtDate = format(updatedAt, 'dd MMMM yyyy, HH:mm', {
    locale: language === 'nb' ? nb : enGB
  })

  return (
    <NewsContainer key={`${heading}-full`}>
      <FirstSection>
        {image && (
          <Image>
            <img src={urlFor(image).url()} alt={heading} />
          </Image>
        )}
        <Byline>
          <span>
            <TranslatedText i18nKey={'BY'} /> {author}
          </span>
          <span>
            <TranslatedText i18nKey="LAST_UPDATED_AT">{(value) => `${value}: ${updatedAtDate}`}</TranslatedText>
          </span>
        </Byline>
      </FirstSection>
      <SecondSection>
        <BlockContent
          blocks={ingress}
          serializers={blockSerializers}
        />
        <BlockContent
          blocks={body}
          serializers={blockSerializers}
        />
      </SecondSection>
    </NewsContainer>
  )
}

const NewsContainer = styled.div`
  display: grid;
`

const FirstSection = styled.div`
  display: grid;
  gap: var(--spacing-tiny);
  margin-bottom: var(--spacing-tiny);
`

const SecondSection = styled.div`
  display: grid;
  gap: var(--spacing-medium);
  margin: var(--spacing-medium) 0;
  max-height: var(--spacing-hugePlus);
  overflow-y: scroll;
  scroll-behavior: smooth;
  @media only screen and (min-width: 1219px) {
    max-height: 35vh;
  }
`

const Image = styled.div`
  height: 100%;
  object-fit: cover;
  max-height: 500px;
  margin: 0 auto;
  img {
    height: 100%;
    max-height: 100%;
  }
`

const Byline = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: italic;
`

export default NewsArticle
