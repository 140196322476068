import React from "react";
import styled from "styled-components";
import { TranslatedText } from "@staccx/i18n";

export const ConfigureWheelPackages = ({ wheelPackage, image, isSelected, onSelect }) => {
  const {
    RimType,
    RimSize,
    TireManufacturer
  } = wheelPackage

  const extension = image.filename.slice(image.filename.lastIndexOf(".") + 1)
  return <WheelPackageWrapper
    isSelected={isSelected}
    onClick={() => onSelect(wheelPackage)}
  >
    <ImageContainer>
      <img src={`data:image/${extension};charset=utf-8;base64,${image.base64}`} alt={RimType}/>
    </ImageContainer>
    <TranslatedText i18nKey={"TYRE_MANUFACTURER_SHORT"}>
      {value => <span>{value}: {TireManufacturer}</span>}
    </TranslatedText>
    <TranslatedText i18nKey={"RIM_SIZE"}>
      {value => <span>{value}: {RimSize}"</span>}
    </TranslatedText>
  </WheelPackageWrapper>
}

export default ConfigureWheelPackages

const WheelPackageWrapper = styled.div`
  max-width: 226px;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--spacing-smallMinus);
  background-color: var(--color-white);
  cursor: pointer;
  padding: var(--spacing-smallPlus);

  span {
    text-align: center;
    padding: var(--spacing-tinyMinus) 0 var(--spacing-tinyMinus) 0;
  }
  
  span:last-child {
    color: var(--color-primary);
  }

  :hover {
    outline: var(--spacing-micro) solid var(--color-primary);
  }
  
  outline: ${props => props.isSelected ? `var(--spacing-micro) solid var(--color-primary)` : `var(--spacing-micro) solid var(--color-line)`};
`

const ImageContainer = styled.figure`
  object-fit: cover;
`
