import React from 'react'
import styled from 'styled-components'
import imageUrlBuilder from '@sanity/image-url'
import sanity from '../../../api/sanity'
import BlockContent from "@sanity/block-content-to-react"
import blockSerializers from "../../../data/blockSerializers"
import { Button } from '@staccx/bento'
import { SmallHeading } from '../../../components/text/Headings'
import { TranslatedText } from '@staccx/i18n'

const builder = imageUrlBuilder(sanity)

const NewsArticleCard = ({ article, onClick = () => {} }) => {
  const { heading, ingress, image } = article

  const urlFor = (source) => {
    return builder.image(source)
  }

  return (
    <NewsRow key={heading}>
      {image && (
        <Image>
          <img src={urlFor(image).url()} alt={heading} />
        </Image>
      )}
      <NewsText>
        <SmallHeading>
          {heading}
        </SmallHeading>
        <Ingress>
          <BlockContent
            blocks={ingress}
            serializers={blockSerializers}
          />
        </Ingress>
        {onClick && (
          <ButtonContainer>
            <Button variant={'secondary'} type={'button'} onClick={() => onClick(article)}>
              <TranslatedText i18nKey="READ-MORE" />
            </Button>
          </ButtonContainer>
        )}
      </NewsText>
    </NewsRow>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-start;
  @media only screen and (min-width: 1219px) {
    justify-content: flex-end;
    height: 100%;
  }
`
const NewsRow = styled.div`
  display: grid;
  grid-template-rows: 0.7fr 1fr;
  grid-template-columns: unset;
  gap: var(--spacing-small);
  border-collapse: collapse;
  transition: 0.2s;
  align-items: start;
  @media only screen and (min-width: 1219px) {
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: unset;
  }
`
const NewsText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: var(--spacing-small);
  height: 100%;
  @media only screen and (min-width: 1219px) {
    gap: var(--spacing-small);
  }
  @media only screen and (max-width: 1219px) {
    margin-top: var(--spacing-small);
  }
`

const Image = styled.div`
  height: 100%;
  object-fit: cover;
  max-height: 250px;
  max-width: 400px;
  width: 100%;
`

const Ingress = styled.div`
  height: fit-content;
  line-height: 26px;
  font-size: var(--font-news);
  @media only screen and (min-width: 1219px) {
    height: 100%;
  }
`

export default NewsArticleCard
