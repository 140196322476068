export interface Fleet {
  regNo: string
  vehicleID: string
  accountID: string
  driverName: string
  HK: string
  totalTerms: string
  totalKm: string
  budgetKm: string
  maxKm: number
  fixedYear: number
  fixedContract: string
  nonFixedYear: string
  nonFixedContract: string
  totalYear: string
  totalContract: string
  accountStatus?: string
  termsNow?: number
}

export interface Fuel {
  amountIncVAT: number
  consolidatedInvoiceAgreement: string
  fuelTransaction?: string
  mainCustomerCode?: any
  tmonth?: string
  tyear?: string
  volume: number
}

export interface Invoice {
  postingDate: string
  invoiceNo: string
  sellToCustomerNo: string
  consolidateInvoiceCode: string
  consolidateDescription: string
  billToName: string
}

export interface InvoiceDetails {
  invoiceNo: string
  collectionOnly: string
  description: string
  consolidateInvoiceNo: string
  consolidateInvoiceCode: string
  carClass: string
  accountNo: string
  invoiceGrouping: string
  mainCustomerCode: string
  driverName: string
  invoiceGroupDescription: string
  vehicleID: string
  regNo: string
  carDescription: string
  documentNo: string
  amountIncVAT: number
  postingGroup: string
  lineNo: string
  docuBizzSourceID: any
  sourceType: any
  group?: string
  subRows?: []
}

export interface Leasing {
  accountID: string
  consolidatedAgreement: string
  consolidatedAgreementID: string
  vehicleID: string
  regNo: string
  returnDate: string
  startDate: string
  maxKm: number
  driverName: string
  totalTerms: number
  termsNow: number
  remainingTerms: number
  totalKm: number
  budgetKm: number
  productType: string
  accountStatus: string
  fixedContract: number
  fixedYear: number
  nonFixedContract: number
  nonFixedYear: number
  totalContract: number
  totalYear: number
  termDate: string
  customerNo: string
  maxPostingDate: string
  finPartnerCode: string
  finPartnerName: string
  termLeasing_excl_VAT: number
  termLeasing_incl_VAT: number
  restLeasing_excl_VAT: number
  restLeasing_incl_VAT: number
}

export interface Environment {
  year: number
  mainCustomerCode: string
  vehicleID: string
  regNo: string
  description: string
  carDescription: string
  accountNo: string
  consolidatedInvoiceAgreement: string
  driverName: string
  fuelType: string
  maxKm: number
  totalKm: number
  volume: number
  fuelConsumptionL10KM: number
  co2EmissionLabel: string
  co2Emission: number
  nOXEmission: number
  nOXEmissionLabel: string
  cIACode: string
  consolidatedAgreement: string
  iGCode: string
  invoiceGroup: string
  litersPerTenKm: number
}

export interface Toll {
  tYear: string
  tMonth: string
  mainCustomerCode: string
  amountIncVAT: number
  antallPasseringer: number
  consolidatedInvoiceAgreement: string
}

export interface Purchase {
  tyear: string
  tmonth: string
  mainCustomerCode: string
  fuelTransaction: string
  amountIncVAT: number
  volume: number
  consolidatedInvoiceAgreement: string
}

export interface EnvironmentStats {
  averageCo2PerCar: number
  averageNoxPerCar: number
}

export interface FleetReportDetails {}

export interface FleetReportExpense {
  itemNo: string
  description: string
  fixedCostsTerm: number
  nonFixedCostsTerm: number
  totalTerm: number
  totalContract: number
  totalYear: number
  termDate: string
  customerNo: string
  accountNo: string
  consolidatedAgreementID: string
  vehicleID: string
  kostnadsart: string
  docuBizzSourceID?: string
  sourceType?: number
  documentNo?: string
  lineNo?: string
  documentType?: string
  amountIncludingVAT?: number
}

export interface TollReportDetails {
  tYear: string
  tMonth: string
  mainCustomerCode: string
  vehicleID: string
  regNo: string
  description: string
  accountNo: string
  consolidatedInvoiceAgreement: string
  amountIncVAT: number
  antallPasseringer: number
  driverName: string
  carDescription: string
  consolidatedInvoiceDescription: string
  invoiceGroupingCode: string
  invoiceGroupingDescription: string
}

export interface TollReportAccountDetails {
  accountNo: string
  consolidatedAgreementID: string
  tMonth: string
  tYear: string
  transactionDate: string
  transactionTime: string
  tollStation: string
  field: string
  operator: string
  referenceNo: string
  amount: number
  customerNo: string
}

export interface PurchaseReportDetails {
  tYear: string
  tMonth: string
  fuelTransaction: string
  mainCustomerCode: string
  vehicleID: string
  regNo: string
  description: string
  accountNo: string
  consolidatedInvoiceAgreement: string
  amountincVAT: number
  volume: number
  driverName: string
  carDescription: string
  consolidatedInvoiceDescription: string
  invoiceGroupingCode: string
  invoiceGroupingDescription: string
}

export interface PurchaseReportAccountDetails {
  transactionDate: string
  transactionTime: string
  referenceNo: string
  retailStationName: string
  productDescription: string
  amountincVAT: number
  accountNo: string
  consolidatedAgreementID: string
  fuelTransaction: string
  tMonth: string
  tYear: string
  volume: number
  paidCostperliter: number
  regKm: string
  customerNo: string
  driverName: string
}

export interface FuelReportDetails {
  tYear: string
  tMonth: string
  fuelTransaction: string
  mainCustomerCode: string
  vehicleID: string
  regNo: string
  accountNo: string
  consolidatedInvoiceAgreement: string
  amountincVAT: number
  volume: number
  driverName: string
  aCode: number
  bCode: number
  cCode: number
  dCode: number
  eCode: number
  fCode: number
  gCode: number
  carDescription: string
  consolidatedInvoiceDescription: string
  invoiceGroupingCode: string
  invoiceGroupingDescription: string
  obs: string
  obsDescription: string
}

export interface FuelReportExpenseDetails {
  transactionDate: string
  transactionTime: string
  referenceNo: string
  retailStationName: string
  productDescription: string
  amountincVAT: number
  volume: number
  paidCostperliter: number
  regKm: number
  obs: string
  accountNo: string
  consolidatedAgreementID: string
  fuelTransaction: string
  tMonth: string
  tYear: string
  customerNo: string
  aCode: number
  aDescription: string
  bCode: number
  bDescription: string
  cCode: number
  cDescription: string
  dCode: number
  dDescription: string
  eCode: number
  eDescription: string
  fCode: number
  fDescription: string
  gCode: number
  gDescription: string
  driverName: string
  obsDescription: string
}

export interface Drivers {
  name: String
  jobTitle: string
  phoneNo: string
  email: string
  department: string
  hasConfiguratorAccess: boolean
  regNo: string
  webUserType: string
}

export interface Mileage {
  MileageReadTime: string
  LapsedMonths: string
  RegistreredMileage: string
  AveragePerMonths: string
  EstimatedEndMileage: string
  EstimatedEndDeviation: string
  ChangesToMaxMileage: string
  DriverName: string
  RegistrationNo: string
  VehicleBrand: string
  VehicleModel: string
  AccountNo: string
  CustomerNo: string
  ConsolidatedInvoiceNo: string
  ConsolidatedInvoiceDesc: string
}

export interface Drivers {
  name: String
  jobTitle: string
  phoneNo: string
  email: string
  department: string
  hasConfiguratorAccess: boolean
  regNo: string
  webUserType: string
}

export interface Driver {
  accountNo: string
  department: string
  email: string
  hasConfiguratorAccess: boolean
  jobTitle: string
  id: string
  name: string
  isWebUser: boolean
  phoneNo: string
  regNo: string
  type: string
  webUserType: string
}

export interface Car {
  registrationNumber: string
  fuel: string
  year: number
  description: string
  mileage: number
  contractMileage: number
  actualDeliveryDate: string
  plannedReturnDate: string
  simulatedMileage: number
  accountNo: string
  budgetNo: string
  insuranceCompany: string
  invoiceGroup: string,
  invoiceGroupDescripton: string
  driver: Driver
  hasDeviations: boolean
  events: any[]
  status: CarStatus
  lifetime: number
  currentLife: number
  maturity: string
  lifespan: number
  make: string
  model: string
  engine: string
  driverName: string
  noCarReplacement: boolean
  firstServiceDist: number
  firstServicePeriod: number
  intermServiceDist: number
  intermServicePeriod: number
  mainServiceDist: number
  mainServicePeriod: number
  nextInspectionDate: string
  actualReturnDate: string
  additionalProducts: AdditionalProduct[]
  taxCalculationBasis?: number
  replacement?: any
  replaceAccountNo: string
  replaces?: any
  deliveryDate: string
  imageURL?: string
  vehicleType: VehicleType
  wheelsDriven: WheelsDriven
  noDoors: string
}

export const VEHICLE_TYPE = {
  CAMPERVAN: "CM",
  CAR_PICKUP: "CP",
  CAR_VAN: "CV",
  CARGO_VAN: "PV",
  CHASSIS_CAB: "BH",
  CHASSIS_COWL: "WH",
  CHASSIS_ONLY: "CX",
  COMBI: "CC",
  COMMERCIAL_HATCH: "CH",
  COMMERCIAL_WAGON: "CE",
  CONVERTIBLE: "CA",
  COUPE: "CO",
  CROSSOVER: "CR",
  CUTAWAY: "BT",
  DROP_SIDE: "DS",
  HATCHBACK: "HA",
  HEAVY_TRUCK: "TR",
  MICRO_CAR: "MC",
  MINI_MPV: "MM",
  MINIVAN: "FW",
  OFF_ROAD_COMMERCIAL: "OC",
  OMNIBUS: "OM",
  PARCEL_VAN: "RV",
  PASSENGER_VAN: "BU",
  PICKUP: "PU",
  PLATFORM_COWL: "WT",
  QUADRICYCLE: "4C",
  SEDAN: "SA",
  SPORT_UTILITY_VEHICLE: "OD",
  TARGA: "TA",
  TIPPER: "TI",
  TRICYCLE: "3C",
  WAGON: "ES",
  WINDOW_VAN: "WV",
} as const;

export type VehicleType = (typeof VEHICLE_TYPE)[keyof typeof VEHICLE_TYPE];

export const WHEELS_DRIVEN = {
  ALL: "4",
  DIRECT: "D",
  FRONT: "F",
  REAR: "R",
} as const;

export type WheelsDriven = (typeof WHEELS_DRIVEN)[keyof typeof WHEELS_DRIVEN];

// NB: The order of the enum values is important, as it is used to determine the order of the number of cars in the carStatusFilter component
export enum CarStatus {
  Incoming = 'incoming',
  Active = 'active',
  Outgoing = 'outgoing',
  Returned = 'returned',
}

export interface ContactDetails {
  companyExternalID: string
  personContactID: string
  contactType: string
  name: string
  address: string
  postCode: string
  city: string
  phone: string
  mail: string
  jobTitle: string
}

export interface Insurance {
  ClaimURL: string
  Code: string
  EmailCarReg: string
  EmailClaimReg: string
  PhoneNoClaim: string
  PhoneNoCarReg: string
  PhoneNoRoadAssist: string
  PolicyNo: string
}
export interface AdditionalProduct {
  AgreementCode: string
  Description: string
  ReferenceNo: string
}

export interface Driver {
  accountNo: string
  department: string
  email: string
  hasConfiguratorAccess: boolean
  jobTitle: string
  id: string
  name: string
  isWebUser: boolean
  phoneNo: string
  regNo: string
  type: string
  webUserType: string
}

export interface Car {
  registrationNumber: string
  fuel: string
  year: number
  description: string
  mileage: number
  contractMileage: number
  actualDeliveryDate: string
  plannedReturnDate: string
  simulatedMileage: number
  accountNo: string
  insuranceCompany: string
  driver: Driver
  hasDeviations: boolean
  events: any[]
  status: CarStatus
  lifetime: number
  currentLife: number
  maturity: string
  lifespan: number
  make: string
  model: string
  engine: string
  driverName: string
  noCarReplacement: boolean
  firstServiceDist: number
  firstServicePeriod: number
  intermServiceDist: number
  intermServicePeriod: number
  mainServiceDist: number
  mainServicePeriod: number
  nextInspectionDate: string
  actualReturnDate: string
  additionalProducts: AdditionalProduct[]
  taxCalculationBasis?: number
  replacement?: any
  replaceAccountNo: string
  replaces?: any
  deliveryDate: string
}

export interface ContactDetails {
  companyExternalID: string
  personContactID: string
  contactType: string
  name: string
  address: string
  postCode: string
  city: string
  phone: string
  mail: string
  jobTitle: string
}

export interface Budget {
  carMake: string
  carModel: string
  driver?: string
  budgetId: string
  signingType: string
}