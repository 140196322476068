import React, { createContext, useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { fetchContacts, fetchDrivers } from '../api/api'
import { useChosenCompany } from './UserProvider'

const DriverContext = createContext({})

const DriverProvider = ({ children }) => {
  const queryClient = useQueryClient()

  const value = {
    updateDrivers: () => queryClient.invalidateQueries('drivers'),
  }

  return <DriverContext.Provider value={value}>{children}</DriverContext.Provider>
}

export const useDrivers = (id = '') => {
  const company = useChosenCompany()
  const {
    data: drivers,
    isLoading,
    refetch,
  } = useQuery(['drivers'], () => fetchDrivers(company.id), {
    enabled: !!company,
  })

  return {
    refetch,
    drivers,
    currentDriver: (drivers || []).find((driver) => driver.id === id),
    isLoading,
  }
}

export const useContacts = (id = '') => {
  const company = useChosenCompany()
  const {
    data: contacts,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(['contacts'], () => fetchContacts(company.id), {
    enabled: !!company,
  })

  return {
    isRefetching,
    refetch,
    contacts,
    currentContact: (contacts || []).find((contact) => contact.id === id),
    isLoading,
  }
}

export const usePersons = (id) => {
  const drivers = useDrivers(id)
  const contacts = useContacts(id)

  return {
    ...drivers,
    ...contacts,
    isLoading: drivers.isLoading || contacts.isLoading,
  }
}

export const useDriverContext = () => useContext(DriverContext)

export default DriverProvider
