import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import { Layout } from '@staccx/bento'
import { SmallHeading } from '../../../components/text/Headings'
import DropDown from '../../../components/DropDownMenu'
import CustomSkeleton from './skeleton/skeleton'
import { css } from 'styled-components'
import formatOptionName from '../../../utils/formatOptionName'

const ChoosePaint = ({
  paints,
  accessoriesError,
  formError,
  selectedPaintType,
  handlePaintChange,
  selectedColor,
  handleColorChange,
}) => {
  return (
    <>
      {paints ? (
        <div>
          <Layout variant="fiftyFifty">
            <TranslatedText i18nKey={'PAINT_TYPE'}>
              {(value) => (
                <div>
                  <SmallHeading>{value}</SmallHeading>
                  <DropDown
                    isError={!!formError.paint}
                    defaultValue={selectedPaintType}
                    options={paints.map((o) => o.paintType)}
                    onChange={handlePaintChange}
                    placeholder={!selectedPaintType && <TranslatedText i18nKey="CHOOSE_PAINT" />}
                    getOptionValue={(option) => `${option['optionId']}`}
                    getOptionLabel={(option) => `${formatOptionName(option['optionName'])}`}
                  />
                </div>
              )}
            </TranslatedText>
            <TranslatedText i18nKey={'COLOR'}>
              {(value) => (
                <div>
                  <SmallHeading>{value}</SmallHeading>
                  <DropDown
                    isError={!!formError.color}
                    value={selectedColor || ''}
                    options={
                      selectedPaintType &&
                      paints.find((options) => options.paintType.optionId === selectedPaintType.optionId)?.colors
                    }
                    onChange={handleColorChange}
                    placeholder={!selectedColor && <TranslatedText i18nKey="CHOOSE_COLOR" />}
                    getOptionValue={(option) => `${option['optionId']}`}
                    getOptionLabel={(option) => `${formatOptionName(option['optionName'])}`}
                  />
                </div>
              )}
            </TranslatedText>
          </Layout>
        </div>
      ) : (
        !accessoriesError && (
          <div>
            <CustomSkeleton
              skeletons={[
                {
                  height: 20,
                  width: 300,
                  count: 1,
                },
                {
                  height: 20,
                  width: 300,
                  count: 1,
                },
              ]}
              theme={css`
                justify-content: space-between;
              `}
            />
          </div>
        )
      )}
    </>
  )
}

export default ChoosePaint
