import styled from 'styled-components'
import { Button, theming } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import Container from '../../../components/Container'
import Complete from '../../../icons/Complete'
import { Link, useNavigate } from 'react-router-dom'

const SigningBatchComplete = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Wrapper>
        <Complete />
        <Title>
          <TranslatedText i18nKey="SIGNING_COMPLETED">{(value) => value ?? 'Signering fullført'}</TranslatedText>
        </Title>

        <StyledButton onClick={() => navigate('/tasks')}>
          <TranslatedText i18nKey="GO_BACK_TASKS">{(value) => value ?? 'Gå tilbake til mine oppgaver'}</TranslatedText>
        </StyledButton>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${theming.spacing.medium()};
  text-align: center;
`

const Title = styled.span`
  font-size: var(--spacing-medium);
  font-weight: 500;
  margin-top: ${theming.spacing.medium()};
  margin-bottom: ${theming.spacing.medium()};
`

const Subtitle = styled.span`
  margin-bottom: var(--spacing-mediumMinus);
  font-size: var(--font-h4);
  font-weight: 400;
`

const LowercaseLink = styled(Link)`
  text-transform: lowercase;
`

const StyledButton = styled(Button)`
  margin-top: ${theming.spacing.large()};
  padding: 0.5rem 4rem;
`

export default SigningBatchComplete