import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import fleetReportData from './fleetReportData'
import { columnSum, formatCurrency } from '../../../../../utils/tableFuncs'

const columns = fleetReportData()

type FleetReportTableProps = {
  data: any
  isLoading?: boolean
}
function FleetReportTable({ data, isLoading }: FleetReportTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    fixedYear: isNaN(columnSum(data, 'fixedYear')) ? formatCurrency(0) : formatCurrency(columnSum(data, 'fixedYear')),
    fixedContract: isNaN(columnSum(data, 'fixedContract'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'fixedContract')),
    nonFixedYear: isNaN(columnSum(data, 'nonFixedYear'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'nonFixedYear')),
    nonFixedContract: isNaN(columnSum(data, 'nonFixedContract'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'nonFixedContract')),
    totalYear: isNaN(columnSum(data, 'totalYear')) ? formatCurrency(0) : formatCurrency(columnSum(data, 'totalYear')),
    totalContract: isNaN(columnSum(data, 'totalContract'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'totalContract')),
  }

  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default FleetReportTable
