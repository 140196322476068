import React, { useState } from 'react'
import { Card } from '../Card'
import { i18nInstance } from '@staccx/i18n'
import styled, { css } from 'styled-components'
import { Button } from '../Button'
import { startBatchSigningProcess } from '../../../../api/api'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import Dialog from '../../../../components/modal/Dialog'
import { CloseButton } from '../../../../theme/Theme.Button'
import Close from '../../../../icons/Close'
import BudgetPreview from './BudgetPreview'
import { H4Heading } from '../../../../components/text/Headings'
import Alert from "../../../../components/Alert";
import { displayBudgetText } from '../displayBudgetText'

const BudgetSingle = ({ budget, user }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [showDialog, setShowDialog] = useState(false)
  const [error, setError] = useState(null)

  const startSigningProcess = async (flowId) => {
    const payload = {
      budgetFlowIds: [flowId],
      signer: {
        email: user.user.contactDetails.mail,
        name: user.user.id,
      },
    }

    if (!payload.signer.email) {
      setError('Mangler e-postadresse for å kunne signere budsjettene.')
      setTimeout(() => {
        setError(null)
      }, 10000)
      return null
    }

    try {
      const { id } = await startBatchSigningProcess(payload)
      return id
    } catch (e) {
      setError('Noe gikk galt.')
      setTimeout(() => {
        setError(null)
      }, 10000)
      return null
    }
  }

  const mutation = useMutation(startSigningProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pending-batch-signings'])
      queryClient.invalidateQueries(['pending-budgets'])
    },
  })

  const handleSubmit = async (flowId) => {
    const signingFlowId = await mutation.mutateAsync(flowId)
    if (signingFlowId) {
      navigate(`/tasks/signing-batch/${signingFlowId}`)
    }
  }

  return (
    <div>
      {error && (
        <FlexRow>
          <AlertWrapper>
            <Alert type="warning">
              {error}
            </Alert>
          </AlertWrapper>
        </FlexRow>
      )}

      <H4Heading>{'Start signering'}</H4Heading>
      <Card
        key={budget.flowId}
        title={displayBudgetText({
          ...budget?.data?.budgetData,
          signingType: budget.data?.signingType,
        })}
        action={
          <ButtonsContainer>
            <Button type={'outline'} onClick={() => setShowDialog(true)}>
              Forhåndsvis
            </Button>
            <Button
              type={'solid'}
              onClick={() => handleSubmit(budget.flowId)}
              disabled={mutation.isLoading}
            >
              Signer
            </Button>
          </ButtonsContainer>
        }
      ></Card>
      <Dialog
        open={showDialog}
        onOpenChange={setShowDialog}
        width="fit-content"
      >
        <CloseButton onClick={() => setShowDialog(false)}>
          <Close />
        </CloseButton>
        <BudgetPreview
          flowId={budget.flowId}
          attachmentKey={budget.data.budgetData.document.attachmentId}
          mimeType={budget.data.budgetData.document.mimetype}
        />
      </Dialog>
    </div>
  )
}

export default BudgetSingle

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`

const AlertWrapper = styled.div`
  margin-bottom: 10px;
  width: 450px;
`
