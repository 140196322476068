import React, { useState } from 'react'
import { useQuery } from 'react-query'
import QuestionCard from '../components/QuestionCard'
import styled from 'styled-components'
import ContactInformation from '../components/ContactInformation'
import { useFeatureToggles, useUserContext } from '../../../context/UserProvider'
import ContactForm from '../components/ContactForm'
import { contactFromSanity, faqFromSanity } from '../../../api/api'
import { useI18nContext } from '../../../context/I18nProvider'
import { TranslatedText } from '@staccx/i18n'
import Container from '../../../components/Container'

const Help = () => {
  const [selected, selectedSet] = useState([])
  const { data = [] } = useQuery(['faq'], () => faqFromSanity())
  const { data: contactData = [] } = useQuery(['contact'], () => contactFromSanity())
  const { language } = useI18nContext()
  const featureToggles = useFeatureToggles()

  const result = data
    .filter((item) => item.headingText.nb !== 'Kontaktinfo')
    .map((faq) => ({
      headingText: faq.headingText[language],
      body: faq.body[language],
    }))

  const contactResult = contactData?.map((contact) => ({
    contact: contact,
  }))

  const selectedHandler = (elem) => {
    if (selected.includes(elem)) {
      selectedSet((prevState) => prevState.filter((s) => s !== elem))
    } else {
      selectedSet((prevState) => [...prevState, elem])
    }
  }

  const { userData } = useUserContext()

  const leftResult = result.splice(0, Math.floor(result.length / 2))

  return (
    <div>
      <Container>
        <Heading>
          <TranslatedText i18nKey="FAQ" />
        </Heading>
        <CardGrid>
          <Grids>
            {result.map((question) => (
              <QuestionCard
                title={question.headingText}
                onClick={selectedHandler}
                selected={selected.includes(question.headingText)}
                text={question.body}
              />
            ))}
          </Grids>
          <Grids>
            {leftResult.map((question) => (
              <QuestionCard
                title={question.headingText}
                onClick={selectedHandler}
                selected={selected.includes(question.headingText)}
                text={question.body}
              />
            ))}
          </Grids>
        </CardGrid>
        <ContactContainerForm>
          {contactResult.length && contactResult[0].contact && (
            <ContactInformation contactData={contactResult[0].contact} />
          )}
          <ContactForm
            userData={userData?.user?.contactDetails}
            chosenCompany={userData?.chosenCompany?.name}
          ></ContactForm>
        </ContactContainerForm>
      </Container>
    </div>
  )
}

export default Help

const CardGrid = styled.div`
  display: grid;
  margin: 0 auto;
  column-gap: var(--spacing-smallPlus);
  align-content: flex-start;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Grids = styled.div`
  display: grid;
  gap: var(--spacing-smallPlus);
  grid-template-columns: 1fr;
  align-content: start;
  position: relative;
`

const Heading = styled.p`
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
  color: var(--color-white);
  position: relative;
`

const ContactContainerForm = styled.div`
  display: flex;
  margin-top: var(--spacing-largePlus);
  justify-content: center;
`
