import React, { useMemo } from 'react'
import Container from '../../../components/Container'
import { Layout } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import ReportTermSelect from '../components/ReportTermSelect'
import { useUserContext } from '../../../context/UserProvider'
import { useQuery } from 'react-query'
import { fetchMileageReport, fetchFleetReportTerms } from '../../../api/api'
import MileageReportTable from '../components/MileageTables/MileageReportTable'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import ExcelButton from '../components/ExcelButton'
import getLastDateInMonth from '../../../utils/getLastDateInMonth'
import { Heading } from '../../../components/text/Headings'

const MileageReport = () => {
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id
  const { data: terms = [] } = useQuery(['fleetReportTerms', companyId], () => fetchFleetReportTerms(companyId), {
    enabled: !!companyId
  })

  const { data = [], status } = useQuery(
    ['mileageReport', companyId, fleetReportTerm],
    () => fetchMileageReport(companyId, fleetReportTerm, getLastDateInMonth(fleetReportTerm)),
    {
      enabled: !!companyId
    }
  )

  const agreementFilter = useFilterOnConsolidateAgreement()

  return (
    <Container width="unlimited">
      <Layout rowGap={'large'}>
        <TranslatedText i18nKey={'MILEAGE_REPORT_MENUITEM'}>{(value) => <Heading>{value}</Heading>}</TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <MileageReportTable
        data={data
          .filter(agreementFilter('ConsolidatedInvoiceNo'))
          .filter((obj, index, self) => index === self.findIndex((t) => t.AccountNo === obj.AccountNo))}
        isLoading={status === 'loading'}
      />
    </Container>
  )
}

export default MileageReport
