import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TranslatedText } from '@staccx/i18n'
import { Button, theming } from '@staccx/bento'
import styled from 'styled-components'
import Container from '../../../components/Container'
import Failure from '../../../icons/Failure'
import { getSigningTasks } from '../../../api/api'
import { completeTask } from '../../../api/flow'

const SigningCallback = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [signingUrl, setSigningUrl] = useState()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  useEffect(() => {
    const fetchSigningTasks = async () => {
      try {
        const data = await getSigningTasks()
        const tasks = data.tasks
        const task = tasks.find((task) => {

          const context = task.context && task.context.document
          if (context) {
            const { taskId, requestId } = context
            return taskId === searchParams.get('taskId') && requestId === searchParams.get('requestId')
          }
          return false
        })
        if (!task) {
          return setIsLoading(false)
        }

        setSigningUrl(task.context.document.signingUrl)

        if (status === 'taskcomplete') {
          await completeTask({
            taskId: task.taskId,
            data: { taskId: searchParams.get('taskId'), requestId: searchParams.get('requestId') }
          })

          const signingType = task.context.signingType

          if (signingType) {
            return navigate(`/tasks/signing/complete/${signingType}`)
          } else {
            navigate('/tasks/signing/complete')
          }

        }

        setIsLoading(false)
      } catch (error) {
        return setIsLoading(false)
      }
    }

    fetchSigningTasks()
  }, [status, navigate, searchParams])

  const handleClick = () => {
    if (signingUrl) {
      window.location.href = signingUrl
    } else {
      navigate('/tasks')
    }
  }

  return (
    <Container isLoading={isLoading} renderChildrenDuringLoading={false}>
      <Wrapper>
        <Failure />
        <Title>
          <TranslatedText i18nKey="SIGNING_FAILED">{(value) => value ?? 'BankID-signering feilet'}</TranslatedText>
        </Title>
        <Subtitle>
          <TranslatedText i18nKey="SIGNING_FAILED_TEXT">
            {(value) => value ?? 'Beklager, la oss prøve signering på nytt'}
          </TranslatedText>
        </Subtitle>
        {signingUrl ? (
          <StyledButton onClick={() => handleClick()}>
            <TranslatedText i18nKey="TRY_AGAIN">{(value) => value ?? 'Prøv igjen'}</TranslatedText>
          </StyledButton>
        ) : (
          <StyledButton onClick={() => handleClick()}>
            <TranslatedText i18nKey="GO_BACK_TASKS">
              {(value) => value ?? 'Gå tilbake til mine oppgaver'}
            </TranslatedText>
          </StyledButton>
        )}
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${theming.spacing.medium()};
  text-align: center;
`

const Title = styled.span`
  font-size: var(--spacing-medium);
  font-weight: 500;
  margin-top: ${theming.spacing.medium()};
  margin-bottom: ${theming.spacing.medium()};
`

const Subtitle = styled.span`
  margin-bottom: var(--spacing-mediumMinus);
  font-size: var(--font-h4);
  font-weight: 400;
`

const StyledButton = styled(Button)`
  margin-top: ${theming.spacing.large()};
  padding: 0.5rem 4rem;
`

export default SigningCallback
