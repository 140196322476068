/* eslint-disable */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { uniqBy } from 'lodash';
import formatOptionName from '../../../utils/formatOptionName';
import { colorFilter, packageFilter, paintTypeFilter } from '../../../api/jato';
import {
  CONFIGURE_BASE_CAR, CONFIGURE_CAR_OPTIONS, CONFIGURE_WHEEL_PACKAGES, SUMMARY,
} from './useCarConfiguration';

const pageSchemas = {
  [CONFIGURE_BASE_CAR]: {
    engine: {
      isRequired: true,
      type: 'VALIDATION_HEADING_ENGINE',
      description: 'VALIDATION_REQUIREMENT_HEADING',
    },
    requirements: {
      isRequired: true,
      type: 'VALIDATION_HEADING_REQUIREMENTS',
      description: 'VALIDATION_REQUIREMENT_HEADING',
    },
    needsVerification: {
      isRequired: false,
      type: 'VALIDATION_HEADING_NEEDS_VERIFICATION',
      description: 'VALIDATION_VERIFICATION_HEADING',
    },
  },
  [CONFIGURE_CAR_OPTIONS]: {
    color: {
      isRequired: true,
      type: 'VALIDATION_HEADING_COLOR',
      description: 'VALIDATION_COLOR_HEADING',
    },
    paint: {
      isRequired: true,
      type: 'VALIDATION_HEADING_PAINT',
      description: 'VALIDATION_COLOR_HEADING',
    },
    requirements: {
      isRequired: true,
      type: 'VALIDATION_HEADING_REQUIREMENTS',
      description: 'VALIDATION_REQUIREMENT_HEADING',
    },
    needsVerification: {
      isRequired: false,
      type: 'VALIDATION_HEADING_NEEDS_VERIFICATION',
      description: 'VALIDATION_VERIFICATION_HEADING',
    },
  },
  [CONFIGURE_WHEEL_PACKAGES]: {
    wheels: {
      isRequired: true,
      type: 'VALIDATION_HEADING_WHEELS',
      description: 'VALIDATION_COLOR_HEADING',
    },
  },
};

pageSchemas[SUMMARY] = {
  ...(Object.keys(pageSchemas).reduce((acc, curr) => ({ ...acc, ...pageSchemas[curr] }), {})),
};

export const useCarOptionRules = (allOptions, initialOptions, page, hasChosenEngine, visitedSteps = {}, hasChosenWheels, carHasSelectableWheels) => {
  const [selectedOptions, selectedOptionsSet] = useState(initialOptions);

  const [allRequiredSelections, allRequiredSelectionsSet] = useState(null);
  const [requiredSelections, requiredSelectionsSet] = useState(null);
  const [currentSelectedOption, currentSelectedOptionSet] = useState(null);
  const [optionThatRequiresSelections, optionThatRequiresSelectionsSet] = useState(null);

  const numberOfTimesInclusionsMemoHasRun = useRef(0);

  // validation states
  const [formError, formErrorSet] = useState({});

  // For initial state to be true: page must be visited, user must have clicked past engine-packages, and page cannot be summary
  const liveValidation = useRef(!!visitedSteps[SUMMARY]);

  useEffect(() => {
    if (allOptions?.length > 0) {
      selectedOptionsSet([...initialOptions, ...allOptions.filter((o) => o.isRequired)]);
    }
  }, [initialOptions?.length, allOptions?.length]);

  useEffect(() => {
    // Should be true upon closing modal for a parent option
    // First OR is if you have selected something in the modal but not everything required
    // Second OR is if you close the modal before selecting anything
    if (
      (currentSelectedOption && optionThatRequiresSelections && currentSelectedOption.optionId === optionThatRequiresSelections.optionId)
      || (optionThatRequiresSelections && !currentSelectedOption)
    ) {
      requiredSelectionsSet(null);
      allRequiredSelectionsSet(null);
      optionThatRequiresSelectionsSet(null);
    } else if (isPartOfRequirement(currentSelectedOption)) { // Check if the selected option is part of the current requiredSelections instance, refreshing requirements
      const { requiredSelections, allRequiredSelections } = validateSelections();

      if (!requiredSelections || requiredSelections.length === 0) {
        // should cause modal to automatically close
        requiredSelectionsSet(null);
        allRequiredSelectionsSet(null);
        optionThatRequiresSelectionsSet(null);
      } else {
        // other options have requirements, so we retain all 'requiredSelections'
        allRequiredSelectionsSet(allRequiredSelections);
        requiredSelectionsSet(requiredSelections);
      }
    }
    // Check if the selected option has requirements
    else if (currentSelectedOption && !optionThatRequiresSelections) {
      const {
        requiredSelections,
        allRequiredSelections,
        parent,
      } = getRequiredOptionsByIncludedOptions(currentSelectedOption);
      // if currentSelectedOption has requirement(s)
      if (requiredSelections) {
        requiredSelectionsSet(requiredSelections);
        allRequiredSelectionsSet(allRequiredSelections);
        optionThatRequiresSelectionsSet(parent);
      }
    }
  }, [currentSelectedOption]);

  const selectedPackages = useMemo(() => selectedOptions.filter(packageFilter), [selectedOptions.length, selectedOptions]);

  const getOptionByOptionId = (optionId) => allOptions.find((o) => o.optionId === optionId);

  const getUnfulfilledRequirement = (option, optionsExcludedFromRequirement = []) => {
    if (!option.requires || option.requires.length === 0) return [];

    // filter out alternatives for this requires by checking that no optionId in a requires object's optionId-array is already checked
    const requires = option.requires.filter((curr) => ![...selectedOptions, ...optionsExcludedFromRequirement].some((opt) => curr.optionId.includes(opt.optionId)));
    // if selected options includes at least one of option ids in each object, do not map it
    return requires.filter((o) => !o.optionId.some((o2) => selectedOptions.some((o3) => o3.includes.includes(o2))));

    // no option ids can be excluded from already-selected option
    // Commented out for now since this interferes with solution from AUT 225
    // return includes.filter(o => o.optionId.every(o2 => selectedOptions.every(o3 => !o3.excludes.includes(o2))))
  };

  // Finds fulfilled requirement for one option
  const getFulfilledRequirement = (option, optionsExcludedFromRequirement = []) => {
    if (!option.requires || option.requires.length === 0) return [];

    // at least one selected option must match ONE of option ids in each object
    return option.requires.filter((curr) => [...selectedOptions, ...optionsExcludedFromRequirement].some((opt) => curr.optionId.includes(opt.optionId)));
  };

  // The following reducers avoid checking a previously identified required option
  const filterAlreadyIdentifiedRequirements = (requirementObjects, allRequirements) => requirementObjects.reduce((acc, reqObject) => [...acc, {
    optionId: reqObject.optionId.filter((requirementId) => !allRequirements.some((identifiedRequirementId) => requirementId !== identifiedRequirementId.optionId)),
  }].filter((o) => o.optionId.length > 0), []);

  const getRequiredOptionsByIncludedOptions = (option) => {
    // initial requirements by option
    const requirements = (option.includes || []).reduce((acc, curr) => {
      const found = getOptionByOptionId(curr);
      if (found) {
        return [
          ...acc,
          found,
        ];
      }
      return acc;
    }, []);

    const requiredSelections = [];
    // if an initially identified option has a requirement, it recursively checks each requirement this might have
    const checkRequirements = (reqs = [], current = null, parent = null) => {
      const allReqs = [...reqs];
      const newReqs = [];
      const option = typeof current === 'object' ? current : getOptionByOptionId(current);

      if (option) {
        const reqsForThisOption = option.requires.map((req) => req.optionId.map((j) => getOptionByOptionId(j))).flat(2);
        allReqs.push(...reqsForThisOption);
        newReqs.push(...reqsForThisOption);
      } else if (parent) {
        newReqs.push(...allReqs);
        newReqs.push(parent);
      }
      for (const requiredOption of newReqs) {
        const unfulfilled = filterAlreadyIdentifiedRequirements(getUnfulfilledRequirement(requiredOption), allReqs);
        const fulfilled = filterAlreadyIdentifiedRequirements(getFulfilledRequirement(requiredOption), allReqs);

        if (unfulfilled.length > 0) {
          const objects = unfulfilled.reduce((acc, curr) => {
            if (curr.optionId.length > 1) {
              return [...acc, { oneOf: curr.optionId.map((o2) => getOptionByOptionId(o2)) }];
            }
            return [...acc, { all: curr.optionId.map((o2) => getOptionByOptionId(o2)) }];
          }, []);

          requiredSelections.push({ requiredBy: requiredOption, requirements: objects });
          return unfulfilled.map((o) => o.optionId.map((o2) => checkRequirements(
            [
              ...reqs,
              ...unfulfilled.map((o) => o.optionId.map((o2) => getOptionByOptionId(o2))).flat(),
              ...fulfilled.map((o) => o.optionId.map((o2) => getOptionByOptionId(o2))).flat(),
              selectedOptions.find((o) => o.optionId === requiredOption.optionId) ? requiredOption : null,
            ].filter((i) => i),
            o2,
          ))).flat(2);
        }
      }
      return uniqBy([...allReqs, ...newReqs], 'optionId');
    };

    const allRequiredSelections = checkRequirements(requirements, null, option);
    return { ...(requiredSelections.length > 0 ? { requiredSelections } : null), allRequiredSelections, parent: option };
  };

  // returns requirements (unfulfilled, fulfilled) for all options
  const validateSelections = (pageToValidate) => {
    // On CONFIGURE_BASE_CAR we only want to validate against packages since other options are selected on the next page
    const optionsToValidateAgainst = pageToValidate === CONFIGURE_BASE_CAR
      ? selectedOptions.filter(packageFilter)
      : pageToValidate === CONFIGURE_CAR_OPTIONS
        ? selectedOptions.filter((s) => !packageFilter(s))
        : selectedOptions;

    const requirements = getRequirementsForSelectedOptions(optionsToValidateAgainst);
    return {
      requiredSelections: requirements.map(({ requiredSelections = [] }) => requiredSelections).flat(),
      allRequiredSelections: requirements.map(({ allRequiredSelections = [] }) => allRequiredSelections).flat(),
      needsVerification: requirements.map(({ needsVerification = [] }) => needsVerification).flat(),
    };
  };

  const findExcludedOptionsThatDoNotWorkWithSelectedOptions = (reqs) => {
    const unavailableRequirements = reqs.filter((r) => excludedOptions.includes(r.optionId));
    if (unavailableRequirements.length > 0) {
      return selectedOptions.reduce((acc, selectedOption) => {
        const selectedOptionExcludes = unavailableRequirements.filter((unavailable) => selectedOption.excludes.some((excludedId) => excludedId === unavailable.optionId));
        if (selectedOptionExcludes.length > 0) {
          return [
            ...acc,
            {
              excludedBy: selectedOption,
              excludes: selectedOptionExcludes,
            },
          ];
        }
        return acc;
      }, []);
    }
  };

  const getRequirementsForSelectedOptions = (options) => {
    const requirements = options.map((option) => getRequiredOptionsByIncludedOptions(option));
    return requirements.map((req) => {
      const uncertain = findExcludedOptionsThatDoNotWorkWithSelectedOptions(req.allRequiredSelections);
      if (uncertain?.length > 0) {
        return {
          ...req,
          needsVerification: uncertain,
        };
      }
      return req;
    });
  };

  const fulfillsRequirements = (option, optionsExcludedFromRequirement = []) => {
    // work-around for unresolved bug where an empty selectedOptions with populated optionsExcludedFromRequirement results in unwanted false
    if (selectedOptions.length === 0 && optionsExcludedFromRequirement.length > 0) {
      return option.requires.every((curr) => optionsExcludedFromRequirement.some((opt) => curr.optionId.includes(opt.optionId)));
    }

    if (!option.requires || option.requires.length === 0) return true;
    if (!selectedOptions?.length > 0 && option.requires?.length > 0) return false;

    return option.requires.every((curr) => [...selectedOptions, ...optionsExcludedFromRequirement].some((opt) => curr.optionId.includes(opt.optionId)));
  };

  // Finds nested, fulfilled requirements
  const getNestedFulfilledRequirements = (option, requirementIds = [], checkedOptions = []) => {
    const foundReqs = getFulfilledRequirement(option).map((req) => req.optionId).flat();
    return foundReqs.reduce((prev, next) => {
      if (checkedOptions.includes(next)) {
        return prev;
      }
      checkedOptions = [...checkedOptions, next];

      const found = selectedOptions.find((o) => o.optionId === next);
      const requirements = found ? [...getNestedFulfilledRequirements(found, requirementIds, checkedOptions), found] : [];
      return [...prev, ...requirements];
    }, []);
  };

  const getRequiringOptions = (option) => selectedOptions.reduce((acc, option2) => {
    if (!option2.requires || option2.requires.length === 0) {
      return acc;
    }

    if (option2.requires
      .some((requirementObject) => requirementObject.optionId
        .some((requirementOption) => requirementOption === option.optionId
            && selectedOptions
              .some((selectedOption) => selectedOption.optionId === requirementOption)))) {
      return [...acc, option2];
    }

    return acc;
  }, []).flat();

  const getOptionDependencies = (option) => {
    const thisOptionsRequirements = optionsDependencyGraph[option.optionId];
    const thisOptionRequiredBy = Object.keys(optionsDependencyGraph)
      .filter((key) => optionsDependencyGraph[key].some((dep) => dep.optionId === option.optionId));
    return {
      option,
      requiredBy: thisOptionRequiredBy,
      requires: thisOptionsRequirements,
    };
  };

  // Finds selected options that do not correctly indicate that it cannot be combined with another
  // e.g: #1 excludes #2, but #2 does not exclude #1. This function results in finding that #2 does not work with #1
  const getSelectedInconsistentExclusions = () => allOptions
    .filter((o) => o.excludes
      .filter((e) => selectedOptions.some((o) => e === o.optionId)).length > 0).flat(2)
    .map(({ optionId }) => optionId);

  const handleAddOption = (option) => {
    if (!option || selectedOptions.some((o) => o.optionId === option.optionId)) {
      return;
    }

    selectedOptionsSet((prevState) => [...prevState, option, ...getNestedUniqueInclusions(option).map((o) => getOptionByOptionId(o))]);
  };

  const handleAddOptionNorwegianEquipment = (option) => {
    if (!option || selectedOptions.some((o) => o.optionId === option.optionId)) {
      return;
    }

    selectedOptionsSet((prevState) => [...prevState, option]);
  };

  const handleRemoveOption = (option, optionWithRequirementsToRetain) => {
    if (!option || !selectedOptions.some((o) => o.optionId === option.optionId)) {
      return;
    }

    // Options that, in most cases, were selected through this option
    const fulfilledRequirements = getNestedFulfilledRequirements(option).map((o) => [o.optionId, ...o.includes]).flat();
    const requiringOptions = getRequiringOptions(option).map(({ optionId }) => optionId);

    let optionIdsToDeselect = [option.optionId, ...getNestedUniqueInclusions(option), ...fulfilledRequirements, ...requiringOptions];

    if (optionWithRequirementsToRetain) {
      // Options that, in most cases, were selected through this option
      const exemptionFulfilledRequirements = getNestedFulfilledRequirements(optionWithRequirementsToRetain).map((o) => [o.optionId, ...o.includes]).flat();
      const exemptionRequiringOptions = getRequiringOptions(optionWithRequirementsToRetain).map(({ optionId }) => optionId);

      const optionIdsToNotDeselect = [...getNestedUniqueInclusions(optionWithRequirementsToRetain), ...exemptionFulfilledRequirements, ...exemptionRequiringOptions];

      if (optionIdsToNotDeselect.length > 0) {
        optionIdsToDeselect = optionIdsToDeselect.filter((o) => !optionIdsToNotDeselect.includes(o));
      }
    }

    selectedOptionsSet((prevState) => prevState.filter(({ optionId }) => !optionIdsToDeselect.some((optToDeselect) => optionId === optToDeselect)));
  };

  const isPartOfRequirement = (option) => {
    if (!option) return false;
    // :upside_down_face:
    const allOptionIds = requiredSelections ? requiredSelections.map((req) => req.requirements.map((req2) => (req2.all || req2.oneOf || []))).flat(2).map((o) => o.optionId) : [];
    return allOptionIds.includes(option.optionId);
  };

  const handleToggleOption = (option, optionWithRequirementsToRetain, triggerRevalidation = true) => {
    // If you close the modal that is prompted when there exists an optionThatRequiresSelections
    if (optionThatRequiresSelections && (option.optionId === optionThatRequiresSelections.optionId)) {
      if (triggerRevalidation) {
        currentSelectedOptionSet(null);
      }
      if (requiredSelections) {
        handleRemoveOption(option);
      }
    } else if (option && selectedOptions.find((o) => o.optionId === option.optionId)) {
      handleRemoveOption(option, optionWithRequirementsToRetain);
      if (triggerRevalidation) {
        currentSelectedOptionSet(null);
      }
    } else {
      if (triggerRevalidation) {
        currentSelectedOptionSet(option);
      }
      handleAddOption(option);
    }
  };

  const handleNorwegianEquipmentToggleOption = (option) => {
    if (option && selectedOptions.find((o) => o.optionId === option.optionId)) {
      handleRemoveOption(option);
      currentSelectedOptionSet(null);
    } else {
      currentSelectedOptionSet(option);
      handleAddOptionNorwegianEquipment(option);
    }
  };

  const getOptionNameByOptionId = (optionId) => {
    if (!allOptions) return null;
    const option = allOptions.find((a) => a.optionId === optionId);
    return option ? formatOptionName(option.optionName) : '';
  };

  const getOptionExcludedBy = (optionId) => {
    if (!allOptions) return null;
    const allOptionsThatExcludeOptionIds = selectedOptions.filter((a) => a.excludes.some((excludedId) => excludedId === optionId));

    return allOptionsThatExcludeOptionIds.filter((a) => selectedOptions.some((selectedOption) => selectedOption.optionId === a.optionId));
  };

  const getExcludedByOption = ({ excludes = [] }) => excludes.filter((e) => selectedOptions.some((o) => e === o.optionId));

  const getOptionIncludedBy = (optionId) => {
    if (!allOptions) return null;
    const allOptionsThatIncludeOptionIds = allOptions.filter((a) => a.includes.some((includedId) => includedId === optionId));

    return uniqBy(allOptionsThatIncludeOptionIds.filter((a) => selectedOptions.some((selectedOption) => selectedOption.optionId === a.optionId)), 'optionId');
  };

  const getSelectedByPackage = (option) => {
    const includingOptions = selectedPackages.filter((o) => o.includes.includes(option.optionId));
    const requiringOptions = getRequiringOptions(option);
    const combined = [...includingOptions, ...requiringOptions];
    return combined.length > 0 ? combined : null;
  };

  // Gets all nested, included options by recursion. Does not include itself, i.e. the passed option
  const getNestedUniqueInclusions = (option) => {
    const getInclusionsByOption = (optionToCheck) => {
      const isOptionsObject = typeof optionToCheck === 'object';

      const optionToUse = isOptionsObject ? optionToCheck : getOptionByOptionId(optionToCheck);

      return [
        ...(!isOptionsObject) ? [optionToCheck] : [],
        ...(optionToUse?.includes || []),
        ...(optionToUse?.includes || []).map((e) => getInclusionsByOption(e)).flat(),
      ];
    };

    return [
      ...new Set(option.includes?.map((opt) => getInclusionsByOption(opt)).flat()),
    ];
  };

  // Finds all nested includes. Triggers on every change to selectedOptions
  const includedOptions = useMemo(() => {
    if (!selectedOptions) return [];

    numberOfTimesInclusionsMemoHasRun.current += 1;

    return [...new Set(selectedOptions?.map((option) => getNestedUniqueInclusions(option)).flat())];
  }, [selectedOptions]);

  // Finds all excludes. Triggers when includedOptions has finished fin
  const excludedOptions = useMemo(() => {
    if (!selectedOptions || numberOfTimesInclusionsMemoHasRun.current === 0) return [];

    return [...new Set([
      ...selectedOptions.map((option) => option.excludes).flat(),
      ...getSelectedInconsistentExclusions(),
    ])];
  }, [numberOfTimesInclusionsMemoHasRun.current]);

  const needsVerification = useMemo(() => {
    if (!excludedOptions) return null;

    const { needsVerification } = validateSelections(page);
    return needsVerification;
  }, [excludedOptions, selectedOptions]);

  const optionsDependencyGraph = useMemo(() => {
    if (!excludedOptions) return [];
    const { allRequiredSelections: fulfilledOptions } = validateSelections();

    return fulfilledOptions.reduce((acc, option) => ({
      ...acc,
      [option.optionId]: getNestedFulfilledRequirements(option),
    }), {});
  }, [selectedOptions, excludedOptions]);

  const doValidation = (schema, pageToValidate = page) => {
    let errors = {};
    // conditions and actions for each key in schema

    if (schema.color) {
      const hasChosenColor = selectedOptions.find(colorFilter);
      if (!hasChosenColor) {
        errors = {
          ...errors,
          color: {
            ...schema.color,
            ...(page !== CONFIGURE_BASE_CAR && { route: CONFIGURE_CAR_OPTIONS }),
          },
        };
      }
    }

    if (schema.paint) {
      const hasChosenPaint = selectedOptions.find(paintTypeFilter);
      if (!hasChosenPaint) {
        errors = {
          ...errors,
          paint: {
            ...schema.paint,
            ...(page !== CONFIGURE_BASE_CAR && { route: CONFIGURE_CAR_OPTIONS }),
          },
        };
      }
    }

    const { requiredSelections, needsVerification } = schema.requirements || schema.needsVerification ? validateSelections(pageToValidate) : {};

    if (schema.requirements && requiredSelections?.length > 0) {
      errors = {
        ...errors,
        requirements: {
          ...schema.requirements,
          options: requiredSelections,
        },
      };
    }

    if (schema.needsVerification && needsVerification?.length > 0) {
      errors = {
        ...errors,
        needsVerification: {
          ...schema.needsVerification,
          options: needsVerification,
        },
      };
    }

    if (schema.engine) {
      if (!hasChosenEngine) {
        errors = {
          ...errors,
          engine: {
            ...schema.engine,
            ...(page !== CONFIGURE_BASE_CAR && { route: CONFIGURE_BASE_CAR }),
          },
        };
      }
    }
    if (schema.wheels) {
      if (!hasChosenWheels && carHasSelectableWheels) {
        errors = {
          ...errors,
          wheels: {
            ...schema.wheels,
            ...(page !== CONFIGURE_WHEEL_PACKAGES && { route: CONFIGURE_WHEEL_PACKAGES }),
          },
        };
      }
    }

    return errors;
  };

  const simpleFormValidator = (pageToValidate = page) => {
    const schema = pageSchemas[pageToValidate] || {};
    return Object.keys(doValidation(schema, pageToValidate)).length > 0;
  };

  // Form validation functions
  const formValidator = () => {
    const schema = pageSchemas[page];

    if (!schema) {
      return {};
    }

    const errors = doValidation(schema);

    formErrorSet(errors);
    liveValidation.current = true;

    return errors;
  };

  const removeFormError = (errorType) => formErrorSet((prevState) => Object.entries(prevState).reduce((acc, curr) => {
    const [key, value] = curr;
    if (key !== errorType) {
      acc[key] = value;
    }
    return acc;
  }, {}));

  useEffect(() => {
    if (liveValidation.current && !!excludedOptions) {
      formValidator();
    }
  }, [selectedOptions.length, liveValidation.current, excludedOptions, hasChosenWheels]);

  const replaceOptionWith = (current, next, retainCommonRequirements) => {
    if ((current && next) && (current.optionId === next.optionId)) {
      return null;
    }

    if (current && selectedOptions.some((o) => o.optionId === current.optionId)) {
      if (retainCommonRequirements) {
        handleToggleOption(current, next);
      } else {
        handleToggleOption(current);
      }
    }

    if (next && !selectedOptions.some((o) => o.optionId === next.optionId)) {
      handleToggleOption(next);
    }
  };

  const setRequiredSelections = (selections) => {
    currentSelectedOptionSet(selections.requiredBy);
  };

  return {
    selectedOptions,
    includedOptions,
    excludedOptions,
    requiredSelections,
    needsVerification,
    allRequiredSelections,
    currentSelectedOption,
    optionThatRequiresSelections,
    handleAddOption,
    handleRemoveOption,
    handleToggleOption,
    handleNorwegianEquipmentToggleOption,
    getOptionNameByOptionId,
    getOptionExcludedBy,
    getExcludedByOption,
    getOptionIncludedBy,
    fulfillsRequirements,
    getSelectedByPackage,
    replaceOptionWith,
    formError,
    formValidator,
    simpleFormValidator,
    removeFormError,
    resetSelections: () => selectedOptionsSet([]),
    selectedPackages,
    setRequiredSelections,
    getOptionDependencies,
  };
};
