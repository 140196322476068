import React, { useEffect, useMemo, useRef, useState } from 'react'
import Flex from '../../../components/Flex'

import { i18nInstance, TranslatedText } from '@staccx/i18n'
import DropDownMenu from '../../../components/DropDownMenu'
import { Car, ContactDetails, Driver } from '../../../types'
import formatDrivers from '../../../utils/driversDropdownFormat'
import { useMutation } from 'react-query'
import { changeDriver, fetchContactDetails } from '../../../api/api'
import customToast from '../../carConfiguration/components/toast/customToast'
import { useCarContext } from '../../../context/CarProvider'
import { useContacts, useDrivers } from '../../../context/DriverProvider'
import { AxiosResponse } from 'axios'
import Toast from '../../carConfiguration/components/toast/Toast'

interface FormattedDriver {
  label: string
  value: string
}

const DriverInput: React.FC<{ car: Car; driver: Driver }> = ({
  car,
  driver: d,
}) => {
  const { refetch }: Record<string, any> = useCarContext()
  const { refetch: refetchDrivers }: Record<string, any> = useDrivers()
  const { refetch: refetchContacts }: Record<string, any> = useContacts()
  const { contacts } = useContacts() as unknown as { contacts: Driver[] }

  const [drivers, driversSet] = useState([])
  const [driver, driverSet] = useState({ value: d.id, label: d.name })
  const cachedDriver = useRef({ value: d.id, label: d.name })

  const filteredContacts = useMemo(() => {
    const mainContacts = contacts?.filter(
      (value) => value.type !== 'Main Contact',
    )
    return contacts?.filter((value) => value.type !== 'Main Contact')
  }, [contacts])

  useEffect(() => {
    driversSet(
      formatDrivers(filteredContacts).sort(
        (a: FormattedDriver, b: FormattedDriver) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
      ),
    )
  }, [filteredContacts])

  const saveDriver = useMutation<
    AxiosResponse,
    never,
    { accountNo: string; driverId: string }
  >(
    'id',
    ({ accountNo, driverId }) => {
      return changeDriver(accountNo, driverId)
    },
    {
      onSuccess: async () => {
        try {
          await Promise.all([refetch(), refetchDrivers(), refetchContacts()])
        } catch (err) {
          console.debug('Could not refresh data after updating driver', err)
        }
        customToast.clearToastById('driver-error')
      },
      onError: () => {
        driverSet(cachedDriver.current)
        customToast.error(<TranslatedText i18nKey="ERROR_DRIVER_404" />, {
          toastId: 'driver-error',
        })
        customToast.clearQueue()
      },
    },
  )

  const checkDriver = (driver: FormattedDriver) => {
    fetchContactDetails(driver.value)
      .then((details) => {
        const contactDetails = details as unknown as ContactDetails

        if (contactDetails.phone && contactDetails.mail) {
          saveDriver.mutate({
            accountNo: car.accountNo,
            driverId: contactDetails.personContactID,
          })
        } else {
          driverSet(cachedDriver.current)
          if (!contactDetails.phone && !contactDetails.mail) {
            customToast.error(
              <TranslatedText i18nKey="ERROR_DRIVER_LACK_INFORMATION" />,
              { toastId: 'driver-error' },
            )
          } else if (!contactDetails.phone && contactDetails.mail) {
            customToast.error(
              <TranslatedText i18nKey="ERROR_DRIVER_LACK_INFORMATION_PHONE" />,
              {
                toastId: 'driver-error',
              },
            )
          } else {
            customToast.error(
              <TranslatedText i18nKey="ERROR_DRIVER_LACK_INFORMATION_EMAIL" />,
              {
                toastId: 'driver-error',
              },
            )
          }

          customToast.clearQueue()
        }
      })
      .catch(() =>
        alert(`Could not find details for driver with id ${driver.value}`),
      )
  }

  return (
    <div>
      <Flex direction={'column'} gap={'var(--spacing-mediumMinus)'}>
        <strong>
          <TranslatedText i18nKey="DRIVER" />
        </strong>

        <DropDownMenu
          width={'333px'}
          options={drivers}
          value={driver}
          placeholder={i18nInstance.translate('CHOOSE_DRIVER')}
          onChange={(d) => {
            cachedDriver.current = driver
            driverSet(d)
            checkDriver(d)
          }}
          defaultValue={undefined}
          getOptionValue={undefined}
          getOptionLabel={undefined}
          isError={undefined}
          resetObject={undefined}
          radius={undefined}
          overlap={undefined}
        />
      </Flex>
      <Toast limit={1} />
    </div>
  )
}

export default DriverInput
