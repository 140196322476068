import React from 'react'
import type { FuelReportDetails } from '../../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'
import { NavLink } from 'react-router-dom'

type DataProps = {
  options: { year: string; month: string }
}
export default function fuelReportData({ options }: DataProps) {
  const columnHelper = createColumnHelper<FuelReportDetails>()
  return [
    columnHelper.accessor('regNo', {
      id: 'regNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }) => (
        <NavLink
          to={`/reports/fuel/expense/${row.original.accountNo}/${options.year}/${options.month}`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {getValue()}
        </NavLink>
      ),
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('invoiceGroupingDescription', {
      id: 'invoiceGroupingDescription',
      header: () => <TranslatedText i18nKey={'INVOICE_GROUP'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('carDescription', {
      id: 'carDescription',
      header: () => <TranslatedText i18nKey={'OBJECT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('driverName', {
      id: 'driverName',
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('amountincVAT', {
      id: 'amountincVAT',
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('volume', {
      id: 'volume',
      header: () => <TranslatedText i18nKey={'VOLUME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('obsDescription', {
      id: 'obsDescription',
      header: () => <TranslatedText i18nKey={'ATTENTION'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }) => `${row.original.obs} - ${row.original.obsDescription}` || '',
      meta: { alignmentType: 'string' },
    }),
  ]
}
