import React from 'react'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { NavLink } from 'react-router-dom'
import { formatCurrency } from '../../../../../utils/tableFuncs'

export default function invoiceReportDetailsTableData() {
  return [
    {
      id: 'description',
      meta: { alignmentType: 'string' },
      accessorKey: 'description',
      header: () => <TranslatedText i18nKey={'COST_TYPE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row: any) => row.getValue() || '',
    },
    {
      id: 'fixedCostsTerm',
      meta: { alignmentType: 'number' },
      accessorKey: 'fixedCostsTerm',
      header: () => <TranslatedText i18nKey={'FIXED_COSTS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row: any) => formatCurrency(row.getValue()) || '',
    },
    {
      id: 'nonFixedCostsTerm',
      meta: { alignmentType: 'number' },
      accessorKey: 'nonFixedCostsTerm',
      header: () => <TranslatedText i18nKey={'VARIABLE_COSTS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row: any) => formatCurrency(row.getValue()) || '',
    },
    {
      id: 'totalTerm',
      meta: { alignmentType: 'number' },
      accessorKey: 'totalTerm',
      header: () => <TranslatedText i18nKey={'SUM_COSTS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }: any) => (
        <NavLink
          to={`/reports/fleet/expense/${row.original.accountNo}/${row.original.itemNo}/month`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {formatCurrency(getValue())}
        </NavLink>
      ),
    },
    {
      id: 'totalYear',
      meta: { alignmentType: 'number' },
      accessorKey: 'totalYear',
      header: () => <TranslatedText i18nKey={'YEAR_TO_DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }: any) => (
        <NavLink
          to={`/reports/fleet/expense/${row.original.accountNo}/${row.original.itemNo}/year`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {formatCurrency(getValue())}
        </NavLink>
      ),
    },
    {
      id: 'totalContract',
      meta: { alignmentType: 'number' },
      accessorKey: 'totalContract',
      header: () => <TranslatedText i18nKey={'CONTRACT_TO_DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row: any) => formatCurrency(row.getValue()) || '',
    },
  ]
}
