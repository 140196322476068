import React, { useState } from 'react'
import { formatMoney, Modal, Text, Wrapper } from '@staccx/bento'
import styled, { css } from 'styled-components'
import Chassis from '../../../icons/Chassis'
import DrivenWheels from '../../../icons/DrivenWheels'
import Doors from '../../../icons/Doors'
import { TranslatedText } from '@staccx/i18n'
import EquipmentModal from './EquipmentModal'
import ProgressBar from './ProgressBar'
import { CONFIRMATION, ROUTES } from '../hooks/useCarConfiguration'
import Driver from '../../../icons/Driver'
import { useInView } from 'react-intersection-observer'
import Tooltip from '../../../components/tooltip/Tooltip'

export const CarTopSummary = ({
  car,
  photoUrl,
  coreCalculatedMonthlyPrice,
  vehiclePhotos = [],
  standardEquipment = {},
  page,
  saveAndNavigate,
  routeValidator,
  visitedPages,
  driver,
  ...props
}) => {
  const [showEquipmentCatalogue, showEquipmentCatalogueSet] = useState()
  const {
    makeKey,
    modelKey,
    shortBodyName,
    drivenWheelsToDisplay,
    numberOfDoorsToDisplay,
    derivativeLocal: engine
  } = car || {}

  const { ref: currentElement, inView: isVisible } = useInView()

  return (
    <>
      <LargeCarSummary isVisible={isVisible} ref={currentElement}>
        <Wrapper>
          <Inner>
            <ImageCollection clickable={vehiclePhotos.length > 0} onClick={() => props.onPress()}>
              {photoUrl && <img src={photoUrl} alt={`${makeKey}-${modelKey}`} />}
              {vehiclePhotos && (
                <SmallImages>
                  {vehiclePhotos.slice(1, 4).map((photo, i) => (
                    <img key={photo} src={photo} alt={`${makeKey}-${modelKey}`} />
                  ))}
                </SmallImages>
              )}
            </ImageCollection>
            <CarSummaryDetails>
              <Wrapper>
                <ModelRow>
                  {makeKey} {modelKey}
                </ModelRow>
                {standardEquipment.length > 0 && (
                  <TranslatedText i18nKey={'SHOW_STANDARD_EQUIPMENT'}>
                    {(value) => (
                      <EquipmentCatalogueToggle onClick={() => showEquipmentCatalogueSet(true)}>
                        {value}
                      </EquipmentCatalogueToggle>
                    )}
                  </TranslatedText>
                )}
                <StyledModal onClose={() => showEquipmentCatalogueSet(false)} isOpen={showEquipmentCatalogue}>
                  <EquipmentModal equipment={standardEquipment} />
                </StyledModal>
              </Wrapper>
              {engine && (
                <EngineRow>
                  <Text>{engine}</Text>
                </EngineRow>
              )}
              <IconRow>
                <IconWithBottomText>
                  <Chassis />
                  <Text>{shortBodyName}</Text>
                </IconWithBottomText>
                <IconWithBottomText>
                  <DrivenWheels />
                  <Text>{drivenWheelsToDisplay}</Text>
                </IconWithBottomText>
                <IconWithBottomText>
                  <Doors />
                  <Text>{numberOfDoorsToDisplay}</Text>
                </IconWithBottomText>
                {driver && (
                  <IconWithBottomText>
                    <Driver />
                    <Text>{driver?.name}</Text>
                  </IconWithBottomText>
                )}
              </IconRow>
            </CarSummaryDetails>
            <MonthlyCostContainer>
              <PriceFromSpan>
                <TranslatedText i18nKey="PRICE_FROM" />
              </PriceFromSpan>
              <Tooltip
                trigger={
                  <div>
                    <PriceSpan>{formatMoney(coreCalculatedMonthlyPrice)},-</PriceSpan>
                    <TranslatedText i18nKey={'MONTH_SHORT'}>
                      {(value) => <CostDenominator>/{value}*</CostDenominator>}
                    </TranslatedText>
                    <VatSpan>
                      {' '}
                      <TranslatedText i18nKey={'EXCL_VAT'} />
                    </VatSpan>
                  </div>
                }
                contentAlign={'bottom'}
              >
                <TranslatedText i18nKey="WARNING_PRICE_ESTIMATE">{(value) => <span>{value}</span>}</TranslatedText>
              </Tooltip>
            </MonthlyCostContainer>
          </Inner>
        </Wrapper>
        <Wrapper>
          <ProgressBar
            elements={ROUTES}
            omit={[CONFIRMATION]}
            currentElement={page}
            onClick={saveAndNavigate}
            validate={routeValidator}
            visitedElements={visitedPages}
          />
        </Wrapper>
      </LargeCarSummary>
      <CarSummaryFixed isVisible={!isVisible}>
        <Wrapper>
          <InnerShort>
            <div>
              <Wrapper>
                <ModelRow>
                  {makeKey} {modelKey}
                </ModelRow>
                <EquipmentCatalogueToggle onClick={() => showEquipmentCatalogueSet(true)}>
                  Vis standardutstyr
                </EquipmentCatalogueToggle>
                <StyledModal onClose={() => showEquipmentCatalogueSet(false)} isOpen={showEquipmentCatalogue}>
                  <EquipmentModal equipment={standardEquipment} />
                </StyledModal>
              </Wrapper>
              {engine && (
                <EngineRow>
                  <Text>{engine}</Text>
                </EngineRow>
              )}
            </div>
            <IconRow>
              <IconWithBottomText>
                <Chassis />
                <Text>{shortBodyName}</Text>
              </IconWithBottomText>
              <IconWithBottomText>
                <DrivenWheels />
                <Text>{drivenWheelsToDisplay}</Text>
              </IconWithBottomText>
              <IconWithBottomText>
                <Doors />
                <Text>{numberOfDoorsToDisplay}</Text>
              </IconWithBottomText>
            </IconRow>
            <MonthlyCostContainer>
              <span>
                <TranslatedText i18nKey="PRICE_FROM" />
              </span>
              <Tooltip
                contentAlign="bottom"
                trigger={
                  <div>
                    <PriceSpan>{formatMoney(coreCalculatedMonthlyPrice)},-</PriceSpan>
                    <TranslatedText i18nKey={'MONTH_SHORT'}>
                      {(value) => <CostDenominator>/{value}*</CostDenominator>}
                    </TranslatedText>
                  </div>
                }
              >
                <TranslatedText i18nKey="WARNING_PRICE_ESTIMATE">{(value) => <span>{value}</span>}</TranslatedText>
              </Tooltip>
            </MonthlyCostContainer>
          </InnerShort>
        </Wrapper>
      </CarSummaryFixed>
    </>
  )
}
const PriceSpan = styled.span`
  font-size: var(--font-large);
  color: var(--color-primary);
`

const VatSpan = styled.span`
  color: var(--color-headerText);
`

const PriceFromSpan = styled.span`
  font-size: var(--font-base);
`

const LargeCarSummary = styled.div`
  ${(props) =>
    !props.isVisible
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
        `};
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  width: 100%;
  position: relative;
  left: 0;
  background-color: var(--color-white);
  border-bottom: var(--spacing-microMinus) solid var(--color-line);
  margin: -35px 0 var(--spacing-large) 0;
  padding: var(--spacing-mediumPlus) 0 var(--spacing-mediumPlus) 0;

  @keyframes appearOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  animation: appearOut 0.1s linear;
`

const StyledModal = styled(Modal)`
  #modal {
    height: calc(90vh - 72px);
    width: 90vw;
    padding: unset;
    max-width: 100%;
  }

  #modal-close {
    padding: var(--spacing-medium);
  }
`

const EquipmentCatalogueToggle = styled.a`
  color: #467eff;
  text-align: center;
  padding-left: var(--spacing-smallMinus);
  text-decoration: none;

  &:hover {
    color: var(--color-text);
    cursor: pointer;
  }
`

const ImageCollection = styled.div`
  display: inline-block;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  img {
    width: 150px;
  }
`

const SmallImages = styled.div`
  display: inline-block;

  img {
    width: var(--spacing-largePlus);
  }
`

const CarSummaryFixed = styled.div`
  ${(props) =>
    !props.isVisible
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
        `};
  // offset from menu
  margin-top: 65px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-line);
  z-index: 99;
  padding: var(--spacing-smallPlus) 0 var(--spacing-smallPlus) 0;
  @keyframes appearIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: appearIn 0.1s linear;
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: 150px auto auto;
  grid-column-gap: var(--spacing-large);
  align-items: center;
  justify-items: right;
`

const InnerShort = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child > div {
    margin: auto;
  }

  > div:nth-child(2) {
    gap: var(--spacing-huge);
  }

  > div:last-child {
    align-self: center;
  }
`

const CarSummaryDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  gap: 1rem;
`

const ModelRow = styled.span`
  font-size: var(--spacing-medium);
  margin-bottom: var(--spacing-small);
`

const IconRow = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    height: var(--spacing-mediumPlus);
  }
`

const EngineRow = styled.div`
  display: flex;
  margin-bottom: var(--spacing-mediumMinus);
  font-size: var(--font-h3);
`

const MonthlyCostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
`

const CostDenominator = styled.span`
  font-size: var(--font-base);
  color: initial;
`

const IconWithBottomText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    width: 35px;
  }

  span {
    font-size: var(--font-base);
  }
`
