import { css } from "styled-components"
import { color, NewBadge, VARIANT_DEFAULT } from "@staccx/bento"

const badgeSharedStyle = css`
  right: 3px;
  bottom: 0;
`

const badgeWrapperSharedStyle = css`
  width: 100%;
`

NewBadge.createVariants({
  badge: {
    warning: css`
      background-color: ${color.warning};
      ${badgeSharedStyle};
    `,
    [VARIANT_DEFAULT]: css`
      background-color: ${color.primary};
      ${badgeSharedStyle};
    `
  },
  wrapper:
    {
      [VARIANT_DEFAULT]: css`
      ${badgeWrapperSharedStyle};
    `,
      warning: css`
      ${badgeWrapperSharedStyle};
    `
    }
})

