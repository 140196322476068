import { useMemo } from 'react'
import { TranslatedText } from '@staccx/i18n'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { createQueryParams } from '../../../utils/createQueryParams'
import { useParams } from '../../../hooks/useParams'
import ChevronLeft from '../../../icons/ChevronLeft'
import { CarStatus as CarStatusTypes } from '../../../types'

const colors = {
  blue: '#2a6df4',
  green: '#66CD73',
  red: '#FF5B30',
}

const CarStatusFilter = ({
  cars,
  active,
  incoming,
  outgoing,
  reset,
  activeFilter,
}) => {
  const colorCount = (status) => {
    if (status === 'incoming') {
      return [incoming.length, colors.blue]
    } else if (status === 'active') {
      return [active.length, colors.green]
    } else if (status === 'outgoing') {
      return [outgoing.length, colors.red]
    } else {
      return ''
    }
  }

  const carStatus = useMemo(() => {
    if (activeFilter.carStatus === undefined) return null
    return activeFilter.carStatus
  }, [activeFilter.carStatus])

  const { status } = useParams()

  return (
    <Wrap>
      {carStatus === null ? (
        <>
          {Object.keys(CarStatusTypes).map((key) => {
            const value = CarStatusTypes[key]
            const params = createQueryParams({
              status: value,
            })
            if (value !== CarStatusTypes.Returned) {
              return (
                <BtnLink
                  key={key}
                  to={`/cars?${params.toString()}`}
                  active={(status === value).toString()}
                >
                  <CarNumber fontColor={colorCount(value)[1]}>
                    {colorCount(value)[0]}
                  </CarNumber>
                  <CarStatus fontColor={colorCount(value)[1]}>
                    <TranslatedText i18nKey={value?.toUpperCase()} />
                  </CarStatus>
                </BtnLink>
              )
            }
          })}
        </>
      ) : (
        <Wrap center>
          <WrapStatus>
            <CarNumber center fontColor={colorCount(carStatus)[1]}>
              {colorCount(carStatus)[0]}
            </CarNumber>
            <CarStatus fontColor={colorCount(carStatus)[1]}>
              <TranslatedText i18nKey={status?.toUpperCase()} />
            </CarStatus>
          </WrapStatus>
        </Wrap>
      )}
      {carStatus !== null && (
        <ChevronWrap>
          <ChevronLeft
            width={50}
            height={50}
            onClick={reset}
            to={`/cars?`}
          ></ChevronLeft>
        </ChevronWrap>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 90%;
  ${(props) =>
    props.center
      ? css`
          justify-content: center;
          margin-top: -24px;
        `
      : css`
          justify-content: space-between;
          margin-top: 50px;
        `}
  transition: background 0.2s ease;
`

const WrapStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CarNumber = styled.div`
  color: ${(props) => props.fontColor};
  ${(props) =>
    props.center
      ? css`
          font-size: 70px;
        `
      : css`
          font-size: 50px;
        `}
`

const CarStatus = styled.div`
  color: ${(props) => props.fontColor};
  font-size: var(--font-h4);
  text-transform: uppercase;
`

const BtnLink = styled(Link)`
  border-width: 0;
  padding: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-direction: column;
  transition: background 0.2s ease;
  justify-content: center;
  text-align: center;
`

const ChevronWrap = styled.div`
  position: absolute;
  top: 80px;
  left: var(--spacing-tiny);
  transition: background 0.2s ease;
  padding: var(--spacing-smallMinus) var(--spacing-mediumMinus);
  color: var(--color-primary);

  :hover {
    cursor: pointer;
  }
`

export default CarStatusFilter
