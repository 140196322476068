import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../../../context/UserProvider'
import SignalSpinnerLoader from "../../../components/loaders/SignalSpinnerLoader";

const Redir = () => {
  const { roleType } = useUserContext()

  if (roleType && roleType !== -1) {
    switch (roleType) {
      case 1:
        return <Navigate to={`/home`} replace />

      case 2:
        return <Navigate to={`/home`} replace />

      default:
        return <Navigate to="/my-car" replace />
    }
  }
  return <SignalSpinnerLoader />
}

export default Redir
