import React from 'react'
import styled, { css } from 'styled-components'
import Chevron from '../../../icons/Chevron'
import BlockContent from '@sanity/block-content-to-react'

const QuestionCard = ({ title, key, selected, onClick, text }) => {
  const serializers = {
    types: {
      h3: (props) => (
        <pre data-language={props.node.language}>
          <h3>{props.node.h3}</h3>
        </pre>
      ),
    },
  }

  return (
    <Wrapper selected={selected}>
      <QuestionCardBox selected={selected} onClick={() => onClick(title)}>
        <Content>
          {title}
          <StyledChevron selected={selected} rotated></StyledChevron>
        </Content>
      </QuestionCardBox>
      {selected && (
        <QuestionCardBoxOpened selected={selected}>
          <BlockContent blocks={text} serializers={serializers} />
        </QuestionCardBoxOpened>
      )}
    </Wrapper>
  )
}

export default QuestionCard

const QuestionCardBox = styled.div`
  height: 65px; // NB hardkodet spesifikk høyde
  transition: 0.3s;
  ${(props) =>
    props.selected
      ? css`
          color: var(--color-white);
        `
      : css`
          color: #5e5b5b;
        `} // farge ikke lagt inn i themes
  ${(props) =>
    props.selected
      ? css`
          background-color: #467eff;
        `
      : css`
          background-color: #e5eeff;
        `} // farge ikke lagt inn i themes
  ${(props) =>
    props.selected
      ? css`
          border-top-left-radius: var(--spacing-tinyPlus);
          border-top-right-radius: var(--spacing-tinyPlus);
        `
      : css`
          border-radius: var(--spacing-tinyPlus);
        `}
  padding: var(--spacing-medium);
  display: block;
  cursor: pointer;
  ${(props) =>
    props.selected
      ? css`
          &: hover;
        `
      : css`&:hover {
    background-color: #FEFEFE; // farge ikke lagt inn i themes
    box-shadow: 4px 4px 4px var(--color-shadow);
  },`}
  &:active {
    cursor: pointer;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

const QuestionCardBoxOpened = styled.div`
  background-color: var(--color-white);
  color: #5e5b5b; // farge ikke lagt inn i themes
  padding: var(--spacing-medium);
  border-bottom-left-radius: var(--spacing-tinyPlus);
  border-bottom-right-radius: var(--spacing-tinyPlus);
  transition: all 0.3s;
  line-height: var(--spacing-medium);
`

const Wrapper = styled.div`
  ${(props) =>
    props.selected
      ? css`
          max-height: 100%;
        `
      : css`
          max-height: 65px;
        `}
`

const StyledChevron = styled(Chevron)`
  ${(props) =>
    props.selected
      ? css`
          color: var(--color-white);
        `
      : css`
          color: #093590;
        `} // farge ikke lagt inn i themes
  ${(props) =>
    props.selected
      ? css`
          transform: rotate(180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
  transition: all 0.3s;
`
