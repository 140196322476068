import PropTypes from "prop-types"
import React from "react"

const Logo = ({ brand, height, width, className }) => {
  return (
    <img
      height={height}
      width={width}
      src={`http://api.test.logos.staccflow.com/${brand}`}
      alt={brand}
      className={className}
      onError={e => e.target.src = "/blank.svg"}
    />
  )
}

export default Logo

Logo.propTypes = {
  brand: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number
}

Logo.defaultProps = {
  height: 48
}
