import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Menu from './menu/Menu'
import Footer from './menu/Footer'
import ObliqueBox from '../pages/help/components/ObliqueBox'

const PageLayout = ({ children }) => {
  const { pathname } = useLocation()
  const HeaderGraphic = pathname === '/help' ? <ObliqueBox /> : null

  return (
    <Layout>
      <Menu />
      <Content>
        {HeaderGraphic}
        {children}
      </Content>
      <Footer />
    </Layout>
  )
}

export default PageLayout

const Layout = styled.div`
  min-height: 100vh;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--color-bgGray);
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
