import React from "react";
import { TranslatedText } from '@staccx/i18n'

export default function eventListAdditionalProductsData() {
    return [
        {
            accessorKey: 'Description',
            id: 'Description',
            header: () => <TranslatedText i18nKey={'DESCRIPTION'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({getValue}) => getValue() || '',
            meta: { alignmentType: 'string' },
        },
        {
            accessorKey: 'ReferenceNo',
            id: 'ReferenceNo',
            header: () => <TranslatedText i18nKey={'REFERENCE_NUMBER'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({getValue}) => getValue() || '',
            meta: { alignmentType: 'string' },
        },
    ]
}