import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { Thumb } from './Thumbnails'
import styled from 'styled-components'
import { Container, Embla, Slide, SlideInner, ThumbContainer, ThumbPadding, Viewport } from './styles/CarouselStyles'
import { NextButton, PrevButton } from './CarouselButtons'

const Carousel = ({ slides, photos }) => {
  const [selectedIndex, selectedIndexSet] = useState(0)
  const [mainViewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    loop: true,
  })
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    selectedClass: '',
    dragFree: true,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const photoByIndex = (index) => photos[index % photos.length]

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) {
        return
      }
      if (emblaThumbs.clickAllowed()) {
        embla.scrollTo(index)
      }
    },
    [embla, emblaThumbs]
  )

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) {
      return
    }
    selectedIndexSet(embla.selectedScrollSnap())
    emblaThumbs.scrollTo(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, emblaThumbs, selectedIndexSet])

  useEffect(() => {
    if (!embla) {
      return
    }
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  const handleKeyDown = (e) => {
    console.log(e.keyCode)
    if (e.keyCode === 37) {
      scrollPrev()
    }
    if (e.keyCode === 39) {
      scrollNext()
    }
  }

  return (
    <CarouselContainer onKeyDown={handleKeyDown} tabIndex="1">
      <Embla>
        <Viewport ref={mainViewportRef}>
          <Container>
            {slides.map((index) => (
              <Slide key={index}>
                <SlideInner>
                  <img src={photoByIndex(index)} alt="A car" />
                </SlideInner>
              </Slide>
            ))}
          </Container>
        </Viewport>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </Embla>
      <ThumbPadding>
        <Viewport ref={thumbViewportRef}>
          <ThumbContainer>
            {slides.map((index) => (
              <Thumb
                onClick={() => onThumbClick(index)}
                selected={index === selectedIndex}
                imgSrc={photoByIndex(index)}
                key={index}
              />
            ))}
          </ThumbContainer>
        </Viewport>
      </ThumbPadding>
      <Span>
        <span>{'Bilde ' + (selectedIndex + 1) + ' av ' + photos.length}</span>
      </Span>
    </CarouselContainer>
  )
}

export default Carousel

const Span = styled.div`
  margin: var(--spacing-small);
  text-align: center;
`

const CarouselContainer = styled.div`
  :focus {
    outline: unset;
  }
`
