import React from 'react'
import { useQuery } from 'react-query'
import { Layout, Text } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import Table from '../../../components/tables/Table'
import arraySplit from '../../../utils/arraySplit'
import { formatCurrency, sum } from '../../../utils/tableFuncs'
import { fetchInvoiceDetails } from '../../../api/api'
import { useParams } from 'react-router-dom'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import InvoiceReportDetailsTable from '../components/InvoiceReportDetailsTable'
import { fetchGroupRows } from '../components/InvoiceReportDetailsTable/fetchGroupRows'
import { groupBy, sumBy } from 'lodash'
import { Heading } from '../../../components/text/Headings'

const InvoiceReportDetails = () => {
  const { invoiceNo } = useParams()
  const lines = []
  const { data = [], isLoading } = useQuery(['invoiceReportDetails', invoiceNo], () => fetchInvoiceDetails(invoiceNo), {
    enabled: !!invoiceNo
  })

  data.forEach((invoice) => {
    let group

    switch (invoice.collectionOnly) {
      case '1':
        group = 'LEIE / FORSIKRING'
        break

      case '0':
        group = 'BILHOLD'
        break

      default:
        group = 'UKJENT'
        break
    }

    invoice.invoiceDetails.forEach((detail) => {
      const result = { ...invoice }
      result.carDescription = result.description
      for (let key in detail) {
        const newKey = key[0].toLowerCase().concat(key.slice(1))
        result[newKey] = detail[key]
      }
      result.group = group
      lines.push(result)
      delete result.invoiceDetails
    })
  })

  // Don't touch this as it organizes the tables based on invoiceGroupDescription
  const splitLines = arraySplit(lines, 'invoiceGroupDescription', 'NO_INVOICE_GROUP')
  const sumTotal = sum(lines, (row) => row.amountIncVAT)

  // NEW TABLE DATA FORMATTING METHOD
  const collapsableData = (invoices) => {
    return Object.entries(groupBy(invoices, 'accountNo')).map(([key, value]) => {
      const accountNoValues = value.map((e) => {
        return {
          amountIncVAT: e.amountIncVAT,
          carDescription: e.carDescription,
          vehicleID: e.vehicleID,
          regNo: e.regNo,
          group: e.group,
          description: e?.description,
          sourceID: e?.docuBizzSourceID,
          sourceType: e?.sourceType,
          documentNo: e?.documentNo,
          lineNo: e?.lineNo
        }
      })
      return {
        accountNo: key,
        carDescription: accountNoValues[0].description,
        vehicleID: accountNoValues[0].vehicleID,
        regNo: accountNoValues[0].regNo,
        amountIncVAT: sumBy(accountNoValues, 'amountIncVAT'),
        subRows: fetchGroupRows(accountNoValues)
      }
    })
  }

  return (
    <Container width="unlimited">
      <TranslatedText i18nKey={'INVOICEREPORT_DETAILS_HEADING'}>{(value) => <Heading>{value}</Heading>}</TranslatedText>
      <TranslatedText i18nKey="INVOICE_NO">
        {(value) => (
          <Text variant="lede" level="4">
            {value} {invoiceNo}
          </Text>
        )}
      </TranslatedText>
      <Layout rowGap={'medium'}>
        {Object.keys(splitLines).map((invoiceGroupDescription) => {
          return (
            <div key={invoiceGroupDescription}>
              {invoiceGroupDescription !== 'NO_INVOICE_GROUP' && <Text level="2">{invoiceGroupDescription}</Text>}
              <RightAlignedRow>
                <ExcelButton />
              </RightAlignedRow>
              <InvoiceReportDetailsTable
                isLoading={isLoading}
                data={collapsableData(splitLines[invoiceGroupDescription])}
                footerData={{
                  amountIncVAT: formatCurrency(sumBy(splitLines[invoiceGroupDescription], 'amountIncVAT'))
                }}
              />
            </div>
          )
        })}

        <Table
          isLoading={isLoading}
          headers={[
            {
              accessor: 'sumTotal',
              i18nKey: 'SUM_TOTAL',
              contentFunction: (row) => formatCurrency(row.value)
            }
          ]}
          data={[{ sumTotal }]}
        />
      </Layout>
    </Container>
  )
}

export default InvoiceReportDetails
