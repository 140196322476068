import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import { Button, Input, ItemGroup, Text } from '@staccx/bento'
import { getDrivenWheelsDescription } from '../../../utils/getDrivenWheelsDescription'
import DrivenWheels from '../../../icons/DrivenWheels'
import Chassis from '../../../icons/Chassis'
import Doors from '../../../icons/Doors'
import Driver from '../../../icons/Driver'
import { SmallHeading } from '../../../components/text/Headings'
import CustomPopover from './popover/Popover'
import capitalizeName from '../../../utils/capitalizeName'

const Car = ({ flow, action, isSelected, banner, children, onDeleteConfig, onSaveFlow }) => {
  const car = flow?.data?.carConfiguration
  const driver = flow?.data?.chosenDriver
  const [customConfigurationName, customConfigurationNameSet] = useState(flow?.data?.customConfigurationName || '')
  const hasChosenBaseModel = !!flow?.data?.carConfiguration?.modelKey

  const isClickable = !children

  return (
    <Link onClick={isClickable ? action : () => {}} isClickable={isClickable} key={car?.vehicleId}>
      <CarContainer isSelected={isSelected}>
        {banner && <Banner>{car?.modelYearToDisplay}</Banner>}
        <ImageDiv>
          <img
            alt={`${car?.makeKey ?? ''} ${car?.modelKey ?? ''}`}
            src={car?.photoUrl || 'https://sslphotos.jato.com/MissingPhotos/missingphoto_100.gif'}
          />
        </ImageDiv>
        <CarDetails>
          <Model>
            {hasChosenBaseModel ? (
              flow?.data?.customConfigurationName || car?.modelKey
            ) : (
              <TranslatedText i18nKey="CAR_CONFIG_NO_CHOSEN_MODEL" />
            )}
            {flow?.status === 'active' && (
              <>
                {!isClickable && hasChosenBaseModel && (
                  <CustomPopover variant="edit">
                    <Input
                      id={'customConfigurationName'}
                      value={customConfigurationName?.toString() || ''}
                      onChange={(e) => customConfigurationNameSet(e.target.value)}
                      label={<TranslatedText i18nKey="CAR_CONFIG_CUSTOM_NAME_DESC" />}
                    />
                    {customConfigurationName !== (flow?.data?.customConfigurationName || '') && (
                      <TranslatedText i18nKey={'SAVE'}>
                        {(value) => (
                          <Button
                            variant="smallSave"
                            onClick={() =>
                              onSaveFlow({
                                flowId: flow?.flowId,
                                customConfigurationName,
                                currentConfigurationName: flow?.data?.customConfigurationName
                              })
                            }
                          >
                            {value}
                          </Button>
                        )}
                      </TranslatedText>
                    )}
                  </CustomPopover>
                )}
                {!isClickable && (
                  <CustomPopover variant="trashcan">
                    <ItemGroup variant="spaced">
                      <SmallHeading>
                        <TranslatedText i18nKey="DELETE_CONFIGURATION">{(value) => `${value}?`}</TranslatedText>
                      </SmallHeading>
                      <Button variant="warning" onClick={() => onDeleteConfig(flow)}>
                        <TranslatedText i18nKey="YES" />
                      </Button>
                    </ItemGroup>
                  </CustomPopover>
                )}
              </>
            )}
          </Model>
          {hasChosenBaseModel && <DetailsRow>
            <div>
              <Icon>
                <Chassis />
              </Icon>
              {car?.shortBodyName && (
                <Text>
                  <TranslatedText i18nKey={car?.shortBodyName?.replace(/\s+/g, '').toUpperCase()}>
                    {(value) => ((value || '').includes('Missing entry') ? capitalizeName(car?.shortBodyName) : value)}
                  </TranslatedText>
                </Text>
              )}
            </div>
            <div>
              <Icon>
                <DrivenWheels />
              </Icon>
              {car?.drivenWheelsToDisplay && <Text>{getDrivenWheelsDescription(car?.drivenWheelsToDisplay)}</Text>}
            </div>
            <div>
              <Icon>
                <Doors />
              </Icon>
              {car?.numberOfDoorsToDisplay && (
                <TranslatedText i18nKey={'DOORS'}>
                  {(value) => (
                    <Text>
                      {car?.numberOfDoorsToDisplay} {value}
                    </Text>
                  )}
                </TranslatedText>
              )}
            </div>
            {!isClickable && driver && (
              <div>
                <Icon>
                  <Driver />
                </Icon>
                {driver && <Text>{driver?.name}</Text>}
              </div>
            )}
          </DetailsRow>}
        </CarDetails>
        {children && <div>{children}</div>}
      </CarContainer>
    </Link>
  )
}

export default Car

const Link = styled.a`
  color: black;
  text-decoration: none;

  > div {
    background-color: var(--color-bg);
  }

  &:hover {
    color: black;
    text-decoration: none;
    ${(props) =>
      props.isClickable
        ? css`
            cursor: pointer;
          `
        : null};
  }
`

const CarContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 0.8fr;
  padding: var(--spacing-medium);
  overflow: hidden;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-medium);

  ${props => props.isSelected ?
    css`
      outline: 2px solid var(--color-primary);
    ` : 
    css`
      :hover {
      box-shadow: 2px 4px 4px var(--color-line);
    }
  `};

  transition: 0.3s;

  > div:nth-child(3) > svg {
    display: flex;
    justify-content: flex-end;
  }
`

const Banner = styled.div`
  color: var(--color-white);
  background-color: var(--color-primary);
  transform: rotate(45deg);
  position: absolute;
  width: var(--spacing-hugePlus);
  top: var(--spacing-smallMinus);
  right: -130px;
  text-align: center;
  font-size: var(--font-base);
  font-weight: var(--fontWeight-bold);
`

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 120px;
`

const CarDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const Model = styled.span`
  font-size: var(--font-medium);
  margin-right: var(--spacing-tiny);
`

const DetailsRow = styled.div`
  display: flex;
  gap: var(--spacing-medium);
  text-align: center;
`

const Icon = styled.div`
  > svg {
    width: 30px;
    height: 30px;
  }
`
