import React from "react"

const Driver2 = () => (
  <svg 
    width="36" 
    height="40" 
    fill="none" 
  >
    <path 
      d="M18 0a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm18 30.398C36 24.18 26.695 20 18 20S0 24.18 0 30.398v5.165l6.355 1.585A12.054 12.054 0 0 0 6 40h24c0-.984-.133-1.934-.355-2.852L36 35.562v-5.164Zm-4 2.04-3.969.996C25.883 30.164 22.191 28 18 28c-4.191 0-7.883 2.164-10.031 5.434L4 32.438v-2.04C4 27.52 10.46 24 18 24s14 3.52 14 6.398v2.04Z"
      fill="#467EFF"
    />
  </svg>
)

export default Driver2