import React from 'react'
import { useQuery } from 'react-query'
import { Text } from '@staccx/bento'
import { Heading } from '../../../components/text/Headings'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import { fetchFuelExpenseReport } from '../../../api/api'
import ExcelButton from '../components/ExcelButton'
import { useParams } from 'react-router-dom'
import Container from '../../../components/Container'
import FuelReportExpenseDetailsTable from '../components/FuelTables/FuelReportExpenseDetails'

const FuelReportExpenseDetails = () => {
  const { year, month, account } = useParams()

  const { data = [], isLoading } = useQuery(['fuelReportExpenseDetails', year, month, account], () =>
    fetchFuelExpenseReport({ year, month, account })
  )

  return (
    <Container width="unlimited">
      <TranslatedText i18nKey={'FUELREPORT_EXPENSE_HEADING'}>
        {(value) => <Heading>{value}</Heading>}
      </TranslatedText>
      <TranslatedText i18nKey="PERIOD">
        {(value) => (
          <Text variant="lede" level="4">
            {value} {year}-{month}
          </Text>
        )}
      </TranslatedText>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <FuelReportExpenseDetailsTable isLoading={isLoading} data={data} />
    </Container>
  )
}

export default FuelReportExpenseDetails
