
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import InfoCircle from '../icons/InfoCircle'
import SuccessCircle from '../icons/SuccessCircle'
import WarningTriangle from '../icons/WarningTriangle'

interface AlertProps {
  children: ReactNode;
  type: 'info' | 'warning' | 'success';
}

const Icon = ({ type }: { type: AlertProps['type'] }) => {
  switch (type) {
    case 'info':
      return <InfoCircle />;
    case 'warning':
      return <WarningTriangle />;
    case 'success':
      return <SuccessCircle />;
    default:
      return null;
  }
}

const getColorStyles = (type: AlertProps['type']) => {
  switch (type) {
    case 'warning':
      return css`
        background-color: var(--color-warning);
        color: var(--color-bg);
      `;
    case 'success':
      return css`
        background-color: var(--color-primary);
        color: var(--color-bg);
      `;
    case 'info':
    default:
      return css`
        background-color: var(--color-line);
        color: var(--color-text);
      `;
  }
}

const Alert: React.FC<AlertProps> = ({ children, type }) => {
  return (
    <StyledAlert type={type}>
      <Icon type={type} />
      <Message>{children}</Message>
    </StyledAlert>
  );
}

export default Alert;

// Specifying the type directly for the StyledAlert component
const StyledAlert: React.FC<AlertProps> = styled.div<AlertProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: var(--borderRadius);
  padding: var(--spacing-smallMinus);
  align-items: center;
  ${props => getColorStyles(props.type)};
`

const Message = styled.div`
  padding-left: var(--spacing-smallPlus);
`
