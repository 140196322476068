import React from 'react'
import styled from 'styled-components'
import * as DialogPrimitive from '@radix-ui/react-dialog'

const Dialog = ({ open = { open }, onOpenChange = { onOpenChange }, trigger, children, width }) => {
  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <Overlay />
      <DialogContent width={width}>{children}</DialogContent>
    </DialogPrimitive.Root>
  )
}

export default Dialog

const DialogContent = styled(DialogPrimitive.Content)`
  max-height: 80vh;
  width: ${({ width }) => (width ? width : '90%')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);
  border-radius: 0;
  padding: var(--spacing-mediumMinus);
  box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, 0.12);
  z-index: 100;
  overflow: auto;
  @media only screen and (min-width: 1219px) {
    width: ${({ width }) => (width ? width : '40vw')};
  }
`
const Overlay = styled(DialogPrimitive.Overlay)`
  background-color: rgb(248, 248, 248, 0.8);
  position: fixed;
  inset: 0;
`
