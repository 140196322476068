import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import fuelReportData from './fuelReportData'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../../../context/UserProvider'
import { columnSum, formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'

type FuelReportTableProps = {
  data: any
  isLoading?: boolean
}
function FuelReportTable({ data, isLoading }: FuelReportTableProps) {
  let navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])
  // @ts-ignore  remove this ts-ignore when UserContext is type safe
  const { handleSetConsolidateInvoice, chosenCompany } = useUserContext()

  const columns = fuelReportData(chosenCompany)
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    amountIncVAT: isNaN(columnSum(data, 'amountIncVAT')) ? '0' : formatCurrency(columnSum(data, 'amountIncVAT')),
    volume: isNaN(columnSum(data, 'volume')) ? formatNumber(0) : formatNumber(columnSum(data, 'volume')),
  }
  return (
    <TanStackTable
      table={table}
      handleRowClick={(e) => {
        handleSetConsolidateInvoice(e.original.consolidatedInvoiceAgreement)
        navigate(`/reports/fuel/${e.original.tyear}/${e.original.tmonth}`)
      }}
      footerData={footerData}
      isLoading={isLoading}
    />
  )
}

export default FuelReportTable
