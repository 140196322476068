import React from 'react'
import Container from '../../../components/Container'
import styled from 'styled-components'
import CompanyInfo from '../components/CompanyInfo'
import UserInfo from '../components/UserInfo'
import ContactPerson from '../../home/components/ContactPerson'
import { fetchSalesPerson } from '../../../api/api'
import { useQuery } from 'react-query'
import { useChosenCompany } from '../../../context/UserProvider'
import { Heading } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'

const UserProfile = () => {
  const company = useChosenCompany()
  const { data: salesPerson } = useQuery(['salesperson', company?.id], () => fetchSalesPerson(company?.id), {
    enabled: !!company?.id,
  })

  return (
    <Container>
      <StyledHeading level={1}>
        <TranslatedText i18nKey={'YOUR_PROFILE'} />
      </StyledHeading>
      <Flex>
        <Content>
          <UserInfo />
          <CompanyInfo />
        </Content>
        <ContactPersonContainer>{salesPerson && <ContactPerson contactPerson={salesPerson} />}</ContactPersonContainer>
      </Flex>
    </Container>
  )
}

export default UserProfile

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`

const Content = styled.div`
  flex-direction: row;
  width: 60%;
  margin-right: var(--spacing-mediumMinus);
`

const ContactPersonContainer = styled.div`
  padding-left: var(--spacing-smallMinus);
  width: 40%;
`

const StyledHeading = styled(Heading)`
  padding-bottom: var(--spacing-medium);
`
