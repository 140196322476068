import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import tableData from './tollReportAccountDetailsData'
import { columnSum, formatCurrency } from '../../../../../utils/tableFuncs'

const columns = tableData()

type TollReportAccountDetailsProps = {
  data: any
  isLoading?: boolean
}
function TollReportAccountDetailsTable({ data, isLoading }: TollReportAccountDetailsProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    amount: isNaN(columnSum(data, 'amount')) ? formatCurrency(0) : formatCurrency(columnSum(data, 'amount')),
  }

  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default TollReportAccountDetailsTable
