import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import mileageReportTableData from './mileageReportTableData'
import TanStackTable from '../../../../../components/tables/TanStackTable'

const columns = mileageReportTableData()

type MileageReportTableProps = {
  data: any
  isLoading?: boolean
}

function MileageReportTable({ data, isLoading }: MileageReportTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <TanStackTable table={table} isLoading={isLoading} wrapHeaderText={true} />
}

export default MileageReportTable
