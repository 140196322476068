import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../components/tables/TanStackTable'
import leasingReportTableData from './leasingReportTableData'
import { columnSum, formatCurrency } from '../../../../utils/tableFuncs'

const columns = leasingReportTableData()

type LeasingReportTableProps = {
  data: any
  isLoading?: boolean
}
function LeasingReportTable({ data, isLoading }: LeasingReportTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    restLeasing_incl_VAT: isNaN(columnSum(data, 'restLeasing_incl_VAT'))
      ? '0'
      : formatCurrency(columnSum(data, 'restLeasing_incl_VAT')),
    restLeasing_excl_VAT: isNaN(columnSum(data, 'restLeasing_excl_VAT'))
      ? '0'
      : formatCurrency(columnSum(data, 'restLeasing_excl_VAT')),
  }

  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default LeasingReportTable
