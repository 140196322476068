import React, { createContext, useContext, useMemo } from 'react'
import { useChosenCompany, useChosenConsolidateInvoice } from './UserProvider'
import Car, { FUEL_TYPE_FILTER_ALL, mapIncomingToCar } from '../model/car'
import { useEventContext } from './EventProvider'
import { useQuery, useQueryClient } from 'react-query'
import { differenceInCalendarMonths } from 'date-fns'
import { usePersons } from './DriverProvider'
import { fetchCars } from '../api/api'
import {CarStatus} from "../types";

const CarContext = createContext({})

const CarProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const company = useChosenCompany()
  const consolidateInvoice = useChosenConsolidateInvoice()
  const events = useEventContext()
  const persons = usePersons()
  const { drivers, contacts } = persons

  const companyId = company ? company.id : null

  const { data, error, refetch } = useQuery(
    ['cars', companyId, consolidateInvoice?.ConsolidateCode],
    () => fetchCars(companyId, consolidateInvoice?.ConsolidateCode),
    { enabled: !!companyId }
  )

  const allCars = useMemo(() => {
    if (!data || !events || !events.usages || !events.deviations || !drivers || !contacts) {
      return null
    }
    const replacements = []

    return data.cars
      .concat(data.incoming.map(mapIncomingToCar))
      .map((car) => ({
        ...car,
        hasDeviations: events.deviations.some((c) => c.carId === car.registrationNumber),
        events: events.usages.filter(
          (e) => car.registrationNumber && (e.accountNo === car.accountNo || e.carId === car.registrationNumber)
        ),
        status: car.isIncoming
          ? CarStatus.Incoming
          : car.actualReturnDate
          ? CarStatus.Returned
          : isActive(car)
          ? CarStatus.Active
          : CarStatus.Outgoing,

        driver: car.isIncoming ? { name: car.driverName } : getDriver(car, persons) || {}
      }))
      .map((car, index, array) => {
        if (car.replaceAccountNo) {
          const replaces = array.find((c) => c.accountNo === car.replaceAccountNo)
          if (replaces) {
            replacements.push({
              car: replaces.registrationNumber,
              replacedBy: car
            })

            return { ...car, replaces }
          }
        }
        return car
      })
      .map((car) => {
        const replacement = replacements.find((c) => c.car === car.registrationNumber)
        if (replacement) {
          return { ...car, replacement: replacement.replacedBy }
        }
        return car
      })
      .map(Car.fromJson)
  }, [data, events, persons, contacts, drivers])

  const incomingCars = useMemo(() => {
    if (!allCars) {
      return null
    }
    return allCars.filter((c) => c.isIncoming)
  }, [allCars])

  const activeCars = useMemo(() => {
    if (!allCars) {
      return null
    }
    return allCars.filter((c) => c.status === CarStatus.Active)
  }, [allCars])

  const outgoingCars = useMemo(() => {
    if (!data) {
      return null
    }
    return (allCars || [])
      .filter((c) => c.status ===  CarStatus.Outgoing)
      .map((car) => {
        const replacement = data.incoming.find((c) => c.replaceAccountNo === car.registrationNumber)
        if (replacement) {
          return {
            ...car,
            replacement
          }
        }
        return car
      })
  }, [allCars, data])

  const currentUsersCar = useMemo(() => {
    return (allCars || []).find((car) => car.accountNo === (company.accountNo || ''))
  }, [company, allCars, data])

  if (error) {
    console.log(error)
  }

  return (
    <CarContext.Provider
      value={{
        currentUsersCar,
        allCars,
        incomingCars,
        activeCars,
        outgoingCars,
        refetch,
        updateCars: () => queryClient.invalidateQueries('cars')
      }}
    >
      {children}
    </CarContext.Provider>
  )
}

export const useAllCars = (filter) => {
  const { allCars } = useContext(CarContext)

  return useMemo(() => {
    if (allCars) {
      if (filter) {
        return allCars.filter(doFilter(filter))
      }
      return allCars
    }
    return null
  }, [filter, allCars])
}

export const useIncomingCars = (filter) => {
  const { incomingCars } = useContext(CarContext)

  return useMemo(() => {
    if (incomingCars) {
      if (filter) {
        return incomingCars.filter(doFilter(filter))
      }
      return incomingCars
    }
    return null
  }, [filter, incomingCars])
}

export const useOutgoingCars = (filter) => {
  const { outgoingCars } = useContext(CarContext)

  return useMemo(() => {
    if (outgoingCars) {
      if (filter) {
        return outgoingCars.filter(doFilter(filter))
      }
      return outgoingCars
    }
    return null
  }, [filter, outgoingCars])
}

export const useActiveCars = (filter) => {
  const { activeCars } = useContext(CarContext)

  return useMemo(() => {
    if (activeCars) {
      if (filter) {
        return activeCars.filter(doFilter(filter))
      }
      return activeCars
    }
    return null
  }, [filter, activeCars])
}

export const useCar = (id) => {
  const { allCars = null } = useContext(CarContext)

  return useMemo(() => {
    if (!allCars) {
      return
    }
    return allCars.find((car) => car.registrationNumber === id || car.budgetNo === id)
  }, [id, allCars])
}

export const useCarByAccountNo = (id) => {
  const { allCars = null } = useContext(CarContext)

  return useMemo(() => {
    if (!allCars) {
      return
    }
    return allCars.find((car) => car.accountNo === id)
  }, [id, allCars])
}

const getDriver = (car, { drivers, contacts }) => {
  const driver = drivers.find((driver) => driver.accountNo === car.accountNo)

  if (driver && contacts) {
    return contacts.find((contact) => contact.id === driver.id)
  }
}

export const notHasReplacementCar = ({ status, replacement, noCarReplacement }) =>
  status === 'outgoing' && !replacement && !noCarReplacement
export const hasReplacementCar = ({ replacement }) => !!replacement
export const isOverMileage = ({ status, mileage, contractMileage }) => status === 'active' && mileage > contractMileage
export const notIsOverMileage = ({ status, mileage, contractMileage }) =>
  status === 'active' && mileage <= contractMileage

const doFilter = (filter) => (car) =>
  filter
    ? [
        filter.fuel
          ? filter.fuel.toUpperCase() === FUEL_TYPE_FILTER_ALL.toUpperCase() ||
            car.fuel.toUpperCase() === filter.fuel.toUpperCase()
          : true,
        filter.account ? car.accountNo === filter.account : true,
        filter.cars ? filter.cars.indexOf(car.registrationNumber) !== -1 : true,
        filter.carStatus ? car.status === filter.carStatus : true,
        filter.car ? car === filter.car : true,
        filter.hasReplacementCar === 'false'
          ? notHasReplacementCar(car)
          : filter.hasReplacementCar === 'true'
          ? hasReplacementCar(car)
          : true,
        filter.isOverMileage === 'true'
          ? isOverMileage(car)
          : filter.isOverMileage === 'false'
          ? notIsOverMileage(car)
          : true
      ].every((test) => test)
    : car

export const outgoingMonths = 6

const now = new Date()

const isActive = (car) => {
  const date = new Date(car.plannedReturnDate)
  const difference = differenceInCalendarMonths(date, now)
  return difference > outgoingMonths
}

export const useCarContext = () => useContext(CarContext)

export default CarProvider
