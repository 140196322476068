import { axiosInstance } from './api'
import qs from 'qs'

export const createNewCarConfig = async (payload) => axiosInstance.post('/flows/customer-car-configuration', payload)
export const deleteCarConfig = async (flowId) => axiosInstance.delete(`car-config/${flowId}`)
export const updateCarConfig = async ({ flowId, data }) => axiosInstance.post(`/car-config/${flowId}/update`, data)
export const completeTask = async ({ taskId, data }) => axiosInstance.post(`/tasks/${taskId}/complete`, data)
export const getTasks = async (flowId) => axiosInstance.get(`/flows/customer-car-configuration/${flowId}/tasks`)
export const getCarConfigFlows = async (companyId, query) =>
  axiosInstance.get(`/car-config/flows/${companyId}${qs.stringify(query, { addQueryPrefix: true })}`)
export const getCarConfigFlowsCount = async (companyId, query) =>
  axiosInstance.get(`/car-config/flows/count/${companyId}${qs.stringify(query, { addQueryPrefix: true })}`)
export const sendAdditionalProductEmail = async (payload) => axiosInstance.post(`/order-additional-product`, payload)
export const sendContactUsEmail = async (payload) => axiosInstance.post(`/contact-us`, payload)
