import React from 'react'
import PageContainerTitled from './PageContainerTitled'
import { TranslatedText } from '@staccx/i18n'
import Car from './Car'
import CustomSkeleton from './skeleton/skeleton'
import { SmallHeading } from '../../../components/text/Headings'
import {
  Right,
  ConfigurationInfoContainer,
  Fraction,
  ActionContainer,
  StyledChevron,
  SeeMore,
} from './LandingPageComponents'
import { css } from 'styled-components'
import SearchBox from '../../../components/menu/SearchBox'

const FLOW_FETCH_INCREMENT_VALUE = 5

const ActiveCarConfigurations = ({
  numActiveItems,
  numMaxActiveItems,
  activeSearch,
  activeSearchSet,
  filteredActive,
  active,
  activeSearchLoading,
  handleOpenConfiguration,
  handleDeleteConfiguration,
  saveFlow,
  activeConfigSet,
}) => {
  return (
    <PageContainerTitled
      title="CAR_CONFIG_LANDING_PAGE_CONTINUE_TEXT"
      numItems={numActiveItems}
      maxNumItems={numMaxActiveItems}
    >
      <SearchBox
        value={activeSearch?.value}
        onChange={(value) =>
          activeSearchSet((prevState) => ({
            ...prevState,
            field: 'data.customConfigurationName',
            value,
          }))
        }
        placeholderi18nKey="SEARCH_BY"
        searchableFields={['NAME', 'OR', 'MODEL']}
        noBorder
      />
      {(filteredActive || active?.flows) && !activeSearchLoading ? (
        (activeSearch.value ? filteredActive : active?.flows)?.map((flow) => (
          <Car
            key={flow?.flowId}
            action={() => handleOpenConfiguration(flow)}
            flow={flow}
            banner={false}
            onDeleteConfig={handleDeleteConfiguration}
            onSaveFlow={saveFlow.mutate}
          >
            <Right>
              <ConfigurationInfoContainer>
                <Fraction>
                  <span>
                    {flow?.data?.carConfiguration?.makeKey} {flow?.data?.carConfiguration?.modelKey}
                  </span>
                </Fraction>
                <Fraction>
                  <span>{flow?.data?.carConfiguration?.derivativeToDisplay}</span>
                </Fraction>
                <Fraction>
                  <span>
                    <TranslatedText i18nKey="LAST_SAVED">
                      {(value) => `${value}: ${flow?.updatedAt?.split('T')?.[0]}`}
                    </TranslatedText>
                  </span>
                </Fraction>
              </ConfigurationInfoContainer>
              <ActionContainer className="chevron" onClick={() => handleOpenConfiguration(flow)}>
                <StyledChevron />
              </ActionContainer>
            </Right>
          </Car>
        ))
      ) : (
        <CustomSkeleton
          skeletons={[
            {
              height: 70,
            },
          ]}
          theme={css`
            grid-template-columns: 1fr;
          `}
        />
      )}
      {numActiveItems < numMaxActiveItems && (
        <SeeMore>
          <TranslatedText i18nKey="SEE_MORE">
            {(value) => (
              <SmallHeading
                onClick={() =>
                  activeConfigSet((prevState) => ({
                    ...prevState,
                    limit: prevState.limit + FLOW_FETCH_INCREMENT_VALUE,
                  }))
                }
              >
                {value}
                ...
              </SmallHeading>
            )}
          </TranslatedText>
        </SeeMore>
      )}
    </PageContainerTitled>
  )
}

export default ActiveCarConfigurations
