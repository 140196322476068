import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ChevronRight2 from '../../../icons/ChevronRight2'

export const Card = ({ icon, href, title, subtitle, action, isHoverable }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (href) {
      navigate(href)
    }
  }

  return (
    <Container onClick={handleClick} isHoverable={isHoverable}>
      <Content>
        {icon}
        <TextContainer>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TextContainer>
      </Content>
      <FloatingIcon>{action}</FloatingIcon>
    </Container>
  )
}

const Container = styled.div`
  background-color: #ffffff;
    min-height: 70px;
    border-radius: 8px;
  
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: ${(props) => (props.isHoverable ? 'pointer' : '')};
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${(props) => (props.isHoverable ? '0px 4px 10px rgba(0, 0, 0, 0.075);' : '')};
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const FloatingIcon = styled.div`
  display: flex;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 21px;
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--color-wcag);
`
