import React from 'react'

interface FuelProps {
  width?: number
  height?: number
  color?: string
}

const Fuel = ({ width = 50, height = 50, color = 'black' }: FuelProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4377_10603)">
      <path
        d="M26.9625 47.7058H2.07349V8.29839C2.07349 4.77247 4.76979 2.07617 8.29574 2.07617H20.7402C24.2662 2.07617 26.9625 4.77247 26.9625 8.29839V47.7058Z"
        stroke={color}
        stroke-width="3.31853"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M26.9624 37.3354H33.1847C36.7106 37.3354 39.4069 34.6391 39.4069 31.1132V10.3725L47.7032 2.07617"
        stroke={color}
        stroke-width="3.31853"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M45.629 4.15027V16.5947C45.629 17.8392 44.7994 18.6688 43.5549 18.6688H39.4067"
        stroke={color}
        stroke-width="3.31853"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M2.07349 20.7429H26.9625"
        stroke={color}
        stroke-width="3.31853"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4377_10603">
        <rect width="49.778" height="49.7778" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Fuel
