import React from 'react'
import Flex from '../../../components/Flex'
import styled from 'styled-components'
import { Car as CarType, CarStatus } from '../../../types'
import { TranslatedText } from '@staccx/i18n'
import Logo from './Logo'
import FuelOutline from '../../../icons/FuelOutline'
import ChassisOutline from '../../../icons/ChassisOutline'
import DrivenWheelsOutline from '../../../icons/DrivenWheelsOutline'
import DoorsOutline from '../../../icons/DoorsOutline'

function mapCarStatus(status: CarStatus): string {
  switch (status) {
    case CarStatus.Active:
      return 'Aktiv'
    case CarStatus.Incoming:
      return 'I bestilling'
    case CarStatus.Outgoing:
      return 'Utløper'
    case CarStatus.Returned:
      return 'Returnert'
    default:
      return ''
  }
}

function mapMake(make: string): string {
  let mappedMake = make.toLowerCase()

  switch (mappedMake) {
    case 'vw':
      mappedMake = 'volkswagen'
      break
    case 'mercedes-benz':
      mappedMake = 'mercedesbenz'
      break
    case 'mercedes':
      mappedMake = 'mercedesbenz'
      break
    default:
      break
  }

  return mappedMake
}

const CarCardNew: React.FC<{ car: CarType }> = ({ car }) => {
  return (
    <Wrapper>
      <Flex gap={'var(--spacing-mediumMinus)'}>
        <Flex direction={'column'} gap={'var(--spacing-smallMinus)'}>
          <StatusBadge>{mapCarStatus(car.status)}</StatusBadge>
          <ImageContainer>
            {car.imageURL ? (
              <img src={car.imageURL ?? ''} alt={'car-image'} />
            ) : (
              <Logo
                brand={mapMake(car.make)}
                width={120}
                height={120}
                className={''}
              />
            )}
          </ImageContainer>
        </Flex>
        <div style={{ width: '100%' }}>
          <Flex direction={'column'} gap={'var(--spacing-smallMinus)'}>
            <BlueText>{car.registrationNumber}</BlueText>
            <BoldText>
              {car.make} {car.model}
            </BoldText>
            <Text>{car.engine}</Text>
            <CarInfoRow>
              <Flex align={'center'} gap={'var(--spacing-tinyMinus)'}>
                <FuelOutline width={16} height={16} />
                <TranslatedText
                  i18nKey={`FUEL_TYPE_${(car?.fuel || '').toUpperCase()}`}
                />
              </Flex>

              {car?.vehicleType && (
                <Flex
                  gap={'var(--spacing-tinyMinus)'}
                  justify={'space-between'}
                  align={'center'}
                >
                  <ChassisOutline width={16} height={16} />
                  <TranslatedText i18nKey={`VEHICLE_TYPE_${car.vehicleType}`} />
                </Flex>
              )}
              {car?.wheelsDriven && (
                <Flex
                  gap={'var(--spacing-tinyMinus)'}
                  justify={'space-between'}
                  align={'center'}
                >
                  <DrivenWheelsOutline width={16} height={16} />
                  <TranslatedText
                    i18nKey={`WHEELS_DRIVEN_${car.wheelsDriven}`}
                  />
                </Flex>
              )}
              {car?.noDoors && car.noDoors !== '0' && (
                <Flex
                  gap={'var(--spacing-tinyMinus)'}
                  justify={'space-between'}
                  align={'center'}
                >
                  <DoorsOutline width={16} height={16} />
                  <Text>{car.noDoors}</Text>
                </Flex>
              )}
            </CarInfoRow>
          </Flex>
        </div>
      </Flex>
    </Wrapper>
  )
}

export default CarCardNew

const Wrapper = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-medium);
  width: 100%;
`

const StatusBadge = styled.div`
  background-color: var(--color-skyBlue);
  padding: var(--spacing-tinyMinus);
`

const ImageContainer = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  font-size: var(--font-base);
`

const BlueText = styled(Text)`
  color: var(--color-primary);
`

const BoldText = styled(Text)`
  font-weight: var(--fontWeight-bold);
`

const CarInfoRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding-top: var(--spacing-mediumMinus);
  font-size: 16px;
`
