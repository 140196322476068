import React, { useMemo, useState } from 'react'
import { Wrapper } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import WheelPackageListItem from '../components/fields/WheelPackage/WheelPackageListItem'
import styled, { css } from 'styled-components'
import { get, uniqBy } from 'lodash'
import { CarTopSummary } from '../components/CarTopSummary'
import { useCarPrice } from '../hooks/useCarPrice'
import CustomSkeleton from '../components/skeleton/skeleton'
import useCarConfiguration, { CONFIGURE_WHEEL_PACKAGES } from '../hooks/useCarConfiguration'
import CarConfigContainer from '../components/CarConfigContainer'
import { ToastContainer } from 'react-toastify'
import Navigation from '../components/Navigation'
import { SmallHeading } from '../../../components/text/Headings'
import useCarConfigurationQueries from '../hooks/useCarConfigurationQueries'
import { useCarOptionRules } from '../hooks/useCarOptionRules'
import { STANDARD_PAINT } from './ConfigureCarOptions'
import { useParams } from 'react-router-dom'
import { flattenAccessories } from '../../../api/jato'
import FormError from '../components/FormError'
import { useFlow } from '../../../context/FlowProvider'
import ImageDialog from '../components/imageGallery/ImageDialog'
import Container from '../../../components/Container'
import Toast from '../components/toast/Toast'
import RadioButtonList from "../../../components/RadioButtonList";

const ConfigureWheelPackages = () => {
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)

  const { saveAndNavigate, errorHandler, companyId, isRedirecting, isLoading } = useCarConfiguration({
    page: CONFIGURE_WHEEL_PACKAGES,
    flow,
    refetch,
  })

  const { carConfiguration, vehicleType, visitedSteps } = flow?.data || {}

  const {
    chosenWheelPackage,
    chosenPackages = [],
    chosenOptions = [],
    chosenFinancingOptions,
    vehicleId,
    derivativeLocal,
  } = carConfiguration || {}

  const hasChosenEngine = !!derivativeLocal

  const [selectedWheelPackage, selectedWheelPackageSet] = useState(chosenWheelPackage ? chosenWheelPackage : null)
  const [selectedTyreType, selectedTyreTypeSet] = useState(chosenWheelPackage ? chosenWheelPackage.TireType : null)
  const [openPhotos, openPhotosSet] = useState(false)

  const { accessoriesRaw, wheelPackagesRaw } = useCarConfigurationQueries(CONFIGURE_WHEEL_PACKAGES, errorHandler, {
    vehicleType,
    companyId,
    vehicleId: vehicleId,
    make: carConfiguration?.makeKey,
    model: carConfiguration?.modelKey,
    modelYear: carConfiguration?.modelYear,
    bodyCode: carConfiguration?.bodyCode,
    doors: carConfiguration?.numberOfDoors,
    drivenWheels: carConfiguration?.drivenWheels,
    engine: carConfiguration?.derivativeLocal,
    fuelType:
      carConfiguration?.fuelType +
      (carConfiguration?.secondaryFuelType && carConfiguration.secondaryFuelType !== '-'
        ? carConfiguration.secondaryFuelType
        : ''),
    ...chosenFinancingOptions,
  })

  const { error: accessoriesError, data: accessoriesData } = accessoriesRaw
  const { error: wheelPackagesError, data: wheelPackages } = wheelPackagesRaw

  const tyreTypes = useMemo(() => {
    if (!wheelPackages) return null
    return uniqBy(wheelPackages.wheelPackages || [], 'TireType')
  }, [wheelPackages])

  const { accessories, photo, photos, standardEquipment } = useMemo(() => {
    if (!accessoriesData) return {}

    const { packages = [], options = {} } = accessoriesData

    return {
      accessories: { options, packages },
      photo: get(accessoriesData, 'vehiclePhotos[0]', []),
      photos: get(accessoriesData, 'vehiclePhotos', []),
      standardEquipment: get(accessoriesData, 'vehicleStandardEquipment', {}),
    }
  }, [accessoriesData])

  const { simpleFormValidator, formError, setRequiredSelections } = useCarOptionRules(
    [...flattenAccessories(accessories), STANDARD_PAINT],
    [...chosenOptions, ...chosenPackages],
    CONFIGURE_WHEEL_PACKAGES,
    !!carConfiguration?.derivativeLocal,
    visitedSteps,
    !!selectedWheelPackage
  )
  const { monthlyPrice } = useCarPrice({
    configuration: accessoriesData
      ? {
          ...carConfiguration,
          chosenWheelPackage: selectedWheelPackage,
        }
      : null,
    options: flow && !isRedirecting ? [...chosenPackages, ...chosenOptions] : [],
    vehicleType,
    financingOptions: chosenFinancingOptions ? chosenFinancingOptions : {},
    errorHandler,
  })

  const updateFlowHandler = ({ direction, route }) => {
    saveAndNavigate.mutate({
      step: CONFIGURE_WHEEL_PACKAGES,
      carConfiguration: {
        ...carConfiguration,
        chosenWheelPackage: selectedWheelPackage,
      },
      direction,
      route,
    })
  }

  const onSelectWheelPackage = (item) => {
    if (selectedWheelPackage && selectedWheelPackage.ItemNo === item.ItemNo) {
      selectedWheelPackageSet(null)
    } else {
      selectedWheelPackageSet(item)
    }
  }

  const onSelectTyreType = (tireType) => {
    if (selectedTyreType && selectedTyreType.TireType === tireType) {
      selectedTyreTypeSet(null)
    } else {
      selectedTyreTypeSet(tireType)
    }

    const selectedWheelPackageIsValidForTireType = selectedWheelPackage
      ? wheelPackages.wheelPackages.some(
          (wheelPackage) =>
            wheelPackage.TireType === tireType && wheelPackage.ItemNo === selectedWheelPackage.ItemNo
        )
      : true

    if (!selectedWheelPackageIsValidForTireType) {
      selectedWheelPackageSet(null)
    }
  }

  const reqError = accessoriesError || wheelPackagesError
  if (reqError) {
    errorHandler(reqError)
  }

  const tyreTypeToDisplay = selectedTyreType ? selectedTyreType : get(tyreTypes, '[0].TireType')

  if (isRedirecting) {
    return null
  }

  return (
    <Container isLoading={isLoading} width="100%">
      <CarTopSummary
        car={carConfiguration}
        photoUrl={photo || carConfiguration?.photoUrl}
        coreCalculatedMonthlyPrice={monthlyPrice}
        vehiclePhotos={photos}
        standardEquipment={standardEquipment}
        onPress={() => {
          if (photos.length > 0) {
            openPhotosSet(!!photos)
          }
        }}
        page={CONFIGURE_WHEEL_PACKAGES}
        saveAndNavigate={updateFlowHandler}
        routeValidator={simpleFormValidator}
        visitedPages={visitedSteps}
      />
      <ConfigureWheelPackagesWrapper>
        {photos && <ImageDialog photos={photos} isOpen={openPhotos} openSet={openPhotosSet} />}
        {wheelPackagesError && <TranslatedText i18nKey="NO_WHEEL_PACKAGE_MSG" />}
        {tyreTypes ? (
          <TyreTypesNew error={!!!selectedWheelPackage}>
            <TranslatedText i18nKey="WINTER_TYRES">{(value) => <SmallHeading>{value}</SmallHeading>}</TranslatedText>
            <RadioButtonList
              items={tyreTypes.map((t) => ({
                key: t.TireType,
                value: t.TireType
              }))}
              value={selectedTyreType}
              onChangeHandler={onSelectTyreType}
            />
          </TyreTypesNew>
        ) : (
          !wheelPackagesError &&
          hasChosenEngine && (
            <CustomSkeleton
              skeletons={[
                {
                  width: 20,
                  height: 20,
                  type: 'circle',
                },
                {
                  height: 20,
                  width: 230,
                },
              ]}
            />
          )
        )}
        {wheelPackages && wheelPackages.wheelPackages && tyreTypeToDisplay !== 'Ingen' ? (
          <WheelPackages>
            <TranslatedText i18nKey={'RIM_HEADING'}>{(value) => <SmallHeading>{value}</SmallHeading>}</TranslatedText>
            <div>
              {wheelPackages.wheelPackages
                .filter((tyreType) => tyreTypeToDisplay === tyreType.TireType)
                .map((wheelPackage) => {
                  const rimType = wheelPackages.rimTypes.find((rimType) => rimType.RimType === wheelPackage.RimType)
                  return rimType ? (
                    <WheelPackageListItem
                      key={wheelPackage.itemNo}
                      onSelect={onSelectWheelPackage}
                      isSelected={selectedWheelPackage && selectedWheelPackage.ItemNo === wheelPackage.ItemNo}
                      wheelPackage={wheelPackage}
                      image={{
                        filename: rimType.RimPictureFilename,
                        base64: rimType.RimPictureBase64,
                      }}
                      error={!hasChosenEngine}
                    />
                  ) : null
                })}
            </div>
          </WheelPackages>
        ) : !wheelPackagesError && !tyreTypeToDisplay && hasChosenEngine ? (
          <CustomSkeleton
            skeletons={[
              {
                width: 226,
                height: 300,
              },
            ]}
            theme={css`
              flex-direction: column;
              > span {
                display: flex;
              }
            `}
          />
        ) : (
          !hasChosenEngine && <TranslatedText i18nKey={'ENGINE_MISSING_MESSAGE_WHEEL_PACKAGES'} />
        )}
        {!wheelPackagesError && !selectedWheelPackage && (
          <FormError
            page={CONFIGURE_WHEEL_PACKAGES}
            errors={formError}
            setRequiredSelections={setRequiredSelections}
            navigate={updateFlowHandler}
          />
        )}
        <Navigation updateFlowHandler={updateFlowHandler} />
      </ConfigureWheelPackagesWrapper>
      <Toast limit={1} />
    </Container>
  )
}

export default ConfigureWheelPackages

const TyreTypesNew = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  border: 1px solid var(--color-line);

  > span {
    background: var(--color-primaryLight);
    line-height: 2.5em;
  }

  > div > div {
    cursor: pointer;
  }

  > div > div:not(:first-child) {
    border-top: var(--spacing-microMinus) solid var(--color-line);
  }

  > span,
  > div > div {
    padding: 0 var(--spacing-smallPlus);
  }
`

const WheelPackages = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-medium);
  }

  > span {
    line-height: 2.5em;
  }
`

const ConfigureWheelPackagesWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`
