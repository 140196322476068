import React from "react";
import styled, { css } from "styled-components"
import * as PopoverPrimitive from '@radix-ui/react-popover';
import Pencil from "../../../../icons/Pencil"
import Trashcan from "../../../../icons/Trashcan"

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = styled(PopoverPrimitive.Trigger)`
  ${props => props.isDefaultSymbol ? css`border: var(--spacing-microMinus) solid var(--color-primary);
    color: var(--color-primary);
    border-radius: var(--spacing-huge);
    width: var(--spacing-mediumMinus);
    height: var(--spacing-mediumMinus);` : css`
    border: none;
  `};
  background: var(--color-white);
  cursor: pointer;
  ${props => props.triggerStyle};
`


export const PopoverContent = styled(PopoverPrimitive.Content)`
  width: 240px;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);
  border-radius: var(--spacing-tinyMinus);
  padding: var(--spacing-small);
  box-shadow: 0 var(--spacing-tinyMinus) var(--spacing-small) 0 rgba(0, 0, 0, 0.2);
`

const variants = {
  default: {
    symbol: "?",
    style: css``
  },
  trashcan: {
    symbol: <Trashcan/>,
    style: css`
      svg {
        width: auto;
        height: 20px;
      }
      :hover {
        svg {
          animation: shake 0.3s;
          animation-iteration-count: 1;
          path {
            fill: var(--color-warning);
          }
        }

        @keyframes shake {
          0% {
            transform: translate(1px, 1px) rotate(0deg);
          }
          10% {
            transform: translate(-1px, -2px) rotate(-1deg);
          }
          20% {
            transform: translate(-3px, 0px) rotate(1deg);
          }
          30% {
            transform: translate(3px, 2px) rotate(0deg);
          }
          40% {
            transform: translate(1px, -1px) rotate(1deg);
          }
          50% {
            transform: translate(-1px, 2px) rotate(-1deg);
          }
          60% {
            transform: translate(-3px, 1px) rotate(0deg);
          }
          70% {
            transform: translate(3px, 1px) rotate(-1deg);
          }
          80% {
            transform: translate(-1px, -1px) rotate(1deg);
          }
          90% {
            transform: translate(1px, 2px) rotate(0deg);
          }
          100% {
            transform: translate(1px, -2px) rotate(-1deg);
          }
        }
      }
    `
  },
  edit: {
    symbol: <Pencil />,
    style: css`
      width: 30px;
      height: 30px;
      svg {
        width: auto;
        height: 20px;
        path {
          fill: var(--color-primary);
        }
        :hover {
          animation: write 0.2s;
          animation-iteration-count: 3;
          
          @keyframes write {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(3px);
            }
            100% {
              transform: translateX(0px);
            }
          }
        }
      }
    `
  }
}

const CustomPopover = ({ variant = "default", children }) => {
  const selectedVariant = variants[variant]

  return <Popover>
    <PopoverTrigger isDefaultSymbol={variant === "default"} triggerStyle={selectedVariant.style}>{selectedVariant.symbol}</PopoverTrigger>
    <PopoverContent>
      {children}
    </PopoverContent>
  </Popover>
}

export default CustomPopover
