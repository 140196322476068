import React from 'react'
import styled from 'styled-components'
import {downloadAttachment, getFlow} from '../../../../api/api'
import { useQuery } from 'react-query'
import CustomSkeleton from '../../../carConfiguration/components/skeleton/skeleton'

const BudgetPreview = ({ flowId, attachmentKey, mimeType }) => {

  const {
    data: file,
    isLoading,
    refetch,
  } = useQuery(['file', flowId, attachmentKey, mimeType], async () => downloadAttachment(flowId, attachmentKey, mimeType), {
    enabled: !!flowId && !!attachmentKey && !!mimeType,
  })

  return (
    <>
      <FilePreviewContainer>
        {isLoading && (
          <CustomSkeleton
            skeletons={[
              {
                width: '100%',
                height: '70vh',
                count: 1,
              },
            ]}
            border={false}
          />
        )}
        {file && (
          <FileIframe
            title="file"
            src={file}
          />
        )}
      </FilePreviewContainer>
    </>
  )
}

const FilePreviewContainer = styled.div`
  width: var(--size-large);
`

const FileIframe = styled.iframe`
  height: 70vh;
  width: 100%;
`
export default BudgetPreview
