import { formatDate as dateFormatter, formatMoney as currencyFormatter } from '@staccx/bento'
import safeNumber from './safeNumber'
import format from 'date-fns/format'

export const sum = (data, field) => {
  let fieldAccessor

  if (typeof field === 'string') {
    fieldAccessor = (row) => row._original[field]
  } else {
    fieldAccessor = field
  }

  return data.reduce((sum, row) => sum + fieldAccessor(row), 0)
}

export const formatCurrency = (value, options, prefix = 'kr ') => {
  return (
    prefix +
    currencyFormatter(safeNumber(value), {
      precision: 2,
      decimal: ',',
      ...options,
    })
  )
}

export const formatNumber = (value, options) => {
  return currencyFormatter(safeNumber(value), {
    precision: 2,
    decimal: ',',
    ...options,
  })
}

export const formatDate = (date, dateFormat = 'dd.MM.yy') => {
  if (typeof date === 'string') {
    if (date !== '') return format(new Date(date), dateFormat)
    return ''
  }
  return format(date, dateFormat)
}

export const columnSum = (data, field) => {
  let fieldAccessor

  if (typeof field === 'string') {
    fieldAccessor = (row) => row[field]
  } else {
    fieldAccessor = field
  }
  return data.reduce((sum, row) => sum + fieldAccessor(row), 0)
}

export const formatTime = (date, format = 'HH:mm') => dateFormatter(date, format)
