import { useEffect } from 'react'
import styled from 'styled-components'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { enGB, nb } from 'date-fns/locale'

const CustomDatePicker = ({ selected, language = 'nb', onChange }) => {
  useEffect(() => {
    const locale = language === 'nb' ? nb : enGB
    registerLocale(language, locale)
    setDefaultLocale(language)
  }, [language])

  const handleRawChange = (event) => {
    const validDate = getValidDate(event?.target?.value)
    if (validDate) {
      onChange(validDate)
    }
  }

  const handleChange = (date) => {
    const validDate = getValidDate(date)
    if (validDate) {
      onChange(validDate)
    }
  }

  const getValidDate = (date) => {
    const inputDate = new Date(date)

    if (isNaN(inputDate.getTime()) || inputDate <= new Date()) {
      return false
    }

    return date
  }

  const handleBlur = (event) => {
    if (!getValidDate(event?.target?.value)) {
      onChange(selected)
    }
  }

  return (
    <DatePickerContainer>
      <DatePicker
        selected={selected}
        onChangeRaw={handleRawChange}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        minDate={new Date()}
        onBlur={handleBlur}
      />
    </DatePickerContainer>
  )
}

export default CustomDatePicker

const DatePickerContainer = styled.div`
  .react-datepicker-wrapper .react-datepicker__input-container input {
    border: 1px solid var(--color-line);
    border-radius: var(--spacing-micro);
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper .react-datepicker__triangle {
      ::after,
      ::before {
        border-bottom-color: var(--color-primary);
      }
    }

    .react-datepicker-popper .react-datepicker__navigation ::before {
      border-color: var(--color-white);
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: var(--color-primary);

        div {
          color: var(--color-white);
        }
      }
    }

    .react-datepicker__day.react-datepicker__day--023.react-datepicker__day--selected {
      background-color: var(--color-primary);
    }
  }
`
