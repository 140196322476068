import React from 'react'
import type { Toll } from '../../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { getConsolidateInvoiceDescription } from '../../../../../utils/getConsolidateInvoiceDescription'
import { formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'

export default function tollReportData(chosenCompany?: string) {
  const columnHelper = createColumnHelper<Toll>()
  return [
    columnHelper.accessor('amountIncVAT', {
      id: 'period',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'PERIOD'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => `${row.row.original.tYear} - ${row.row.original.tMonth}` || '',
    }),
    columnHelper.accessor('consolidatedInvoiceAgreement', {
      id: 'consolidatedInvoiceAgreement',
      meta: { alignmentType: 'string' },
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_AGREEMENT'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => getConsolidateInvoiceDescription(row.getValue(), chosenCompany) || '',
    }),
    columnHelper.accessor('amountIncVAT', {
      id: 'amountIncVAT',
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('antallPasseringer', {
      id: 'antallPasseringer',
      header: () => <TranslatedText i18nKey={'PASSINGS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
  ]
}
