import React from 'react'

interface DoorsProps {
  width?: number
  height?: number
  color?: string
}

const Doors = ({ width = 38, height = 35, color = '#467EFF' } : DoorsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4167 0C14.9261 0 12.5772 1.17421 11.0833 3.16667L3.89339 12.7533C1.6796 13.1572 0 15.0862 0 17.4167V33.25C0 34.124 0.709333 34.8333 1.58333 34.8333H36.4167C37.2907 34.8333 38 34.124 38 33.25V17.4167C38 16.0155 37.3814 14.7699 36.4167 13.9006V1.58333C36.4166 1.16342 36.2498 0.760717 35.9529 0.463794C35.6559 0.16687 35.2532 4.1985e-05 34.8333 0H17.4167ZM17.4167 3.16667H33.25V12.6667H7.91667L13.616 5.06543C14.513 3.86905 15.9204 3.16667 17.4167 3.16667ZM28.5 17.4167H31.6667C32.5407 17.4167 33.25 18.126 33.25 19C33.25 19.874 32.5407 20.5833 31.6667 20.5833H28.5C27.626 20.5833 26.9167 19.874 26.9167 19C26.9167 18.126 27.626 17.4167 28.5 17.4167Z"
      fill={color}
    />
  </svg>
)

export default Doors
