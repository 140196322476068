import { css } from "styled-components"
import { color, LayoutItem, spacing, VARIANT_DEFAULT } from "@staccx/bento"

LayoutItem.createVariants({
    container:
      {
        [VARIANT_DEFAULT]: css`
          .no-cssgrid & {
            margin-bottom: ${spacing.medium};
          }
        `,
        main: css`
          @media only screen and (min-width: 1000px) {
            max-width: calc(100vw - 300px);
          }
        `,
        sidebar: css`
          @media only screen and (max-width: 1000px) {
            position: absolute;
            width: 100%;
            background: ${color.white};
            min-height: 100vh;
            display: ${p => (p.menuOpen ? "block" : "none")};
            z-index: 2;
          }
        `
      }
  }
)
