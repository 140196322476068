import qs from "qs"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const useParams = () => {
  const { search } = useLocation()
  const [params, paramsSet] = useState(qs.parse(search, {
    ignoreQueryPrefix: true
  }))

  const handleParamsChange = () => {
    paramsSet(qs.parse(search, {
      ignoreQueryPrefix: true
    }))
  };
  useEffect(() => {
    handleParamsChange()
  }, [search]);

  return params
}
