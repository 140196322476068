import React from 'react'
import styled, { css } from 'styled-components'
import Loader from './Loader'

const Container = ({ isLoading, children, width = 'large', renderChildrenDuringLoading = true, css = {} }) => {
  return (
    <Home isLoading={isLoading} width={width} css={css}>
      {isLoading && <Loader />}
      {isLoading && !renderChildrenDuringLoading ? null : children}
    </Home>
  )
}

const Home = styled.div`
  ${(props) =>
    props?.width?.includes('%')
      ? css`
          width: ${props.width};
        `
      : css`
          width: var(--size-${props.width});
        `}
  ${(props) =>
    props?.width === 'unlimited'
      ? css`
          margin: var(--spacing-navBarOffset) var(--spacing-large) 0 var(--spacing-large);
        `
      : css`
          margin: var(--spacing-navBarOffset) auto 0 auto;
        `}
  background-color: var(--color-bgGray);
  z-index: 0;
  ${(props) => props.css};
  max-width: 90%;
`

export default Container
