import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatNumber } from '../../../../utils/tableFuncs'
import type { EnvironmentStats } from '../../../../types'

export default function statsTableData() {
  const columnHelper = createColumnHelper<EnvironmentStats>()
  return [
    columnHelper.accessor('averageCo2PerCar', {
      id: 'averageCo2PerCar',
      header: () => <TranslatedText i18nKey={'AVERAGE_CO2_PER_CAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 2 }) + ' kg' || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('averageNoxPerCar', {
      id: 'averageNoxPerCar',
      header: () => <TranslatedText i18nKey={'AVERAGE_NOX_PER_CAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 2 }) + ' g' || '',
      meta: { alignmentType: 'number' },
    }),
  ]
}
