import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Notification = ({ notification }) => {
  return (
    <StyledNavLink to={notification.path}>
      <NotificationBox color={notification.color}>
        <Row>
          <HeaderSpan fontColor={notification.fontColor}>{notification.type}</HeaderSpan>
        </Row>
        <Row>
          <TitleSpan>{notification.title}</TitleSpan>
        </Row>
        <Row>
          <Column>
            <MessageSpan>{notification.message}</MessageSpan>
          </Column>
          <Column>
            <MessageSpan>{notification.date}</MessageSpan>
          </Column>
        </Row>
      </NotificationBox>
    </StyledNavLink>
  )
}

export default Notification

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`
const NotificationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  height: 100%;
  color: var(--color-black);
  background-color: ${(props) => props.color};
  border-radius: 12px;
  padding: var(--spacing-small);
  gap: var(--spacing-tiny);
  transition: 0.2s;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    box-shadow: 4px 4px 4px var(--color-shadow);
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderSpan = styled.span`
  color: ${(props) => props.fontColor};
  font-size: var(--font-text);
  font-weight: var(--fontWeight-veryBold);
`
const TitleSpan = styled.span`
  font-size: var(--font-notificationHeader);
  font-weight: var(--fontWeight-veryBold);
`
const MessageSpan = styled.span`
  font-size: var(--font-text);
  font-weight: var(--fontWeight-normal);
`
