import React from 'react'
import { SlideThumbnail, ThumbInner, Thumbnail } from './styles/CarouselStyles'

export const Thumb = ({ selected, onClick, imgSrc }) => (
  <Thumbnail selected={selected}>
    <ThumbInner onClick={onClick}>
      <SlideThumbnail>
        <img src={imgSrc} alt="A car" />
      </SlideThumbnail>
    </ThumbInner>
  </Thumbnail>
)
