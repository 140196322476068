import React from "react"
import { Navigate } from "react-router-dom"
import { useCarContext } from "../../../context/CarProvider"
import SignalSpinnerLoader from "../../../components/loaders/SignalSpinnerLoader";

const MyCar = () => {
  const carContext = useCarContext()
  const car = carContext.currentUsersCar

  if (!car) {
    return <SignalSpinnerLoader/>
  }

  return <Navigate to={`/cars/${car.registrationNumber}`} replace />

}

export default MyCar
