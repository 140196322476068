import React, { useState } from 'react'
import { TranslatedText } from '@staccx/i18n'
import ContactForm from '../../../components/modal/ContactForm'
import DialogBox from '../../../components/modal/DialogBox'
import { quitDriver } from '../../../api/api'
import Dialog from '../../../components/modal/Dialog'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import Close from '../../../icons/Close'
import DriversTable from '../DriversTable'

const DriverList = ({ companyId, drivers, isLoading, ...props }) => {
  const [showEditDriverModal, showEditDriverModalSet] = useState(false)
  const [selectedDriver, selectedDriverSet] = useState(null)
  const [discontinue, discontinueSet] = useState(false)

  return (
    <>
      <Dialog open={showEditDriverModal} onOpenChange={showEditDriverModalSet}>
        <RightAlignedRow>
          <Close
            onClick={() => {
              showEditDriverModalSet(false)
              discontinueSet(false)
            }}
          />
        </RightAlignedRow>
        {!discontinue ? (
          <ContactForm
            companyId={companyId}
            driver={selectedDriver || null}
            onFinish={async () => {
              selectedDriverSet(null)
              showEditDriverModalSet(false)
              props.onEditDriverFinish()
            }}
            onDiscontinue={() => discontinueSet(true)}
          />
        ) : (
          <DialogBox
            heading={<TranslatedText i18nKey="DISCONTINUE_DRIVER" />}
            body={<TranslatedText i18nKey="DISCONTINUE_DRIVER_TEXT" />}
            confirmButtonText={<TranslatedText i18nKey="DISCONTINUE" />}
            cancelButtonText={<TranslatedText i18nKey="PREVIOUS" />}
            onFinish={async () => {
              await quitDriver(selectedDriver.id) // TODO: Add spinner/loading
              discontinueSet(false)
              selectedDriverSet(null)
              showEditDriverModalSet(false)
              props.onEditDriverFinish()
            }}
            onCancel={() => discontinueSet(false)}
          />
        )}
      </Dialog>
      <DriversTable
        data={drivers || []}
        isLoading={isLoading}
        showDialog={showEditDriverModal}
        showDialogSet={showEditDriverModalSet}
        selectedDriverSet={selectedDriverSet}
      />
    </>
  )
}

export default DriverList
