import { FUEL_TYPE_FILTER_ALL } from "../model/car"

export const fuelReduce = fuelType => (acc, curr) => {
  if (fuelType === FUEL_TYPE_FILTER_ALL || curr.fuel === fuelType) {
    if (curr.status !== "incoming") {
      return acc + 1
    }
  }
  return acc
}

export default fuelReduce
