import React from "react";
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '../../../../utils/tableFuncs'
import { fromPairs } from "lodash";

export default function eventListDeviationsData() {
    return [
        {
            accessorKey: 'date',
            id: 'date',
            header: () => <TranslatedText i18nKey={'DATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ row }) => {
              const date = row.original.date 
              return date ? formatDate(date) : ''
            },
            meta: { alignmentType: 'date' },
          },
          {
            accessorKey: 'carId',
            id: 'carId',
            header: () => <TranslatedText i18nKey={'CAR_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'car.driverName',
            id: 'car.driverName',
            header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'description',
            id: 'description',
            header: () => <TranslatedText i18nKey={'DESCRIPTION'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'code',
            id: 'code',
            header: () => <TranslatedText i18nKey={'CODE'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
    ]
}