import React from "react"
import PropTypes from "prop-types"
import { Alert, Layout } from "@staccx/bento"
import Box from "../../../components/layouts/Box"
import styled from "styled-components"
import { i18nInstance } from "@staccx/i18n"

const LoginWrapper = ({ children }) => {
  const loginNotice = i18nInstance.translate("LOGIN_WARNING_TEXT")

  return (
    <Bg>
      <Layout variant="centered">
        <Box variant="login">
          {loginNotice && !loginNotice.startsWith("Missing key") && (
            <LoginNotice type={"warning"}>{loginNotice}</LoginNotice>
          )}
          {children}
        </Box>
      </Layout>
    </Bg>
  )
}

const LoginNotice = styled(Alert)`
  margin-bottom: 20px;
`

const Bg = styled.div`
  background-image: url("/autoplan-login-background.jpg");
  background-size: cover;
`

LoginWrapper.propTypes = {
  children: PropTypes.any
}

export default LoginWrapper
