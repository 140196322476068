import React, {useState, useMemo, useEffect} from 'react'
import DriverList from '../components/Drivers'
import { TranslatedText } from '@staccx/i18n'
import Fuse from 'fuse.js/dist/fuse.common'
import { useContacts } from '../../../context/DriverProvider'
import { useUserContext } from '../../../context/UserProvider'
import { useQueryClient } from 'react-query'
import Container from '../../../components/Container'
import styled from 'styled-components'
import { useCarContext } from '../../../context/CarProvider'
import { useDriverContext } from '../../../context/DriverProvider'
import { useUser } from '../../../context/UserProvider'
import NewEmployee from '../components/NewEmployee'
import SearchBox from '../../../components/menu/SearchBox'
import {ReportHeading} from "../../../components/text/Headings";

const options = {
  isCaseSensitive: false,
  threshold: 0.1,
  useExtendedSearch: false,
  keys: ['name', 'regNo']
}

const Drivers = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const name = params.get('name');

  const { chosenCompany, roleType } = useUserContext()
  const { contacts, isLoading, refetch, isRefetching } = useContacts()
  const queryClient = useQueryClient()

  const [pattern, patternSet] = useState('')
  const [filteredDrivers, filteredDriversSet] = useState(null)

  const [showNewDriverModal, showNewDriverModalSet] = useState(false)
  const user = useUser()
  const { updateCars } = useCarContext()
  const { updateDrivers } = useDriverContext()

  const filteredContacts = useMemo(() => {
    const mainContacts = contacts?.filter((value) => value.type !== 'Main Contact')
    return mainContacts
  }, [contacts])

  useEffect(() => {
    if (name) {
      const result = fuse.search(name)
      filteredDriversSet(result.map((i) => i.item))
      patternSet(name);
    }
  }, []);

  const handleOnChange = (pattern, fuse) => {
    if (!pattern) {
      filteredDriversSet(null)
      return patternSet(pattern)
    }

    const result = fuse.search(pattern)
    filteredDriversSet(result.map((i) => i.item))
    patternSet(pattern)
  }

  const emptySearch = () => {
    return filteredDriversSet(null), patternSet('')
  }

  const onEditDriverFinish = () => {
    queryClient.invalidateQueries('cars')
    queryClient.invalidateQueries('drivers').then(() => refetch(), emptySearch())
    queryClient.invalidateQueries('contacts')
  }

  if (roleType !== 2) {
    return null
  }
  const fuse = new Fuse(filteredContacts || [], options)

  return (
    <Container>
      <TranslatedText i18nKey={'EMPLOYEES'}>{(value) => <ReportHeading>{value}</ReportHeading>}</TranslatedText>
      <Content>
        <SearchBox value={pattern} onChange={async (value) => await handleOnChange(value, fuse)} />
        <NewEmployee
          contactSite
          user={user}
          showNewDriverModal={showNewDriverModal}
          showNewDriverModalSet={showNewDriverModalSet}
          updateDrivers={updateDrivers}
          updateCars={updateCars}
          onEditDriverFinish={onEditDriverFinish}
        />
      </Content>
      <DriverList
        isLoading={!filteredDrivers && !filteredContacts && (isLoading || isRefetching)}
        drivers={filteredDrivers || filteredContacts}
        companyId={chosenCompany.id}
        onEditDriverFinish={() => onEditDriverFinish()}
      />
    </Container>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--spacing-largeMinus);
  padding-bottom: var(--spacing-mediumPlus);
`

export default Drivers
