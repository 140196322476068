import React from 'react';
import styled from 'styled-components'
import * as RadioGroup from '@radix-ui/react-radio-group';

const RadioButtonList = ({items, value, onChangeHandler}) => {
  return (
    <div>
      <form>
        <RadioGroupRoot value={value} onValueChange={onChangeHandler}>
          {items.map((item) => (
            <RadioGroupItemWrapper>
              <RadioGroupItem value={item.key} id={item.key}>
                <RadioGroupIndicator />
              </RadioGroupItem>
              <label htmlFor={item.key}>
                {item.value}
              </label>
            </RadioGroupItemWrapper>
          ))}
        </RadioGroupRoot>
      </form>
    </div>
  )

};

export default RadioButtonList;


const RadioGroupRoot = styled(RadioGroup.Root)`
  display: flex;
  flex-direction: column;
`

const RadioGroupItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-smallPlus);
  box-shadow: 0 var(--spacing-microMinus) 0 0 var(--color-line);
  height: 40px;
  cursor: pointer;
  
  label {
    flex-grow: 1;
    text-align: left;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding-left: var(--spacing-smallPlus);
    letter-spacing: normal;
    font-size: var(--font-base);
    font-weight: var(--fontWeight-normal);
  }
`

const RadioGroupItem = styled(RadioGroup.Item)`
  background-color: white;
  width: var(--font-large);
  height: var(--font-large);
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--color-line);
  
  :hover {
    border: 1px solid black;
  }
`

const RadioGroupIndicator = styled(RadioGroup.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: var(--color-primary);
    width: var(--font-base);
    height: var(--font-base);
  }
`