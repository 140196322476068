import React from 'react'
import type { Invoice } from '../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { getConsolidateInvoiceDescription } from '../../../../utils/getConsolidateInvoiceDescription'
import { formatDate } from '@staccx/bento'

export default function invoiceReportTableData(chosenCompany?: string) {
  const columnHelper = createColumnHelper<Invoice>()
  return [
    columnHelper.accessor('invoiceNo', {
      id: 'invoiceNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'INVOICE_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('consolidateInvoiceCode', {
      id: 'consolidateInvoiceCode',
      meta: { alignmentType: 'string' },
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_AGREEMENT'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => getConsolidateInvoiceDescription(row.getValue(), chosenCompany) || '',
    }),
    columnHelper.accessor('postingDate', {
      id: 'postingDate',
      header: () => <TranslatedText i18nKey={'INVOICE_POSTING_DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(new Date(row.getValue()), 'dd.MM.yy') || '',
      meta: { alignmentType: 'date' },
    }),
    columnHelper.accessor('consolidateDescription', {
      id: 'consolidateDescription',
      header: () => (
        <TranslatedText i18nKey={'INVOICE_CONSOLIDATE_DESCRIPTION'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('billToName', {
      id: 'billToName',
      header: () => <TranslatedText i18nKey={'INVOICE_BILL_TO_NAME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
  ]
}
