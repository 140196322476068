export const arraySplit = (arr, splitByKey, catchAllKey) => {
  const out = {}

  arr.forEach(doc => {
    const keyName = doc[splitByKey]

    if (keyName) {
      initializeKey(out, keyName, [])
      out[keyName].push(doc)
    } else {
      initializeKey(out, catchAllKey, [])
      out[catchAllKey].push(doc)
    }
  })

  return out
}

const initializeKey = (obj, prop, value) => {
  if (typeof obj[prop] === "undefined") {
    obj[prop] = value
  }
}

export default arraySplit