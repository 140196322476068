import React, { createContext, useContext } from 'react';
import { useQuery } from "react-query"
import { fetchDeviations, fetchInvoices, fetchUsages } from "../api/api"
import { useChosenCompany } from "./UserProvider"

const EventContext = createContext({})

const EventProvider = ({ children }) => {
  const company = useChosenCompany()
  const companyId = company && company.id

  const { data: events = [], isLoading: eventsLoading } = useQuery(["usages", companyId],
    async () => await fetchUsages(company.id), {
      enabled: !!companyId
    })

  const { data: deviations = [], isLoading: deviationsLoading } = useQuery(["deviations", companyId],
    async () => await fetchDeviations(company.id), {
      enabled: !!companyId,
    })

  const { data: invoices = [], isLoading: invoicesLoading } = useQuery(["invoices", companyId],
    async () => await fetchInvoices(company.id), {
      enabled: !!companyId
    })

  const value = {
    usages: events.concat(deviations).concat(invoices),
    deviations,
    invoices,
    isLoading: eventsLoading || deviationsLoading || invoicesLoading
  }

  return (
    <EventContext.Provider value={value}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => useContext(EventContext)

export default EventProvider;
