import React from 'react'
import { Text } from '@staccx/bento'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import ConfirmIcon from '../../../icons/Confirmation'
import { useParams } from 'react-router-dom'
import { useFlow } from '../../../context/FlowProvider'
import useCarConfiguration, { CONFIRMATION } from '../hooks/useCarConfiguration'
import CarConfigContainer from '../components/CarConfigContainer'
import Container from "../../../components/Container"

const Confirmation = () => {
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)

  const { isRedirecting, isLoading } = useCarConfiguration({ page: CONFIRMATION, flow, refetch })

  if (isRedirecting) {
    return null
  }

  return (
    <Container isLoading={isLoading}>
      <Centered>
        <ConfirmIcon />
      </Centered>
      <Centered>
        <OrderConfirmHeader>
          <TranslatedText i18nKey="ORDER_CONFIRM_SHORT" />
        </OrderConfirmHeader>
      </Centered>
      <Centered>
        <OrderConfirm>
          <TranslatedText i18nKey="ORDER_CONFIRM_LONG" />
        </OrderConfirm>
      </Centered>
    </Container>
  )
}

export default Confirmation

const Centered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: var(--spacing-medium);
`
const OrderConfirmHeader = styled(Text)`
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
  margin: var(--spacing-largePlus) 0 var(--spacing-medium) 0;
`

const OrderConfirm = styled(Text)`
  font-size: var(--font-h3);
  font-weight: var(--fontWeight-normal);
  justify-content: center;
`
