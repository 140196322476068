import React from "react"

const Plus2 = () => (
  <svg 
    width="21" 
    height="21" 
    fill="none"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M10.344.156C4.63.156 0 4.787 0 10.5c0 5.713 4.631 10.344 10.344 10.344 5.712 0 10.344-4.631 10.344-10.344 0-5.713-4.632-10.344-10.344-10.344ZM.95 10.5a9.394 9.394 0 1 1 18.788 0 9.394 9.394 0 0 1-18.788 0Zm9.394-5.315a.5.5 0 0 1 .5.5V10h4.315a.5.5 0 1 1 0 1h-4.315v4.316a.5.5 0 1 1-1 0V11H5.528a.5.5 0 0 1 0-1h4.316V5.685a.5.5 0 0 1 .5-.5Z" 
      fill="#0F55E6"
    />
  </svg>
)

export default Plus2