import React, { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { getCarConfigFlows, getCarConfigFlowsCount, getTasks } from '../api/flow'
import { useChosenCompany, useChosenConsolidateInvoice, useUser } from "./UserProvider"
import { useParams } from 'react-router-dom'
import { CONFIRMATION } from "../pages/carConfiguration/hooks/useCarConfiguration"

const useConsolidateInvoiceQuery = () => {
  const chosenConsolidateInvoice = useChosenConsolidateInvoice()
  const code = chosenConsolidateInvoice?.ConsolidateCode !== "_ALL_" ? chosenConsolidateInvoice?.ConsolidateCode : null
  if (code) {
    return {
      'data.consolidateInvoiceId': `^${code}$`,
    }
  }
  return {}
}

const FlowContext = createContext({})

// This provider doesn't do anything right now since it is not initiated as a parent component, so it has no value by itself.
// If we later wish to preload flows when the user first loads in, initiate a selection of the hooks inside the provider
// and add this provider to the render function of AppProvider
const FlowProvider = ({ children }) => {
  return <FlowContext.Provider value={{}}>{children}</FlowContext.Provider>
}

export const useFlowsCount = (hasFetchedFlows) => {
  const { flowId } = useParams()
  const company = useChosenCompany()
  const user = useUser()
  const consolidateInvoiceId = useConsolidateInvoiceQuery()

  const { data: activeCount, refetch: aRefetch } = useQuery(
    ['activeCount', consolidateInvoiceId, hasFetchedFlows, user?.user?.roleType],
    () => getCarConfigFlowsCount(company?.id, { status: 'active', ...consolidateInvoiceId }),
    { enabled: !!company?.id && !flowId && hasFetchedFlows && user?.user?.roleType === 2 }
  )
  const { data: completedCount, refetch: cRefetch } = useQuery(
    ['completedCount', consolidateInvoiceId, hasFetchedFlows, user?.user?.roleType],
    () => getCarConfigFlowsCount(company?.id, { status: 'completed', ...consolidateInvoiceId }),
    { enabled: !!company?.id && !flowId && hasFetchedFlows && user?.user?.roleType === 2 }
  )

  return {
    activeCount: activeCount?.count,
    completedCount: completedCount?.count,
    refetchActiveCount: aRefetch,
    refetchCompleteCount: cRefetch,
  }
}

export const useFlow = (flowId, page = null, shouldPoll = false) => {
  const company = useChosenCompany()
  const { data: foundFlow, refetch } = useQuery([flowId], async () => {
    const { flows } = await getCarConfigFlows(company.id, {
      flowId,
    })
    return flows
  }, {
    ...(shouldPoll ? { refetchInterval: data => data?.configurationStep !== page ? 3000 : 15000 } : { })
  })

  return { flow: foundFlow?.[0], refetch, isPolling: shouldPoll && foundFlow?.[0].data?.configurationStep !== page }
}

export const useTasks = (flow, page) => {
  const { data: tasks, refetch } = useQuery(
    ['tasks', flow?.flowId, flow?.updatedAt],
    () => getTasks(flow?.flowId),
    {
      enabled: !!flow?.flowId,
      ...(page !== CONFIRMATION ? { refetchInterval: data => data?.tasks?.length > 0 || flow?.data?.configurationStep === CONFIRMATION ? 60000 : 5000 } : {})
    }
  )

  return { tasks, refetch }
}

export const defaultFlowRequestConfig = {
  active: {
    skip: 0,
    limit: 5,
    sort: 'createdAt',
    dir: '-1',
  },
  completed: {
    skip: 0,
    limit: 5,
    sort: 'createdAt',
    dir: '-1',
  },
}

export const useFlows = (flowConfig = defaultFlowRequestConfig) => {
  const { active, completed } = flowConfig

  const { flows: aFlows, refetch: aRefetch, error: aError } = useActiveFlows(active)
  const { flows: cFlows, refetch: cRefetch, error: cError } = useCompletedFlows(completed)

  return {
    active: {
      flows: aFlows,
      refetch: aRefetch,
      error: aError,
    },
    completed: {
      flows: cFlows,
      refetch: cRefetch,
      error: cError,
    },
    flowLoading: !aFlows && !cFlows,
  }
}

const useActiveFlows = (config = defaultFlowRequestConfig.active) => {
  const { flowId } = useParams()
  const company = useChosenCompany()
  const consolidateInvoiceId = useConsolidateInvoiceQuery()

  const {
    data: activeFlows,
    refetch: flowRefetch,
    error: flowDataError,
  } = useQuery(
    ['activeCarFlow', config?.skip, config?.limit, company?.id, consolidateInvoiceId],
    async () => {
      const { flows } = await getCarConfigFlows(company.id, {
        status: 'active',
        ...config,
        ...consolidateInvoiceId,
        view: 'landingPage'
      })
      return flows
    },
    { enabled: !!company.id && !flowId }
  )

  return {
    flows: activeFlows,
    refetch: flowRefetch,
    error: flowDataError,
  }
}

const useCompletedFlows = (config = defaultFlowRequestConfig.completed) => {
  const { flowId } = useParams()
  const company = useChosenCompany()
  const consolidateInvoiceId = useConsolidateInvoiceQuery()

  const {
    data: completedFlows,
    refetch: completedFlowRefetch,
    error: completedFlowDataError,
  } = useQuery(
    ['completedCarFlow', config?.skip, config?.limit, company?.id, consolidateInvoiceId],
    async () => {
      const { flows } = await getCarConfigFlows(company.id, {
        status: 'completed',
        ...config,
        ...consolidateInvoiceId,
        view: 'landingPage'
      })
      return flows
    },
    { enabled: !!company.id && !flowId }
  )

  return {
    flows: completedFlows,
    refetch: completedFlowRefetch,
    error: completedFlowDataError,
  }
}

export const useFlowSearch = (active, { field, value }) => {
  const company = useChosenCompany()
  const consolidateInvoiceId = useConsolidateInvoiceQuery()

  const { data, isLoading } = useQuery(
    ['searchFlow', active, value, consolidateInvoiceId],
    async () => {
      const { flows } = await getCarConfigFlows(company.id, {
        ...(active ?
          { status: 'active', ...consolidateInvoiceId } :
          { status: 'completed', ...consolidateInvoiceId }),
        // prefixing with ~ equals a regex contains search
        [field]: `~${value}`,
        sort: 'createdAt',
        dir: '-1',
      })
      return flows
    },
    { enabled: !!company?.id && value?.length > 0 }
  )

  return { flowSearchResults: data?.length > 0 ? data : null, searchLoading: isLoading }
}

export const useFlowContext = () => useContext(FlowContext)

export default FlowProvider
