import styled from 'styled-components'
import { theming } from '@staccx/bento'
import { i18nInstance } from '@staccx/i18n'
import { Card } from '../Card'
import Car2 from '../../../../icons/Car2'
import ChevronRight2 from '../../../../icons/ChevronRight2'

export const BudgetSelectionsList = ({ budgetSelections }) => {
  return (
    <ListContainer>
      {budgetSelections.map((flow) => (
        <Card
          key={flow._id}
          icon={<Car2 />}
          href={`/tasks/budget-selection/${flow.flowId}`}
          title={`${flow.data.budgetData.carMake.toUpperCase()} ${flow.data.budgetData.carModel.toUpperCase()} - ${i18nInstance.translate(
            'BUDGET'
          )} ${flow.data.budgetData.carBudgetNo}`}
          action={<ChevronRight2 />}
          isHoverable
        />
      ))}
    </ListContainer>
  )
}

const ListContainer = styled.div`
  margin-bottom: ${theming.spacing.medium()};
`
