import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Box = ({ children, className, variant, size, ...otherProps }) => {
  let boxStyles = {}

  // Apply variant styles
  if (variant === 'login') {
    boxStyles = {
      backgroundColor: 'var(--color-white)',
      borderRadius: '2px',
      boxShadow: '0 0 20px 5px rgba(0, 0, 0, 0.5)',
      padding: 'var(--spacing-large)',
      minWidth: '320px'
    }
  } else if (variant === 'lined') {
    boxStyles = {
      border: `1px solid var(--color-line)`,
      padding: 'var(--spacing-small)'
    }
  } else if (variant === 'graph') {
    boxStyles = {
      border: `1px solid var(--color-line)`,
      padding: '20px',
      margin: '20px'
    }
  } else if (variant === 'paddingTop') {
    boxStyles = {
      paddingTop: '22px',
      paddingLeft: '0',
      paddingRight: '0',
      paddingBottom: '0'
    }
  }

  return (
    <StyledBox className={className} size={size} style={boxStyles} {...otherProps}>
      {children}
    </StyledBox>
  )
}

const StyledBox = styled.div`
  ${({ size }) => size && `padding: var(--spacing-${size})`};
`

Box.defaultProps = {
  className: '',
  size: null,
  variant: 'default' // Add a default variant or remove this line if not needed
}

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string
}

export default Box
