import React, {useEffect} from 'react'
import Container from '../../../components/Container'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getFlow} from '../../../api/api'
import styled from "styled-components";

const SigningBatchLoader = () => {
  const {flowId} = useParams()

  const {
    data: flow,
    isLoading,
    error,
  } = useQuery(['signing-batch', flowId], () => getFlow(flowId), {
    // Polling interval in milliseconds (e.g., 5000 ms for every 5 seconds)
    refetchInterval: 5000,
    enabled: true,
  })

  useEffect(() => {
    if (!isLoading && flow?.data?.signing?.signingTask?.url) {
      window.location.href = flow.data.signing.signingTask.url
    }
  }, [isLoading, flow])

  return (
    <Container isLoading={true}>
        <CenteredBox>
          Vi oppretter din signeringsordre. Dette kan ta inntil 1 minutt. Du kan
          gå tilbake å fullføre signering når den er klar.
        </CenteredBox>
    </Container>
  )
}

export default SigningBatchLoader

const CenteredBox = styled.div`
    width: 100%;
    text-align: center;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
`;