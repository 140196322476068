import { css } from 'styled-components'
import { spacing, Text } from '@staccx/bento'

Text.createVariants({
  text: {
    lede: css`
      margin-bottom: ${spacing.small};
      display: inline-block;
    `,
    page: css`
      display: flex;
      align-items: center;
    `
  }
})

