import styled, { css } from 'styled-components'
import { Button, color, fontWeight, spacing, VARIANT_DEFAULT } from '@staccx/bento'
import { lighten } from 'polished'

const isIE = () => navigator.userAgent.match(/trident/i) !== null

const arrowLeft = isIE()
  ? css``
  : css`
      &::before {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' height='10' width='6' viewBox='0 0 6 10'%3e%3cpath fill='%23467EFF' d='M-.06 2.06L1.35.65l4.71 4.7-4.7 4.71-1.42-1.41 3.3-3.3z'/%3e%3c/svg%3e");
        padding-left: ${spacing.tiny};
        transition: 0.2s ease-out transform;
        transform: rotate(180deg);
      }
    `
export const arrowRight = isIE()
  ? css``
  : css`
      &::after {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' height='10' width='6' viewBox='0 0 6 10'%3e%3cpath fill='%23467EFF' d='M-.06 2.06L1.35.65l4.71 4.7-4.7 4.71-1.42-1.41 3.3-3.3z'/%3e%3c/svg%3e");
        padding-left: ${spacing.tiny};
        transition: 0.2s ease-out transform;
      }
    `

export const goto = css`
  background-color: transparent;
  min-height: auto;
  border-width: 0;
  color: ${color.text};
  display: flex;
  padding-left: 0;
  padding-right: 0;

  > svg {
    padding-right: ${spacing.tiny};
    color: ${color.wcag};
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: ${color.primary};

    &::after {
      transform: translateX(3px);
    }
  }
`

const buttonDisabled = css`
  &:disabled {
    background-color: ${color.disabled};
  }
}
`

Button.createVariants({
  button: {
    [VARIANT_DEFAULT]: css`
      ${buttonDisabled};
    `,
    secondary: css`
      background-color: transparent;
      color: ${color.primary};
      border: 1px solid ${color.primary};
      &:hover,
      &:focus,
      &:active {
        background-color: ${color.primary};
        color: ${color.white};
      }
    `,
    submenu: css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: transparent;
      border-left: 2px solid transparent;
      text-align: left;
      color: ${color.wcag};
      border-radius: 0;

      &:hover,
      &:focus,
      &:active {
        border-color: ${color.primary};
        background-color: ${color.subtleHover};
        color: ${color.black};
      }
    `,
    download: css`
      min-height: auto;
      border: 2px solid ${color.positive};
      line-height: 1;
      background-color: transparent;
      color: ${color.positive};
      padding-left: ${spacing.tiny};
      padding-right: ${spacing.tiny};
      padding-top: 0;
      padding-bottom: 0;
      display: flex;

      > span {
        padding-left: ${spacing.tiny};
      }

      &:hover,
      &:focus,
      &:active {
        background-color: ${color.positive};
        color: ${color.white};
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    `,
    goto: css`
      ${goto} ${arrowRight};
    `,
    status: css`
      ${goto} ${arrowRight}
      width: 30%;
    `,
    next: css`
      width: 30%;

      ${goto} ${arrowRight}
      &:disabled {
        color: ${color.disabled};
        cursor: text;
      }
    `,
    previous: css`
      width: 30%;

      ${goto} ${arrowLeft}
      &:disabled {
        color: ${color.disabled};
        cursor: text;
      }
    `,
    link: css`
      ${goto};
      line-height: ${spacing.tiny};
      text-align: right;
      width: 100%;
    `,
    filter: css`
      background-color: transparent;
      min-height: auto;
      border-width: 1px;
      padding: ${spacing.tiny} ${spacing.small};
      font-weight: ${fontWeight.normal};
      border-color: ${(p) => (p.active ? color('primary')(p) : 'transparent')};
      color: ${(p) => (p.active ? color('text')(p) : color('primary')(p))};

      &:hover,
      &:focus,
      &:active {
        border-color: ${color.primary};
        background: transparent;
        color: ${color.black};
      }
    `,
    hamburger: css`
      min-height: auto;
      border: 2px solid ${color.primary};
      line-height: 1;
      background-color: transparent;
      color: ${color.primary};
      padding-left: ${spacing.tiny};
      padding-right: ${spacing.tiny};
      padding-top: 0;
      padding-bottom: 0;
      display: flex;

      > span {
        padding-left: ${spacing.tiny};
      }

      &:hover,
      &:focus,
      &:active {
        background-color: ${color.primary};
        color: ${color.white};
      }

      @media only screen and (min-width: 1000px) {
        display: none;
      }
    `,
    toggleButton: css`
      display: flex;
      align-items: center;
      text-align: center;
      line-height: 17px;
      border: 1px solid ${color.primary};
      border-radius: 0;
      margin: 0 -1px 0 -1px;
      background-color: ${({ active }) => (active ? color.primary : color.white)};
      color: ${({ active }) => (active ? color.white : color.primary)};
    `,
    rounded: css`
      border-radius: 20px;
      width: 120px;
      height: 45px;
      transition: 0.3s;
      :hover {
        box-shadow: 4px 4px 4px var(--color-shadow);
      }
    `,
    warning: css`
      background-color: ${color.warning};
      width: 100%;

      &:hover,
      &:focus,
      &:active {
        background-color: ${color.warning};
      }
    `,
    warningNormal: css`
      background-color: ${color.warning};

      &:hover,
      &:focus,
      &:active {
        background-color: ${color.warning};
      }
    `,
    newEmployee: css`
      width: 25%;
      height: 45px;
      background-color: var(--color-primary);
      border-radius: var(--spacing-tinyMinus);
      border: none;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        box-shadow: var(--spacing-tinyMinus) var(--spacing-tinyMinus) var(--spacing-tinyMinus) var(--color-shadow);
      }
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: var(--fontWeight-bold);
      font-size: var(--font-text);
      color: var(--color-white);
      height: 100%;
    `,
    hidden: css`
      background-color: transparent;
      padding: 0;
      margin: 0;
      line-height: 0;
      &:focus,
      &:active,
      &:hover {
        background-color: transparent;
      }
    `,
  },
})

export const StyledButton = styled.button`
  background-color: var(--color-primary);
  color: var(--color-white);
  border-width: 0;
  border-radius: var(--borderRadius);
  padding: var(--spacing-tiny) var(--spacing-medium);
  cursor: pointer;
  min-height: var(--spacing-large);
  text-decoration: none;
  text-align: center;
  display: inline-block;
  font-size: var(--font-base);
  line-height: 32px;
  font-weight: var(--fontWeight-normal);
  white-space: nowrap;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const ExportButton = styled.button`
  background-color: var(--color-white);
  color: var(--color-primary);
  border-style: solid solid hidden solid;
  border-color: var(--color-primary);
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  border-width: 1px;
  padding: var(--spacing-tinyPlus);
  cursor: pointer;
  min-height: var(--spacing-large);
  text-decoration: none;
  text-align: center;
  display: inline-block;
  font-size: var(--font-text);
  line-height: 20px;
  font-weight: var(--fontWeight-normal);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    outline: none;
    background-color: var(--color-white);
    color: var(--color-primary);
  }
`

export const NewCarButton = styled.button`
  height: var(--spacing-huge);
  width: var(--spacing-hugePlus);
  color: var(--color-black);
  background-color: var(--color-primaryLight);
  border-width: 0;
  border-radius: var(--borderRadius);
  font-size: var(--font-medium);
  font-weight: var(--fontWeight-bold);

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: var(--color-black);
    background-color: var(--color-bg);
    box-shadow: 4px 4px 4px var(--color-line);
    transform: scale(1.02);
  }

  transition: 0.3s;
`

export const ReportButton = styled.button`
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.3fr;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: var(--spacing-tiny) var(--spacing-medium);
  color: var(--color-black);
  background-color: var(--color-primaryLight);
  border-radius: 12px;
  border-width: 0;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: var(--color-black);
    background-color: var(--color-bg);
    box-shadow: 4px 4px 4px var(--color-shadow);
    transform: scale(1.02);
  }

  transition: 0.3s;
`

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  display: block;
  float: right;
  height: var(--spacing-medium);
  width: var(--spacing-medium);
  border-radius: var(--spacing-micro);
  svg {
    color: var(--color-primary);
  }
  &:hover {
    background-color: var(--color-bgGray);
    cursor: pointer;
    outline: 1px solid var(--color-primary);
  }
`
