import React from 'react'
import styled from 'styled-components'
import Plus2 from '../../../icons/Plus2'

const AnchorButton = React.forwardRef(({ text, action, ...props }, ref) => {
  return (
    <StyledButton onClick={action} ref={ref} {...props}>
      <Row>
        <Column>
          <Text>
            <Plus2 />
            {text}
          </Text>
        </Column>
      </Row>
    </StyledButton>
  )
})

export default AnchorButton

const StyledButton = styled.button`
  width: 100%;
  height: 5rem;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-line);
  font-weight: var(--fontWeight-veryBold);
  border-radius: 12px;
  transition: 0.2s;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: var(--color-black);
    background-color: var(--color-subtleHover);
    box-shadow: 4px 4px 4px var(--color-shadow);
  }
  @media (min-width: 50px) {
    font-size: var(--font-text);
  }
  @media (min-width: 600px) {
    font-size: var(--font-text);
  }
  @media (min-width: 900px) {
    font-size: var(--font-base);
  }
`
const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  height: 100%;
  padding: var(--spacing-smallPlus);
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: var(--spacing-small);
`
