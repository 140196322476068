import React from "react"

const Excel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      fill="currentColor"
      d="M14.17 0v2.45h8.19c.46.02.97-.02 1.37.26.28.4.25.91.26 1.38v14.2c-.01.79.07 1.6-.1 2.38-.1.57-.78.58-1.24.6h-8.48V24A6620.5 6620.5 0 0 1 0 21.55V2.45L14.17 0zm-6.1 7.46c-.46 1.13-1 2.22-1.35 3.38-.34-1.1-.78-2.15-1.19-3.22l-1.8.1c.63 1.4 1.3 2.78 1.92 4.18-.72 1.37-1.4 2.75-2.1 4.12l1.8.06c.42-1.09.95-2.13 1.32-3.24.33 1.2.9 2.3 1.36 3.43.66.04 1.31.09 1.97.12l-2.27-4.57c.74-1.48 1.47-2.98 2.2-4.47l-1.85.11zm6.1-4.19v1.64h2.18v1.9h-2.18v1.1h2.18v1.9h-2.18v1.1h2.18v1.9h-2.18v1.1h2.18v1.9h-2.18v1.1h2.18v1.9h-2.18v1.64h9V3.27h-9zm3.27 1.64h3.82v1.9h-3.82v-1.9zm0 3h3.82v1.9h-3.82v-1.9zm0 3h3.82v1.9h-3.82v-1.9zm0 3h3.82v1.9h-3.82v-1.9zm0 3h3.82v1.9h-3.82v-1.9z"
    />
  </svg>
)

export default Excel
