import React from 'react'
import type { Fuel } from '../../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'
import { getConsolidateInvoiceDescription } from '../../../../../utils/getConsolidateInvoiceDescription'

export default function fuelReportData(chosenCompany?: string) {
  const columnHelper = createColumnHelper<Fuel>()

  return [
    columnHelper.accessor('tyear', {
      id: 'account',
      header: () => <TranslatedText i18nKey={'PERIOD'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }) => row?.original?.tyear + '-' + row?.original?.tmonth || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('consolidatedInvoiceAgreement', {
      id: 'consolidatedInvoiceAgreement',
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_AGREEMENT'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => getConsolidateInvoiceDescription(row.getValue(), chosenCompany) || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('amountIncVAT', {
      id: 'amountIncVAT',
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('volume', {
      id: 'volume',
      header: () => <TranslatedText i18nKey={'VOLUME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
  ]
}
