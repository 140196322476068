import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'
import { useChosenCompany, useChosenConsolidateInvoice, useUser } from '../../../context/UserProvider'
import { completeTask, createNewCarConfig, deleteCarConfig, updateCarConfig } from '../../../api/flow'
import customToast from '../components/toast/customToast'
import { useTasks } from '../../../context/FlowProvider'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import { categoriesFromSanity } from '../../../api/api'

// ROUTES
export const LANDING_PAGE = 'car-order'
export const CHOOSE_CAR = 'start'
export const CONFIGURE_BASE_CAR = 'engine-packages'
export const CONFIGURE_CAR_OPTIONS = 'options'
export const CONFIGURE_WHEEL_PACKAGES = 'wheel-packages'
export const CONFIGURE_AGREEMENTS = 'agreements'
export const SUMMARY = 'summary'
export const CONFIRMATION = 'confirmation'
export const NO_ACCESS = 'no-access'
export const UNKNOWN_ERROR = 'unknown-error'

export const ROUTES = [
  CHOOSE_CAR,
  CONFIGURE_BASE_CAR,
  CONFIGURE_CAR_OPTIONS,
  CONFIGURE_WHEEL_PACKAGES,
  CONFIGURE_AGREEMENTS,
  SUMMARY,
  CONFIRMATION,
]

export const routeKeys = ROUTES.reduce((acc, curr) => ({ ...acc, [curr]: `/car-config/${curr}` }), {})

const pageMapNext = {
  [CHOOSE_CAR]: CONFIGURE_BASE_CAR,
  [CONFIGURE_BASE_CAR]: CONFIGURE_CAR_OPTIONS,
  [CONFIGURE_CAR_OPTIONS]: CONFIGURE_WHEEL_PACKAGES,
  [CONFIGURE_WHEEL_PACKAGES]: CONFIGURE_AGREEMENTS,
  [CONFIGURE_AGREEMENTS]: SUMMARY,
  [SUMMARY]: CONFIRMATION,
}

const pageMapPrevious = {
  [SUMMARY]: CONFIGURE_AGREEMENTS,
  [CONFIGURE_AGREEMENTS]: CONFIGURE_WHEEL_PACKAGES,
  [CONFIGURE_WHEEL_PACKAGES]: CONFIGURE_CAR_OPTIONS,
  [CONFIGURE_CAR_OPTIONS]: CONFIGURE_BASE_CAR,
  [CONFIGURE_BASE_CAR]: CHOOSE_CAR,
}

export const navigablePages = Object.keys(pageMapNext)

export const PRICE_CALCULATION = 'could not find any relevant price.'
export const PRICE_CALCULATION_NO_ENGINE = 'price-calc-no-engine'
export const BODIES_REQUEST = '/car-config/bodies'
export const WHEEL_PACKAGES_REQUEST = '/wheel-packages'
export const AGREEMENTS_REQUEST = '/agreements/service'

export const errorMap = {
  [NO_ACCESS]: `error/${NO_ACCESS}`,
  [UNKNOWN_ERROR]: `error/${UNKNOWN_ERROR}`,
}

const errorRedirectExemptions = [PRICE_CALCULATION, PRICE_CALCULATION_NO_ENGINE, BODIES_REQUEST, NO_ACCESS]

const mapBrochureData = ({ vehiclesBuilder }) => {
  const fields = {
    co2Emission: null,
    mainServiceDistKm: 0,
    mainServicePeriodMonth: 0,
    interServiceDistKm: 0,
    interServicePeriodMonth: 0,
    noxEmission: 0,
    fuelConsumption: 0,
    firstRegistrationFee: 0,
    vatPrice: 0,
    chargable: false,
    EUClass: '',
  }

  const { vehiclePerformance, vehicleEbrochurePage } = vehiclesBuilder
  const { pageItem } = vehicleEbrochurePage

  const co2Item = pageItem.find(
    (item) => item.schemaId === 62203 && typeof item.dataValue === 'string' && item.dataValue.length > 0
  )
  const mainServiceDistKmItem = pageItem.find((item) => item.schemaId === 38802 && item.dataValue.length > 0)
  const mainServicePeriodMonthItem = pageItem.find((item) => item.schemaId === 38803 && item.dataValue.length > 0)
  const interServiceDistKmItem = pageItem.find((item) => item.schemaId === 38702 && item.dataValue.length > 0)
  const interServicePeriodMonthItem = pageItem.find((item) => item.schemaId === 38703 && item.dataValue.length > 0)
  const chargableItem = pageItem.find((item) => item.schemaId === 48602 && item.dataValue === 'P')
  const EUClassItem = pageItem.find((item) => item.schemaId === 7602 && item.dataValue.length > 0)

  if (co2Item) {
    fields.co2Emission = Number(co2Item.dataValue)
  }
  if (mainServiceDistKmItem) {
    fields.mainServiceDistKm = Number(mainServiceDistKmItem.dataValue)
  }
  if (mainServicePeriodMonthItem) {
    fields.mainServicePeriodMonth = Number(mainServicePeriodMonthItem.dataValue)
  }
  if (interServiceDistKmItem) {
    fields.interServiceDistKm = Number(interServiceDistKmItem.dataValue)
  }
  if (interServicePeriodMonthItem) {
    fields.interServicePeriodMonth = Number(interServicePeriodMonthItem.dataValue)
  }
  if (EUClassItem) {
    fields.EUClass = EUClassItem.dataValue
  }
  fields.chargable = Boolean(chargableItem)

  if (vehiclePerformance) {
    fields.noxEmission = vehiclePerformance.noxEmission
    fields.fuelConsumption =
      vehiclePerformance.fuelConsumptionKm42005Value === null ? 0 : vehiclePerformance.fuelConsumptionKm42005Value
    fields.firstRegistrationFee = vehiclePerformance.firstRegistrationFee
    fields.vatPrice = vehiclePerformance.vatPrice
  }

  return fields
}

const getTaskMap = ({ page, direction }) => {
  const taskMaps = {
    ...Object.keys(pageMapNext).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          taskType: 'configure-car',
          action: 'update',
        },
      }),
      {}
    ),
    [SUMMARY]: {
      taskType: 'configure-car',
      action: direction === 'next' ? 'complete' : 'update',
    },
  }

  return taskMaps[page]
}

const getNextPage = (direction, page, route) => {
  switch (direction) {
    case 'next':
      return pageMapNext[page]
    case 'skip':
      return route
    default:
      return pageMapPrevious[page]
  }
}

const useCarConfiguration = ({ flow, refetch, page, flowIsPolling }) => {
  const { tasks: tasksData } = useTasks(flow, page)
  const completionAttempts = useRef(0)
  const [hasGottenError, hasGottenErrorSet] = useState(false)
  const { data: standardEquipmentCategories } = useQuery(['standardEquipmentCategories'], () => categoriesFromSanity())
  const navigate = useNavigate()
  const { user } = useUser()
  const chosenCompany = useChosenCompany()
  const chosenConsolidateInvoice = useChosenConsolidateInvoice()
  const companyId = chosenCompany?.id
  const queryClient = useQueryClient()
  const [error, errorSet] = useState(null)
  const isRedirecting = useRef(false)
  const isAwaitingConfirmation = page === SUMMARY && completionAttempts.current > 0 && flowIsPolling
  const flowId = flow?.flowId
  const flowConfigurationStep = get(flow, 'data.configurationStep')
  const flowVehicleId = get(flow, 'data.carConfiguration.vehicleId')

  useEffect(() => {
    if (page === SUMMARY && completionAttempts.current > 0 && flow?.data?.coreError) {
      hasGottenErrorSet(true)
      customToast.error(i18nInstance.translate(flow?.data?.coreError?.sanityKey), {
        toastId: flow?.data?.coreError?.sanityKey,
      })
      customToast.clearQueue()
    } else {
      hasGottenErrorSet(false)
      customToast.clearToastById(flow?.data?.coreError?.sanityKey)
    }
  }, [flow?.data?.coreError, page, completionAttempts.current])

  useEffect(() => {
    const shouldRedirectUser =
      !errorMap[page] &&
      flowConfigurationStep !== page &&
      [...navigablePages, CONFIRMATION].includes(flowConfigurationStep)
    if (shouldRedirectUser) {
      isRedirecting.current = true
      navigate(`/car-order/${flowId}/${flowConfigurationStep}`)
    }
  }, [flowConfigurationStep])

  const errorHandler = (err) => {
    // Work-around for when react query (probably) cancels a refetch from saveAndNavigate after the user has been navigated to another page, which in turn creates another instance of this hook (and the same fetches)
    if (err?.constructor?.name === 'CancelledError') {
      return
    }

    if (!err) {
      errorSet(null)
      return
    }

    const errorData = get(err, 'response.data')
    const { errorType } = errorData || {}

    const url = err?.request?.responseURL ?? ''
    const hasQueryParams = url.includes('?')
    const uri = hasQueryParams
      ? url.slice(url.indexOf('/api') + 4, url.indexOf('?'))
      : url.slice(url.indexOf('/api') + 4)

    if (error || errorRedirectExemptions.includes(uri)) {
      return
    }

    if (errorType === NO_ACCESS) {
      errorSet(errorType)
      return
    }
    if (
      get(errorData, 'Result.Message', '').toLowerCase().includes(PRICE_CALCULATION) ||
      uri.includes('/car-config/calculate/monthly/')
    ) {
      errorSet(PRICE_CALCULATION)
      return
    }
    if (err === PRICE_CALCULATION_NO_ENGINE) {
      errorSet(PRICE_CALCULATION_NO_ENGINE)
      return
    }
    if (uri === WHEEL_PACKAGES_REQUEST) {
      errorSet(WHEEL_PACKAGES_REQUEST)
      return
    }
    if (error?.status === 400) {
      errorSet(UNKNOWN_ERROR)
      return
    }
    if (uri === AGREEMENTS_REQUEST) {
      errorSet(AGREEMENTS_REQUEST)
      return
    }
    if (!error && err !== PRICE_CALCULATION_NO_ENGINE) {
      errorSet(UNKNOWN_ERROR)
    }
  }
  const saveAndNavigate = useMutation(
    async ({
      carConfiguration,
      chosenDriver,
      chosenReplacingCar,
      vehicleType,
      prices,
      direction,
      formValidator = () => ({}),
      route,
      additionalInformation,
      customConfigurationName,
    }) => {
      if (direction === 'next' && Object.keys(formValidator()).length > 0) {
        hasGottenErrorSet(true)
        return
      }

      const mappedTask = getTaskMap({ page, direction })

      const nextPage = getNextPage(direction, page, route)

      const vehiclesBuilder = queryClient.getQueryData(['vehiclesbuilder', flowVehicleId, companyId, vehicleType])
      if (mappedTask.action === 'complete') {
        const matchingTask = tasksData.tasks.find((task) => task.taskType === mappedTask.taskType)
        if (matchingTask) {
          await completeTask({
            taskId: matchingTask.taskId,
            data: {
              configurationStep: nextPage,
              carConfiguration,
              accepted: direction === 'next',
              user: {
                id: user.id,
                roleType: user.roleType,
              },
              prices: {
                sumAccessoriesRetailPrice: prices.sumAccessoriesRetailPrice,
                sumOptionsWithoutResidualValueCalcPrice: prices.sumOptionsWithoutResidualValueCalcPrice,
                monthlyLeasingCost: prices.monthlyPrice,
              },
              mappedBrochureData: mapBrochureData({ vehiclesBuilder }),
              standardEquipment: vehiclesBuilder.vehicleStandardEquipment.filter((equipment) =>
                standardEquipmentCategories?.[0]?.standardEquipmentCategories?.includes(equipment.categoryName)
              ),
              additionalInformation,
              customConfigurationName,
            },
          })
          completionAttempts.current++
        }
      } else if (mappedTask.action === 'update') {
        const options = {
          flowId: flow.flowId,
          data: {
            configurationStep: nextPage,
            ...(chosenDriver ? { chosenDriver } : null),
            ...(chosenReplacingCar ? { chosenReplacingCar } : null),
            ...(vehicleType ? { vehicleType } : null),
            user: {
              id: user.id,
              roleType: user.roleType,
            },
            carConfiguration,
            additionalInformation,
            customConfigurationName,
          },
        }
        await updateCarConfig(options)
      }

      await refetch()
    },
    { onError: (err) => errorHandler(err) }
  )

  useEffect(() => {
    if (isRedirecting.current) {
      return
    }

    if (error) {
      switch (error) {
        case AGREEMENTS_REQUEST:
          break
        case WHEEL_PACKAGES_REQUEST:
          break
        case PRICE_CALCULATION:
          customToast.error(<TranslatedText i18nKey="CANNOT_CALCULATE_CONTANT_AUTOPLAN" />, {
            toastId: PRICE_CALCULATION,
          })
          break
        case PRICE_CALCULATION_NO_ENGINE:
          customToast.info(<TranslatedText i18nKey="CANNOT_CALCULATE_LEASING_BC_MOTOR" />, {
            toastId: PRICE_CALCULATION_NO_ENGINE,
            autoClose: false,
          })
          break
        case 'CORE_UNKNOWN_BUDGET_CREATION_ERROR':
          customToast.error('CORE_UNKNOWN_BUDGET_CREATION_ERROR')
          break
        default:
          customToast.error(<TranslatedText i18nKey="CANNOT_CALCULATE_DEFAULT" />, { toastId: UNKNOWN_ERROR })
      }
      customToast.clearQueue()
    } else {
      ;[UNKNOWN_ERROR, PRICE_CALCULATION, PRICE_CALCULATION_NO_ENGINE].forEach((err) => customToast.clearToastById(err))
      customToast.clearQueue()
    }

    if (errorMap[error] && !errorRedirectExemptions.includes(error)) {
      isRedirecting.current = true
      queryClient.cancelQueries().then(() => navigate(`/car-order/${flowId}/${errorMap[error]}`))
    }
  }, [error, flowConfigurationStep, navigate, page, queryClient, flow?.updatedAt, flowId])

  const restartCarConfig = useMutation('restartCarConfig', async () => {
    try {
      if (flowId) {
        await deleteCarConfig(flowId)
        createNewCarConfig({
          companyId,
          consolidateInvoiceId:
            chosenConsolidateInvoice?.ConsolidateCode === '_ALL_' ? null : chosenConsolidateInvoice?.ConsolidateCode,
          configurationStep: CHOOSE_CAR,
          user: {
            id: user?.id,
            roleType: user?.roleType,
          },
        }).then((data) => navigate(`/car-order/${data?.id}/${CHOOSE_CAR}`))
      }
    } catch (err) {
      errorHandler(err)
    }
  })

  const hasFailed = hasGottenError || saveAndNavigate.isError || restartCarConfig.isError

  return {
    companyId,
    flowVehicleId,
    flow: flow && flow.data,
    isRedirecting: isRedirecting.current,
    error,
    errorHandler,
    saveAndNavigate,
    restartCarConfig,
    refresh: flowConfigurationStep ? () => navigate(`/car-order/${flowId}/${flowConfigurationStep}`) : () => ({}),
    isLoading:
      saveAndNavigate.isLoading ||
      restartCarConfig.isLoading ||
      !flow ||
      flowIsPolling ||
      isAwaitingConfirmation ||
      (!saveAndNavigate.isIdle && !hasFailed),
  }
}

export default useCarConfiguration
