import React from 'react'
import styled from 'styled-components'
import AutoplanLogoBlue from '../../../icons/AutoplanLogoBlue'

const CarConfigContainer = ({ isLoading, children }) => {
  return (
    <CarConfig isLoading={isLoading}>
      {isLoading && (
        <Loader>
          <AutoplanLogoBlue />
        </Loader>
      )}
      {children}
    </CarConfig>
  )
}

const CarConfig = styled.div``

const Loader = styled.div`
  z-index: 100;
  background-color: rgba(245, 245, 245, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @keyframes move {
    from {
      left: 40%;
    }
    to {
      left: 60%;
    }
  }

  svg {
    z-index: 100;
    position: absolute;
    -webkit-animation: move 5s linear 0.2s infinite;
    animation: move 5s linear 0.2s infinite;
    width: var(--spacing-hugePlus);
    left: 40%;
    top: 35%;
  }
`

export default CarConfigContainer
