import React from 'react'
import styled from 'styled-components'

const Money = () => {
  return (
    <Style>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 110.19 122.88"
        width="40"
      >
        <path
          d="M78.62,111.75l4.86-24.67l4.92,7.42c10.6-4.26,16.55-11.27,17.44-22.07c8.71,15.22,3.42,28.87-7.61,36.86 l5.02,7.56L78.62,111.75L78.62,111.75L78.62,111.75L78.62,111.75z M0,86.55l69.88-69.88L106.21,53l-69.88,69.88L0,86.55L0,86.55z M42.44,59.08c5.91-5.91,15.47-5.91,21.39,0c5.91,5.91,5.91,15.47,0,21.39c-5.91,5.91-15.47,5.91-21.39,0 C36.52,74.55,36.52,64.99,42.44,59.08L42.44,59.08z M17.21,80.4l46.46-46.46c2.96,2.96,7.78,2.96,10.73,0L88.77,48.3 c-2.96,2.96-2.96,7.78,0,10.73L42.31,105.5c-2.96-2.96-7.78-2.96-10.73,0L17.21,91.14C20.17,88.18,20.17,83.36,17.21,80.4 L17.21,80.4L17.21,80.4z M47.94,5.1l-4.86,24.67l-4.92-7.42c-10.6,4.26-16.55,11.27-17.44,22.07c-8.71-15.22-3.42-28.87,7.61-36.86 L23.32,0L47.94,5.1L47.94,5.1L47.94,5.1L47.94,5.1z"
        />
      </svg>
    </Style>
  )
}

export default Money

const Style = styled.div`
  > svg {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: var(--color-primary);
  }
`
