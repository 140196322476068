import React from 'react'
import { i18nInstance } from '@staccx/i18n'
import { Button } from '../Button'
import { Card } from '../Card'
import { format } from 'date-fns'
import styled from 'styled-components'
import Warning from '../../../../icons/Warning'
import { deleteFlow } from '../../../../api/api'
import Loader from '../../../../components/Loader'
import { useMutation, useQueryClient } from 'react-query'
import { displayBudgetText } from '../displayBudgetText'

const BatchSigningList = ({ pendingBatchSignings, user }) => {
  const queryClient = useQueryClient()

  const handleClick = (flow) => {
    const signingUrl = flow.data.signing.signingTask.url
    if (signingUrl) {
      window.location.href = signingUrl
    }
  }

  const handleDelete = async (flow) => {
    const flowId = flow.flowId
    await mutation.mutateAsync(flowId)
  }

  const mutation = useMutation(deleteFlow, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pending-batch-signings'])
      queryClient.invalidateQueries(['pending-budgets'])
    },
  })

  return (
    <div>
      {mutation.isLoading && <Loader />}
      {pendingBatchSignings.map((flow) => {
        if (!flow.data?.signing) {
          return null
        }

        const expiresAt = new Date(flow.data.signing.signingTask.expiresAt)

        const warningDate = new Date(expiresAt)
        warningDate.setDate(expiresAt.getDate() - 7) // 7 days before deadline

        const warning = new Date().getTime() > warningDate.getTime()
        const expired = new Date().getTime() > expiresAt.getTime()

        const budgets = flow.data.budgets

        return (
          <Card
            key={flow.flowId}
            title={
              <FlexColumn>
                {Object.values(budgets).map((budget) => {
                  return <div>{displayBudgetText(budget)}</div>
                })}
              </FlexColumn>
            }
            action={
              <ButtonsContainer>
                <Button
                  type={'outline'}
                  onClick={() => handleDelete(flow)}
                  disabled={mutation.isLoading}
                >
                  Slett
                </Button>
                <Button
                  type={'solid'}
                  onClick={() => handleClick(flow)}
                  disabled={mutation.isLoading}
                >
                  Fullfør
                </Button>
              </ButtonsContainer>
            }
            subtitle={
              <SubtitleWrapper>
                <Text isError={expired}>
                  {i18nInstance.translate('DEADLINE') ?? 'Frist'}:{' '}
                  {format(expiresAt, 'dd.MM.yyyy')}
                </Text>
                {warning && !expired && (
                  <WarningWrapper>
                    <Warning />
                    <Text isError={true}> Utløper snart</Text>
                  </WarningWrapper>
                )}
              </SubtitleWrapper>
            }
          />
        )
      })}
    </div>
  )
}

export default BatchSigningList

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
`

const Text = styled.span`
  color: ${(props) => (props.isError ? 'var(--color-warning)' : 'inherit')};
`

const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
