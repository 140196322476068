import qs from "qs"
import { useLocation } from "react-router-dom"

export const createQueryParams = (obj, keepExisting = true) => {
  const { search: useLocationSearch } = useLocation()

  // To ensure we keep existing
  const search = keepExisting ? qs.parse(useLocationSearch, {
    ignoreQueryPrefix: true
  }) : {}
  // Overwrite or add status
  const params = new URLSearchParams({
    ...search,
    ...obj
  })

  // Hack for arrays
  for (let key in obj) {
    if(Array.isArray(obj[key])) {
      params.delete(key)
      for (const value of obj[key]) {
        params.append(key, value)
      }
    }
  }

  return params
}
