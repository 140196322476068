import React, { useState } from 'react'

import styled, { css } from 'styled-components'
import { i18nInstance } from '@staccx/i18n'
import { startBatchSigningProcess } from '../../../../api/api'
import { useNavigate } from 'react-router-dom'
import { Button } from '@staccx/bento'
import BudgetCheckboxListItem from './BudgetCheckboxListItem'
import { useMutation, useQueryClient } from 'react-query'
import {H4Heading} from "../../../../components/text/Headings";
import Alert from "../../../../components/Alert";

const BudgetCheckboxList = ({ pendingBudgets, user }) => {
  const queryClient = useQueryClient()
  let navigate = useNavigate()

  const [selectedBudgets, setSelectedBudgets] = useState([])
  const [error, setError] = useState(null)

  const startSigningProcess = async (flowIds) => {
    const payload = {
      budgetFlowIds: flowIds,
      signer: {
        email: user.user.contactDetails.mail,
        name: user.user.id,
      },
    }

    if (!payload.signer.email) {
      setError('Mangler e-postadresse for å kunne signere budsjettene.')
      setTimeout(() => {
        setError(null)
      }, 10000)
      return null
    }

    try {
      const { id } = await startBatchSigningProcess(payload)
      return id
    } catch (e) {
      setError('Noe gikk galt.')
      setTimeout(() => {
        setError(null)
      }, 10000)
      return null
    }
  }

  const mutation = useMutation(startSigningProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pending-batch-signings'])
      queryClient.invalidateQueries(['pending-budgets'])
    },
  })

  const handleCheckboxChange = (flowId) => {
    if (selectedBudgets.includes(flowId)) {
      setSelectedBudgets(selectedBudgets.filter((id) => id !== flowId))
    } else {
      setSelectedBudgets([...selectedBudgets, flowId])
    }
  }

  const handleSubmitAll = async () => {
    const signingFlowId = await mutation.mutateAsync(
      pendingBudgets.map((flow) => flow.flowId),
    )
    if (signingFlowId) {
      navigate(`/tasks/signing-batch/${signingFlowId}`)
    }
  }

  const handleSubmitSelected = async () => {
    const signingFlowId = await mutation.mutateAsync(selectedBudgets)
    if (signingFlowId) {
      navigate(`/tasks/signing-batch/${signingFlowId}`)
    }
  }

  return (
    <div>
      {error && (
        <FlexRow>
          <AlertWrapper>
            <Alert type="warning">
              {error}
            </Alert>
          </AlertWrapper>
        </FlexRow>
      )}

      <HeaderWrapper>
        <H4Heading>{'Start signering'}</H4Heading>
        <ButtonsContainer>
          <Button
            variant="secondary"
            onClick={handleSubmitAll}
            disabled={mutation.isLoading}
          >
            Signer alle
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitSelected}
            disabled={mutation.isLoading || selectedBudgets.length === 0}
          >{`Signer valgte ${
            selectedBudgets.length > 0 ? `(${selectedBudgets.length})` : ''
          }`}</Button>
        </ButtonsContainer>
      </HeaderWrapper>

      {pendingBudgets.map((flow) => (
        <BudgetCheckboxListItem
          flow={flow}
          key={flow.flowId}
          selectedValues={selectedBudgets}
          handleChange={handleCheckboxChange}
        />
      ))}
    </div>
  )
}

export default BudgetCheckboxList

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`

const AlertWrapper = styled.div`
  margin-bottom: 10px;
  width: 450px;
`
