import React from 'react'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '../../../../utils/tableFuncs'

export default function carListData() {
  
  return [
    {
      accessorKey: 'registrationNumber',
      id: 'registrationNumber',
      header: () => <TranslatedText i18nKey={'CAR_ID'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }: any) => getValue() || '',
      meta: { alignmentType: 'string' },
    },
    {
      accessorKey: 'driver.name',
      id: 'driver.name',
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }: any) => getValue() || '',
      meta: { alignmentType: 'string' },
    },
    {
      accessorKey: 'status',
      id: 'status',
      header: () => <TranslatedText i18nKey={'STATUS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }: any) => {
        const status = row.original.status 
        let statusTable = status+'_table'
        return status ? <TranslatedText i18nKey={statusTable.toUpperCase()}>{(value: any) => <span>{value}</span>}</TranslatedText> : ''
      },
      meta: { alignmentType: 'string' },
    },
    {
      accessorKey: 'car',
      id: 'car',
      header: () => <TranslatedText i18nKey={'CAR'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }: any) => row.original.make + ' ' + row.original.model || '',
      meta: { alignmentType: 'string' },
    },
    {
      accessorKey: 'maturity',
      id: 'maturity',
      header: () => <TranslatedText i18nKey={'MATURITY'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }: any) => getValue() || '',
      meta: { alignmentType: 'string' },
    },
    {
      accessorKey: 'RETURN_DATE',
      id: 'RETURN_DATE',
      header: () => <TranslatedText i18nKey={'RETURN_DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }: any) => {
        const { plannedReturnDate, deliveryDate } = row.original
        const date = plannedReturnDate || deliveryDate
        return date ? formatDate(date) : ''
      },
      meta: { alignmentType: 'date' },
    },
    {
      accessorKey: 'CAR_REPLACE_HEADER',
      id: 'CAR_REPLACE_HEADER',
      header: () => <TranslatedText i18nKey={'CAR_REPLACE_HEADER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }: any) => {
        const { replaces, replacement } = row.original
        return replaces ? replaces.registrationNumber : replacement ? replacement.budgetNo : ''
      },
      meta: { alignmentType: 'string' },
    },
  ]
}
