import React from 'react';
const Trashcan = () => {
  return <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16675 3.125H20.8334V5.20833H4.16675V3.125Z" fill="#467EFF"/>
    <path
      d="M17.7085 5.20898L14.5835 2.08398H10.4168L7.29183 5.20898H17.7085ZM5.2085 7.29232V20.834C5.2085 21.9794 6.1464 22.9173 7.29183 22.9173H17.7085C18.8539 22.9173 19.7918 21.9794 19.7918 20.834V7.29232H5.2085ZM10.4168 20.834H8.3335V9.37565H10.4168V20.834ZM16.6668 20.834H14.5835V9.37565H16.6668V20.834Z"
      fill="#467EFF"/>
  </svg>
}
export default Trashcan
