import React, { useState } from 'react'
import { css } from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDebounce } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { CHOOSE_CAR, navigablePages, SUMMARY } from '../hooks/useCarConfiguration'
import AnchorButton from '../components/AnchorButton'
import Plus from '../../../icons/Plus'
import { defaultFlowRequestConfig, useFlows, useFlowsCount, useFlowSearch } from '../../../context/FlowProvider'
import { createNewCarConfig, deleteCarConfig, updateCarConfig } from '../../../api/flow'
import { useChosenCompany, useChosenConsolidateInvoice, useUserContext } from "../../../context/UserProvider"
import Container from '../../../components/Container'
import ActiveCarConfigurations from '../components/ActiveCarConfigurations'
import CompletedCarConfigurations from '../components/CompletedCarConfigurations'

function LandingPage() {
  const { userData: user } = useUserContext()
  const chosenCompany = useChosenCompany()
  const consolidateInvoice = useChosenConsolidateInvoice()
  const navigate = useNavigate()

  const [activeConfig, activeConfigSet] = useState(defaultFlowRequestConfig.active)
  const [completedConfig, completedConfigSet] = useState(defaultFlowRequestConfig.completed)

  const [activeSearch, activeSearchSet] = useState({ value: '' })
  const [debouncedActive] = useDebounce(activeSearch, 800)
  const [completedSearch, completedSearchSet] = useState({ value: '' })
  const [debouncedCompleted] = useDebounce(completedSearch, 800)
  const [isLoading, isLoadingSet] = useState(false)

  const { active, completed } = useFlows({ active: activeConfig, completed: completedConfig })
  const { activeCount, refetchActiveCount, completedCount, refetchCompleteCount } = useFlowsCount((active?.flows?.concat(completed?.flows) || []).length > 0)

  const { flowSearchResults: filteredActive, searchLoading: activeSearchLoading } = useFlowSearch(true, debouncedActive)
  const { flowSearchResults: filteredCompleted, searchLoading: completedSearchLoading } = useFlowSearch(
    false,
    debouncedCompleted
  )

  const handleOpenConfiguration = (flow) => {
    const step = flow.data.configurationStep
    const { flowId } = flow
    if (!step) {
      navigate(`/car-order/${flowId}/${CHOOSE_CAR}`)
    }
    const route = navigablePages.includes(step)
    if (route) {
      navigate(`/car-order/${flowId}/${step}`)
    }
  }

  const handleCreateNewConfiguration = async ({ flow, step }) => {
    isLoadingSet(true)
    const flowId = flow?.flowId
    await createNewCarConfig({
      configurationStep: step,
      companyId: chosenCompany?.id,
      consolidateInvoiceId:
        consolidateInvoice?.ConsolidateCode === '_ALL_' ? null : consolidateInvoice?.ConsolidateCode,
      ...(flowId && {
        flowId,
        isDuplicateConfiguration: true,
        configurationStep: SUMMARY,
      }),
      user: {
        id: user?.user?.id,
        roleType: user?.user?.roleType,
      },
    }).then((data) => navigate(`/car-order/${data?.id}/${flowId ? SUMMARY : CHOOSE_CAR}`))
    isLoadingSet(false)
  }

  const handleRefresh = async () => {
    isLoadingSet(true)
    await Promise.all([active?.refetch(), completed?.refetch(), refetchActiveCount(), refetchCompleteCount()])
    isLoadingSet(false)
  }

  const handleDeleteConfiguration = async ({ flowId }) => {
    isLoadingSet(true)
    if (flowId) {
      await deleteCarConfig(flowId)
      await handleRefresh()
    }
    isLoadingSet(false)
  }

  const saveFlow = useMutation(
    'customConfigurationName',
    async ({ flowId, customConfigurationName, currentConfigurationName }) => {
      if (flowId && (customConfigurationName || customConfigurationName !== currentConfigurationName)) {
        await updateCarConfig({ flowId, data: { customConfigurationName } })
      }
    },
    { onSuccess: () => handleRefresh() }
  )

  const numActiveItems = activeSearch?.value ? filteredActive?.length || 0 : active?.flows?.length || 0
  const numMaxActiveItems = activeSearch?.value ? filteredActive?.length || 0 : activeCount || active?.flows?.length || 0

  const numCompletedItems = completedSearch?.value ? filteredCompleted?.length || 0 : completed?.flows?.length || 0
  const numMaxCompletedItems = completedSearch?.value ? filteredCompleted?.length || 0 : completedCount || 0

  return (
    <Container
      isLoading={isLoading}
      css={css`
        display: flex;
        flex-direction: column;
        gap: var(--spacing-medium);
      `}
    >
      <div>
        <TranslatedText i18nKey={'MAKE_NEW_CAR'}>
          {(value) => (
            <AnchorButton
              text={value}
              id="nyBil"
              action={async () => await handleCreateNewConfiguration({ step: CHOOSE_CAR })}
              icon={<Plus width="40" fill="#2a6df4" />}
            />
          )}
        </TranslatedText>
      </div>
      <ActiveCarConfigurations
        numActiveItems={numActiveItems}
        numMaxActiveItems={numMaxActiveItems}
        activeSearch={activeSearch}
        activeSearchSet={activeSearchSet}
        filteredActive={filteredActive}
        active={active}
        activeSearchLoading={activeSearchLoading}
        handleOpenConfiguration={handleOpenConfiguration}
        handleDeleteConfiguration={handleDeleteConfiguration}
        saveFlow={saveFlow}
        activeConfigSet={activeConfigSet}
      />
      <CompletedCarConfigurations
        numCompletedItems={numCompletedItems}
        numMaxCompletedItems={numMaxCompletedItems}
        completedSearch={completedSearch}
        completedSearchSet={completedSearchSet}
        filteredCompleted={filteredCompleted}
        completed={completed}
        completedSearchLoading={completedSearchLoading}
        handleOpenConfiguration={handleOpenConfiguration}
        handleCreateNewConfiguration={handleCreateNewConfiguration}
        completedConfigSet={completedConfigSet}
      />
    </Container>
  )
}

export default LandingPage
