import React from 'react'
import styled from "styled-components"
import { TranslatedText } from "@staccx/i18n"

export default ({ car, ...rest }) => {
  return <TextContainer { ...rest }>
    <TextBold>{car.description}</TextBold>
    <div>
      <TranslatedText i18nKey='REG_NO_SHORT'>
        {value => <TextBold>{value}: </TextBold>}
      </TranslatedText>
      <Text>{car.registrationNumber}</Text>
    </div>
    <div>
      <TranslatedText i18nKey='ACCOUNT_NO'>
        {value => <TextBold>{value}: </TextBold>}
      </TranslatedText>
      <Text>{car.accountNo}</Text>
    </div>
    {car.driverName && <div>
      <TranslatedText i18nKey='DRIVER'>
        {value => <TextBold>{value}: </TextBold>}
      </TranslatedText>
      <Text>{car.driverName}</Text>
    </div>}
    <div>
      <TranslatedText i18nKey='PLANNED_RETURN_DATE'>
        {value => <TextBold>{value}: </TextBold>}
      </TranslatedText>
      <Text>{car?.plannedReturnDate && new Date(car.plannedReturnDate).toLocaleDateString().split('T')[0]}</Text>
    </div>
  </TextContainer>
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Text = styled.span`
  font-size: var(--font-base);
  line-height: 1.5em;
`

const TextBold = styled(Text)`
  font-weight: var(--fontWeight-bold)
`
