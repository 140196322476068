import React from "react"
const AnchorIcon = (props) => (
  <svg
    width="16"
    height="32"
    viewBox="0 0 21 36"
    fill="none"
    {...props}
  >
    <path
      d="M2 34L18 18L2 2"
      stroke="currentColor"
      strokeWidth="4"
      {...props}
    />
  </svg>
)
export default AnchorIcon
