import CustomSkeleton from './skeleton'
import React from 'react'
import { css } from 'styled-components'

type TableSkeletonProps = {
  colSpan: number
}

export function TableSkeleton({ colSpan }: TableSkeletonProps) {
  const n = 3

  return (
    <>
      {[...Array(n)].map((e, i) => (
        <tr key={`loading-table-row-${i}`}>
          <td colSpan={colSpan}>
            <CustomSkeleton
              skeletons={[
                {
                  height: 'var(--spacing-large)',
                  count: 1,
                },
              ]}
              theme={css`
                line-height: 0;
                gap: var(--spacing-micro);
                grid-template-columns: 1fr;
                padding: 0;
              `}
            />
          </td>
        </tr>
      ))}
    </>
  )
}
