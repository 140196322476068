import React from 'react'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import { ExportButton } from '../../../theme/Theme.Button'
import Excel from '../../../icons/Excel'
import { useReportDownloader } from '../../../api/api'

const ExcelButton = () => {
  let download = useReportDownloader()

  return (
    <ExportButton onClick={() => download()}>
      <Row>
        <Column>
          <Excel />
        </Column>
        <Column>
          <TranslatedText i18nKey="SAVE_EXCEL" />
        </Column>
      </Row>
    </ExportButton>
  )
}

export default ExcelButton

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 var(--spacing-tinyMinus);
`
