import React from "react"

const Parking = ()=> (
  <svg 
    width="36" 
    height="36" 
    fill="none"
  >
    <path 
      d="M21.64 14.3c0 2.04-1.46 3.24-3.8 3.24-.64 0-1.461-.142-1.84-.24v-5.679c.32-.082 1.3-.242 2.18-.242 2.199 0 3.46 1.101 3.46 2.922Z" fill="#467EFF"/><path d="M32 0H4C1.8 0 0 1.8 0 4v28c0 2.2 1.8 4 4 4h28c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4Zm-7.8 18.898c-1.5 1.442-3.72 2.082-6.321 2.082-.578 0-1.48-.101-1.879-.16V28h-4V8.422C13.36 8.18 15.262 8 17.96 8c2.72 0 4.661.54 5.962 1.602 1.238 1 2.078 2.636 2.078 4.578 0 1.941-.64 3.601-1.8 4.718Z" 
      fill="#467EFF"/>    
  </svg>
)

export default Parking