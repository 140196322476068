import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Label, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import { fetchTollReport } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import TollReportTable from '../components/TollTables/TollReport'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import { Heading } from '../../../components/text/Headings'

const TollReport = () => {
  const [selectedYear, selectedYearSet] = useState(new Date().getFullYear().toString())

  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], status } = useQuery(['tollReport', companyId], () => fetchTollReport(company.id), {
    enabled: !!companyId
  })

  const reportYears = useMemo(() => {
    return [...new Set(data.map((el) => el.tYear))]
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const filterFunc = agreementFilter('consolidatedInvoiceAgreement')
  const items = reportYears.map((year) => ({ value: year, label: year }))

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'TOLLREPORT_HEADING'}>{(value) => <Heading>{value}</Heading>}</TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="PERIOD">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  options={items}
                  onChange={(selectedItem) => selectedYearSet(selectedItem.value)}
                  value={{ value: selectedYear, label: selectedYear }}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <TollReportTable
        isLoading={status === 'loading'}
        data={data.filter((el) => el.tYear === selectedYear).filter(filterFunc)}
      />
    </Container>
  )
}

export default TollReport
