// This is a temporary solution using json representation of excel found in https://trello.com/c/2eToqsKQ/166-aut-166-norskmontert-utstyr-statisk-l%C3%B8sning
const excelExport = [
  {
    AccessoriesID: "NMU-1",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "E350",
    Beskrivelse: "Skvettlapper foran",
    Obligatorisk: "",
    Pris: "2500"
  },
  {
    AccessoriesID: "NMU-2",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "E351",
    Beskrivelse: "Skvettlapper bak",
    Obligatorisk: "",
    Pris: "2500"
  },
  {
    AccessoriesID: "NMU-3",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "Loyds",
    Beskrivelse: "Loyds Led- Belysning ",
    Obligatorisk: "",
    Pris: "1250"
  },
  {
    AccessoriesID: "NMU-4",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Interiør",
    Utstyrskode: "Loyds",
    Beskrivelse: "Utvidet varevegg Transporter",
    Obligatorisk: "",
    Pris: "2500"
  },
  {
    AccessoriesID: "NMU-5",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "E499",
    Beskrivelse: "Lazer ekstralys grillmontert",
    Obligatorisk: "",
    Pris: "17000"
  },
  {
    AccessoriesID: "NMU-6",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Interiør",
    Utstyrskode: "Loyds",
    Beskrivelse: "Gulvplate med hjulhusbeskyttelse",
    Obligatorisk: "",
    Pris: "9000"
  },
  {
    AccessoriesID: "NMU-7",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "E540",
    Beskrivelse: "Led Varsellysbjelke på tak",
    Obligatorisk: "",
    Pris: "19000"
  },
  {
    AccessoriesID: "NMU-7",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "E440",
    Beskrivelse: "Terkselbeskyttelse Folie",
    Obligatorisk: "",
    Pris: "1700"
  },
  {
    AccessoriesID: "NMU-8",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "E449",
    Beskrivelse: "Lastkantbeskyttelse",
    Obligatorisk: "",
    Pris: "3100"
  },
  {
    AccessoriesID: "NMU-9",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "E444",
    Beskrivelse: "Panserbeskyttelse",
    Obligatorisk: "",
    Pris: "2800"
  },
  {
    AccessoriesID: "NMU-10",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "G210",
    Beskrivelse: "Lasteholder Thule",
    Obligatorisk: "",
    Pris: "7200"
  },
  {
    AccessoriesID: "NMU-11",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Volkswagen",
    Modell: "Transporter",
    Motor: "2.0DSG",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Interiør",
    Utstyrskode: "E425",
    Beskrivelse: "Setetrekk HTS Active",
    Obligatorisk: "",
    Pris: "5000"
  },
  {
    AccessoriesID: "NMU-12",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Montere fr. bøyle Antec sort standard, Vito",
    Obligatorisk: "",
    Pris: "7 770"
  },
  {
    AccessoriesID: "NMU-13",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Montere fr. bøyle Antec sort bred, Vito",
    Obligatorisk: "",
    Pris: "7 770"
  },
  {
    AccessoriesID: "NMU-14",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Montere fr. bøyle Antec blank standard, Vito",
    Obligatorisk: "",
    Pris: "7 770"
  },
  {
    AccessoriesID: "NMU-15",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Montere fr. bøyle Antec blank bred, Vito",
    Obligatorisk: "",
    Pris: "7 770"
  },
  {
    AccessoriesID: "NMU-16",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "",
    Beskrivelse: "Lazer Grillkit for Vito m. adaptiv - m. ST4 EVO kombo",
    Obligatorisk: "",
    Pris: "12 975"
  },
  {
    AccessoriesID: "NMU-17",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "",
    Beskrivelse: "Lazer Linear 18 Elite LED fjernlys på skiltbrakett",
    Obligatorisk: "",
    Pris: "12 595"
  },
  {
    AccessoriesID: "NMU-18",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "",
    Beskrivelse: "Lazer Linear 18 Elite LED fjernlys mont. på bøyle ( bøyle kommer i tillegg )",
    Obligatorisk: "",
    Pris: "11 795"
  },
  {
    AccessoriesID: "NMU-19",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Lys",
    Utstyrskode: "",
    Beskrivelse: "2 x 1000-metere runde 9\" led. Vito og Sprinter ( evt. bøyle i tilegg )",
    Obligatorisk: "",
    Pris: "12 200"
  },
  {
    AccessoriesID: "NMU-20",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Bakfangerbeskyttelse Vito, sort pvc",
    Obligatorisk: "",
    Pris: "1 199"
  },
  {
    AccessoriesID: "NMU-21",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Panserbeskytter Vito, GAMER-DF017",
    Obligatorisk: "",
    Pris: "1 961"
  },
  {
    AccessoriesID: "NMU-22",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Skvettlapper foran Vito",
    Obligatorisk: "x",
    Pris: "800"
  },
  {
    AccessoriesID: "NMU-23",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Skvettlapper bak Vito",
    Obligatorisk: "x",
    Pris: "1 200"
  },
  {
    AccessoriesID: "NMU-24",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "3m steinsprutpakke, Vito",
    Obligatorisk: "",
    Pris: "800"
  },
  {
    AccessoriesID: "NMU-25",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Setetrekk 3-seter Vito, kunstskinn,- GAMER-ST002 ( Ikke i kombinasjon med SA8 )",
    Obligatorisk: "",
    Pris: "4 000"
  },
  {
    AccessoriesID: "NMU-26",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Setetrekk 2-seter Vito, kunstskinn. GAMER-ST001 ( Ikke i kombinasjon med SA8 )",
    Obligatorisk: "",
    Pris: "3 680"
  },
  {
    AccessoriesID: "NMU-27",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Thule WingbBar Evo 2. stk. lasteholdere på bil uten rail, Vito",
    Obligatorisk: "",
    Pris: "4 027"
  },
  {
    AccessoriesID: "NMU-28",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Thule WingBar Evo 3 stk. lasteholdere på bil uten rail, Vito",
    Obligatorisk: "",
    Pris: "6 041"
  },
  {
    AccessoriesID: "NMU-29",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Thule WingBar Evo 4 stk. lasteholdere på bil uten rail, Vito",
    Obligatorisk: "",
    Pris: "8 054"
  },
  {
    AccessoriesID: "NMU-30",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Mercedes-Benz",
    Modell: "Vito",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Interiør",
    Utstyrskode: "",
    Beskrivelse: "Vannvarmer m. vifte i varerom v. motordrift. ( Vito )",
    Obligatorisk: "",
    Pris: "11 000"
  },
  {
    AccessoriesID: "NMU-31",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Skoda",
    Modell: "Enyaq",
    Motor: "iV80X",
    Drivstoffype: "Elektrisk",
    Bilkategori: "PERSONBIL",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "SKVETTLAPPER BAK",
    Obligatorisk: "",
    Pris: "1 016"
  },
  {
    AccessoriesID: "NMU-32",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Skoda",
    Modell: "Enyaq",
    Motor: "iV80X",
    Drivstoffype: "Elektrisk",
    Bilkategori: "PERSONBIL",
    Kategori: "Interiør",
    Utstyrskode: "",
    Beskrivelse: "BAGASJEROMSMATTE",
    Obligatorisk: "",
    Pris: "1 182"
  },
  {
    AccessoriesID: "NMU-33",
    Kontaktnr: "",
    Kontaktnavn: "",
    Merke: "Skoda",
    Modell: "Enyaq",
    Motor: "iV80X",
    Drivstoffype: "Elektrisk",
    Bilkategori: "PERSONBIL",
    Kategori: "Interiør",
    Utstyrskode: "",
    Beskrivelse: "GUMMIMATTER FORAN OG BAK",
    Obligatorisk: "",
    Pris: "636"
  },
  {
    AccessoriesID: "NMU-34",
    Kontaktnr: "KT033276",
    Kontaktnavn: "Forsvaret",
    Merke: "Ford",
    Modell: "Ranger",
    Motor: "",
    Drivstoffype: "Diesel",
    Bilkategori: "VARE<3500",
    Kategori: "Eksteriør",
    Utstyrskode: "",
    Beskrivelse: "Ekstralys 1000m Kat 7",
    Obligatorisk: "",
    Pris: "14 760",
    EksternLeverandor: "602086"
  }
]

export const getNorskMontertUtstyr = ({ makeNameLocal, modelNameLocal }) => {
  return excelExport
    .filter(({ Merke, Modell }) => Merke === makeNameLocal && Modell === modelNameLocal)
    .map(({ AccessoriesID, Beskrivelse, Pris , EksternLeverandor}) => ({
        optionId: AccessoriesID,
        optionName: Beskrivelse,
        optionType: NORWEGIAN_MOUNTED_ACCESSORIES,
        optionTypeName: NORWEGIAN_MOUNTED_ACCESSORIES,
        price: Number.parseInt(Pris),
        thirdPartyEquipment: !!EksternLeverandor,
      }))
}

export const NORWEGIAN_MOUNTED_ACCESSORIES = "NORWEGIAN_MOUNTED_ACCESSORIES"
