import React from 'react'
import {  Layout } from '@staccx/bento'
import { Heading } from '../../../components/text/Headings'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import FleetReportTable from '../components/FleetTables/FleetReport'
import ReportTermSelect from '../components/ReportTermSelect'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useQuery } from 'react-query'
import { fetchFleetReport, fetchFleetReportTerms } from '../../../api/api'
import { useUserContext } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const FleetReport = () => {
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id
  const { data: terms = [] } = useQuery(['fleetReportTerms', companyId], () => fetchFleetReportTerms(companyId), {
    enabled: !!companyId
  })

  const { data = [], status } = useQuery(
    ['fleetReport', companyId, fleetReportTerm],
    () => fetchFleetReport(companyId, fleetReportTerm, 'fleet'),
    { enabled: !!companyId }
  )
  const agreementFilter = useFilterOnConsolidateAgreement()

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'FLEETREPORT_HEADING'}>
          {(value) => <Heading>{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>

      <FleetReportTable
        data={data
          .filter(agreementFilter('consolidatedAgreementID'))
          .filter((el) => el?.termDate?.startsWith(fleetReportTerm))}
        isLoading={status === 'loading'}
      />
    </Container>
  )
}

export default FleetReport
