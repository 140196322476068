import React from 'react';
import styled from "styled-components";
// @ts-ignore
import {TranslatedText, i18nInstance} from '@staccx/i18n';
import {SmallHeading} from "../../../../components/text/Headings";

type RemarksProps = {
  notes: string;
  setNotes: (notes: string) => void;
};

const Remarks: React.FC<RemarksProps> = ({ notes, setNotes }) => {
  return (
    <Column>
      <Row>
        <SmallHeading><TranslatedText i18nKey="CAR_CONFIG_ORDER_ADDITIONAL_INFO_NOTES_HEADING"/></SmallHeading>
      </Row>
      <Row>

        <TranslatedText i18nKey="CAR_CONFIG_ORDER_ADDITIONAL_INFO_NOTES">
          {(value: string) => (

            <TextArea
              placeholder={value}
              onChange={(e) => {
              // @ts-ignore
                if (e.target.value.length <= 200) {
                // @ts-ignore
                  setNotes(e.target.value)
              }
            }}
              value={notes }
              >
              {notes}
            </TextArea>
            )}
        </TranslatedText>
      </Row>
      <Row>
        {notes.length > 0 ? `${notes.length}/200`: ''}
      </Row>
    </Column>
  );
};

export default Remarks;


const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: var(--spacing-large);

    @media only screen and (max-width: 1219px) {
        width: 100%;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: var(--spacing-tinyMinus) 0;
    width: 100%;

    > div.react-select-container {
        width: 100%;
    }
`

const TextArea = styled.textarea<React.HTMLProps<HTMLTextAreaElement>>`
    font-size: var(--font-base);
    color: var(--color-text);
    resize: vertical;
    border: 1px solid var(--color-line);
    border-radius: var(--spacing-micro);
    width: 100%;
    height: 80px;
    max-height: 150px;
    padding: var(--spacing-tiny);
    white-space: pre-line;

    :focus {
        border: 2px solid var(--color-primary);
    }

    transition: 0.2s linear;
`