import React from "react"
import { Route, Routes } from "react-router-dom"
import LoginChoice from "../routes/LoginChoice"
import Login from "../routes/Login"
import LoginWrapper from "./LoginWrapper"

export const LOGIN_PATHNAME = "/login"

const Authorization = () => {
  return (
    <LoginWrapper>
        <Routes>
          <Route path={"*"} element={<LoginChoice/>} />
          <Route path={"loginsms"} element={<Login type={"sms"} />} />
          <Route path={"loginemail"} element={<Login type={"email"} />} />
          <Route path={"callback-login"} element={<Login />} />
          <Route path={"magiclink"} element={<Login type={"email"} />} />
        </Routes>
    </LoginWrapper>
  )
}

Authorization.propTypes = {}

export default Authorization
