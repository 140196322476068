import fuelReduce from "../../utils/fuelReduce"
import { useCarContext } from "../../context/CarProvider"

const FuelTypeFilterCount = ({ filter, children }) => {
  const { allCars } = useCarContext()

  const count = allCars
    ? allCars.reduce(fuelReduce(filter), 0)
    : ""
  return children({ count })
}
export default FuelTypeFilterCount
