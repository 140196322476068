import React from 'react'
import { useQuery } from 'react-query'
import { Label, Layout, LayoutItem, Text } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import { useParams } from 'react-router-dom'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { fetchTollReportDetails } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import TollReportDetailsTable from '../components/TollTables/TollReportDetails'
import { Heading } from '../../../components/text/Headings'

const TollReportDetails = () => {
  const { year, month } = useParams()
  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], isLoading } = useQuery(
    ['tollReportDetails', companyId, year, month],
    () => fetchTollReportDetails(company.id, year, month),
    { enabled: !!companyId }
  )
  const agreementFilter = useFilterOnConsolidateAgreement()
  const filterFunc = agreementFilter('consolidatedInvoiceAgreement')

  return (
    <Container width="unlimited">
      <TranslatedText i18nKey={'TOLLREPORT_DETAILS_HEADING'}>{(value) => <Heading>{value}</Heading>}</TranslatedText>
      <Layout variant="fiftyFifty">
        <LayoutItem>
          <TranslatedText i18nKey="PERIOD">{(value) => <Label>{value}</Label>}</TranslatedText>
          <Text>
            {year}-{month}
          </Text>
        </LayoutItem>

        <LayoutItem>
          <ConsolidateInvoiceSelect />
        </LayoutItem>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <TollReportDetailsTable isLoading={isLoading} options={{ year, month }} data={data.filter(filterFunc)} />
    </Container>
  )
}

export default TollReportDetails
