import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;

    .content {
        visibility: hidden;
    }

    :hover {
        .content {
            visibility: visible;
        }
    }
`

const Trigger = styled.div`
    position: relative;
    cursor: pointer;
`

const Content = styled.div`
    z-index: 100;
    width: 250px;
    word-break: break-word;
    text-overflow: ellipsis;

    padding: var(--spacing-smallPlus);
    margin: var(--spacing-tinyPlus);
    background-color: var(--color-bg);
    border: 2.5px solid var(--color-primary);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: absolute;
    ${(props) =>
            props.contentAlign === 'top'
                    ? css`
                        bottom: calc(100% + 20px);
                    `
                    : css`
                        top: calc(100% + 20px);
                    `};
`

const ArrowBase = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 10px solid transparent;
`

const TopArrow = styled(ArrowBase)`
    bottom: -21px;
    border-top-color: var(--color-primary);
`

const TopArrowInner = styled.div`
    position: absolute;
    bottom: -14.8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: var(--color-bg);
`

const BottomArrow = styled(ArrowBase)`
    top: -21px; 
    border-bottom-color: var(--color-primary);
`

const BottomArrowInner = styled.div`
    position: absolute;
    top: -14.8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: var(--color-bg);
`

export default ({ trigger, children, contentAlign = 'top' }) => {
  return (
    <Wrapper>
      <Trigger className="trigger">{trigger}</Trigger>
      <Content className="content" contentAlign={contentAlign}>
        {contentAlign === 'top' ? (
          <>
            <TopArrow />
            <TopArrowInner />
          </>
        ) : (
          <>
            <BottomArrow />
            <BottomArrowInner />
          </>
        )}
        {children}
      </Content>
    </Wrapper>
  )
}
