import React from 'react'

interface ChassisProps {
  width?: number
  height?: number
  color?: string
}

const Chassis = ({
  width = 50,
  height = 50,
  color = 'black',
}: ChassisProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4377_10610)">
      <path
        d="M13.222 31.1133C13.222 32.8315 11.8291 34.2244 10.1109 34.2244C8.39267 34.2244 6.99977 32.8315 6.99977 31.1133C6.99977 29.3951 8.39267 28.0022 10.1109 28.0022C11.8291 28.0022 13.222 29.3951 13.222 31.1133Z"
        fill={color}
      />
      <path
        d="M41.2221 34.2244C42.9404 34.2244 44.3333 32.8315 44.3333 31.1133C44.3333 29.3951 42.9404 28.0022 41.2221 28.0022C39.5039 28.0022 38.111 29.3951 38.111 31.1133C38.111 32.8315 39.5039 34.2244 41.2221 34.2244Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.797378 23.0863C0.781754 23.1818 0.775312 23.2774 0.777522 23.3718V45.1133C0.777522 45.9724 1.47397 46.6688 2.33308 46.6688H14.7776C15.6367 46.6688 16.3331 45.9724 16.3331 45.1133V40.4466H34.9999V45.1133C34.9999 45.9724 35.6963 46.6688 36.5555 46.6688H49C49.8591 46.6688 50.5555 45.9724 50.5555 45.1133V23.3719C50.5577 23.2774 50.5513 23.1818 50.5356 23.0862C50.5203 22.9907 50.4962 22.898 50.4642 22.8091L44.9631 6.30601C44.3279 4.39566 42.5401 3.11328 40.5346 3.11328H10.7985C8.78931 3.11328 7.00516 4.39712 6.3699 6.30601L0.86883 22.8091C0.836865 22.8981 0.812768 22.9907 0.797378 23.0863ZM9.32209 7.28766C9.53338 6.65226 10.1262 6.22439 10.7985 6.22439H40.5346C41.2047 6.22439 41.8003 6.6542 42.0113 7.28875L46.8417 21.7799H4.49131L9.32209 7.28766ZM38.111 40.4466H47.4444V43.5577H38.111V40.4466ZM47.4444 37.3355H3.88865V24.8911H47.4444V37.3355ZM3.88865 40.4466H13.222V43.5577H3.88865V40.4466Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4377_10610">
        <rect
          width="49.778"
          height="49.7778"
          fill="white"
          transform="translate(0.780029)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Chassis
