export const safeNumber = value => {
  if (typeof value === "string" && /^[0-9]+(\.[0-9]+)?/.test(value)) {
    return parseFloat(value)
  }

  if (typeof value === "number") {
    return value
  }

  return 0
}

export default safeNumber