import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCar } from '../../../context/CarProvider'
import Container from '../../../components/Container'
import styled from 'styled-components'
import CarCardNew from '../components/CarCardNew'
import Flex from '../../../components/Flex'
import { Car, CarStatus } from '../../../types'
import MileageCard from '../components/MileageCard'
import DriverInput from '../components/DriverInput'
import AgreementDetailsCard from '../components/AgreementDetailsCard'
import ServiceCard from '../components/ServiceCard'
import EventFilter from '../components/EventFilter'
import EventListTable from '../components/EventListTables'
import BannersAndToasts from '../components/BannersAndToasts'

const CarDetailsNew = () => {
  const { id } = useParams()
  const car: Car = useCar(id)

  const [currentFilter, currentFilterSet] = useState('ALL')
  const { accountNo } = car || {}

  if (!car) {
    return null
  }

  return (
    <Container isLoading={!car} renderChildrenDuringLoading={false}>
      <Flex direction={'column'} gap={'var(--spacing-hugeMinus)'}>
        <div style={{ width: '100%' }}>
          <Flex direction={'column'} gap={'var(--spacing-mediumPlus)'}>
            <BannersAndToasts car={car} />

            <TilesSection>
              <Flex gap={'var(--spacing-largeMinus)'} justify={'center'}>
                <TilesSectionItem>
                  <Flex
                    direction={'column'}
                    gap={'var(--spacing-mediumPlus)'}
                    justify={'flex-start'}
                  >
                    <CarCardNew car={car} />
                    {car.status && car.status !== CarStatus.Incoming && (
                      <MileageCard car={car} />
                    )}
                  </Flex>
                </TilesSectionItem>

                <TilesSectionItem>
                  <Flex
                    direction={'column'}
                    gap={'var(--spacing-mediumPlus)'}
                    justify={'flex-start'}
                  >
                    <DriverInput car={car} driver={car?.driver || null} />
                    <AgreementDetailsCard car={car} />
                    {[CarStatus.Active, CarStatus.Outgoing].includes(car?.status) && (
                      <ServiceCard car={car} />
                    )}
                  </Flex>
                </TilesSectionItem>
              </Flex>
            </TilesSection>
          </Flex>
        </div>

        <EventSection>
          <EventFilter
            accountNo={accountNo}
            currentFilter={currentFilter}
            setFilter={currentFilterSet}
            showCarFilter={true}
          />
          <EventListTable
            carsData={[car]}
            currentFilter={currentFilter}
            isLoading={car === null}
          />
        </EventSection>
      </Flex>
    </Container>
  )
}

export default CarDetailsNew

const TilesSection = styled.div`
  width: 100%;
`

const EventSection = styled.div`
  width: 100%;
`

const TilesSectionItem = styled.div`
  flex: 1;
`
