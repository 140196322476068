import React from 'react'
import { createGlobalStyle } from 'styled-components'

export default () => (
  <>
    <GlobalLoader />
    <div className="fullPageLoadingWrapper">
      <svg width="186" height="143" viewBox="0 0 186 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.300049 142.3V119.6L162.4 72.5L162.8 72.4V69.2L0.300049 22.1V0L185.4 56.7V85.6L0.300049 142.3Z"
          fill="#2a6df4"
        />
        <mask
          id="mask0_2991_24103"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="186"
          height="143"
        >
          <path d="M0.300049 119.6V142.3L185.4 85.6V56.7L0.300049 0V22.1V119.6Z" fill="#2a6df4" />
        </mask>
        <g mask="url(#mask0_2991_24103)">
          <rect className="fullPageLoader" x="0" y="0" width="19" height="128" fill="#2a6df4" />
        </g>
      </svg>
    </div>
  </>
)

const GlobalLoader = createGlobalStyle`
  .fullPageLoadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgba(245, 245, 245, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .fullPageLoader {
    animation: fullPageLoadingMove 1.5s ease-out infinite;
    will-change: transform;
  }

  @keyframes fullPageLoadingMove {
    from {
      transform: translateX(-20px);
    }
    to {
      transform: translateX(160px);
    }
  }
`
