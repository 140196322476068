import React from "react"

const Driver = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="20px" height="20px" viewBox="0 0 100.000000 100.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
       fill="#2a6df4" stroke="none">
      <path d="M435 986 c-130 -58 -153 -245 -42 -343 88 -77 216 -42 268 72 71 156
-79 336 -226 271z" />
      <path d="M390 574 c-88 -15 -153 -39 -175 -63 -26 -28 -159 -231 -194 -296
-28 -53 -27 -66 15 -146 l36 -69 64 0 64 0 0 33 c0 80 75 177 169 221 65 29
197 29 262 0 94 -44 169 -141 169 -221 l0 -33 64 0 64 0 37 71 c29 56 36 77
30 102 -3 18 -52 104 -108 192 l-102 160 -70 23 c-53 18 -98 25 -185 27 -63 2
-126 2 -140 -1z" />
      <path d="M419 187 c-80 -30 -139 -99 -139 -162 l0 -25 220 0 220 0 0 28 c0 44
-41 104 -92 135 -56 35 -151 46 -209 24z" />
    </g>
  </svg>

)

export default Driver
