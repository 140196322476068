import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import type { Mileage } from '../../../../../types'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '@staccx/bento'

export default function mileageReportDetailsTableData() {
  const columnHelper = createColumnHelper<Mileage>()
  return [
    columnHelper.accessor('MileageReadTime', {
      id: 'MileageReadTime',
      header: () => <TranslatedText i18nKey={'MILEAGE_READ_TIME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
      meta: { alignmentType: 'date' },
    }),
    columnHelper.accessor('LapsedMonths', {
      id: 'LapsedMonths',
      header: () => <TranslatedText i18nKey={'LAPSED_MONTHS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('RegistreredMileage', {
      id: 'RegistreredMileage',
      header: () => <TranslatedText i18nKey={'REGISTERED_MILEAGE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('AveragePerMonths', {
      id: 'AveragePerMonths',
      header: () => <TranslatedText i18nKey={'AVERAGE_PER_MONTHS'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('EstimatedEndMileage', {
      id: 'EstimatedEndMileage',
      header: () => (
        <TranslatedText i18nKey={'ESTIMATED_END_MILEAGE'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('EstimatedEndDeviation', {
      id: 'EstimatedEndDeviation',
      header: () => (
        <TranslatedText i18nKey={'ESTIMATED_END_DEVIATION'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('ChangesToMaxMileage', {
      id: 'ChangesToMaxMileage',
      header: () => (
        <TranslatedText i18nKey={'CHANGES_TO_MAX_MILEAGE'}>{(value: string) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' },
    }),
  ]
}
