import React from "react"

const Invoice = () => (
  <svg 
    width="32" 
    height="40" 
    fill="none" 
  >
    <path 
      d="M20 0H4C1.8 0 0 1.8 0 4v32c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V12L20 0Zm-2 34H8v-4h10v4Zm6-6H8v-4h16v4Zm0-6H8v-4h16v4Zm-6-8V3l11 11H18Z" 
      fill="#467EFF"
    />
  </svg>
)

export default Invoice
