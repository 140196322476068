import styled, { css } from "styled-components"
import { borderRadius, color, ItemGroup } from "@staccx/bento"

ItemGroup.createVariants({
    group:
      {
        overview: css`
          justify-content: space-around;
          background-color: ${color.gray};
        `,
        pagination: css`
          justify-content: center;
          margin-top: 24px;
        `,
        paginationLeft: css`
          justify-content: left;

          > button:last-child {
            background-color: #D4D8DD;
            color: ${color.black};
          }
        ;
          margin-top: 20px;
        `,
        even: css`
          justify-content: flex-start;
          gap: var(--spacing-mediumMinus);
          padding: var(--spacing-tiny) 0;
        `,
        transaction: css`
          "borderLeft": "1px solid #E4E2E2",
          "borderRight": "1px solid #E4E2E2",
          "height": ${({ transactionsActive }) => (transactionsActive ? "auto" : "10px")}
        `,
        spaced: css`
          justify-content: space-between;
          gap: var(--spacing-tiny);
        `
      },
    wrapper: {
      overview: css`
        border-radius: ${borderRadius.normal};
      `
    }
  }
)

export const StyledItemGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: var(--spacing-small);

  > button:last-child {
    background-color: #D4D8DD;
    color: var(--color-black);
  }
`

export const RightAlignedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-small);
`