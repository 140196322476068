import { useMemo, useState } from "react"

export const usePagination = ({data, items = 10 }) => {
  const [currentPage, pageSet] = useState(0)

  const results = useMemo(() => {
    if(data?.length < currentPage * items) {
      return data
    }
    return data.slice(currentPage * items, (currentPage + 1) * items)
  }, [data, items, currentPage])
  
  const pageCount = Math.ceil(data?.length / items)

  const next = () => {
    if (currentPage >= pageCount) {
      pageSet(pageCount)
    }
    pageSet(currentPage + 1)
  }

  const prev = () => {
    if (currentPage < 0) {
      pageSet(0)
    }
    pageSet(currentPage - 1)
  }

  return {
    results,
    next,
    prev,
    pageCount, 
    currentPage
  }
}
