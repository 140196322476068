import { useMemo } from 'react'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import format from 'date-fns/format'
import { isOverMileage, notHasReplacementCar } from '../../../context/CarProvider'
import { differenceInCalendarMonths } from 'date-fns'
import { outgoingMonths } from '../../../context/CarProvider'

const SIGNING_NOTIFICATION_COLOR = '#E5EEFF'
const SIGNING_NOTIFICATION_FONT_COLOR = '#0D4BCC'

const CAR_NOTIFICATION_COLOR = '#FE9789'
const CAR_NOTIFICATION_FONT_COLOR = '#903209'

const CAR_NOTIFICATION_SECONDARY_COLOR = '#FFE4CC'
const CAR_NOTIFICATION_SECONDARY_FONT_COLOR = '#CB6201'

export const useNotifications = (pendingSignings, budgetSelections, allCars) => {
  const carNotifications = useCarNotifications(allCars)

  const notifications = useMemo(() => {
    const signingNotification = pendingSignings &&
      pendingSignings.length > 0 && {
        type: <TranslatedText i18nKey={'SIGNING_NOTIFICATION_TYPE'} />,
        title: `${pendingSignings.length} ${i18nInstance.translate('SIGNING_NOTIFICATION_TITLE')}`,
        message: `${i18nInstance.translate('DEADLINE')}: ${format(
          new Date(pendingSignings[0].createdAt).setMonth(new Date(pendingSignings[0].createdAt).getMonth() + 1),
          'dd.MM.yyyy'
        )}`,
        color: SIGNING_NOTIFICATION_COLOR,
        fontColor: SIGNING_NOTIFICATION_FONT_COLOR,
        path: '/tasks'
      }

    const budgetSelectionNotification = budgetSelections &&
      budgetSelections.length > 0 && {
        type: <TranslatedText i18nKey={'BUDGET_SELECTION_NOTIFICATION_TYPE'} />,
        title: <TranslatedText i18nKey={'BUDGET_SELECTION_NOTIFICATION_TITLE'} />,
        color: SIGNING_NOTIFICATION_COLOR,
        fontColor: SIGNING_NOTIFICATION_FONT_COLOR,
        path: '/tasks'
      }

    return [signingNotification, budgetSelectionNotification, ...carNotifications].filter(Boolean)
  }, [pendingSignings, budgetSelections, carNotifications])

  return notifications
}

const now = new Date()

const isOutgoing = (car) => {
  const date = new Date(car.plannedReturnDate)
  const difference = differenceInCalendarMonths(date, now)
  return difference <= outgoingMonths
}

const useCarNotifications = (allCars) =>
  useMemo(() => {
    if (!allCars) return []

    const notifications = allCars
      .filter((car) => !car.actualReturnDate)
      .reduce(
        (notifications, car) => {
          const overMileage = isOverMileage(car)
          if (overMileage) {
            notifications.mileageOverLimit.push({ ...car, type: 'mileageOverLimit' })
          }

          const returnDateLessThanSixMonths = isOutgoing(car)
          if (returnDateLessThanSixMonths) {
            notifications.returnDateLessThanSixMonths.push({ ...car, type: 'returnDateLessThanSixMonths' })
          }

          const noReplacementCar = notHasReplacementCar(car)
          if (noReplacementCar) {
            notifications.unclarified.push({ ...car, type: 'unclarified' })
          }

          return notifications
        },
        {
          mileageOverLimit: [],
          returnDateLessThanSixMonths: [],
          unclarified: []
        }
      )

    const carNotifications = []

    if (notifications.mileageOverLimit.length > 1) {
      carNotifications.push({
        type: <TranslatedText i18nKey={'MILEAGE_OVER_LIMIT'} />,
        title: (
          <TranslatedText i18nKey={'CARS_OVER_MILEAGE'}>
            {(value) => `${notifications.mileageOverLimit.length} ${value}`}
          </TranslatedText>
        ),
        color: CAR_NOTIFICATION_COLOR,
        fontColor: CAR_NOTIFICATION_FONT_COLOR,
        path: '/cars?isOverMileage=true'
      })
    } else if (notifications.mileageOverLimit.length === 1) {
      const car = notifications.mileageOverLimit[0]
      carNotifications.push({
        type: <TranslatedText i18nKey={'MILEAGE_OVER_LIMIT'} />,
        title: (
          <TranslatedText i18nKey={'CAR_OVER_MILEAGE'}>
            {(value) => `${car.make} ${car.model} ${car.registrationNumber} ${value}`}
          </TranslatedText>
        ),
        message: car.mileage + ' / ' + car.contractMileage,
        color: CAR_NOTIFICATION_COLOR,
        fontColor: CAR_NOTIFICATION_FONT_COLOR,
        path: '/cars/' + car.registrationNumber
      })
    }

    if (notifications.returnDateLessThanSixMonths.length > 1) {
      carNotifications.push({
        type: <TranslatedText i18nKey={'IS_OUTGOING'} />,
        title: (
          <TranslatedText i18nKey={'CARS'}>
            {(value) => `${notifications.returnDateLessThanSixMonths.length} ${value}`}
          </TranslatedText>
        ),
        message: <TranslatedText i18nKey={'OUTGOING'} />,
        color: CAR_NOTIFICATION_SECONDARY_COLOR,
        fontColor: CAR_NOTIFICATION_SECONDARY_FONT_COLOR,
        path: '/cars?status=outgoing'
      })
    } else if (notifications.returnDateLessThanSixMonths.length === 1) {
      const car = notifications.returnDateLessThanSixMonths[0]
      carNotifications.push({
        type: <TranslatedText i18nKey={'IS_OUTGOING'} />,
        message: <TranslatedText i18nKey={'OUTGOING'} />,
        title: car.make + ' ' + car.model + ' (' + car.registrationNumber + ')',
        date: car.plannedReturnDate,
        color: CAR_NOTIFICATION_SECONDARY_COLOR,
        fontColor: CAR_NOTIFICATION_SECONDARY_FONT_COLOR,
        path: '/cars/' + car.registrationNumber
      })
    }

    if (notifications.unclarified.length > 1) {
      carNotifications.push({
        type: <TranslatedText i18nKey={'UNCLARIFIED_CARS'} />,
        title: (
          <TranslatedText i18nKey={'CARS_UNCLARIFIED'}>
            {(value) => `${notifications.unclarified.length} ${value}`}
          </TranslatedText>
        ),
        message: <TranslatedText i18nKey={'ORDER_REORDER_RETURN'} />,
        color: CAR_NOTIFICATION_SECONDARY_COLOR,
        fontColor: CAR_NOTIFICATION_SECONDARY_FONT_COLOR,
        path: '/cars?hasReplacementCar=false'
      })
    } else if (notifications.unclarified.length === 1) {
      const car = notifications.unclarified[0]
      carNotifications.push({
        type: <TranslatedText i18nKey={'UNCLARIFIED_CARS'} />,
        title: (
          <TranslatedText i18nKey={'IS_UNCLARIFIED'}>{(value) => `${car.registrationNumber} ${value}`}</TranslatedText>
        ),
        message: <TranslatedText i18nKey={'ORDER_REORDER_RETURN'} />,
        color: CAR_NOTIFICATION_SECONDARY_COLOR,
        fontColor: CAR_NOTIFICATION_SECONDARY_FONT_COLOR,
        path: '/cars/' + car.registrationNumber
      })
    }

    return carNotifications
  }, [allCars])
