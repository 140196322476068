import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { format, startOfMonth } from 'date-fns'
import { fetchUserWithDetails } from '../api/api'
import featureToggles from '../autoplan_config'
import { i18nInstance } from "@staccx/i18n"

const UserContext = createContext({})

function UserProvider({ children }) {
  const queryClient = useQueryClient()
  const { data: userData } = useQuery('user', () => fetchUserWithDetails())

  const [consolidateInvoice, consolidateInvoiceSet] = useState(null)
  const [fleetReportTerm, fleetReportTermSet] = useState(localStorage.getItem('fleetReportTerm'))
  const [environmentReportYear, environmentReportYearSet] = useState(localStorage.getItem('environmentReportYear'))

  const derived = useMemo(() => {
    if (!userData) {
      return {}
    }
    const savedCompanyId = localStorage.getItem('companyId')

    const savedCompany = userData.user.companies.find((c) => c.id === savedCompanyId)

    const candidateChosenCompany =
      savedCompany ||
      (userData.chosenCompany.id && userData.chosenCompany) ||
      userData.user.companies.find((c) => !!c.id)
    localStorage.setItem('companyId', candidateChosenCompany.id)

    const newVar = {
      chosenCompany: candidateChosenCompany,
      roleType: userData.user.roleType,
      isDriver: userData.user.adminIsDriver || userData.user.roleType === 1,
      hasConfigurationAccess: userData.user.configuratorAccess,
    }
    return newVar
  }, [userData])

  useEffect(() => {
    if (Object.keys(derived).length === 0) return

    const savedConsolidateInvoiceId = JSON.parse(localStorage.getItem('consolidateInvoice'))
    const savedConsolidateInvoiceCode = savedConsolidateInvoiceId && savedConsolidateInvoiceId.ConsolidateCode

    const consolidateId = derived.chosenCompany.consolidateAgreement.find(
      (a) => a.ConsolidateCode === savedConsolidateInvoiceCode
    ) || { ConsolidateCode: '_ALL_', ConsolidateDescription: 'ALL' }

    consolidateInvoiceSet(consolidateId)
    localStorage.setItem('consolidateInvoice', JSON.stringify(consolidateId))
  }, [derived])

  const handleSetConsolidateInvoice = (selectedConsolidateInvoice) => {
    const consolidateObject =
      selectedConsolidateInvoice?.ConsolidateCode !== '_ALL_'
        ? derived.chosenCompany.consolidateAgreement.find((a) => a.ConsolidateCode === selectedConsolidateInvoice.ConsolidateCode)
        : { ConsolidateCode: selectedConsolidateInvoice?.ConsolidateCode, ConsolidateDescription: i18nInstance.translate("ALL") }

    consolidateInvoiceSet(consolidateObject || consolidateInvoice)
    localStorage.setItem('consolidateInvoice', JSON.stringify(consolidateObject || consolidateInvoice))
  }

  const handleFleetReportTerm = (term) => {
    const savedFleetReportTerm = localStorage.getItem('fleetReportTerm')
    if (savedFleetReportTerm !== term) {
      localStorage.setItem('fleetReportTerm', term)
      fleetReportTermSet(term)
    }
  }

  const handleEnvironmentReportYear = (year) => {
    const savedEnvReportYear = localStorage.getItem('environmentReportYear')
    const yearString = year.toString()
    if (savedEnvReportYear !== yearString) {
      localStorage.setItem('environmentReportYear', yearString)
      environmentReportYearSet(yearString)
    }
  }

  if (!fleetReportTerm) {
    fleetReportTermSet(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  }

  if (!environmentReportYear) {
    environmentReportYearSet(new Date().getFullYear().toString())
  }

  return (
    <UserContext.Provider
      value={{
        ...derived,
        consolidateInvoice,
        fleetReportTerm,
        environmentReportYear,
        handleSetConsolidateInvoice,
        handleFleetReportTerm,
        handleEnvironmentReportYear,
        userData,
        featureToggles,
        updateCurrentUser: () => queryClient.invalidateQueries('user').then(() => console.log('Updating userdata')),
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)

export const useUser = () => {
  const { userData } = useUserContext()
  return userData
}

export const useChosenCompany = () => {
  const { chosenCompany } = useContext(UserContext)
  return chosenCompany
}

export const useFeatureToggles = () => {
  const { featureToggles } = useContext(UserContext)
  return featureToggles
}

export const useChosenConsolidateInvoice = () => {
  const { consolidateInvoice } = useContext(UserContext)
  return consolidateInvoice
}

export default UserProvider
