import React from 'react'
import styled from 'styled-components'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import Close from '../../../../icons/Close'
import { RightAlignedRow } from '../../../../theme/Theme.ItemGroup'
import Carousel from './Carousel'

const ImageDialog = ({ children, openSet, isOpen, photos }) => {
  const SLIDE_COUNT = photos.length
  const slides = Array.from(Array(SLIDE_COUNT).keys())

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={openSet}>
      <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
      <Overlay />
      <DialogContent>
        <RightAlignedRow>
          <DialogPrimitive.DialogClose asChild>
            <Close />
          </DialogPrimitive.DialogClose>
        </RightAlignedRow>
        <Carousel slides={slides} photos={photos} />
      </DialogContent>
    </DialogPrimitive.Root>
  )
}

export default ImageDialog

const DialogContent = styled(DialogPrimitive.Content)`
  width: 40vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);
  border-radius: var(--spacing-small);
  padding: var(--spacing-mediumMinus);
  box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, 0.12);
  z-index: 1000;

  :focus {
    outline: unset;
  }
`
const Overlay = styled(DialogPrimitive.Overlay)`
  background-color: rgb(248, 248, 248, 0.8);
  position: fixed;
  inset: 0;
  z-index: 1000;
`
