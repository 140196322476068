import React, { useMemo, useState } from 'react'
import { Label, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import PurchaseReportTable from '../components/PurchaseTables/PurchaseReportTable'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useQuery } from 'react-query'
import { fetchPurchaseReport } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import { Heading } from '../../../components/text/Headings'

const PurchaseReport = () => {
  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], status } = useQuery(['purchaseReport', companyId], () => fetchPurchaseReport(companyId), {
    enabled: !!companyId
  })

  const [year, yearSet] = useState(new Date().getFullYear().toString())

  const reportYears = useMemo(() => {
    return [...new Set(data.map((el) => el.tyear))]
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const items = reportYears.map((year) => ({ value: year, label: year }))
  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'PURCHASEREPORT_HEADING'}>{(value) => <Heading>{value}</Heading>}</TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="PERIOD">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  options={items}
                  onChange={(selectedItem) => yearSet(selectedItem.value)}
                  value={{ value: year, label: year }}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <PurchaseReportTable
        isLoading={status === 'loading'}
        data={data.filter((el) => el.tyear === year).filter(agreementFilter('consolidatedInvoiceAgreement'))}
      />
    </Container>
  )
}

export default PurchaseReport
