import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Notification from './Notification'
import { TranslatedText } from '@staccx/i18n'
import LinkButton from '../../../components/LinkButton'

const NotificationView = ({ notifications }) => {
  const [showAllNotifications, showAllNotificationsSet] = useState(false)

  const fetchedNotifications = useMemo(() => {
    if (showAllNotifications) return notifications
    return notifications.slice(0, 6)
  }, [showAllNotifications, notifications])

  const updateText = () => {
    if (showAllNotifications) return <TranslatedText i18nKey="SHOW_LESS" />
    return <TranslatedText i18nKey="SHOW_ALL" />
  }

  return (
    <>
      <NotificationGrid>
        {fetchedNotifications.map((not, i) => (
          <Notification notification={not} key={`notification-${i}`} />
        ))}
      </NotificationGrid>
      {notifications?.length > 6 && (
        <ShowMoreDiv>
          <LinkButton onClick={() => showAllNotificationsSet((current) => !current)}>{updateText()}</LinkButton>
        </ShowMoreDiv>
      )}
    </>
  )
}

export default NotificationView

const NotificationGrid = styled.div`
  display: grid;
  margin: 0 auto;
  gap: var(--spacing-medium);
  height: 100%;
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-smallPlus);

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ShowMoreDiv = styled.div`
  float: right;
`
