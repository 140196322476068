import React, { useState } from 'react'
import { Button } from '@staccx/bento'
import Flex from '../../../components/Flex'
import Dialog from '../../../components/modal/Dialog'
import styled from 'styled-components'
import PencilHollow from '../../../icons/PencilHollow'
import { useMutation, useQueryClient } from 'react-query'
import { recordMileage } from '../../../api/api'
import customToast from '../../carConfiguration/components/toast/customToast'
import { TranslatedText } from '@staccx/i18n'

const MileageDialog = ({ car }) => {
  const queryClient = useQueryClient()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [mileage, setMileage] = useState(car.mileage || '')

  const saveMileage = useMutation(
    'mileage',
    ({ accountNo, mileage }) => {
      return recordMileage(accountNo, mileage)
    },
    {
      onSuccess: () => {
        return queryClient
          .invalidateQueries('cars')
          .then(() => console.log('Invalidating car'))
      },
      onError: () => {
        setMileage(car.mileage || '')
        customToast.error(<TranslatedText i18nKey="ERROR_MILEAGE_500" />, {
          toastId: 'mileage-error',
        })
        customToast.clearQueue()
      },
    },
  )

  const formatMileage = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const handleChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setMileage(numericValue)
  }

  const handleSubmit = () => {
    saveMileage.mutate({ accountNo: car.accountNo, mileage })
    setIsDialogOpen(false)
  }

  return (
    <div>
      <ButtonWithIcon onClick={() => setIsDialogOpen(!isDialogOpen)} />

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} width="362px">
        <Flex direction={'column'} gap={'var(--spacing-small)'}>
          <StyledHeader>Oppdater kjørelengde</StyledHeader>

          <div style={{ width: '100%' }}>
            <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
              <strong>Antall kilometer</strong>
              <StyledInput
                id="mileage"
                value={formatMileage(mileage.toString()) || ''}
                onKeyDown={(e) => {
                  if (
                    !(
                      (e.key >= '0' && e.key <= '9') ||
                      e.key === 'Backspace' ||
                      e.key === 'ArrowLeft' ||
                      e.key === 'ArrowRight' ||
                      e.key === 'Delete'
                    )
                  ) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => handleChange(e)}
              />
            </Flex>
          </div>

          <Flex gap={'var(--spacing-small)'}>
            <Button onClick={handleSubmit}>Lagre</Button>
            <Button
              variant={'secondary'}
              onClick={() => setIsDialogOpen(false)}
            >
              Avbryt
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </div>
  )
}

export default MileageDialog

const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  border: 1px solid #cccccc;
  outline: none;
  padding: var(--spacing-tinyPlus);

  &:hover {
    border-color: var(--color-wcag);
  }

  &:focus {
    border: 3px solid var(--color-primary);
  }
`

const StyledHeader = styled.div`
  font-size: var(--font-h4);
  font-weight: var(--fontWeight-bold);
`

const StyledDialogButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isHovered ? `var(--color-primary)` : 'var(--color-white)'};
  border: 2px solid var(--color-primary);
  padding: var(--spacing-tinyPlus);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${(props) =>
      props.isHovered ? `var(--color-primary)` : 'var(--color-white)'};
  }
`

const ButtonWithIcon = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <StyledDialogButton
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
    >
      <PencilHollow
        color={isHovered ? 'var(--color-white)' : `var(--color-primary)`}
      />
    </StyledDialogButton>
  )
}
