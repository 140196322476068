const { axiosInstance } = require("./api")
const qs = require("qs")

export const getMakes = async filter => {
  const query = {
    companyId: filter.companyId,
    vehicleType: filter.vehicleType
  }
  return axiosInstance.get(`/car-config/makes${qs.stringify(query, { addQueryPrefix: true })}`)
}

export const getModels = async filter => {
  const query = {
    companyId: filter.companyId,
    vehicleType: filter.vehicleType,
    make: filter.make
  }
  return axiosInstance.get(`/car-config/models${qs.stringify(query, { addQueryPrefix: true })}`)
}

export const getBodies = async filter => {
  const query = {
    companyId: filter.companyId,
    vehicleType: filter.vehicleType,
    make: filter.make,
    model: filter.model,
    modelYear: filter.modelYear
  }
  return axiosInstance.get(`/car-config/bodies${qs.stringify(query, { addQueryPrefix: true })}`)
}

export const getEngines = async filter => {
  const query = {
    companyId: filter.companyId,
    vehicleType: filter.vehicleType,
    make: filter.make,
    model: filter.model,
    modelYear: filter.modelYear,
    bodyCode: filter.bodyCode,
    doors: filter.doors,
    drivenWheels: filter.drivenWheels
  }
  return axiosInstance.get(`/car-config/engines${qs.stringify(query, { addQueryPrefix: true })}`)
}

export const getVehiclesBuilder = async filter => {
  const query = {
    companyId: filter.companyId,
    vehicleType: filter.vehicleType,
    vehicleId: filter.vehicleId
  }
  return axiosInstance.get(`/car-config/vehicles-builder${qs.stringify(query, { addQueryPrefix: true })}`)
}

const optionMap = ({ categoryName, optionTypeName, schemaIds }) => {
  switch (optionTypeName?.toLowerCase()) {
    case "colour":
      if (["utvendig farge", "external color"].includes(categoryName.toLowerCase())) {
        return "color"
      }
      if (categoryName.toLowerCase() === "interiør uttrykk") {
        return "interior"
      }
      return "misc"
    case "packages":
      if (categoryName.toLowerCase() === "utstyr") {
        return "equipment-package"
      } else if (categoryName.toLowerCase() === "interiør uttrykk") {
        return "interior-package"
      } else if (categoryName.toLowerCase() === "aktiv sikkerhet") {
        return "security-package"
      } else if (categoryName.toLowerCase() === "motor og drivverk") {
        return "engine-package"
      } else return "other-package"
    case "option":
      if (categoryName.toLowerCase() === "utvendig design" && schemaIds.split(",").includes(EXTERIOR_PAINT_TYPE_SCHEMA_ID)) {
        return "paint-type"
      }
      if (categoryName.toLowerCase() === "utvendig design" && !schemaIds.split(",").includes(EXTERIOR_PAINT_TYPE_SCHEMA_ID)) {
        return "exterior"
      }
      if (categoryName.toLowerCase() === "interiør uttrykk") {
        return "interior"
      }
      return "misc"
    case "norwegian_mounted_accessories":
      return "norwegianMountedAccessories"
    default:
      return "misc"
  }
}

export const flattenAccessories = (accessories) => {
  if (accessories) {
    return [...accessories.packages, ...Object.keys(accessories.options).map(optionKey => accessories.options[optionKey]).flat()]
  }
  return []
}

export const EXTERIOR_PAINT_TYPE_SCHEMA_ID = "15201"

export const paintTypeFilter = ({ optionTypeName, categoryName, schemaIds }) => {
  return categoryName?.toLowerCase() === "utvendig design" && schemaIds.split(",").includes(EXTERIOR_PAINT_TYPE_SCHEMA_ID) && optionTypeName?.toLowerCase() === "option"
}
export const colorFilter = (option) => optionMap(option) === "color"
export const interiorFilter = (option) => optionMap(option) === "interior"
export const exteriorFilter = (option) => optionMap(option) === "exterior"
export const norwegianMountedAccessoriesFilter = (option) => optionMap(option) === "norwegianMountedAccessories"
export const packageFilter = ({ optionTypeName }) => optionTypeName?.toLowerCase() === "packages"
export const packageEquipmentFilter = (option) => optionMap(option) === "equipment-package"
export const packageInteriorFilter = (option) => optionMap(option) === "interior-package"
export const packageSecurityFilter = (option) => optionMap(option) === "security-package"
export const packageOtherFilter = (option) => optionMap(option) === "other-package"
export const packageEngineFilter = (option) => optionMap(option) === "engine-package"
export const miscFilter = (option) => optionMap(option) === "misc"

export const jatoVehicleOptionsMap = data => data.map(i => ({ ...i, formattedCategoryName: optionMap(i) }))

