import React, { useMemo } from 'react'
import styled from 'styled-components'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import { useI18nContext } from '../../context/I18nProvider'
import Search from '../../icons/Search'

const SearchBox = ({ disabled, onChange, value, placeholderi18nKey = 'SEARCH', searchableFields = [], noBorder }) => {
  const { language } = useI18nContext()
  const size = 30
  const placeholder =
    useMemo(
      () =>
        `${i18nInstance.translate(placeholderi18nKey)} ${searchableFields
          .map((s) => i18nInstance.translate(s)?.toLowerCase())
          .join(' ')}`,
      [language]
    ) + '...'

  return (
    <InputContainer noBorder={noBorder}>
      <Search width={size} height={size} />
      <Input
        disabled={disabled}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </InputContainer>
  )
}

const InputContainer = styled.div`
  border: ${(props) => (props.noBorder ? 'none' : 'var(--spacing-microMinus) solid var(--color-secondary)')};
  box-shadow: ${(props) =>
    props.noBorder ? 'none' : '0px var(--spacing-tinyMinus) var(--spacing-small) rgba(0, 0, 0, 0.04)'};
  border-radius: ${(props) => (props.noBorder ? 'none' : 'var(--spacing-tinyMinus)')};
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  background-color: var(--color-white);
  padding: var(--spacing-tinyMinus) var(--spacing-smallMinus);
  width: 70%;
  &:hover {
    transition: 0.2s;
    box-shadow: var(--spacing-tinyMinus) var(--spacing-tinyMinus) var(--spacing-tinyMinus) rgba(0, 0, 0, 0.04);
  }
`

const Input = styled.input.attrs({ type: 'text' })`
  border: 0;
  width: 100%;
  border-radius: 0;
  font-weight: var(--fontWeight-light);
  font-size: var(--font-base);
  padding-left: var(--spacing-tinyMinus);

  :focus {
    border: 0;
    border-radius: 0;
    outline: none;
  }
`

export default SearchBox
