import { Wrapper, theming } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import styled from 'styled-components'
import Container from '../../../components/Container'
import {
  useBudgetSelections,
  usePendingBudgets,
  usePendingSignings,
  usePendingBatchSignings,
} from '../../../context/SigningProvider'
import { SigningsList } from '../components/signing/SigningsList'
import { BudgetSelectionsList } from '../components/budgetSelection/BudgetSelectionsList'
import { Link } from 'react-router-dom'
import { useUser } from '../../../context/UserProvider'
import BudgetCheckboxList from '../components/budgets/BudgetCheckboxList'
import BudgetSingle from '../components/budgets/BudgetSingle'
import BatchSigningList from '../components/signing/BatchSigningList'

import {H4Heading, ReportHeading} from "../../../components/text/Headings";

const Tasks = () => {
  const user = useUser()

  const { pendingSignings, isLoading: signingsLoading } = usePendingSignings(
    user?.user.id,
  )
  const { budgetSelections, isLoading: budgetSelectionsLoading } =
    useBudgetSelections(user?.user.id)

  const {pendingBudgets, isLoading: pendingBudgetsLoading} = usePendingBudgets(user?.user.id)

  const { pendingBatchSignings, isLoading: pendingBatchSigningLoading } =
    usePendingBatchSignings(user?.user.id)


  return (
    <Container
      isLoading={signingsLoading || budgetSelectionsLoading || pendingBudgetsLoading || pendingBatchSigningLoading}
      renderChildrenDuringLoading={false}
    >
      <Wrapper>
        <TranslatedText i18nKey={'MY_TASKS'}>
          {(value) => <ReportHeading>{value ?? 'Mine oppgaver'}</ReportHeading>}
        </TranslatedText>

        {pendingSignings?.length === 0 &&
          budgetSelections?.length === 0 &&
          pendingBudgets?.length === 0 &&
          pendingBatchSignings?.length === 0 && (
            <TranslatedText i18nKey={'NO_TASKS'}>
              {(value) =>
                value ??
                'Du har ingen oppgaver som venter på deg for øyeblikket.'
              }
            </TranslatedText>
          )}
        {pendingBudgets?.length === 1 && (
          <>
            <BudgetSingle budget={pendingBudgets[0]} user={user} />
          </>
        )}
        {pendingBudgets?.length > 1 && (
          <>
            <BudgetCheckboxList pendingBudgets={pendingBudgets} user={user} />
          </>
        )}
        {pendingBatchSignings?.length > 0 && (
          <>
            <H4Heading>{'Venter på signering'}</H4Heading>

            <BatchSigningList pendingBatchSignings={pendingBatchSignings} user={user} />
          </>
        )}
        {pendingSignings?.length > 0 && (
          <>
            <TranslatedText i18nKey={'DOCUMENTS_FOR_SIGNING'}>
              {(value) => (
                <H4Heading>{value ?? 'Dokumenter til signering'}</H4Heading>
              )}
            </TranslatedText>
            <SigningsList pendingSignings={pendingSignings} />
          </>
        )}
        {budgetSelections?.length > 0 && (
          <>
            <TranslatedText i18nKey={'CUSTOMIZE_CAR_OPTIONS'}>
              {(value) => (
                <H4Heading>{value ?? 'Gjøre tilvalg på bil'}</H4Heading>
              )}
            </TranslatedText>
            <BudgetSelectionsList budgetSelections={budgetSelections} />
          </>
        )}
      </Wrapper>
    </Container>
  )
}

const StyledLink = styled(Link)`
  margin-top: ${theming.spacing.large()};
  display: block;
  text-decoration: none;
`

export default Tasks
