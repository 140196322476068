import React from 'react'
import DropDown from '../../../components/DropDownMenu'
import { format, subMonths } from 'date-fns'
import { nb, enGB } from 'date-fns/locale'
import { useUserContext } from '../../../context/UserProvider'
import { Label } from '@staccx/bento'
import { useI18nContext } from '../../../context/I18nProvider'
import styled from 'styled-components'

export const languageChosen = () => {
  if (useI18nContext().language == 'nb') {
    return nb
  } else {
    return enGB
  }
}
const ReportTermSelect = ({ reportTerms, label }) => {
  const { handleFleetReportTerm, fleetReportTerm } = useUserContext()

  const fleetReportTerms = reportTerms.map((element) => {
    const value = new Date(element)
    const label = format(value, 'yyyy MMMM', {
      locale: languageChosen()
    })
    return {
      value,
      label
    }
  })

  let selectedTerm = {
    value: '',
    label: ''
  }

  if (fleetReportTerm) {
    const dateTerm = new Date(fleetReportTerm)
    selectedTerm = {
      value: dateTerm,
      label: format(dateTerm, 'yyyy MMMM', {
        locale: nb
      })
    }
  }

  const handleTermSelect = (selectedTerm) => {
    const termValue = format(selectedTerm.value, 'yyyy-MM-dd')
    handleFleetReportTerm(termValue)
  }

  return (
    <div>
      <Label>{label}</Label>
      <DropDown options={fleetReportTerms} onChange={handleTermSelect} value={selectedTerm} />
    </div>
  )
}

export default ReportTermSelect