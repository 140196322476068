import { TranslatedText } from '@staccx/i18n'
import formatOptionName from '../../../utils/formatOptionName'
import { MultiOptionListItem } from './fields/MultiOptionListItem/MultiOptionListItem'
import React from 'react'
import styled from 'styled-components'
import { SmallHeading } from '../../../components/text/Headings'
import Dialog from '../../../components/modal/Dialog'

const RequiresModal = ({
  requiredSelections,
  needsVerification,
  optionThatRequiresSelections,
  currentSelectedOption,
  handleToggleOption,
  getOptionNameByOptionId,
  getOptionIncludedBy,
  getExcludedByOption,
  touchedOptions,
  fulfillsRequirements,
  getSelectedByPackage,
  handleRemoveOption,
  getOptionDependencies,
}) => {
  const { selectedOptions, excludedOptions, includedOptions } = touchedOptions

  // requiredSelections may contain requirements that do not relate to the last selected or computed option that had requirements,
  // meaning some requirements may be fulfilled and others may not. This ensures we only display a modal if the option the user
  // last clicked on still has requirements
  const requirementsForCurrentSelectedOrComputedOption = requiredSelections?.filter(
    (opt) =>
      (optionThatRequiresSelections && opt.requiredBy.optionId === optionThatRequiresSelections.optionId) ||
      (currentSelectedOption && opt.requiredBy.optionId === currentSelectedOption.optionId)
  )

  const needsManualReviewForCurrentSelectedOrComputedOption = needsVerification?.filter(
    (opt) =>
      (optionThatRequiresSelections && opt.excludedBy.optionId === optionThatRequiresSelections.optionId) ||
      (currentSelectedOption && opt.excludedBy.optionId === currentSelectedOption.optionId)
  )

  let isOpen =
    requirementsForCurrentSelectedOrComputedOption?.length > 0 ||
    needsManualReviewForCurrentSelectedOrComputedOption?.length > 0

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        handleToggleOption(optionThatRequiresSelections)
        isOpen = false
      }}
      width={"50vw"}
    >
      {needsVerification?.length > 0
        ? needsVerification.map((o) => (
            <Outer>
              <ManualReview>
                <Inner>
                  <SmallHeading>
                    <TranslatedText i18nKey={'NEEDS_MANUAL_REVIEW'} />
                  </SmallHeading>
                  <div>
                    <TranslatedText i18nKey={'SELECTING_EQUIPMENT'} />:
                    <ol>
                      {requiredSelections?.map((o) => (
                        <ItalicizedLi key={o.requiredBy.optionId}>
                          {formatOptionName(o.requiredBy.optionName)}
                        </ItalicizedLi>
                      ))}
                    </ol>
                  </div>
                  <span>
                    <TranslatedText i18nKey={'REQUIRES_DESELECTION_OF_OPTIONS'} />:
                  </span>
                </Inner>
                <MultiOptionListItem
                  disabled={false}
                  key={o.excludedBy.optionId}
                  option={o.excludedBy}
                  onSelect={handleToggleOption}
                  getOptionNameByOptionId={getOptionNameByOptionId}
                  getOptionIncludedBy={getOptionIncludedBy}
                  getExcludedByOption={getExcludedByOption}
                  touchedOptions={{ selectedOptions, excludedOptions, includedOptions }}
                  fulfillsRequirements={fulfillsRequirements}
                  getSelectedByPackage={getSelectedByPackage}
                  handleRemoveOption={handleRemoveOption}
                  getOptionDependencies={getOptionDependencies}
                  triggerRevalidation={false}
                />
              </ManualReview>
              <span>
                <TranslatedText i18nKey={'CLOSE_WINDOW_TO_ABORT_SELECTION'} />
              </span>
            </Outer>
          ))
        : requirementsForCurrentSelectedOrComputedOption?.map((o) => (
            <div>
              <TranslatedText i18nKey={'REQUIRED_BY'}>
                {(value) => (
                  <SmallHeading>
                    {value} "{formatOptionName(o.requiredBy.optionName)}"
                  </SmallHeading>
                )}
              </TranslatedText>
              <OptionsListModal>
                {o.requirements.map((req) => {
                  const header = req.all ? 'CHOOSE_ALL' : 'ONE_OF'
                  const data = req.all ? req.all : req.oneOf
                  return (
                    <>
                      <TranslatedText i18nKey={header} />
                      {data.map((req) => (
                        <MultiOptionListItem
                          key={req.optionId}
                          option={req}
                          onSelect={handleToggleOption}
                          getOptionNameByOptionId={getOptionNameByOptionId}
                          getOptionIncludedBy={getOptionIncludedBy}
                          getExcludedByOption={getExcludedByOption}
                          touchedOptions={{ selectedOptions, excludedOptions, includedOptions }}
                          fulfillsRequirements={fulfillsRequirements}
                          getSelectedByPackage={getSelectedByPackage}
                          handleRemoveOption={handleRemoveOption}
                          getOptionDependencies={getOptionDependencies}
                        />
                      ))}
                    </>
                  )
                })}
              </OptionsListModal>
            </div>
          ))}
    </Dialog>
  )
}

export default RequiresModal

const OptionsListModal = styled.ul`
  display: grid;
  align-items: center;
`

const ManualReview = styled.div`
  display: grid;
`

const Inner = styled.div`
  display: grid;
  gap: var(--spacing-smallMinus);
`

const Outer = styled.div`
  display: grid;
  gap: var(--spacing-mediumPlus);
`

const ItalicizedLi = styled.li`
  font-style: italic;
`
