import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatDate } from '../../../../../utils/tableFuncs'
import { PurchaseReportAccountDetails } from '../../../../../types'

export default function purchaseReportAccountDetailsData() {
  const columnHelper = createColumnHelper<PurchaseReportAccountDetails>()
  return [
    columnHelper.accessor('transactionDate', {
      id: 'transactionDate',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
    }),
    columnHelper.accessor('transactionTime', {
      id: 'transactionTime',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'TIME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
    }),
    columnHelper.accessor('referenceNo', {
      id: 'referenceNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'CARD_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('retailStationName', {
      id: 'retailStationName',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'STATION'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('productDescription', {
      id: 'productDescription',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'PRODUCT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('amountincVAT', {
      id: 'amountincVAT',
      meta: { alignmentType: 'number' },
      header: () => <TranslatedText i18nKey={'INVOICE_INC_VAT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
    }),
  ]
}
