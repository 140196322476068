// import client from "@sanity/client"

export const sanity = {
  projectId: 'gwvp4jhb',
  dataset: 'production',
  useCdn: false, // `false` if you want to ensure fresh data
}

export const extractTextFromBlocks = (blocks = []) =>
  blocks
    .filter((val) => val._type === 'block')
    .map((block) =>
      block.children
        .filter((child) => child._type === 'span')
        .map((span) => span.text)
        .join('')
    )
    .join('')

export const createTextArray = (blocks = []) =>
  blocks
    .filter((val) => val._type === 'block')
    .map((block) => block.children.filter((child) => child._type === 'span').map((span) => span.text))
    .flat()
    .filter((s) => s.length)

export default sanity
