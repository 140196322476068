import React from 'react'

interface ChassisProps {
  width?: number;
  height?: number;
  color?: string;
}

const Chassis = ({ width= 40, height = 31, color='#467EFF' } : ChassisProps) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 40 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.4355 12.5865L33.4171 3.96252C32.5943 1.61292 30.3679 0.0333252 27.8775 0.0333252H12.1225C9.63215 0.0333252 7.40575 1.61293 6.58442 3.96106L3.56602 12.5851C1.95415 13.6455 0.93335 15.4773 0.93335 17.4925V27.9C0.93335 29.5207 2.24602 30.8333 3.86668 30.8333C5.48735 30.8333 6.80002 29.5207 6.80002 27.9V26.0505C6.80002 26.0505 14.5455 26.4333 20 26.4333C25.4545 26.4333 33.2 26.0505 33.2 26.0505V27.9C33.2 29.5207 34.5127 30.8333 36.1333 30.8333C37.754 30.8333 39.0667 29.5207 39.0667 27.9V17.4925C39.0667 15.4773 38.0444 13.6455 36.4355 12.5865ZM7.67268 9.73239L9.35348 4.93053C9.76562 3.75426 10.8759 2.96666 12.1225 2.96666H27.8775C29.1241 2.96666 30.2344 3.75426 30.6465 4.93053L32.3273 9.73239C32.5913 10.4877 31.9621 11.2489 31.1716 11.1213C28.2955 10.6564 24.598 10.3 20 10.3C15.402 10.3 11.7045 10.6564 8.82842 11.1213C8.03788 11.2489 7.40868 10.4877 7.67268 9.73239ZM7.53335 20.5667C6.31895 20.5667 5.33335 19.5811 5.33335 18.3667C5.33335 17.1523 6.31895 16.1667 7.53335 16.1667C8.74775 16.1667 9.73335 17.1523 9.73335 18.3667C9.73335 19.5811 8.74775 20.5667 7.53335 20.5667ZM24.4 19.1H15.6C14.7904 19.1 14.1333 18.4429 14.1333 17.6333C14.1333 16.8237 14.7904 16.1667 15.6 16.1667H24.4C25.2096 16.1667 25.8667 16.8237 25.8667 17.6333C25.8667 18.4429 25.2096 19.1 24.4 19.1ZM32.4667 20.5667C31.2523 20.5667 30.2667 19.5811 30.2667 18.3667C30.2667 17.1523 31.2523 16.1667 32.4667 16.1667C33.6811 16.1667 34.6667 17.1523 34.6667 18.3667C34.6667 19.5811 33.6811 20.5667 32.4667 20.5667Z"
      fill={color}
    />
  </svg>
)

export default Chassis
