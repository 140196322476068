import React from 'react'

const Search = ({ width = 24, height = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
    <path
      fill="#2a6df4"
      d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2a7.04 7.04 0 1 0-14.1 0c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z"
    />
  </svg>
)

export default Search
