import styled from "styled-components"
import { Button, color } from "@staccx/bento"

const LinkButton = styled(Button)`
  background-color: transparent;
  color: ${color("primary")};
  min-height: 0;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-left: auto;
  padding: 0;
  line-height: 0;
  &:hover,
  &:focus {
    color: ${color("primary")};
    background-color: transparent;
    text-decoration: underline;
`

export default LinkButton
