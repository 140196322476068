import { css } from "styled-components"
import { color, font, Input, spacing, VARIANT_DEFAULT } from "@staccx/bento"

const placeholder = css`
  font-size: ${font.base};
  color: ${color.wcag};
`

const darkPlaceholder = css`
  font-size: ${font.base};
  color: #95979C;
`

Input.createVariants(
  {input:
  {
    [VARIANT_DEFAULT]: css`
      min-height: 44px;
    `,
    search: css`
      padding-left: ${spacing.large};
      border-color: transparent;
      display: block;
      width: 100%;
      min-height: 44px;
      margin: 0px auto;
      border: 1px solid rgb(228, 226, 226);
      border-radius: 4px;
      padding-left: 12px;
      padding-right: 12px;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      transition: border-color 0.2s ease-out 0s;
      appearance: none;

      &::-webkit-input-placeholder {
        ${placeholder};
      }
      &:-moz-placeholder {
        ${placeholder};
      }
      &::-moz-placeholder {
        ${placeholder};
      }
      &:-ms-input-placeholder {
        ${placeholder};
      }
      &:-webkit-autofill {
        ${placeholder};
      }
    `,
    filledInput: css`
      min-height: 44px;
      background-color: #F2F4F7;
      border: none;

      &::-webkit-input-placeholder {
        ${darkPlaceholder};
      }
      &:-moz-placeholder {
        ${darkPlaceholder};
      }
      &::-moz-placeholder {
        ${darkPlaceholder};
      }
      &:-ms-input-placeholder {
        ${darkPlaceholder};
      }
      &:-webkit-autofill {
        ${darkPlaceholder};
      }
    `
  }}
)
