import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Auth, Login as LoginOTP, stages } from '@staccx/open-id'
import { Button, Input, ItemGroup, Layout } from '@staccx/bento'
import Alert from '../../../components/Alert'
import { TranslatedText } from '@staccx/i18n'
import { Link } from 'react-router-dom'
import Logo from '../../../icons/Logo'
import SignalSpinnerLoader from "../../../components/loaders/SignalSpinnerLoader";

const oidcConfig = {
  authority: process.env.REACT_APP_AUTOPLAN_OIDC_URL || 'https://oidc.dev.autoplan.staccflow.com',
  client_id: 'autoplan',
  redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-login',
  response_type: 'id_token token',
  scope: 'openid fleet drivers usage invoice user',
  silent_redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-login-silent',
  post_logout_redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-logout',
  automaticSilentRenew: true,
  clockSkew: 600
}

if (window.location.hostname !== 'localhost' && window.location.hostname !== '10.0.2.2') {
  const hostParts = window.location.host.split('.')
  hostParts.splice(0, 1, 'oidc')
  oidcConfig.authority = `https://${hostParts.join('.')}`
}

const configEmail = {
  acrValue: 'magic-link-email-provider',
  codePostUri: '/api/magic-link-email/magic-link',
  idPostUri: '/api/magic-link-email/request-link',
  callbackPath: '/callback/magic-link-email-provider',
  magicPollUri: '/api/magic-link-email/magic-link-completed'
}

const configSms = {
  acrValue: 'magic-link-sms-provider',
  codePostUri: '/api/magic-link-sms/magic-link',
  idPostUri: '/api/magic-link-sms/request-link',
  callbackPath: '/callback/magic-link-sms-provider',
  magicPollUri: ''
}

const baseConfig = {
  oidcConfig,
  redirectAfterLogin: '/'
}

export const authService = new Auth(oidcConfig, true)
const redirectStages = [
  stages.errorDuringLogin,
  stages.tooManyAttempts,
  stages.failedToSubmitCode,
  stages.alreadySucceeded
]

const Login = ({ type = 'small' }) => {
  let config = null
  if (type === 'email') {
    config = { ...baseConfig, ...configEmail }
  } else if (type === 'sms') {
    config = { ...baseConfig, ...configSms }
  } else {
    config = { ...baseConfig, ...configSms }
  }

  useEffect(() => {
    sessionStorage.removeItem('loggedoutReason')
  }, [])

  return (
    <LoginOTP oidcConfig={oidcConfig} {...config}>
      {({ lastError, stage, handleIdInput, handleCodeInput, submitCode, submitId }) => {
        if (redirectStages.includes(stage)) {
          window.location.replace('/')
        }
        if ([stages.submittingCode, stages.waitingForState, stages.submittingId, void 0].includes(stage)) {
          return (
            <Layout>
              <Center>
                <SignalSpinnerLoader />
              </Center>
            </Layout>
          )
        }

        return (
          <React.Fragment>
            {stage === stages.waitingForCode && (
              <form onSubmit={submitCode}>
                <Layout>
                  <Center>
                    <Logo />
                  </Center>
                  <Input autoFocus onChange={handleCodeInput} id="loggin" label="Engangskode" />
                  {lastError && (
                    <TranslatedText i18nKey={lastError}>
                      {(value) => <Alert type={'warning'}>{value}</Alert>}
                    </TranslatedText>
                  )}

                  <Button variant="primary" type="submit">
                    {'Logg inn'}
                  </Button>
                  {type === 'email' && (
                    <TranslatedText i18nKey="MAGICLINK_CODE_MESSAGE">{(value) => <p>{value}</p>}</TranslatedText>
                  )}
                </Layout>
              </form>
            )}

            {stage === stages.waitingForId && (
              <form onSubmit={submitId}>
                <Layout>
                  <Center>
                    <Logo />
                  </Center>
                  <TranslatedText i18nKey={type === 'sms' ? 'LOGIN_PHONE_INPUT_LABEL' : 'LOGIN_EMAIL_INPUT_LABEL'}>
                    {(value) =>
                      type === 'sms' ? (
                        <Input
                          mode="phone"
                          autoFocus
                          id="loggin"
                          onChange={(e) => handleIdInput({ target: { value: e.rawValue } })}
                          label={value}
                        />
                      ) : (
                        <Input id="loggin" autoFocus onChange={handleIdInput} label={value} />
                      )
                    }
                  </TranslatedText>

                  {lastError && (
                    <TranslatedText i18nKey={lastError}>
                      {(value) => <Alert type={'warning'}>{value}</Alert>}
                    </TranslatedText>
                  )}

                  <ItemGroup>
                    <TranslatedText i18nKey={'LOGIN_PHONE_GETOTP_LABEL'}>
                      {(value) => (
                        <Button variant="primary" type="submit">
                          {value}
                        </Button>
                      )}
                    </TranslatedText>
                    <TranslatedText i18nKey="LOGIN_PHONE_GETOTP_LABEL">
                      {(value) => (
                        <Link to={'/login'}>
                          <Button variant="secondary">Tilbake</Button>
                        </Link>
                      )}
                    </TranslatedText>
                  </ItemGroup>
                </Layout>
              </form>
            )}
            {stage === stages.waitingForValidation && (
              <TranslatedText i18nKey="MAGICLINK_MESSAGE">{(value) => <div>{value}</div>}</TranslatedText>
            )}
          </React.Fragment>
        )
      }}
    </LoginOTP>
  )
}

const Center = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  width: 200px;
`

export default Login
