import { css } from "styled-components"
import { Layout, spacing, VARIANT_DEFAULT } from "@staccx/bento"

Layout.createVariants(
  {
    container:
      {
        [VARIANT_DEFAULT]: css`
          .no-cssgrid & {
            > * {
              margin-bottom: ${spacing.medium};
            }

            ${p =>
                    p.grid === "fiftyFifty" &&
                    css`
                      display: flex;

                      > * {
                        width: 50%;
                      }
                    `};
          }
        `,
        centered: css`
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 12px;
          grid-template-areas:
            ". main main ."
            ". main main .";
          height: 100vh;
          > * {
            grid-area: main;
            align-self: center;
            justify-self: center;
        }
      `,
        fourUp: css`
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 12px;

          .no-cssgrid & {
            display: flex;
            flex-wrap: wrap;

            > * {
              width: 25%;
            }
          }

          @media only screen and (max-width: 1200px) and (min-width: 1000px) {
            grid-template-columns: repeat(2, 1fr);
            .no-cssgrid & > * {
              width: 50%;
            }
          }
          @media only screen and (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
            .no-cssgrid & > * {
              width: 50%;
            }
          }
          @media only screen and (max-width: 450px) {
            grid-template-columns: repeat(1, 1fr);
            .no-cssgrid & > * {
              width: 100%;
            }
          }
        `,
        fiftyFifty: css`
          display: grid;
          grid-template-columns: 1fr;
          
          @media only screen and (min-width: 730px) {
            grid-template-areas: initial;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 48px;
        }
      `,
        form: css`
          grid-template-areas: initial;

          .no-cssgrid & {
            display: flex;

            > * {
              width: 50%;
            }
          }
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        `,
        dashboard: css`
          grid-template-columns: 340px 1fr;
          grid-gap: 0;
          min-height: 100vh;
          grid-template-areas: "aside main";
        `,
        responsiveDashboard: css`
          grid-template-columns: 300px 1fr;
          grid-gap: 0;
          @media only screen and (max-width: 1000px) {
            grid-template-areas: "main main";
          }
          @media only screen and (min-width: 1000px) {
            .no-cssgrid & {
              display: flex;

              > *:first-child {
                width: 300px;
              }

              > *:last-child {
                width: calc(100% - 300px);
              }
            }
          }
        `,
        formElements: css`
          .no-cssgrid & {
            > * {
              margin-bottom: ${spacing.medium};
            }
          }
        `
      }
  }
)
