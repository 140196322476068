import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import AnchorIcon from '../../../icons/Anchor'
import { TranslatedText } from '@staccx/i18n'
import { ReportButton } from '../../../theme/Theme.Button'

const ReportAnchor = ({ text, icon, path }) => {
  return (
    <NavLink to={path}>
      <ReportButton>
        <Left>{icon}</Left>
        <TranslatedText i18nKey={text}>{(value) => <ReportHeading>{value}</ReportHeading>}</TranslatedText>
        <Right>
          <AnchorIcon stroke={`var(--color-primary)`} />
        </Right>
      </ReportButton>
    </NavLink>
  )
}

export default ReportAnchor

const ReportHeading = styled.p`
  text-align: left;
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
`
const Left = styled.div`
  display: flex;
  justify-content: center;
`
const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`
