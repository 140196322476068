import React from 'react'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import Driver2 from '../../../icons/Driver2'

const ContactPerson = ({ contactPerson }) => {
  const Image = contactPerson.SalesPersonPictureBase64 === '' ? null : contactPerson.SalesPersonPictureBase64

  return (
    <Row>
      <Column>
        <Headshot>
          {Image ? (
            <HeadshotImg src={`data:image/png;base64,${Image}`} />
          ) : (
            <EmptyHeadshot>
              <Avatar>
                <Driver2 />
              </Avatar>
            </EmptyHeadshot>
          )}
        </Headshot>
      </Column>
      <Column padding>
        <Heading>
          <TranslatedText i18nKey="YOUR_CONTACTPERSON" />
        </Heading>
        <Text>{contactPerson.Name}</Text>
        <Text link email onClick={() => (window.location = `mailto:${contactPerson.Email}`)}>
          {contactPerson.Email}
        </Text>
      </Column>
    </Row>
  )
}

export default ContactPerson

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${(props) => (props.padding ? 'var(--spacing-smallPlus)' : '')};
`
const Headshot = styled.div`
  width: 85px;
  height: 85px;
`
const HeadshotImg = styled.img`
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
`

const EmptyHeadshot = styled.div`
  border-radius: 50%;
  background-color: var(--color-primaryLight);
  display: inline-block;
  width: 100%;
  height: 100%;
  justify-content: stretch;
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Heading = styled.p`
  font-size: var(--font-base);
  font-weight: var(--fontWeight-veryBold);
  padding: 0 0 var(--spacing-small) 0;
`
const Text = styled.p`
  font-size: var(--font-base);
  font-weight: var(--fontWeight-normal);
  color: ${(props) => (props.link ? 'var(--color-primary)' : '')};
  &:hover {
    cursor: ${(props) => (props.link ? 'pointer' : '')};
  }
`
