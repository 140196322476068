import React from 'react'
import styled from 'styled-components'
import ReportAnchor from '../components/ReportAnchor'
import { useFeatureToggles } from '../../../context/UserProvider'
import { reportsSubmenuWithIcons } from '../../../data/routes'
import Container from '../../../components/Container'

const ReportsOverview = () => {
  const featureToggles = useFeatureToggles()
  return (
    <Container>
      <Grid>
        {reportsSubmenuWithIcons(featureToggles) &&
          reportsSubmenuWithIcons(featureToggles).map((report) => (
            <ReportAnchor key={report.path} text={report.name} icon={report.icon} path={report.path} />
          ))}
      </Grid>
    </Container>
  )
}

export default ReportsOverview

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  > a {
    text-decoration: none;
  }
  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
`
