import React from 'react'
import DropDown from '../DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import { useUserContext } from '../../context/UserProvider'
import { Label } from '@staccx/bento'

export const ConsolidateInvoiceSelect = () => {
  const { consolidateInvoice, handleSetConsolidateInvoice, chosenCompany } = useUserContext()
  if (!chosenCompany) return null

  const consolidatedAgreements = [
    { value: '_ALL_', label: <TranslatedText i18nKey='ALL'/> },
    ...chosenCompany.consolidateAgreement.map((item) => ({
      value: item.ConsolidateCode,
      label: `${item.ConsolidateCode} - ${item.ConsolidateDescription}`,
    })),
  ]

  return (
    <TranslatedText i18nKey="CONSOLIDATED_AGREEMENT">
      {(value) => (
        <div>
          <Label>{value}</Label>
          <DropDown
            options={consolidatedAgreements}
            onChange={(selectedItem) => handleSetConsolidateInvoice({ ConsolidateCode: selectedItem.value })}
            value={{
              value: consolidateInvoice?.ConsolidateCode,
              label: consolidateInvoice?.ConsolidateDescription,
            }}
          />
        </div>
      )}
    </TranslatedText>
  )
}
