import React from 'react'
import type { FuelReportExpenseDetails } from '../../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatDate, formatNumber } from '../../../../../utils/tableFuncs'

export default function fuelReportData() {
  const columnHelper = createColumnHelper<FuelReportExpenseDetails>()
  return [
    columnHelper.accessor('transactionDate', {
      id: 'transactionDate',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate('20' + row.getValue().split('.').reverse().join('-') + 'T00:00:00') || '',
    }),
    columnHelper.accessor('transactionTime', {
      id: 'transactionTime',
      header: () => <TranslatedText i18nKey={'TIME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue().split(':').slice(0, 2).join(':') || '',
      meta: { alignmentType: 'date' },
    }),
    columnHelper.accessor('referenceNo', {
      id: 'referenceNo',
      header: () => <TranslatedText i18nKey={'CARD_NO'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('retailStationName', {
      id: 'retailStationName',
      header: () => <TranslatedText i18nKey={'STATION'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('productDescription', {
      id: 'productDescription',
      header: () => <TranslatedText i18nKey={'PRODUCT'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('amountincVAT', {
      id: 'amountincVAT',
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('volume', {
      id: 'volume',
      header: () => <TranslatedText i18nKey={'VOLUME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue()) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('paidCostperliter', {
      id: 'paidCostperliter',
      header: () => <TranslatedText i18nKey={'PRICE_PER_LITER'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue(), { precision: 2 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('regKm', {
      id: 'regKm',
      header: () => <TranslatedText i18nKey={'ACCUMULATED_KM'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('obs', {
      id: 'obs',
      header: () => <TranslatedText i18nKey={'ATTENTION'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
  ]
}
