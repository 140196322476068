import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import {
  getBudgetSelections, getPendingBatchSignings,
  getPendingBudgets,
  getPendingSignings,
  getPendingUserTasks,
  getSigningFlows
} from '../api/api'

const SigningContext = createContext({})

const SigningProvider = ({ children }) => <SigningContext.Provider>{children}</SigningContext.Provider>

export const usePendingSignings = (contactId) => {
  const {
    data: pendingSignings,
    isLoading,
    refetch
  } = useQuery(['pending-signings'], () => getPendingSignings(contactId), { enabled: !!contactId })

  return {
    pendingSignings,
    isLoading,
    refetch
  }
}

export const usePendingBudgets = (contactId) => {
  const { data: pendingBudgets, isLoading } = useQuery(['pending-budgets'], () => getPendingBudgets(contactId), {
    enabled: !!contactId
  })

  return {
    pendingBudgets,
    isLoading
  }

}

export const usePendingBatchSignings = (contactId) => {
  const { data: pendingBatchSignings, isLoading } = useQuery(['pending-batch-signings'], () => getPendingBatchSignings(contactId), {
    enabled: !!contactId
  })

  return {
    pendingBatchSignings,
    isLoading
  }
}

export const useBudgetSelections = (contactId) => {
  const { data: budgetSelections, isLoading } = useQuery(['budget-selections'], () => getBudgetSelections(contactId), {
    enabled: !!contactId
  })

  return {
    budgetSelections,
    isLoading
  }
}

export const usePendingUserTasks = (id) => {
  const { data: pendingUserTasks, isLoading } = useQuery(['pending-user-tasks'], () => getPendingUserTasks(id))

  return {
    pendingUserTasks,
    isLoading
  }
}

export const useSigningContext = () => useContext(SigningContext)

export default SigningProvider
