import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { TranslatedText } from "@staccx/i18n"
import { Text } from "@staccx/bento"
import StyledTable from "./StyledTable"

const CellContainer = styled.div`
  width: 100%;
  text-align: ${({ align = "right" }) => align};
  line-height: 20px;
`

const CellRenderer = (contentFunction, isLastColumn) => row => {
  return (
    <CellContainer className={isLastColumn ? "last" : ""}>
      {contentFunction ? contentFunction(row) : row.value}
    </CellContainer>
  )
}

const Table = (props) => {
  const [sortedHeader, sortedHeaderSet] = useState(null)

  const getColumn = ({ i18nKey, contentFunction, accessor, ...rest }, index, headers) => {
    const isLastColumn = index === headers.length - 1
    return {
      Header: ({ column }) => {
        const sorted =
          column.sortable && sortedHeader && sortedHeader.id === column.id

        const sortClassName = sorted
          ? sortedHeader.desc
            ? "sorted-desc"
            : "sorted-asc"
          : ""

        return (
          <TranslatedText i18nKey={i18nKey}>
            {value => (
              <Text className={sortClassName}>
                {value ? value.toUpperCase() : ""}
              </Text>
            )}
          </TranslatedText>
        )
      },
      Cell: CellRenderer(contentFunction, isLastColumn),
      sortable: true,
      accessor,
      ...rest
    }
  }

  const {
    headers,
    data = [],
    onRowClick,
    clickable,
    hasChevron,
    ...rest
  } = props

  const columns = headers.map(getColumn)

  return (
    <StyledTable
      loading={props.isLoading}
      columns={columns}
      pageSize={data.length}
      showPagination={false}
      PaginationComponent={() => null}
      data={data}
      minRows={0}
      loadingText={<TranslatedText i18nKey={"LOADING_ROWS"}/>}
      noDataText={<TranslatedText i18nKey={"NO_ROWS_FOUND"}/>}
      onSortedChange={c => {
        sortedHeaderSet({ sortedHeader: c[0] })
      }}
      space={"18px"}
      clickable={clickable}
      hasChevron={hasChevron}
      getTrProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            if (onRowClick) {
              onRowClick({
                e,
                handleOriginal,
                state,
                rowInfo,
                column,
                instance
              })
            } else if (handleOriginal) {
              handleOriginal()
            }
          }
        }
      }}
      {...rest}
    />
  )
}

Table.propTypes = {
  data: PropTypes.any,
  headers: PropTypes.any,
  onRowClick: PropTypes.func,
  clickable: PropTypes.bool,
  hasChevron: PropTypes.bool
}

Table.defaultProps = {
  clickable: false,
  hasChevron: false
}

export default Table
