import React from "react";
import { OptionHeading, OptionText, OptionWrapper, StyledCheckBox, StyledLabel } from "./MultiOptionListItem"
import formatOptionName from "../../../../../utils/formatOptionName"

export const MultiOptionListItemSimple = ({
  option,
  onSelect,
  selectedOptions
}) => {

  const isChecked = selectedOptions?.some((opt) => opt.optionId === option.optionId)
  const isDisabled = false

  return (
    <OptionWrapper>
      <StyledLabel htmlFor={option.optionId.toString()}>
        <StyledCheckBox
          onChange={() => onSelect(option)}
          id={option.optionId.toString()}
          checked={isChecked}
          disabled={isDisabled}
        />
        <OptionText>
          <div>
            <OptionHeading isDisabled={isDisabled} level={6}>
              {formatOptionName(option.optionName)}
            </OptionHeading>
          </div>
        </OptionText>
      </StyledLabel>
    </OptionWrapper>
  )
}
