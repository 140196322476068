import React from 'react'
import { Label, Layout, LayoutItem, Text } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import { useParams } from 'react-router-dom'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useChosenCompany } from '../../../context/UserProvider'
import { useQuery } from 'react-query'
import { fetchPurhcaseReportDetails } from '../../../api/api'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import PurchaseReportDetailsTable from '../components/PurchaseTables/PurchaseReportDetails'
import { Heading } from '../../../components/text/Headings'

const PurchaseReportDetails = () => {
  const company = useChosenCompany()
  const companyId = company && company.id
  const { year, month } = useParams()
  const agreementFilter = useFilterOnConsolidateAgreement()

  const { data = [], isLoading } = useQuery(
    ['purchaseReportDetails', companyId, year, month],
    () => fetchPurhcaseReportDetails(company.id, year, month),
    { enabled: !!companyId }
  )

  return (
    <Container width="unlimited">
      <TranslatedText i18nKey={'PURCHASEREPORT_DETAILS_HEADING'}>
        {(value) => <Heading>{value}</Heading>}
      </TranslatedText>
      <Layout variant="fiftyFifty">
        <LayoutItem>
          <TranslatedText i18nKey="PERIOD">{(value) => <Label>{value}</Label>}</TranslatedText>
          <Text>
            {year}-{month}
          </Text>
        </LayoutItem>
        <LayoutItem>
          <ConsolidateInvoiceSelect />
        </LayoutItem>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <PurchaseReportDetailsTable
        isLoading={isLoading}
        options={{ year, month }}
        data={data.filter(agreementFilter('consolidatedInvoiceAgreement'))}
      />
    </Container>
  )
}

export default PurchaseReportDetails
