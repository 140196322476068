import styled, { css } from 'styled-components'

export const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;

  ${(props) =>
  props.type === 'solid'
    ? css`
          background-color: #2a6df4;
          color: white;
        `
    : props.type === 'outline'
      ? css`
            background-color: transparent;
            border: 2px solid #2a6df4;
            color: #2a6df4;
          `
      : css`
            background-color: transparent;
            color: #2a6df4;
            font-size: 16px;
          `};

  &:hover {
    ${(props) =>
  props.type === 'outline'
    ? css`
            background-color: #2a6df4;
            color: white;
          `
    : css`
              `};
  }
`