import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import tableData from './tollReportDetailsData'
import { columnSum, formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'

type TollReportDetailsProps = {
  data: any
  isLoading?: boolean
  options: { year: string; month: string }
}
function TollReportDetailsTable({ data = [], isLoading, options }: TollReportDetailsProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  const columns = tableData({ options })

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    amountIncVAT: isNaN(columnSum(data, 'amountIncVAT'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'amountIncVAT')),
    antallPasseringer: isNaN(columnSum(data, 'antallPasseringer'))
      ? formatNumber(0)
      : formatNumber(columnSum(data, 'antallPasseringer'), { precision: 0 }),
  }

  return <TanStackTable table={table} isLoading={isLoading} footerData={footerData} />
}

export default TollReportDetailsTable
