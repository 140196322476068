import React from 'react'
import styled from 'styled-components'

const ObliqueBox = () => <ObliqueDiv></ObliqueDiv>

export default ObliqueBox

const ObliqueDiv = styled.div`
  background-color: #272a31;
  width: 100%;
  position: absolute;
  top: 72px;
  left: 0;
  overflow: hidden;
  padding-top: 500px;
  z-index: 0;

  ::after {
    content: '';
    display: block;
    background: var(--color-bgGray);
    height: 500px;
    width: 8000px;
    position: absolute;
    z-index: 1;
    bottom: -50%;
    left: 50%;
    margin-left: -4000px;
    -webkit-transform: rotate(-6deg);
    transform: rotate(-9deg);
  }
`
