import React from "react"

const Environment = () => (
  <svg 
    width="48" 
    height="32" 
    fill="none" 
  >
    <path 
      d="M28.54 14.57c-1.65 0-2.517 1.235-2.61 3.707l-.008 1.004c0 1.332.219 2.36.664 3.078.441.72 1.102 1.078 1.973 1.078.828 0 1.468-.351 1.918-1.058.449-.703.68-1.723.683-3.043v-.625c0-1.355-.223-2.383-.676-3.086-.457-.7-1.105-1.055-1.945-1.055Z" fill="#467EFF"/><path d="M38.71 12.07C37.349 5.191 31.282 0 24 0c-5.785 0-10.793 3.277-13.297 8.07C4.688 8.72 0 13.812 0 20c0 6.629 5.371 12 12 12h26c5.523 0 10-4.477 10-10 0-5.281-4.105-9.559-9.29-9.93ZM13.888 22.488c.367.625.996.938 1.886.938.758 0 1.332-.18 1.735-.531.394-.356.61-.922.633-1.696h3.265c-.043.95-.297 1.785-.765 2.512-.47.727-1.125 1.293-1.97 1.687-.847.403-1.812.602-2.898.602-1.789 0-3.199-.586-4.23-1.75C10.516 23.082 10 21.437 10 19.309v-.676c0-1.332.23-2.504.7-3.504.46-1 1.132-1.774 2.007-2.32.871-.543 1.883-.82 3.031-.82 1.653 0 2.98.437 3.989 1.308 1.003.867 1.574 2.07 1.71 3.598H18.16c-.023-.832-.23-1.426-.625-1.79-.394-.363-.992-.543-1.797-.543-.816 0-1.418.31-1.797.918-.379.614-.582 1.59-.597 2.93v.965c-.004 1.453.18 2.492.543 3.113ZM34.5 19.281c0 1.328-.246 2.504-.738 3.528-.492 1.023-1.196 1.808-2.102 2.363-.906.55-1.941.828-3.101.828-1.165 0-2.192-.266-3.09-.8-.899-.536-1.598-1.298-2.098-2.29-.504-.988-.77-2.129-.793-3.414v-.77c0-1.331.246-2.511.735-3.53.492-1.02 1.195-1.81 2.105-2.364.914-.555 1.953-.832 3.121-.832 1.156 0 2.184.273 3.09.82.902.551 1.605 1.332 2.11 2.344.5 1.016.753 2.18.761 3.488v.63ZM42 26h-6v-1.477l2.691-2.527c.32-.289.957-.851.957-1.422 0-.504-.273-.816-.824-.816a.708.708 0 0 0-.601.297 1.201 1.201 0 0 0-.223.73h-2c0-.785.254-1.445.762-1.98.504-.535 1.16-.801 1.965-.805h-.008.008-.004c.992 0 1.754.215 2.289.648.535.43.804 1.055.804 1.864 0 .5-.136.957-.414 1.37-.273.415-.875.977-1.812 1.68l-.5.438H42v2Z" 
      fill="#467EFF"/>
  </svg>
)

export default Environment