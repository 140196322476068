import { TranslatedText } from "@staccx/i18n";
import formatOptionName from "../../../utils/formatOptionName";
import React from "react";
import styled from "styled-components";

const FormError = ({ page, errors, setRequiredSelections, navigate }) => {
  if (Object.keys(errors).length > 0) {
    return <List>
      {!!errors["requirements"] && <div>
        <FormErrorHeading>
          <TranslatedText i18nKey={errors["requirements"].description}/>
        </FormErrorHeading>
        <ol>
          {errors.requirements.options.map(opt =>
            <ClickableLi
              key={opt.requiredBy.optionId}>
                <ErrorButton onClick={() => setRequiredSelections(opt)}>
                  {formatOptionName(opt.requiredBy.optionName)}
                </ErrorButton>
            </ClickableLi>)}
        </ol>
      </div>}
      {!!errors["needsVerification"] && <div>
        <FormErrorHeading>
          <TranslatedText i18nKey={errors["needsVerification"].description}/>
        </FormErrorHeading>
        <ol>
          {errors.needsVerification?.options?.map(opt =>
            <ClickableLi
              key={opt.excludedBy.optionId}
            >
              <ErrorButton onClick={() => setRequiredSelections(({ requiredBy: opt.excludedBy }))}>
                {formatOptionName(opt.excludedBy.optionName)}
              </ErrorButton>
            </ClickableLi>
          )}
        </ol>
      </div>}
      {Object.keys(errors).filter(key => !["requirements", "needsVerification"].includes(key) && errors[key]).length > 0 && <div>
        <FormErrorHeading>
          <TranslatedText i18nKey={"REQUIRES"}/>
        </FormErrorHeading>
        <ol>
          {Object.keys(errors).filter(key => key !== "requirements" && errors[key].isRequired).map(error =>
            !!errors[error]?.route && errors[error]?.route !== page ? <ClickableLi key={error}>
                <ErrorButton onClick={() => navigate({ route: errors[error]?.route, direction: "skip" })}>
                  <TranslatedText i18nKey={errors[error].type}/>
                </ErrorButton>
              </ClickableLi> :
              <li key={error}>
                <TranslatedText i18nKey={errors[error].type}/>
              </li>
          )}
        </ol>
      </div>}
    </List>
  }

  return null
}

export default FormError

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-smallMinus);

  ol {
    padding: var(--spacing-smallMinus) 0;
  }
`

const ClickableLi = styled.li`
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-primary);
`

const ErrorButton = styled.button`
  all: unset;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const FormErrorHeading = styled.span`
  font-size: var(--font-h3);
  color: var(--color-warning);
`
