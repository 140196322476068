import { TranslatedText } from '@staccx/i18n'
import { Button, theming } from '@staccx/bento'
import styled from 'styled-components'

export const BooleanOption = ({ label, selected, onClick }) => (
  <Column>
    <Row>
      <Heading>{label}</Heading>
    </Row>
    <Row>
      <ButtonWrapper>
        <SwitchButton key="YES" variant="toggleButton" active={selected} onClick={() => onClick(true)}>
          <TranslatedText i18nKey="YES">{(value) => value}</TranslatedText>
        </SwitchButton>
        <SwitchButton key="NO" variant="toggleButton" active={!selected} onClick={() => onClick(false)}>
          <TranslatedText i18nKey="NO">{(value) => value}</TranslatedText>
        </SwitchButton>
      </ButtonWrapper>
    </Row>
  </Column>
)

const Heading = styled.label`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h4);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: ${theming.spacing.large()};

  @media only screen and (max-width: 1219px) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: var(--spacing-tinyMinus) 0;
  width: 100%;
  > div.react-select-container {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  > button {
    font-weight: var(--fontWeight-bold);
    font-size: var(--font-h3);
    margin: 0 auto;
    width: 100%;
    height: var(--spacing-large);
  }

  button:first-child {
    border-radius: var(--spacing-tinyMinus) 0 0 var(--spacing-tinyMinus);
  }

  button:last-child {
    border-radius: 0 var(--spacing-tinyMinus) var(--spacing-tinyMinus) 0;
  }
`

const SwitchButton = styled(Button)`
  height: var(--spacing-large);
`
