import { Button, ItemGroup } from "@staccx/bento";
import { TranslatedText } from "@staccx/i18n";
import React from "react";
import styled from "styled-components";

const defaultButtonNames = { next: "NEXT", previous: "PREVIOUS" }

const Navigation = ({ updateFlowHandler, buttonNames = defaultButtonNames }) => {
  buttonNames = {
    ...defaultButtonNames,
    ...buttonNames
  }

  return <ItemGroup variant="paginationLeft">
    <StyledButton onClick={() => updateFlowHandler({ direction: "next" })}>
      <TranslatedText i18nKey={buttonNames.next}/>
    </StyledButton>
    <StyledButton onClick={() => updateFlowHandler({ direction: "previous" })}>
      <TranslatedText i18nKey={buttonNames.previous}/>
    </StyledButton>
  </ItemGroup>
}

export default Navigation

const StyledButton = styled(Button)`
  height: var(--spacing-largePlus);
  width: auto;
  font-size: var(--font-h3);
`
