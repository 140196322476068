import React from 'react'

interface DoorsProps {
  width?: number
  height?: number
  color?: string
}

const Doors = ({ width = 50, height = 50, color = 'black' }: DoorsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.25 29.5C30.2835 29.5 29.5 30.2835 29.5 31.25C29.5 32.2165 30.2835 33 31.25 33H39.5833C40.5498 33 41.3333 32.2165 41.3333 31.25C41.3333 30.2835 40.5498 29.5 39.5833 29.5H31.25Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.61643 7.98304C15.3823 3.58997 23.5656 0.333374 33.3333 0.333374H43.7499C45.3191 0.333374 46.824 0.956736 47.9336 2.06633C49.0432 3.17592 49.6666 4.68084 49.6666 6.25004V22.8871L49.6668 22.9166L49.6666 22.9462V47.9167C49.6666 48.8832 48.8831 49.6667 47.9166 49.6667H2.08325C1.11675 49.6667 0.333252 48.8832 0.333252 47.9167V22.9167C0.333252 17.9728 3.93779 12.3096 9.61643 7.98304ZM45.4588 4.5412C45.912 4.99441 46.1666 5.6091 46.1666 6.25004V21.1666H4.0424C4.76135 18.0032 7.3327 14.1231 11.7376 10.767C16.9092 6.82677 24.351 3.83337 33.3333 3.83337H43.7499C44.3909 3.83337 45.0055 4.08799 45.4588 4.5412ZM3.83325 46.1667V24.6666H46.1666V46.1667H3.83325Z"
      fill={color}
    />
  </svg>
)

export default Doors
