import React from 'react'
import styled, { css } from 'styled-components'
import Tick from '../../../icons/Tick'
import { TranslatedText } from '@staccx/i18n'
import { routeKeys } from '../hooks/useCarConfiguration'

const OK = 1
const UNCOMPLETED = 2
const NEEDS_WORK = 3

const getCircleColor = (number) => {
  switch (number) {
    case 1:
      return { color: 'primary', state: number }
    case 2:
      return { color: 'disabled', state: number }
    case 3:
      return { color: 'warning', state: number }
    default:
      return { color: 'primary', state: 2 }
  }
}

const getPageState = (
  elementsToValidate,
  index,
  indexOfCurrent,
  validate,
  element,
  currentElement,
  isVisited,
  lastElement
) => {
  const isBeforeCurrent = indexOfCurrent !== -1 && index < indexOfCurrent
  const errorsExistOnThis = elementsToValidate.includes(element) && validate(element)
  const isNotCurrentElement = currentElement !== element && element !== lastElement
  const isNotLastElement = element !== lastElement

  return isBeforeCurrent && isNotCurrentElement && isNotLastElement
    ? getCircleColor(errorsExistOnThis ? NEEDS_WORK : OK)
    : getCircleColor(isBeforeCurrent && isVisited && isNotLastElement ? OK : UNCOMPLETED)
}

const ProgressBar = ({
  elements,
  currentElement,
  onClick = () => {},
  omit = [],
  validate = () => {},
  visitedElements = {}
}) => {
  elements = elements.filter((el) => !omit.includes(el))
  const indexOfCurrent = elements.indexOf(currentElement)
  const lastElement = elements.slice(-1)[0]

  // all except omitted and last element
  const elementsToValidate = elements.filter((el) => !omit.includes(el)).slice(0, -1)
  const processed = elements.reduce(
    (acc, element, index) => ({
      ...acc,
      [element]: getPageState(
        elementsToValidate,
        index,
        indexOfCurrent,
        validate,
        element,
        currentElement,
        !!visitedElements[element],
        lastElement
      )
    }),
    {}
  )

  return (
    <ProgressBarContainer elements={elements}>
      {elements.map((el, i) => (
        <Container index={i} onClick={() => onClick({ route: el, direction: 'skip' })} key={el}>
          <div>
            <Circle color={processed[el].color}>
              <Tick />
            </Circle>
          </div>
          <div>
            <TranslatedText i18nKey={routeKeys[el]}>
              {(value) => (
                <ProgressHeading isCurrent={el === currentElement}>
                  {!value.startsWith('Missing entry') ? value : el}
                </ProgressHeading>
              )}
            </TranslatedText>
          </div>
        </Container>
      ))}
    </ProgressBarContainer>
  )
}

export default ProgressBar

const ProgressBarContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: '. ${(props) => props.elements.map((el, i) => `item${i} `)} .';
  grid-template-columns: 0 ${(props) => props.elements.map((el, i) => `1fr `)};
`

const Container = styled.div`
  display: grid;
  grid-area: ${(props) => `item${props.index}`};
  position: relative;
  gap: var(--spacing-smallMinus);

  > div {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-primary);
  }

  &:first-child::after {
    left: 50%;
    width: 50%;
  }

  &:last-child::after {
    left: 0;
    width: 50%;
  }
`

const Circle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: var(--color-${(props) => props.color});
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: var(--spacing-smallPlus);
  }
`

const ProgressHeading = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);

  :hover {
    transition: 0.3s;
    transform: scale(1.1);
  }

  ${(props) =>
    props.isCurrent
      ? css`
          font-weight: var(--fontWeight-bold);
        `
      : null};
  cursor: pointer;
`
