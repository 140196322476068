import styled from 'styled-components'
import { add, format } from 'date-fns'
import { i18nInstance } from '@staccx/i18n'
import { Button, theming } from '@staccx/bento'
import { Card } from '../Card'
import Signing from '../../../../icons/Signing'

export const SigningsList = ({ pendingSignings }) => {
  const handleClick = (flow) => {
    const signingUrl = flow.data.documents[0].signingUrl
    if (signingUrl) {
      window.location.href = signingUrl
    }
  }

  return (
    <ListContainer>
      {pendingSignings.map((flow) => {
        const expiresAt = add(new Date(flow.createdAt), { months: 1 })
        const signingType = flow.data.signingType
        return (
          <Card
            key={flow._id}
            icon={<Signing />}
            title={`${flow.data.budgetData.carMake.toUpperCase()} ${flow.data.budgetData.carModel.toUpperCase()} - ${
              signingType === 'budget' 
                ? i18nInstance.translate('BUDGET') ?? 'Budsjett'
                : i18nInstance.translate('EXTENSION') ?? 'Forlengelse'
            } ${flow.data.budgetId}`}
            subtitle={
              <Text isError={new Date().getTime() > expiresAt.getTime()}>
                {i18nInstance.translate('DEADLINE') ?? 'Frist'}: {format(expiresAt, 'dd.MM.yyyy')}
              </Text>
            }
            action={<Button onClick={() => handleClick(flow)}>Gå til signering</Button>}
          />
        )
      })}
    </ListContainer>
  )
}

const ListContainer = styled.div`
  margin-bottom: ${theming.spacing.medium()};
`

const Text = styled.span`
  color: ${(props) => (props.isError ? 'var(--color-warning)' : 'inherit')};
`
