import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import mileageReportDetailsTableData from './mileageReportDetailsTableData'
import TanStackTable from '../../../../../components/tables/TanStackTable'

const columns = mileageReportDetailsTableData()

type MileageReportDetailsTableProps = {
  data: any
  isLoading?: boolean
}

function MileageReportDetailsTable({ data, isLoading }: MileageReportDetailsTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <TanStackTable table={table} isLoading={isLoading} />
}

export default MileageReportDetailsTable
