import React, { useState } from 'react'
import { Card } from '../Card'
import { i18nInstance } from '@staccx/i18n'
import { Button } from '@staccx/bento'
import Dialog from '../../../../components/modal/Dialog'
import { CloseButton } from '../../../../theme/Theme.Button'
import Close from '../../../../icons/Close'
import BudgetPreview from './BudgetPreview'
import styled from 'styled-components'
import { displayBudgetText } from '../displayBudgetText'

const BudgetCheckboxListItem = ({ flow, selectedValues, handleChange }) => {
  const [showDialog, setShowDialog] = useState(false)

  const budgetData = flow.data.budgetData

  return (
    <>
      <Card
        key={flow.flowId}
        icon={
          <Checkbox
            type="checkbox"
            checked={selectedValues.includes(flow.flowId)}
            onChange={() => handleChange(flow.flowId)}
          />
        }
        title={displayBudgetText({
          ...budgetData,
          signingType: flow?.data?.signingType,
        })}
        action={
          <Button variant={'goto'} onClick={() => setShowDialog(true)}>
            Forhåndsvis
          </Button>
        }
      ></Card>

      <Dialog
        open={showDialog}
        onOpenChange={setShowDialog}
        width="fit-content"
      >
        <CloseButton onClick={() => setShowDialog(false)}>
          <Close />
        </CloseButton>
        <BudgetPreview
          flowId={flow.flowId}
          attachmentKey={budgetData.document.attachmentId}
          mimeType={budgetData.document.mimetype}
        />
      </Dialog>
    </>
  )
}

const Checkbox = styled.input`
  margin-right: 5px;
  width: 20px;
  height: 20px;
  padding: 15px;
  box-sizing: border-box;
`

export default BudgetCheckboxListItem
