import { useRef } from 'react'
import { flexRender, Table } from '@tanstack/react-table'
import styled from 'styled-components'

import { useVirtual } from 'react-virtual'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import ChevronRight from '../../icons/ChevronRight'
import { TableSkeleton } from '../../pages/carConfiguration/components/skeleton/TableSkeleton'

type MetaType = {
  alignmentType?: string
}

type TanStackTableProps = {
  table: Table<any>
  handleRowClick?: (row: any) => void
  isLoading?: boolean
  footerData?: Record<string, any>
  checkForMissingData?: boolean
  wrapHeaderText?: boolean
}

export default function TanStackTable({
  table,
  handleRowClick,
  isLoading,
  footerData = {},
  checkForMissingData,
  wrapHeaderText
}: TanStackTableProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10
  })

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0

  return (
    <TableWrapper ref={tableContainerRef}>
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    wrapHeaderText={wrapHeaderText}
                    key={header.id}
                    data-type={`${
                      header?.column.columnDef && (header?.column.columnDef.meta as MetaType).alignmentType
                    }`}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                  >
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? `cursor-pointer select-none sorted-${header?.column?.getIsSorted() as string}`
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: '',
                        desc: '',
                        default: ''
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </TableHead>
                )
              })}
              {!!handleRowClick && (
                <TableHead data-type={'chevron'} key={'chevron-placement'}>
                  {' '}
                </TableHead>
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading && (
            <TableSkeleton
              colSpan={
                !!handleRowClick
                  ? table?.getHeaderGroups()[0]?.headers?.length + 1
                  : table?.getHeaderGroups()[0]?.headers?.length
              }
            />
          )}
          {!isLoading && table.getRowModel().rows?.length === 0 && (
            <tr key={'no-rows-found'}>
              <EmptyTableData
                colSpan={
                  !!handleRowClick
                    ? table?.getHeaderGroups()[0]?.headers?.length + 1
                    : table?.getHeaderGroups()[0]?.headers?.length
                }
              >
                <Wrapper>
                  <TranslatedText i18nKey={'NO_ROWS_FOUND'} />
                </Wrapper>
              </EmptyTableData>
            </tr>
          )}
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index]
            return (
              <TableRow
                wrapHeaderText={wrapHeaderText}
                key={row.id}
                canRowClick={!!handleRowClick}
                onClick={() => handleRowClick && handleRowClick(row)}
                missingData={checkForMissingData ? !row.original.phoneNo || !row.original.email : false}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableData
                      key={cell.id}
                      data-type={`${cell?.column.columnDef && (cell?.column.columnDef.meta as MetaType).alignmentType}`}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableData>
                  )
                })}
                {!!handleRowClick && (
                  <TableData data-type={'chevron'}>
                    <ChevronRight width={12} height={12} />
                  </TableData>
                )}
              </TableRow>
            )
          })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
        {Object.keys(footerData).length > 0 && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <TableFooter key={header.id} data-type={'number'}>
                    {header.isPlaceholder ? null : footerData[header.id]}
                  </TableFooter>
                ))}
                {!!handleRowClick && <TableData data-type={'chevron'}></TableData>}
              </tr>
            ))}
          </tfoot>
        )}
      </StyledTable>
    </TableWrapper>
  )
}

interface StyledEmptyTableDataProps extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  colSpan: number

}
const EmptyTableData = styled.td<StyledEmptyTableDataProps>`
  border: 1px solid var(--color-line);
`

const Wrapper = styled.div`
  height: var(--spacing-huge);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface StyledTableWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  ref: any

}

const TableWrapper = styled.div<StyledTableWrapperProps>`
  position: relative;
  max-height: 70vh;
  overflow: auto;
`

interface StyledTableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  canRowClick: boolean;
  missingData: boolean;
  wrapHeaderText: boolean | undefined;
}

const TableRow = styled.tr<StyledTableRowProps>`
  border-bottom: 1px solid var(--color-rowLine);
  height: var(--spacing-large);
  padding: 0 var(--spacing-tiny);
  white-space: ${(props) => (props.wrapHeaderText ? 'wrap' : '')};
  &:first-of-type {
    border-top: ${({ canRowClick }) => (canRowClick ? '1px solid transparent' : 'none')};
  }
  &:hover {
    &:first-of-type {
      border-top-color: ${({ canRowClick }) => (canRowClick ? 'var(--color-rowLine)' : 'inherit')};
    }
    cursor: ${({ canRowClick }) => (canRowClick ? 'pointer' : 'default')};
    background-color: ${({ canRowClick }) => (canRowClick ? 'var(--color-secondary)' : 'inherit')};
  }
  background-color: ${(props) => (props.missingData ? 'var(--color-subtleWarning)' : '')};
`

interface StyledTableHeadProps extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  wrapHeaderText?: boolean | undefined;
}

const TableHead = styled.th<StyledTableHeadProps>`
  color: var(--color-headerText);
  font-weight: var(--fontWeight-normal);
  text-transform: uppercase;
  font-size: var(--font-small);
  white-space: ${(props) => (props.wrapHeaderText ? 'wrap' : 'nowrap')};
  padding: var(--spacing-tiny) var(--spacing-tiny);
  position: relative;

  div.sorted-asc span {
    &::after {
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' height='10' width='6' viewBox='0 0 6 10'%3e%3cpath fill='%23467EFF' d='M-.06 2.06L1.35.65l4.71 4.7-4.7 4.71-1.42-1.41 3.3-3.3z'/%3e%3c/svg%3e");
      padding-left: 18px;
      transition: 0.2s ease-out transform;
      transform: rotate(270deg);
      display: inline-table;
      margin-left: 15px;
      height: 10px;
      width: 6px;
    }
  }

  div.sorted-desc span {
    &::after {
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' height='10' width='6' viewBox='0 0 6 10'%3e%3cpath fill='%23467EFF' d='M-.06 2.06L1.35.65l4.71 4.7-4.7 4.71-1.42-1.41 3.3-3.3z'/%3e%3c/svg%3e");
      padding-left: 18px;
      transition: 0.2s ease-out transform;
      transform: rotate(90deg);
      display: inline-table;
      margin-left: 15px;
      height: 10px;
      width: 6px;
    }
  }
`

interface StyledTableDataProps extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  'data-type': string
}

const TableData = styled.td<StyledTableDataProps>`
  font-size: var(--font-small);
  font-weight: var(--fontWeight-normal);
  white-space: break-spaces;
  max-width: var(--spacing-huge);
  width: fit-content;
  color: var(--color-headerText);
  a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary);
  }
`

interface StyledTableFooterProps extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  'data-type': string
}
const TableFooter = styled.th<StyledTableFooterProps>`
  color: var(--color-headerText);
  font-weight: var(--fontWeight-normal);
  font-size: var(--font-small);
  height: var(--spacing-large);
  white-space: nowrap;
`

const StyledTable = styled.table`
  border-collapse: initial;
  width: 100%;
  position: relative;
  border: 1px solid var(--color-line);
  overflow: auto;
  font-size: var(--font-small);
  thead,
  tfoot {
    height: var(--spacing-large);
    background-color: var(--color-secondary);
    white-space: break-spaces;
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
  }

  tbody {
    background: var(--color-rowBackground);
  }

  tbody tr:last-child {
    border-bottom: 0;
  }

  th,
  td {
    padding: var(--spacing-tiny) var(--spacing-tiny);
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
  }

  ${TableHead}, ${TableData}, ${TableFooter} {
    &[data-type='string'],
    &[data-type='date'] {
      text-align: start;
    }
    &[data-type='chevron'],
    &[data-type='number'] {
      text-align: end;
    }
    &[data-type='chevron'] {
      width: 2rem;
    }
  }
`
