import { Budget } from '../../../types'
// @ts-ignore
import {i18nInstance} from "@staccx/i18n";

export function displayBudgetText(budget: Budget): string {
  const makeModel = `${budget?.carMake?.toUpperCase() ?? ''} ${
    budget?.carModel?.toUpperCase() ?? ''
  }`
  const signingType =  i18nInstance.translate(budget.signingType.toUpperCase()) ?? 'Ukjent'
  const budgeTypeAndId = `${signingType} ${budget.budgetId || ''}`
  const driver = budget.driver

  if (driver) {
    return `${makeModel} - ${budgeTypeAndId} - ${driver}`
  } else {
    return `${makeModel} - ${budgeTypeAndId}`
  }
}
