import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import TanStackTable from '../../../../../components/tables/TanStackTable'
import tableData from './fuelReportDetailsData'
import { columnSum, formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'

type FuelReportDetailsTableProps = {
  data: any
  isLoading?: boolean
  options: { year: string; month: string }
}
function FuelReportDetailsTable({ data, isLoading, options }: FuelReportDetailsTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])

  const columns = tableData({ options })
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const footerData = {
    amountincVAT: isNaN(columnSum(data, 'amountincVAT'))
      ? formatCurrency(0)
      : formatCurrency(columnSum(data, 'amountincVAT')),
    volume: isNaN(columnSum(data, 'volume')) ? formatNumber(0) : formatNumber(columnSum(data, 'volume')),
  }
  return <TanStackTable table={table} footerData={footerData} isLoading={isLoading} />
}

export default FuelReportDetailsTable
