import React from 'react'
// @ts-ignore
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatDate, formatTime } from '../../../../../utils/tableFuncs'
import { createColumnHelper } from '@tanstack/react-table'
import { TollReportAccountDetails } from '../../../../../types'

export default function tollReportAccountDetailsData() {
  const columnHelper = createColumnHelper<TollReportAccountDetails>()
  return [
    columnHelper.accessor('transactionDate', {
      id: 'transactionDate',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'DATE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
    }),
    columnHelper.accessor('transactionTime', {
      id: 'transactionTime',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'TIME'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatTime(row.getValue()) || '',
    }),
    columnHelper.accessor('referenceNo', {
      id: 'referenceNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'CHIP_ID'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('tollStation', {
      id: 'tollStation',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'PLACE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('field', {
      id: 'field',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'TOLL_FIELD'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      meta: { alignmentType: 'number' },
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value: string) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
    }),
  ]
}
