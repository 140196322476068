import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { Text } from '@staccx/bento'
import { LanguageSettings, TranslatedText } from '@staccx/i18n'
import { useI18nContext } from '../../../context/I18nProvider'

const renderLanguage = (langCode) => {
  switch (langCode) {
    case 'nb':
    case 'en':
      return <TranslatedText i18nKey={langCode} />
    default:
      return <Text>{langCode}</Text>
  }
}

const Language = () => {
  const { setLanguage: setContextLanguage } = useI18nContext()

  return (
    <LanguageSettings>
      {({ setLanguage, language, languages }) => (
        <StyledDropDown
          components={{
            IndicatorSeparator: () => null,
          }}
          options={languages.map((lang) => ({
            value: lang,
            label: renderLanguage(lang),
          }))}
          onChange={(lang) => {
            setLanguage(lang.value)
            setContextLanguage(lang.value)
          }}
          value={{ value: language, label: renderLanguage(language) }}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      )}
    </LanguageSettings>
  )
}

export default Language

const StyledDropDown = styled(Select)`
  div.react-select__control {
    width: auto;
    border-radius: var(--borderRadius);
    color: var(--color-black);
    background-color: #f2f2f2;
    height: var(--spacing-large);
    border: none;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      box-shadow: 4px 4px 4px var(--color-shadow);
    }
  }

  div.react-select__dropdown-indicator {
    padding: var(--spacing-tinyPlus) var(--spacing-smallPlus) var(--spacing-tinyPlus) var(--spacing-tinyPlus);
    color: var(--color-black);
  }

  div.react-select__value-container {
    font-size: var(--font-base);
  }

  div.react-select__menu {
    border-radius: 0;
    margin-top: var(--spacing-tinyMinus);
  }

  div.react-select__menu-list {
    padding: 0;
  }

  div.react-select__option {
    font-size: var(--font-base);

    &:hover {
      cursor: pointer;
      outline: none;
      background-color: var(--color-subtleHover);
      color: var(--color-black);
    }
  }
`
