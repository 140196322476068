import React from "react"

const AutoplanSmall = () => (
  <svg width="67" height="44" viewBox="0 0 67 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.60743 31.4541H2.99184L0.0199456 41.1676L0 41.2274H1.31641L2.13418 38.475H5.44515L6.24297 41.1876L6.26292 41.2274H7.59928L4.60743 31.494V31.4541ZM3.76972 32.7905L5.10607 37.358H2.39347L3.72983 32.7905H3.76972Z" fill="#0D4BCC"/>
<path d="M13.583 38.9937C13.583 39.233 13.5431 39.4325 13.4434 39.592C13.3436 39.7516 13.224 39.8912 13.0644 39.9909C12.9048 40.0907 12.7253 40.1704 12.5259 40.2303C12.3264 40.2901 12.107 40.3101 11.8876 40.3101C10.8305 40.3101 10.3119 39.6917 10.3119 38.4352V33.9873H9.09521V38.6147C9.09521 39.4923 9.31462 40.1904 9.75342 40.6691C10.1922 41.1478 10.7906 41.4071 11.5485 41.4071C11.8477 41.4071 12.107 41.3672 12.3463 41.2874C12.5658 41.2076 12.7652 41.1079 12.9248 40.9882C13.0843 40.8685 13.224 40.709 13.3237 40.5494C13.4035 40.4098 13.4832 40.2702 13.563 40.1505V41.2475H14.7797V33.9873H13.563V38.9937H13.583Z" fill="#0D4BCC"/>
<path d="M18.8088 32.0127H17.7117V33.3889C17.7117 33.6083 17.6719 33.7679 17.5921 33.8676C17.5123 33.9474 17.3527 33.9873 17.1333 33.9873H16.4153V35.0644H17.5921V40.0109C17.5921 40.3699 17.6918 40.6691 17.9112 40.8885C18.1107 41.1079 18.4298 41.2276 18.8287 41.2276H20.2648V40.1505H18.8088V35.0644H20.3845V33.9873H18.8088V32.0127V32.0127Z" fill="#0D4BCC"/>
<path d="M27.2657 34.8448C26.9665 34.5057 26.6274 34.2464 26.2285 34.0669C25.8296 33.8874 25.3908 33.8076 24.8922 33.8076C24.4135 33.8076 23.9547 33.9073 23.5558 34.0669C23.1569 34.2464 22.7979 34.5057 22.5186 34.8448C22.2394 35.1839 22.0001 35.5828 21.8604 36.0415C21.7009 36.5003 21.6211 37.0189 21.6211 37.5973C21.6211 38.1757 21.7009 38.6943 21.8604 39.153C22.02 39.6118 22.2394 40.0107 22.5186 40.3498C22.7979 40.6889 23.1569 40.9481 23.5558 41.1277C23.9547 41.3072 24.4135 41.3869 24.8922 41.3869C25.3709 41.3869 25.8296 41.2872 26.2285 41.1277C26.6274 40.9481 26.9665 40.6889 27.2657 40.3498C27.5449 40.0107 27.7843 39.6118 27.9439 39.153C28.1034 38.6943 28.1832 38.1757 28.1832 37.5973C28.1832 37.0388 28.1034 36.5003 27.9439 36.0415C27.7843 35.5828 27.5449 35.1839 27.2657 34.8448ZM26.8867 37.0986V38.1159C26.8867 38.8539 26.7072 39.4123 26.3283 39.7714C25.9692 40.1304 25.4706 40.3099 24.8922 40.3099C24.3138 40.3099 23.8151 40.1304 23.4561 39.7714C23.0971 39.4123 22.8976 38.8539 22.8976 38.1159V37.0986C22.8976 36.3607 23.0771 35.8022 23.4561 35.4432C23.8151 35.0841 24.3138 34.9046 24.8922 34.9046C25.4706 34.9046 25.9493 35.0841 26.3283 35.4432C26.6873 35.8022 26.8867 36.3607 26.8867 37.0986Z" fill="#0D4BCC"/>
<path d="M35.4432 34.8453C35.1839 34.5262 34.8648 34.2669 34.5058 34.0874C34.1468 33.9079 33.7279 33.8281 33.2691 33.8281C32.7705 33.8281 32.3317 33.9478 31.9527 34.1672C31.6137 34.3667 31.3544 34.6858 31.1549 35.0847V33.9877H29.9382V44.0004H31.1549V40.1309C31.5937 40.9686 32.2918 41.4075 33.2492 41.4075C33.688 41.4075 34.1069 41.3077 34.4858 41.1482C34.8448 40.9687 35.164 40.7094 35.4233 40.3902C35.6826 40.0711 35.882 39.6522 36.0216 39.1935C36.1613 38.7347 36.2211 38.1962 36.2211 37.6178C36.2211 37.0394 36.1613 36.5208 36.0216 36.0421C35.902 35.5833 35.7025 35.1844 35.4432 34.8453ZM34.9446 36.9995V38.2162C34.9446 38.8345 34.7651 39.3531 34.426 39.732C34.0869 40.111 33.5883 40.3104 32.9899 40.3104C32.7506 40.3104 32.5112 40.2706 32.2918 40.2107C32.0724 40.1509 31.873 40.0512 31.7134 39.9315C31.5538 39.8118 31.4342 39.6522 31.3344 39.4927C31.2347 39.3132 31.1948 39.1137 31.1948 38.8943V36.2216C31.1948 36.0421 31.2347 35.8626 31.3344 35.703C31.4342 35.5434 31.5538 35.4038 31.7134 35.2842C31.873 35.1645 32.0724 35.0648 32.2918 35.0049C32.5112 34.9451 32.7506 34.9052 32.9899 34.9052C33.6082 34.9052 34.0869 35.1046 34.426 35.4836C34.7651 35.8626 34.9446 36.3812 34.9446 36.9995Z" fill="#0D4BCC"/>
<path d="M39.2929 30.876H38.0962V40.031C38.0962 40.3701 38.1959 40.6693 38.4153 40.8887C38.6148 41.1081 38.9339 41.2277 39.3328 41.2277H40.37V40.1507H39.2929V30.876Z" fill="#0D4BCC"/>
<path d="M47.2313 36.2809C47.2313 35.5229 46.9919 34.9046 46.4933 34.4658C46.0146 34.027 45.3165 33.8076 44.4389 33.8076C43.7807 33.8076 43.2422 33.9472 42.8034 34.2265C42.3645 34.5057 42.0454 34.8448 41.8659 35.2437L41.846 35.2836L42.584 35.9817L42.6238 35.9219C42.8034 35.6027 43.0427 35.3434 43.3419 35.1639C43.6211 34.9844 43.9801 34.8847 44.399 34.8847C44.9575 34.8847 45.3763 35.0243 45.6356 35.2636C45.8949 35.5229 46.0345 35.9019 46.0345 36.4005V37.0588H44.6782C43.6211 37.0588 42.8432 37.2383 42.3446 37.6172C41.846 37.9762 41.5867 38.5148 41.5867 39.2129C41.5867 39.891 41.8061 40.4296 42.2249 40.8285C42.6438 41.2274 43.2621 41.4268 44.02 41.4268C44.5187 41.4268 44.9774 41.3072 45.3364 41.0878C45.6955 40.8684 45.9548 40.5492 46.1143 40.1104C46.1742 40.4296 46.2739 40.6889 46.4534 40.9083C46.6329 41.1476 46.952 41.2673 47.3908 41.2673H48.069V40.1902H47.2512V36.2809H47.2313ZM42.8233 39.0134C42.8233 38.6743 42.9629 38.435 43.2422 38.2555C43.5214 38.076 43.9801 37.9762 44.5985 37.9762H46.0146V39.1131C46.0146 39.2927 45.9747 39.4522 45.875 39.5918C45.7952 39.7514 45.6556 39.8711 45.496 39.9908C45.3364 40.1104 45.137 40.1902 44.9176 40.27C44.6982 40.3298 44.4389 40.3697 44.1597 40.3697C43.7408 40.3697 43.4017 40.27 43.1624 40.0905C42.923 39.911 42.8034 39.6517 42.8034 39.3126V39.0134H42.8233Z" fill="#0D4BCC"/>
<path d="M54.7108 34.5456C54.272 34.0669 53.6736 33.8076 52.9157 33.8076C52.3772 33.8076 51.9384 33.9273 51.5993 34.1666C51.3001 34.386 51.0607 34.6852 50.8812 35.0642V33.9672H49.6646V41.2274H50.8812V36.2011C50.8812 35.9817 50.9211 35.7822 51.0209 35.6227C51.1206 35.4631 51.2403 35.3235 51.3998 35.2238C51.5594 35.124 51.7389 35.0243 51.9383 34.9844C52.1378 34.9246 52.3572 34.9046 52.5766 34.9046C53.6138 34.9046 54.1523 35.5429 54.1523 36.7795V41.2274H55.369V36.6C55.349 35.7224 55.1296 35.0243 54.7108 34.5456Z" fill="#0D4BCC"/>
<path d="M29.9783 23.8549V28.3826L66.8976 17.0734V11.3092L29.9783 0V4.40798L40.5494 7.53944V20.7235L40.4896 20.7434L29.9783 23.8549ZM44.2793 19.7063V8.53672L44.3989 8.57661L62.3899 13.8024V14.4406L62.3101 14.4606L44.2793 19.7063Z" fill="#0D4BCC"/>
</svg>


)

export default AutoplanSmall