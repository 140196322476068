import styled from 'styled-components'
import LinkButton from "../LinkButton"
import { fetchInvoiceDocument, fetchInvoiceDocumentAttachments } from "../../api/api"
import Pdf from "../../icons/Pdf"

export default ({ row, getValue }) => {
  const { sourceID, sourceType = -1 } = row.original

  if (sourceType === 0 && sourceID) {
    return (
      <LinkButtonMarginless onClick={() => fetchInvoiceDocument(sourceID, `${row.original.documentNo}-${row.original.lineNo}`)}>
        <Pdf />
        {getValue()}
      </LinkButtonMarginless>
    )
  } else if (sourceType === 2 && sourceID) {
    return (
      <LinkButtonMarginless
        onClick={() => fetchInvoiceDocumentAttachments(sourceID, `${row.original.documentNo}-${row.original.lineNo}`)}
      >
        <Pdf />
        {getValue()}
      </LinkButtonMarginless>
    )
  }
  return getValue()
}

const LinkButtonMarginless = styled(LinkButton)`
  margin: 0;
  font-size: var(--font--small);
`
