import React from "react";
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '../../../../utils/tableFuncs'

export default function eventListRefuelData() {
    return [
        {
          accessorKey: 'date',
          id: 'date',
          header: () => <TranslatedText i18nKey={'DATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
          cell: ({ row }) => {
            const date = row.original.date 
            return date ? formatDate(date) : ''
          },
          meta: { alignmentType: 'date' },
        },
          {
            accessorKey: 'carId',
            id: 'carId',
            header: () => <TranslatedText i18nKey={'CAR_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'car.driverName',
            id: 'car.driverName',
            header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'location',
            id: 'location',
            header: () => <TranslatedText i18nKey={'LOCATION'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'volume',
            id: 'volume',
            header: () => <TranslatedText i18nKey={'VOLUME'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
          {
            accessorKey: 'price',
            id: 'price',
            header: () => <TranslatedText i18nKey={'PRICE'}>{(value) => <span>{value}</span>}</TranslatedText>,
            cell: ({ getValue }) => getValue() || '',
            meta: { alignmentType: 'string' },
          },
    ]
}