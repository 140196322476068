import React from "react"
import { Text } from "@staccx/bento"
import { Heading} from "../components/text/Headings";
import ReactMarkdown from "react-markdown"

const blockSerializer = {
  types: {
    block: props => {
      switch (props.node.style) {
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
          return <Heading level={props.node.style}>{props.children}</Heading>
        default:
          return (
            <>
              <Text level={props.node.style}>{props.children}</Text>
              <br />
            </>
          )      }
    }, 
    localeMarkdown: ({node}) => console.log(node) || <ReactMarkdown children={node} />
  },
  list: props => <Text>{props.children}</Text>,
  marks: {}
}

export default blockSerializer
