import React from 'react'
import styled from 'styled-components'
import { ToastContainer } from "react-toastify"

const Toast = (props) => <StyledToast props={props} />

export default Toast

const StyledToast = styled(ToastContainer)`
  top: 75px;
  z-index: 999;
`
