import React from 'react'

const Warning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="18"
    height="18"
  >
    <path
      d="M14.5425 2.59838C13.459 0.467205 10.5409 0.467209 9.4574 2.59838L1.34086 18.5627C0.371498 20.4694 1.59676 23 3.88342 23H20.1165C22.4032 23 23.6284 20.4694 22.6591 18.5627L14.5425 2.59838ZM12 8C12.5523 8 13 8.44772 13 9V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V9C11 8.44772 11.4477 8 12 8ZM12 16.5C12.5523 16.5 13 16.9477 13 17.5V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V17.5C11 16.9477 11.4477 16.5 12 16.5Z"
      fill="#FF5B30"
    ></path>
  </svg>
)

export default Warning
