import React from 'react'
import PropTypes from 'prop-types'
import {
  easeBackOut,
  easeBounceOut,
  easeCircleOut,
  easeLinear,
  max,
  min,
  scaleLinear,
  select,
  selectAll,
} from 'd3'
import styled from 'styled-components'
import { Button } from '@staccx/bento'
import { createQueryParams } from '../../../utils/createQueryParams'
import { CarStatus } from '../../../types'

const colors = {
  blue: '#2a6df4',
  green: '#66CD73',
  red: '#FF5B30',
}

const isStatus = (carStatus, status) => status === carStatus
const shouldRenderGroup = (carStatus) => (status) =>
  !carStatus || isStatus(status, carStatus)

class CarParkOverview extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.rerender = this.rerender.bind(this)

    this.createLine = this.createLine.bind(this)

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.rerender()
  }

  componentDidUpdate() {
    this.rerender()
  }

  rerender() {
    if (!this.props.active || !this.props.incoming || !this.props.outgoing) {
      return
    }

    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(() => {
      const node = this.node

      if (!node) {
        return
      }

      const width = node.width.baseVal.value

      const top = select(node)

      const endsWidth = width * this.props.endsRatio

      const endActiveX = width - endsWidth

      const activeWidth = width - endsWidth * 2 - 12

      const { carStatus } = this.props
      const shouldRender = shouldRenderGroup(carStatus)

      const isIncoming = isStatus(CarStatus.Incoming, carStatus)
      const isActive = isStatus(CarStatus.Active, carStatus)
      const isOutgoing = isStatus(CarStatus.Outgoing, carStatus)

      const active = this.createDataset(
        this.props.active,
        isActive ? width : activeWidth,
        'plannedReturnDate',
        CarStatus.Active,
      )
      const incoming = this.createDataset(
        this.props.incoming,
        isIncoming ? width : endsWidth,
        'deliveryDate',
        CarStatus.Incoming,
      )
      const outgoing = this.createDataset(
        this.props.outgoing,
        isOutgoing ? width : endsWidth,
        'plannedReturnDate',
        CarStatus.Outgoing,
      )

      this.createCars(
        '#active',
        active,
        isActive ? 0 : endsWidth,
        isActive ? width : activeWidth,
      )
      this.createCars('#incoming', incoming, 0, isIncoming ? width : endsWidth)
      //
      this.createCars(
        '#outgoing',
        outgoing,
        isOutgoing ? 0 : width - endsWidth,
        isOutgoing ? width : endsWidth,
      )

      // Incoming
      this.createLine(
        top,
        30,
        isIncoming ? width - 12 : endsWidth,
        colors.blue,
        true,
        shouldRender(CarStatus.Incoming),
        CarStatus.Incoming,
      )
      // Active
      this.createLine(
        top,
        isActive ? 30 : endsWidth,
        isActive ? width - 12 : endActiveX,
        colors.green,
        false,
        shouldRender(CarStatus.Active),
        CarStatus.Active,
      )
      // Outgoing
      this.createLine(
        top,
        isOutgoing ? 30 : endActiveX,
        width - 12,
        colors.red,
        true,
        shouldRender(CarStatus.Outgoing),
        CarStatus.Outgoing,
      )

      const getSize = (status) => (shouldRender(status) ? 6 : 0.1)
      const getColor = (status, fallback) => {
        switch (status) {
          case CarStatus.Incoming:
            return colors.blue
          case CarStatus.Active:
            return colors.green
          case CarStatus.Outgoing:
            return colors.red
          default:
            return fallback
        }
      }
      const getCount = (status) => {
        switch (status) {
          case CarStatus.Incoming:
            return this.props.incoming.length
          case CarStatus.Active:
            return this.props.active.length
          case CarStatus.Outgoing:
            return this.props.outgoing.length
          default:
            return ''
        }
      }
      // Start incoming
      this.createDot(
        top,
        24,
        6,
        getColor(carStatus, colors.blue),
        carStatus === null || !shouldRender(CarStatus.Active),
        0,
      )
      // Start active
      this.createDot(top, endsWidth, getSize(null), colors.green, false, 1)
      // End Incoming Start outgoing
      this.createDot(top, endActiveX, getSize(null), colors.green, false, 2)
      // End outgoing
      this.createDot(
        top,
        width - 8,
        6,
        getColor(carStatus, colors.red),
        carStatus === null || !shouldRender(CarStatus.Active),
        3,
      )

      this.createNumber(
        '#incomingCount',
        endsWidth * 0.5,
        48,
        this.props.incoming.length,
        colors.blue,
        CarStatus.Incoming,
        !carStatus,
        this.props.incoming,
      )

      this.createNumber(
        '#activeCount',
        endActiveX * 0.5 - endsWidth + 24,
        48,
        this.props.active.length,
        colors.green,
        CarStatus.Active,
        !carStatus,
        this.props.active,
      )

      this.createNumber(
        '#outgoingCount',
        endsWidth * 0.5,
        48,
        this.props.outgoing.length,
        colors.red,
        CarStatus.Outgoing,
        !carStatus,
        this.props.outgoing,
      )

      this.createNumber(
        '#currentSelectedCount',
        '50%',
        64,
        getCount(carStatus),
        getColor(carStatus),
        carStatus,
        carStatus !== null,
      )
    }, 100)
  }

  createDataset(data, width, prop = 'plannedReturnDate', status) {
    const { carStatus = undefined } = this.props
    if (carStatus && carStatus !== status) {
      return []
    }
    const maximum = max(data, (car) => car[prop])
    const minimum = min(data, (car) => car[prop])

    const count = data.length

    const maxCount = width / 24
    const spread = count < Math.floor(maxCount)

    const distance = 25
    const now = spread ? 0 : new Date(minimum).getTime()
    const then = spread ? data.length : new Date(maximum).getTime()

    const scale = scaleLinear()
      .domain([then, now])
      .range([distance, width - distance])

    const filtered = data.reduce((acc, current, index) => {
      const planned = spread ? index : new Date(current[prop]).getTime()
      const x = Math.floor(scale(planned))

      const position = Math.floor(x / distance) * distance
      if (!acc.hasOwnProperty(position)) {
        acc[position] = {
          cars: [current],
          links: [],
          ...(current.replaces && {
            links: [current.replaces.registrationNumber],
          }),
          ...(current.replacement && {
            links: [current.replacement.registrationNumber],
          }),
          x: position,
          hasEvents: Math.random() > 0.75,
          status,
        }
      } else {
        acc[position].cars.push(current)
        if (current.replaces) {
          acc[position].links.push(current.replaces.registrationNumber)
        }
        if (current.replacement) {
          acc[position].links.push(current.replacement.budgetNo)
        }
      }
      return acc
    }, {})

    return Object.keys(filtered).map((key) => filtered[key])
  }

  createCars(group, dataset, offsetX, fullWidth, width = 24, height = 24) {
    // selectAll(".car > *").remove()

    const selection = select(group)
      .attr('transform', (d) => `translate(${offsetX}, 0)`)
      .selectAll('.car')
      .data(dataset)

    const self = this

    const car = selection
      .enter()
      .append('svg')
      .attr('class', 'car')
      .attr('viewBox', '0 0 24 24')
      .attr('y', self.props.height / 6)
      .attr('height', height + 36) // magic number. Text fits above now
      .attr('width', width)
      .attr('cursor', 'pointer')
      .attr('id', function (d, i) {
        return 'car_' + d.cars.map((c) => c.registrationNumber).join('-')
      })
      .attr('x', (car) => 0)
      .on('mouseover', function (d, i) {
        select(this)
          .transition()
          .ease(easeCircleOut)
          .duration('200')
          .attr('y', self.props.height / 7)
        const links = selectAll(`svg[id*='${d.links.join('-')}']`)
        if (links) {
          links
            .transition()
            .ease(easeCircleOut)
            .duration('200')
            .attr('y', self.props.height / 7)
        }
      })
      .on('mouseout', function (d, i) {
        select(this)
          .transition()
          .ease(easeBounceOut)
          .duration('500')
          .attr('y', self.props.height / 6)

        const links = selectAll(`svg[id*='${d.links.join('-')}']`)
        if (links) {
          links
            .transition()
            .ease(easeBounceOut)
            .duration('500')
            .attr('y', self.props.height / 6)
        }
      })
      .on('click', function (d) {
        const { cars, status } = d
        const params = createQueryParams({
          cars: cars.map((c) => c.registrationNumber),
          status,
        })
        self.props.history.push(`/cars?${params.toString()}`)
        // self.props.filterByCars({ cars, carStatus: status })
      })
      .append('g')
      .append('path')
      .attr('fill', 'currentColor')
      .attr(
        'd',
        'M8 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm9 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5.04 8.23A7 7 0 0 1 10.94 5H11a8 8 0 0 1 7.9 6.84l.1.16h1a2 2 0 0 1 2 2v3a1 1 0 0 1-1 1h-1a3 3 0 0 0-6 0h-3a3 3 0 0 0-6 0H4a1 1 0 0 1-1-1v-3c0-2.04.77-4.12 2.04-5.77zM16.9 12a6 6 0 0 0-11.82 0h11.82z',
      )
      .select(function () {
        return this.parentNode
      })

    // selection.attr("x", car => Math.max(car.x, 0))

    const scaleDistance = scaleLinear()
      .domain([offsetX, fullWidth])
      .range([100, 800])

    car
      .select(function () {
        return this.parentNode
      })
      .transition()
      .ease(easeBackOut)
      .delay(500)
      .duration((c) => scaleDistance(c.x))
      .attr('x', (c) => Math.max(c.x, 0))

    selection
      .transition()
      .ease(easeBackOut)
      .duration((c) => scaleDistance(c.x))
      .attr('x', (c) => Math.max(c.x, 0))

    const bubble = car
      .append('svg')
      .attr('class', 'infoBubble')
      .attr('viewBox', '0 0 24 24')
      .attr('width', 24)
      .attr('height', 24)
      .attr('y', -18)
      .attr('x', 2)
      .append('path')
      .attr(
        'd',
        'M5 2h14c1.657 0 3 1.343 3 3v12c0 1.657-1.343 3-3 3h-4l-3 3-3-3h-4c-1.657 0-3-1.343-3-3v-12c0-1.657 1.343-3 3-3z',
      )
      .select(function () {
        return this.parentNode
      })
      .append('path')
      .attr(
        'd',
        'M12 22.293l2.793-2.793h4.207c1.381 0 2.5-1.119 2.5-2.5v-12c0-1.381-1.119-2.5-2.5-2.5h-14c-1.381 0-2.5 1.119-2.5 2.5v12c0 1.381 1.119 2.5 2.5 2.5h4.207l2.793 2.793z',
      )
      .attr('fill', '#F8F8F8')
      .attr('stroke', '#D0D0D0')
      .attr('strokeLinejoin', 'square')
      .select(function () {
        return this.parentNode
      })

    bubble
      .append('text')
      .attr('class', 'infoText')
      .text((car) => {
        return `${car.cars.length}${
          car.status === CarStatus.Outgoing && !car.replacement ? '!' : ''
        }`
      })
      .attr('text-anchor', 'middle')
      .attr('y', 16)
      .attr('x', width * 0.5)
      .attr('id', (d, i) => {
        return 'text' + i
      })
      .attr('font-size', `12px`)
      .attr('fill', 'currentColor')
      .attr('pointer-events', 'none')

    selection.select('.infoText').text((car) => {
      return `${car.cars.length}`
    })

    // bubble.attr("opacity", car => (car.cars.length > 1 ? "1" : "0"))
    selection
      .select('.infoBubble')
      .attr('opacity', (car) => (car.cars.length > 1 ? '1' : '0'))

    selection.exit().remove()
  }

  createLine(
    node,
    from,
    to,
    color = 'red',
    dasharray = true,
    shouldRender = true,
    status,
  ) {
    const line = node
      .select('#line-' + status)
      .transition()
      .ease(easeBackOut)
      .delay(200)
      .attr('x1', from)
      .attr('y1', this.props.height / 2.5)
      .attr('x2', to)
      .attr('y2', this.props.height / 2.5)
      .style('stroke', color)
      .style('stroke-width', 5)
      .style('opacity', shouldRender ? 1 : 0)

    if (dasharray) {
      line.style('stroke-dasharray', 1)
    }
  }

  createDot(
    node,
    offsetX,
    size,
    color = 'red',
    transparentCenter = false,
    index = 0,
  ) {
    node
      .select(`#circle-${index}`)
      .transition()
      .ease(easeLinear)
      .delay(200)
      .attr('cx', offsetX)
      .attr('cy', this.props.height / 2.5)
      .attr('r', size)
      .style('fill', transparentCenter ? 'transparent' : color)
      .style('stroke', color)
      .style('stroke-width', size * 0.7)
  }

  createNumber(node, x, size, text, color, status, shouldRender, cars) {
    select(node)
      // .on("click", () => this.props.filterByCars({ carStatus: status, cars }))
      .text(text)
      .attr('text-anchor', 'middle')
      .attr('y', this.props.height / 2)
      .attr('x', x)
      .attr('font-size', `${size}px`)
      .attr('fill', color)
      // .attr("cursor", "pointer")

      .style('opacity', shouldRender ? 1 : 0)
  }

  render() {
    return (
      <div>
        <svg
          width={'100%'}
          height={this.props.height}
          ref={(node) => (this.node = node)}
        >
          <g id={'incoming'}></g>

          <g id={'active'}></g>
          <g id={'outgoing'}></g>

          <g>
            <line
              id="line-incoming"
              x1="30"
              y1="188"
              x2="30"
              y2="188"
              style={{
                stroke: 'rgb(42, 109, 244)',
                strokeWidth: 5,
                strokeDasharray: 1,
              }}
            />
            <line
              id="line-active"
              x1="30"
              y1="188"
              x2="30"
              y2="188"
              style={{
                stroke: 'rgb(42, 109, 244)',
                strokeWidth: 5,
              }}
            />
            <line
              id="line-outgoing"
              x1="30"
              y1="188"
              x2="30"
              y2="188"
              style={{
                stroke: 'rgb(42, 109, 244)',
                strokeWidth: 5,
                strokeDasharray: 1,
              }}
            />
            <circle
              cx="683.4"
              cy="188"
              r="6"
              id={'circle-0'}
              style={{
                fill: 'rgb(102, 205, 115)',
                stroke: 'rgb(102, 205, 115)',
                strokeWidth: 4.2,
              }}
            />
            <circle
              cx="683.4"
              cy="188"
              r="6"
              id={'circle-1'}
              style={{
                fill: 'rgb(102, 205, 115)',
                stroke: 'rgb(102, 205, 115)',
                strokeWidth: 4.2,
              }}
            />
            <circle
              cx="683.4"
              cy="188"
              r="6"
              id={'circle-2'}
              style={{
                fill: 'rgb(102, 205, 115)',
                stroke: 'rgb(102, 205, 115)',
                strokeWidth: 4.2,
              }}
            />
            <circle
              cx="683.4"
              cy="188"
              r="6"
              id={'circle-3'}
              style={{
                fill: 'rgb(102, 205, 115)',
                stroke: 'rgb(102, 205, 115)',
                strokeWidth: 4.2,
              }}
            />
          </g>
        </svg>
      </div>
    )
  }
}

export default CarParkOverview

CarParkOverview.propTypes = {
  active: PropTypes.any,
  cars: PropTypes.array,
  endsRatio: PropTypes.number,
  filterByCars: PropTypes.any,
  height: PropTypes.number,
  incoming: PropTypes.array,
  incomingWidth: PropTypes.number,
  maxCarsSpread: PropTypes.number,
  outgoing: PropTypes.any,
  outgoingMonths: PropTypes.number,
}

CarParkOverview.defaultProps = {
  endsRatio: 0.15,
  height: 200,
  incomingWidth: 150,
  maxCarsSpread: 15,
  outgoingMonths: 6,
}
